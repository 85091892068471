import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  loggedInCode!: string;
  loggedInlanguage: any;
  send_date = new Date()

  @Input() portalTranslate: any
  formattedDate: any;
  constructor(public translate: TranslateService) {
    this.send_date.setMonth(this.send_date.getMonth());
    this.formattedDate = this.send_date.toISOString().slice(0, 4);
  }

  ngOnInit(): void {
    this.loggedInlanguage = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
    if (this.loggedInlanguage.languageCode === 'EN') {
      this.loggedInCode = 'en';
    }
    else if (this.loggedInlanguage.languageCode === 'CN') {
      this.loggedInCode = 'cn';
    }
    else {
      this.loggedInCode = 'en';
    }
    this.translate.addLangs(['en', 'cn']);
    this.translate.setDefaultLang(this.loggedInCode);
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang?.match(/en|cn/) ? browserLang : this.loggedInCode);
  }

}
