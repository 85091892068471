import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '../../../vendor.service';

@Component({
  selector: 'app-supplyon-delivery',
  templateUrl: './supplyon-delivery.component.html',
  styleUrls: ['./supplyon-delivery.component.scss']
})
export class SupplyonDeliveryComponent implements OnInit {
  SCMData: any = [];
  constructor(private _service: VendorService, public translate: TranslateService) { }

  public orderClicked = false;
  public confirmorderClicked = false;
  public confirmDeliveryClicked = false
  public deliveryClicked = false;
  public confirmPartlyClicked = false;
  public confirmOpenClicked = false;
  public confirmDueClicked = false;
  public confirmNextClicked = false;
  public confirmNextValueClicked = false;
  public confirmDueValueClicked = false;
  public SCM: boolean = true;
  public procureToPay: boolean = false;
  public supplyOnDropdown: any;
  public noData = false;
  public singleDataValue: any;
  public orderNewValue: any;
  public newUpdatesValue: any;
  public color: any;
  public imageExist: any;
  public overWrite: any;
  public orderUnconfirmedVaule: any;
  public withOutUpdateValue: any;
  public orderDatedVaule: any;
  public partlyConfirmedVaule: any;
  public orderOpenVaule: any;
  public orderUnconfirmedVauleLink: any;
  public withOutUpdateValueLink: any;
  public orderDatedVauleLink: any;
  public partlyConfirmedVauleLink: any;
  public orderOpenVauleLink: any;
  public orderNewValueLink: any;
  public newUpdatesValueLink: any;
  public overDueASN!: number;
  public dueTodayASN!: number;
  public dueNext7DaysASN!: number;
  public asnPendingVauleLink!: null;
  public asnPendingVaule!: null;
  public asnCreationDueLink: any;
  public overDueAsnLink: any;
  public dueTodayAsnLink: any;
  public dueNext7DaysAsnLink: any;
  public asnCreationDue!: number;
  public newOrderBarValue: any;
  public orderUpdateBarValue: any;
  public orderPartlyConfirmedBarValue: any;
  public orderOpenBarVaule: any;
  public orderDateBarValue: any;
  public dueNext7DaysAsnBar!: number;
  public overDueAsnBar!: number;
  public dueTodayAsnBar!: number;
  public supplyOnData: any;
  // public portalTranslate = false;
  public supplyOnDefaultDL: any;
  public id: any;
  public asnScmButton: any;
  public noDeliveryData!: boolean;
  public asnProcureToPayButton = false;
  @Input() delivery!: boolean;
  @Input() noDelivery!: boolean;
  @Input() deliveryProcureToPay!: boolean;
  @Input() singleArrayValue: { asnDetails: Object, dataPoints: Array<object>, smaId: string }[] = [];
  @Input() dropDownlistData!: Array<Object>;
  @Input() supplyOnDeliveryTranslate!: boolean;
  @Input() portalTranslate!: boolean;

  ngOnChanges() {
    // create header using child_id
    this.portalTranslate = this.supplyOnDeliveryTranslate;
  }

  ngOnInit(): void {

    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }

    this._service.getSupplyOnDataDropdownApi.subscribe(data => {
      let response: any = data
      if (response.length) {
        this.supplyOnDefaultDL = response[0].sma
        this.supplyOnDropdown = response
      }

    })
    this._service.scmApiData.subscribe(data => {
      let response: any = data
      if (Object.keys(response).length !== 0 && response.constructor === Object) {
        this.getOrderData()
      }
    })

  }

  getOrderData() {
    let data = this._service.getSCMData();
    this.SCMData = data
    data.forEach((element: { smaId: any; }) => {
      if (element.smaId == this.supplyOnDefaultDL) {
        this.getDeliverDetails(element)
      }
    });

  }




  getDeliverDetails(supplyonIntegratedData: any) {
    this.singleDataValue = supplyonIntegratedData;
    let asnPending = false;
    let total = false;
    if (this.singleDataValue) {
      if (this.singleDataValue.dataPoints.length !== 0) {
        this.singleDataValue.dataPoints.map((ele: { identifier: string; value: any; link: any; }) => {
          if (ele.identifier === "dueDeliveries_asnStandaloneCreation_next7days") {
            this.dueNext7DaysASN = ele.value;
            this.dueNext7DaysAsnLink = ele.link;
          }
          else if (ele.identifier === "dueDeliveries_asnStandaloneCreation_today") {
            this.dueTodayASN = ele.value;
            this.dueTodayAsnLink = ele.link;
          }
          else if (ele.identifier === "dueDeliveries_asnStandaloneCreation_overdue") {
            this.overDueASN = ele.value;
            this.overDueAsnLink = ele.link;
          }
          else if (ele.identifier === "dueDeliveries_asnStandaloneCreation_total") {
            this.asnCreationDue = ele.value;
            this.asnCreationDueLink = ele.link;
            total = true
          }

          //Advance shipping No will starts here
          else if (ele.identifier === "asn_pending") {
            this.asnPendingVaule = ele.value;
            this.asnPendingVauleLink = ele.link;
            asnPending = true
          }
          //Advance shipping No will ends here

        })
        if (!asnPending) {
          this.asnPendingVaule = null;
          this.asnPendingVauleLink = null;
        }
        if (!total) {
          this.noDeliveryData = true;
          this.noData = true;
        }
      }
      else {
        this.noDeliveryData = true;
      }

      this.asnColors();
    }
    else {
      this.noDeliveryData = true;
      this.asnProcureToPayButton = false;
    }
  }



  public asnColors() {
    this.overDueAsnBar = ((this.overDueASN / this.asnCreationDue) * 100);
    this.overDueAsnBar = (this.overDueAsnBar > 90) ? Math.floor((this.overDueASN / this.asnCreationDue) * 100) : Math.ceil((this.overDueASN / this.asnCreationDue) * 100);
    this.dueTodayAsnBar = ((this.dueTodayASN / this.asnCreationDue) * 100);
    this.overDueAsnBar ? NaN : this.overDueAsnBar = 0;
    this.dueTodayAsnBar = (this.dueTodayAsnBar > 90) ? Math.floor((this.dueTodayASN / this.asnCreationDue) * 100) : Math.ceil((this.dueTodayASN / this.asnCreationDue) * 100);
    this.dueTodayAsnBar ? NaN : this.dueTodayAsnBar = 0;
    this.dueNext7DaysAsnBar = ((this.dueNext7DaysASN / this.asnCreationDue) * 100);
    this.dueNext7DaysAsnBar = (this.dueNext7DaysAsnBar > 90) ? Math.floor((this.dueNext7DaysASN / this.asnCreationDue) * 100) : Math.ceil((this.dueNext7DaysASN / this.asnCreationDue) * 100);
    this.dueNext7DaysAsnBar ? NaN : this.dueNext7DaysAsnBar = 0;
  }



  public supplyOnDropdownList(event: any) {

    let smaList = this.SCMData.map((data: { smaId: any; }) => {
      return data.smaId;
    })

    if (smaList.indexOf(this.supplyOnDefaultDL) === -1) {
      this._service.supplyOn(event);
    } else {
      this.SCMData.map((data: { smaId: any; }) => {
        if (data.smaId == event) {
          this.getDeliverDetails(data)
        }
      })

    }

  }


}
