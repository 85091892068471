import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import {finalize } from 'rxjs/operators';
import {  
  HttpRequest,  
  HttpHandler,  
  HttpEvent,  
  HttpInterceptor  
} from '@angular/common/http';  

@Injectable({
  providedIn: 'root'
})
export class InterceptorService implements HttpInterceptor{

  constructor(public loaderSerivce:LoaderService) { }
  intercept(req:HttpRequest<any>,next:HttpHandler):Observable<HttpEvent<any>>{
    // throw new Error ('method not implemented');
    this.loaderSerivce.isLoading.next(true);
    console.log("san22", this.loaderSerivce.isLoading);
    return next.handle(req).pipe(
      finalize(
        ()=>{
          this.loaderSerivce.isLoading.next(true);
          console.log("san22", this.loaderSerivce.isLoading);
        }
      )
    )
  }
}
