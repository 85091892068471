<div class="contact-support">
  <div class="contact-table">{{ 'home.queries' | translate }}</div>
  <!-- <a (click)="openContactForm()" class="link" style="padding-left: 10px;cursor: pointer;">{{ 'home.contactUs' | translate }}</a> -->
  <div style="overflow-x:hidden;">
    <!-- <table>
        <tr>
          <th>{{ 'home.Application' | translate }}</th>
          <th>{{ 'home.Module' | translate }}</th>
          <th>{{ 'home.SupportEmailAddress' | translate }}</th>
        </tr>
        <tr>
          <td>{{ 'home.generalVal' | translate }}</td>
          <td> {{ 'home.all' | translate }} </td>
          <td> <a class="link" href="mailto:SupplierPortalSupport@se.com" target="_blank">SupplierPortalSupport@se.com</a> </td>
        </tr>
        <tr>
          <td>{{ 'home.a' | translate }}</td>
          <td *ngIf="portalTranslate">
            <li *ngFor = "let data of module_ch"> {{data}}</li>       
          </td>
          <td *ngIf="!portalTranslate">
            <li *ngFor = "let data of module"> {{data}}</li>       
          </td>
          <td rowspan="2"><a class="link" href="mailto:support.ssp-srm@synertrade.com" target="_blank">support.ssp-srm@synertrade.com</a></td>
        </tr>
        <tr>
          <td>{{ 'home.b' | translate }}</td>
          <td *ngIf="portalTranslate">
            <li *ngFor = "let data of srmModule_ch">{{data}}</li></td>
            <td *ngIf="!portalTranslate">
              <li *ngFor = "let data of srmModule">{{data}}</li></td>
        </tr>
        <tr>
          <td>{{ 'home.ProcuretoPay' | translate }}</td>
          <td  *ngIf="!portalTranslate"> <li *ngFor = "let data of p2pModule">{{data}}</li></td>
          <td  *ngIf="portalTranslate"> <li *ngFor = "let data of p2pModule_ch">{{data}}</li></td>
          <td><a class="link" href="mailto:support@coupa.com" target="_blank">support@coupa.com</a></td>
        </tr>
        <tr>
          <td>{{ 'home.SCM' | translate }}</td>
          <td  *ngIf="!portalTranslate"> <li *ngFor = "let data of scmModule">{{data}}</li></td>
          <td  *ngIf="portalTranslate"> <li *ngFor = "let data of scmModule_ch">{{data}}</li></td>
          <td><a class="link" href="mailto:customer-support@supplyon.com" target="_blank">customer-support@supplyon.com</a></td>
        </tr>
        </table> -->
    <app-contact-form [message]="message" (closeTicketDailog)="handleDialogClose($event)"></app-contact-form>
  </div>
</div>
<div *ngIf="openForm">
  <app-contact-form [isOpen]="openForm" [portalTranslate]="portalTranslate"
    (canCloseDialog)="canCloseDialog($event)"></app-contact-form>
</div>