<div style="max-width:1220px !important ; width: 100%;
   margin: 0 auto;
   z-index: 99;
   padding-top: 2% !important;
   padding-bottom: 2% !important;
   background: #fafafa;" class="surround22 admin-view-sticky">
    <div class="chip_nav_width" id="chip-nav-btns" style="width: 80%;margin: auto;">
        <ng-container>
            <div class="row gx-0">
                <div class="col-lg-2">
                    <button (click)="scroll($event, 'scrollisHome')" id="isHome"
                        class="active mobileHeader mobileSubHeaderWidth">
                        <span style="text-align: center">
                            <se-icon size="small" style="margin-left: -10%;">home</se-icon>
                            {{ "home.home" | translate }}
                        </span>
                    </button>
                </div>
                <div class="col-lg-2">
                    <button (click)="scroll($event, 'scrollisPortal')" id="isPortal"
                        class="mobileHeader mobileSubHeaderWidth">
                        <span style="text-align: center">
                            <se-icon size="small" style="margin-left: -10%;">action_generate_labels</se-icon>
                            {{
                            "home.PortalPrinciple"
                            | translate }}
                        </span>
                    </button>
                </div>
                <div class="col-lg-2">
                    <button (click)="scroll($event, 'scrollisContact')" id="isContact"
                        class="mobileHeader mobileSubHeaderWidth">
                        <se-icon size="small" style="margin-left: -10%;">headset</se-icon>
                        {{ "home.ContactSupport" |
                        translate }}
                    </button>
                </div>
                <div class="col-lg-2">
                    <button (click)="scroll($event, 'scrollisTraffic')" id="isTraffic"
                        class="mobileHeader mobileSubHeaderWidth">
                        {{ "home.trafficMonitoring" | translate }}
                    </button>
                </div>
                <div class="col-lg-2">
                    <button (click)="scroll($event, 'scrollisFeedback')" id="isFeedback"
                        class="mobileHeader mobileSubHeaderWidth">
                        {{ "home.feedback" | translate }}
                    </button>
                </div>
            </div>
        </ng-container>
    </div>
    <!-- Mobile Screen Dropdown  (valueChange)="valueChange($event)" -->
    <div id="dropdowncontainer" class="mobileAdmin m-4 d-md-none d-block">
        <div id="menudropdown">
            <kendo-dropdownlist (selectionChange)="selectionChange($event)" [data]="listItemsEnglish" textField="text"
                valueField="value" [(ngModel)]="selectedItem" style="width: 100%; font-weight: bold;">
            </kendo-dropdownlist>
        </div>
    </div>
</div>
<div style="max-width:1220px !important ; margin: 0 auto;">
    <div id="scrollisHome"></div>
    <app-admin-hometable [adminHomeTableTranslate]="portalTranslate"></app-admin-hometable>
    <div style="height:10px !important"></div>
    <!-- For Portal principle -->
    <div style="width: 90%; margin: auto;">
        <form name="newForm" [formGroup]="newForm">
            <div class="form-group" style="text-align: left;margin-left:0.5%">
                <label for="address">
                    <h4 style="color:#3dcd58;font-weight: 400;">{{ "home.PortalPrinciple" | translate }} </h4>
                </label>
                <div id="scrollisPortal"></div>
                <textarea *ngIf="!portalTranslate" [innerHTML]="getInnerHTML(portalpDataEN)" formControlName="address"
                    style="height:150px;" class="form-control"></textarea>
                <textarea *ngIf="portalTranslate" [innerHTML]="getInnerHTML(portalpDataCH)" formControlName="address1"
                    style="height:150px;" class="form-control"></textarea>
            </div>
            <div class="form-group" style="text-align: right;">
                <button class="btn saveButton" (click)="update()">{{ "home.save" | translate }}</button>
            </div>
        </form>
    </div>
    <!-- For file upload  -->
    <!-- <div class="container" style="padding-right: 2% !important;margin-top: 5.5% !important;">
      <form [formGroup]="myForm" (ngSubmit)="imageSubmit()" style="margin-left: -3% !important">
        <div class="form-group" style="text-align: left;margin-left:0.5%">
        </div>
        <div class="form-group" style="text-align: left;">
          <label for="file">
            <h4 style="color:#3dcd58"> {{ "home.fileUpload" | translate }} </h4>  
          </label>
      
          <input formControlName="file" id="file" type="file" class="form-control" draggable="false"
            (change)="onFileChange($event)" style="height:47px">
           <div *ngIf="fileNameEn && !portalTranslate" class="alert alert-success" style="margin-top:10px;">
            Image name
             <span style="font-weight:bold;">"{{fileNameEn}}"</span>
              exists
          </div>       
           <div *ngIf="fileNameCh && portalTranslate" class="alert alert-success" style="margin-top:10px;">图片名称 <span style="font-weight:bold;">"{{fileNameCh}}"</span> 存在</div>
         
           <div *ngIf="fileSize> 20480" class="alert alert-success">
            <div> Maximum File size limit is 20 MB.</div>
          </div>
          <div *ngIf="fileExtValidation===true" class="alert alert-success">
            <div> File type is not supported, Please select "jpg","jpeg","png" file type</div>
          </div>
        </div>
        <div>
          <kendo-dialog title="Success Message" *ngIf="opened" (close)="close()" >
            <p  class="popupText">{{ "home.imageuploadSuccess" | translate }}</p>          
            <kendo-dialog-actions>
              <button kendoButton (click)="close()" themeColor="primary">ok</button>
            </kendo-dialog-actions>
          </kendo-dialog>
          <kendo-dialog title="Success Message" *ngIf="homeEditSave || editPPsave" (close)="close()" >
            <p  class="popupText">{{ "home.dataUploadSuccess" | translate }}</p>          
            <kendo-dialog-actions>
              <button kendoButton (click)="close()" themeColor="primary">ok</button>
            </kendo-dialog-actions>
          </kendo-dialog>
          <kendo-dialog title="Success Message" *ngIf="editPPsave" (close)="close()" >
            <p class="popupText">{{ "home.portalDataSuccessMsg" | translate }}</p>          
            <kendo-dialog-actions>
              <button kendoButton (click)="close()" themeColor="primary">ok</button>
            </kendo-dialog-actions>
          </kendo-dialog>
          <kendo-dialog title="Success Message" *ngIf="cspopupmsg" (close)="close()" >
            <p class="popupText">{{ "home.contactSupportDataSuccessMsg" | translate }}</p>
            <kendo-dialog-actions>
              <button kendoButton (click)="close()" themeColor="primary">ok</button>
            </kendo-dialog-actions>
          </kendo-dialog>
          
          <kendo-dialog title="Error Message" *ngIf="emptyImage && !homeEditSave" (close)="close()">
            <p class="popupText">{{ "home.selectImage" | translate }}</p>
            <kendo-dialog-actions>
              <button kendoButton (click)="close()" themeColor="primary">ok</button>
            </kendo-dialog-actions>
          </kendo-dialog>
          <kendo-dialog title="Info Message" *ngIf="imageExist" (close)="close()">
            <p class="popupText">Image already exist. If you want to overwrite click OK</p>
            <kendo-dialog-actions>
              <button kendoButton (click)="overWriteImage()" themeColor="primary">Ok</button>
              <button kendoButton (click)="close()" themeColor="primary">Cancel</button>            
            </kendo-dialog-actions>
          </kendo-dialog>
          </div>
      
      
        <div class="form-group" style="text-align: right;">        
          <button *ngIf="!portalTranslate" class="btn saveButton" 
            [disabled]="fileSize>20480 || fileExtValidation===true" type="submit">{{ "home.upload" | translate
            }}</button>
          <button *ngIf="portalTranslate" class="btn saveButton"
            type="submit">上传</button>
        </div>
      </form>
      </div> -->
    <!-- For contact support -->
    <div style="width: 90%; margin: auto;">
        <form name="contactForm" [formGroup]="contactForm">
            <div class="form-group" style="text-align: left;margin-left:0.5%">
                <label for="contact">
                    <h4 style="color:#3dcd58; font-weight: 400;">{{ "home.ContactSupport" | translate }} </h4>
                </label>
                <div id="scrollisContact"></div>
                <textarea *ngIf="!portalTranslate" [innerHTML]="getInnerHTML(contactNewDataEn)" style="height:150px;"
                    class="form-control" formControlName="contact"></textarea>
                <textarea *ngIf="portalTranslate" [innerHTML]="getInnerHTML(contactNewDataCh)" style="height:150px;"
                    class="form-control" formControlName="contact1"></textarea>
            </div>
            <div class="form-group" style="    text-align: right;">
                <button *ngIf="!portalTranslate" class="btn saveButton" (click)="onSubmit()">Save</button>
                <button *ngIf="portalTranslate" class="btn saveButton" (click)="update()">节省</button>
            </div>
        </form>
    </div>
    <app-contact-support-table [portalTranslate]=portalTranslate></app-contact-support-table>
    <!-- For traffic monitoring -->
    <div>
        <div id="scrollisTraffic"></div>
        <app-traffic-monitoring [trafficMonitoringTranslate]="portalTranslate"></app-traffic-monitoring>
    </div>
    <!-- For feedback -->
    <div style="width: 90%; margin: 2% auto;">
        <div style="
         background-color: #fafafa;
         color: #2dcd58;
         font-size: 22px;
         padding-bottom: 2.5% !important;
         font-family: Nunito;
         " id="scrollisFeedback">
            <span> {{ "home.FeedbackRating" | translate }}</span>
        </div>
        <div style="width: 100%; margin: auto;">
            <div style="display: flex; flex-direction:row; margin-bottom:0.5%">
                <div style="width:40px">5 <span class="fa fa-star" style="font-size: 18px;color:#3dcd58"></span> </div>
                <div class="progress" style="height:10px; margin-top: 6px;width: 250px; margin-right: 10px;">
                    <div style="width:200px" class="progress-bar" role="progressbar" aria-valuenow="star1"
                        aria-valuemin="20" aria-valuemax="100" [style.width.%]="star5color"
                        style="background-color:#ffbf00;">
                    </div>
                </div>
                <div>{{star5}}</div>
            </div>
            <div style="display: flex; flex-direction:row;margin-bottom:0.5%">
                <div style="width:40px">4 <span class="fa fa-star" style="font-size: 18px;color:#3dcd58"></span> </div>
                <div class="progress" style="height:10px; margin-top: 6px;width: 250px; margin-right: 10px;">
                    <div style="width:200px" class="progress-bar" role="progressbar" aria-valuenow="star1"
                        aria-valuemin="20" aria-valuemax="100" [style.width.%]="star4color"
                        style="background-color:#ffbf00">
                    </div>
                </div>
                <div>{{star4}}</div>
            </div>
            <div style="display: flex; flex-direction:row;margin-bottom:0.5%">
                <div style="width:40px">3 <span class="fa fa-star" style="font-size: 18px;color:#3dcd58"></span> </div>
                <div class="progress" style="height:10px; margin-top: 6px;width: 250px; margin-right: 10px;">
                    <div style="width:200px" class="progress-bar" role="progressbar" aria-valuenow="star1"
                        aria-valuemin="20" aria-valuemax="100" [style.width.%]="star3color"
                        style="background-color:#ffbf00;">
                    </div>
                </div>
                <div>{{star3}}</div>
            </div>
            <div style="display: flex; flex-direction:row;margin-bottom:0.5%">
                <div style="width:40px">2 <span class="fa fa-star" style="font-size: 18px;color:#3dcd58"></span> </div>
                <div class="progress" style="height:10px; margin-top: 6px;width: 250px; margin-right: 10px;">
                    <div style="width:200px" class="progress-bar" role="progressbar" aria-valuenow="star1"
                        aria-valuemin="20" aria-valuemax="100" [style.width.%]="star2color"
                        style="background-color:#ffbf00;">
                    </div>
                </div>
                <div>{{star2}}</div>
            </div>
            <div style="display: flex; flex-direction:row;margin-bottom:0.5%">
                <div style="width:40px">1 <span class="fa fa-star" style="font-size: 18px;color:#3dcd58"></span> </div>
                <div class="progress" style="height:10px; margin-top: 6px;width: 250px; margin-right: 10px;">
                    <div style="width: 200px;" class="progress-bar" role="progressbar" aria-valuenow="star1"
                        aria-valuemin="20" aria-valuemax="100" [style.width.%]="star1color"
                        style="background-color:#ffbf00;">
                    </div>
                </div>
                <div>{{star1}}</div>
            </div>
        </div>
    </div>
    <div style="width: 90%; margin: 30px auto;">
        <a href="{{feedbackdownloadData}}">
            <div style="border:2px solid #3dcd58; color:#3dcd58; display: inline-block; margin-bottom: 80px">
                <se-icon size="medium" style="font-size: 28px;padding-left: 7px;margin-top: 8px;
            font-weight: 400;">action_download_stroke</se-icon>
                <span style="font-size:0.85rem;font-weight: bold;vertical-align: super; padding-right: 7px;">
                    <span *ngIf="!portalTranslate">
                        Download Feedback and Comments(.xlsx)</span>
                    <span *ngIf="portalTranslate">
                        下载反馈和评论(.xlsx)</span>
                </span>
            </div>
        </a>
    </div>
</div>