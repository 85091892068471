import { Component, Input, OnChanges, OnInit, SimpleChanges, TemplateRef, ViewEncapsulation, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '../vendor.service';
import { NgbCarouselConfig, NgbModal, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';

@Component({
  selector: 'app-online-repository',
  templateUrl: './online-repository.component.html',
  styleUrls: ['./online-repository.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbCarouselConfig]
})
export class OnlineRepositoryComponent implements OnInit, OnChanges {

  @Input() portalTranslate: any;
  getRepoList: any;
  repoURL: any;
  key: any;
  showDafaults = true;
  selectedTemplate = '';
  selectedView: string = 'default';
  preSigned: any;
  ppVideo: any;
  public contractClicked = false;
  public companyClicked = false;
  public eSourcingClicked = false;
  public supplierQualificationClicked = false;
  public actionPlanClicked = false;
  public eSurveyClicked = false;
  active = 1;

  private modalService = inject(NgbModal);

  // keyValue: [any, any] = key.split("/");
  // repoCategory: any = keyValue[0];
  // repoFileName: any = keyValue[1];

  images = ['700.png', '800.png', '900.png'].map((n) => `/assets/images/sample/repo/${n}`);


  constructor(public translate: TranslateService, public _service: VendorService, config: NgbCarouselConfig, private router: Router) {
    // customize default values of carousels used by this component tree
    config.interval = 2000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = true;
  }

  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  ngOnInit(): void {
    this._service.getRepoData();
    this._service.GetImageAPI.subscribe((data) => {
      if (data) {
        this.preSigned = JSON.parse(sessionStorage.getItem('GetImage') || '{}');
        this.ppVideo = this.preSigned.URL_VDO;
      }
    });

  }

  getInnerHTML(val: string) {
    return val.replace(/\r?\n|\r/g, '<br/>');
  }

  onOpenUrl(url: string) {
    window.open(url);
  }

  // onKnldgClick(e: any) {
  //   this.showDafaults = !this.showDafaults;

  // }

  // communicationClick(e: any) {

  // }

  openVerticallyCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  onSspSrmClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'ssp-srm';
  }

  onSspScmClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'ssp-scm';
  }

  onI2PClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'i2p';
  }

  onCoinnovationClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'co-innovation';
  }

  onImportantRepClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'important-rep';
  }


}
