import { Component, inject, TemplateRef } from '@angular/core';
import { VendorService } from '../../vendor.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

interface IRepoFileList {
  key: string;
  lastModified: string;
  preSignedURL: string;
  size: number;
}

@Component({
  selector: 'app-i2p-repo',
  templateUrl: './i2p-repo.component.html',
  styleUrl: './i2p-repo.component.scss'
})
export class I2pRepoComponent {

  getRepoList: IRepoFileList[] = [];
  filteredI2PList: Observable<IRepoFileList[]> = new BehaviorSubject<IRepoFileList[]>([]);
  selectedTemplate = '';
  showDafaults = true;
  showFileUpload = false;
  selectedFile: File | null = null;
  uploadResult: any;
  showDeleteButton = true;
  showUploadButton = true;
  showPromptText = true;
  showSuccessMessage = false;
  showUploadMessage = false;
  loadingbutton = false;

  private modalService = inject(NgbModal);

  constructor(public _service: VendorService) { }

  ngOnInit(): void {
    this._service.getRepoData().subscribe((data: any) => {
      this.getRepoList = data;
      this.filterI2PData();
    })

    this._service.IdmsInfo.subscribe(data => {
      if (data) {
        this.showFileUpload = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}').persona === 'EMP';
      }
    });
  }

  openVerticallyCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true, size: 'md' });
  }

  openModalVerticallyCenter(deleteFile: TemplateRef<any>) {
    this.modalService.open(deleteFile, { centered: true, size: 'md' });
  }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];

    if (this.selectedFile) {
      if (!this.fileSizeValidator(this.selectedFile.size)) {
        this.showError("File size exceeds the 10MB limit.");
        this.resetFileInput();
        return;
      }

      if (!this.fileTypeValidator(this.selectedFile.name)) {
        this.showError("Invalid file type. Allowed types are PDF and MP4");
        this.resetFileInput();
        return;
      }
    }
  }

  fileSizeValidator(fileSize: number): boolean {
    const maxFileSize = 10 * 1024 * 1024;
    return fileSize <= maxFileSize;
  }

  fileTypeValidator(fileName: string): boolean {
    const allowedExtensions = ['.pdf', '.mp4'];
    const fileExtension = fileName.toLowerCase().slice((fileName.lastIndexOf(".") - 1 >>> 0) + 2);
    return allowedExtensions.includes('.' + fileExtension);
  }

  showError(message: string) {
    alert(message);
  }

  uploadFile() {
    if (this.selectedFile) {
      this.loadingbutton = true;
      const actionType = "uploadRepoFile";
      const folderName = "UserGuides/I2P/";
      const fileSize = this.selectedFile.size;
      this._service.setRepoFileUpload(actionType, this.selectedFile, folderName, fileSize);

      // Reset input and show success message after upload
      this._service.repoFileUploadResult.subscribe(result => {
        this.resetFileInput();
        this.uploadResult = result;
      });

      setTimeout(() => {
        this.showUploadButton = false;
        this.showUploadMessage = true;
        setTimeout(() => {
          this.showUploadButton = true;
          this.showUploadMessage = false;
          this.loadingbutton = false;
        }, 3000);
      }, 8000);
    }
  }

  deleteRepoFile(key: string) {
    this.loadingbutton = true;
    this._service.deleteRepofile(key);
    setTimeout(() => {
      this.showDeleteButton = false;
      this.showPromptText = false;
      this.showSuccessMessage = true;
      this.loadingbutton = false;
      // Automatically hide success message and reset to default view after 3 seconds
      setTimeout(() => {
        this.showSuccessMessage = false;
        this.showDeleteButton = true;
        this.showPromptText = true;
        this.loadingbutton = false;
      }, 3000);
    }, 4000);
  }

  resetFileInput() {
    const fileInput = document.querySelector('input[type="file"]') as HTMLInputElement;
    if (fileInput) {
      fileInput.value = '';
    }
    this.selectedFile = null;
  }

  downloadFile(repo: IRepoFileList) {
    // Create a new Blob object from the preSignedURL
    fetch(repo.preSignedURL)
      .then(response => response.blob())
      .then(blob => {
        // Create a temporary URL for the Blob
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download
          = repo.key.split('UserGuides/I2P/')[1];
        document.body.appendChild(a);
        a.click();

        // Clean up the temporary URL
        window.URL.revokeObjectURL(url);
      })
  }

  getFileTypeIcon(fileType: IRepoFileList): string {
    const fileExtension = fileType.key.split('.').pop()?.toLowerCase();
    if (fileExtension === 'pdf') {
      return "../assets/images/sample/icon-pdf.webp";
    } else if (fileExtension === 'mp4') {
      return "../assets/images/sample/icon-mp4.webp";
    } else {
      // Default image or handle other file types as needed
      return "../assets/images/sample/1180x787.webp";
    }
  }


  filterI2PData() {
    const filteredData = this.getRepoList.filter(item => item.key.includes('UserGuides/I2P/'));
    (this.filteredI2PList as BehaviorSubject<IRepoFileList[]>).next(filteredData);
  }

  getInnerHTML(val: string) {
    return val.replace(/\r?\n|\r/g, '<br/>');
  }

  onOpenUrl(url: string) {
    window.open(url);
  }

  onRepositoryClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'repository';
  }

}
