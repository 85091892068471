import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { VendorService } from '../../../vendor.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-contract',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent implements OnInit, OnChanges {
  tooltipGridValue: any;
  public pageSize = 10;
  public skip = 0;

  @Input() portalTranslate: any;

  public sort: SortDescriptor[] = [
    {
      field: 'End_date',
      dir: 'asc'
    }
  ];
  tooltipGridContractValue: any;
  Contracts!: string;
  @Output() canCloseDialog = new EventEmitter;
  constructor(private _service: VendorService, public translate: TranslateService) { }

  ngOnInit(): void {
    this._service.contractData.subscribe(data => {
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        let contractList: any = data
        this.tooltipGridContractValue = contractList.getContractData.contractList;
      }
    })
    this.Contracts = environment.Contracts;
  }


  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  onContractcancelClick() {
    this.canCloseDialog.emit(false)
  }

  public onPageChange(state: any): void {
    this.pageSize = state.take;
  }

}
