<div *ngIf="data?.length">
    <kendo-chart [transitions]="false" style="margin-top: 8%;">
        <kendo-chart-title text="Region Wise chart" font="20pt bold">
        </kendo-chart-title>
        <kendo-chart-legend position='bottom' orientation='horizontal'></kendo-chart-legend>
        <kendo-chart-series>
            <kendo-chart-series-item type="pie" [data]="data" categoryField="region" field="loginCount" [autoFit]="true"
                [colorField]="'color'">
                <kendo-chart-series-item-labels position="outsideEnd" [content]="labelContent">
                </kendo-chart-series-item-labels>
            </kendo-chart-series-item>
        </kendo-chart-series>
    </kendo-chart>
</div>