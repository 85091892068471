import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { VendorService } from '../../vendor.service';
import { environment } from '../../../environments/environment';
import { FormGroup, FormControl, FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-feedback-rating',
  templateUrl: './feedback-rating.component.html',
  styleUrls: ['./feedback-rating.component.scss']
})
export class FeedbackRatingComponent implements OnInit, OnChanges {

  constructor(private fb: FormBuilder, public translate: TranslateService, public router: Router, private _service: VendorService) {
    this.feedbackgroupForm = this.fb.group({

      STC: [{ value: '', disabled: this.checkIfNoneSelected },],
      SRM: [{ value: '', disabled: this.checkIfNoneSelected },],
      PTP: [{ value: '', disabled: this.checkIfNoneSelected },],
      SCM: [{ value: '', disabled: this.checkIfNoneSelected },],
      PUE: [{ value: '', disabled: this.checkIfNoneSelected },],
      I2P: [{ value: '', disabled: this.checkIfNoneSelected },],
      OP: [{ value: '', disabled: this.checkIfNoneSelected },],
      KH: [{ value: '', disabled: this.checkIfNoneSelected },],
      None: [{ value: '', disabled: this.checkIfNoneSelected },],
      // None: [{ value: '', disabled: this.checkIfOthersAreSelected },],
      comment: ['',]
    });

  }

  ngOnChanges(): void {
    this.feedbackwindowClose = this.feedbackwindowValue;
  }

  public starbackroundColor1 = false;
  public starbackroundColor2 = false;
  public starbackroundColor3 = false;
  public starbackroundColor4 = false;
  public starbackroundColor5 = false;
  public starRatingScreen = true;
  public checkboxScreen = false;
  public textareaScreen = false;
  public ratingSuccessScreen = false;
  public feedbackwindowClose = true;
  public firstCheckBox: any;
  public feedbackgroupForm: FormGroup;
  public feedbackForm: any;
  public CommentLength = "";
  public logoutURL!: string;
  public nextButton = true;
  public feedbackStarForm: any;
  public feedbackcheckboxForm!: boolean;
  public showstarsuccessmessage = false;
  checkIfOthersAreSelected = false;
  checkIfNoneSelected = false;
  categoryNextButton = true;
  @Input() feedbackwindowValue!: boolean;
  @Input() logoutlinkClicked!: boolean;
  @Output() closedDialog = new EventEmitter()

  commentForm = new FormGroup({
    comment: new FormControl(""),
  })



  ngOnInit(): void {
    this.logoutURL = environment.logoutURL;
    this.feedbackwindowClose = this.feedbackwindowValue;
    // this.CommentLength;
  }

  get f() {
    return this.feedbackgroupForm.controls;
  }


  checkSelected(e: any, item: any) {
    if (item === 'None' && e.target.checked) {
      this.checkIfNoneSelected = true
      this.feedbackgroupForm.controls['STC'].disable()
      this.feedbackgroupForm.controls['SRM'].disable()
      this.feedbackgroupForm.controls['PTP'].disable()
      this.feedbackgroupForm.controls['SCM'].disable()
      this.feedbackgroupForm.controls['PUE'].disable()
      this.feedbackgroupForm.controls['I2P'].disable()
      this.feedbackgroupForm.controls['OP'].disable()
      this.feedbackgroupForm.controls['KH'].disable()
    } else if (item === 'None' && e.target.checked === false) {
      this.checkIfNoneSelected = false
      this.feedbackgroupForm.controls['STC'].enable()
      this.feedbackgroupForm.controls['SRM'].enable()
      this.feedbackgroupForm.controls['PTP'].enable()
      this.feedbackgroupForm.controls['SCM'].enable()
      this.feedbackgroupForm.controls['PUE'].enable()
      this.feedbackgroupForm.controls['I2P'].enable()
      this.feedbackgroupForm.controls['OP'].enable()
      this.feedbackgroupForm.controls['KH'].enable()
    } else if (item !== 'None' && e.target.checked) {
      this.checkIfOthersAreSelected = true;
      this.feedbackgroupForm.controls['None'].disable()
    } else if (item !== 'None' && e.target.checked === false) {
      // Comment Below code
      // this.checkIfOthersAreSelected = false;

      if (!this.feedbackgroupForm.controls['STC'].value && !this.feedbackgroupForm.controls['SRM'].value &&
        !this.feedbackgroupForm.controls['PTP'].value && !this.feedbackgroupForm.controls['SCM'].value &&
        !this.feedbackgroupForm.controls['PUE'].value && !this.feedbackgroupForm.controls['I2P'].value &&
        !this.feedbackgroupForm.controls['OP'].value && !this.feedbackgroupForm.controls['KH'].value) {
        this.feedbackgroupForm.controls['None'].enable()
      }
    }
    this.categoryNextButton = !(this.feedbackgroupForm.controls['STC'].value || this.feedbackgroupForm.controls['SRM'].value ||
      this.feedbackgroupForm.controls['PTP'].value || this.feedbackgroupForm.controls['SCM'].value || this.feedbackgroupForm.controls['PUE'].value ||
      this.feedbackgroupForm.controls['I2P'].value || this.feedbackgroupForm.controls['OP'].value || this.feedbackgroupForm.controls['KH'].value || this.feedbackgroupForm.controls['None'].value)
  }

  // checkSelected(e: any, item: any) {
  //   if (item === 'None' && e.target.checked) {
  //     this.checkIfNoneSelected = true
  //     this.feedbackgroupForm.controls['STC'].disable()
  //     this.feedbackgroupForm.controls['SRM'].disable()
  //     this.feedbackgroupForm.controls['PTP'].disable()
  //     this.feedbackgroupForm.controls['SCM'].disable()
  //     this.feedbackgroupForm.controls['PUE'].disable()
  //     this.feedbackgroupForm.controls['I2P'].disable()
  //   } else if (item === 'STC' && e.target.checked) {
  //     this.checkIfNoneSelected = true
  //     this.feedbackgroupForm.controls['SRM'].disable()
  //     this.feedbackgroupForm.controls['PTP'].disable()
  //     this.feedbackgroupForm.controls['SCM'].disable()
  //     this.feedbackgroupForm.controls['PUE'].disable()
  //     this.feedbackgroupForm.controls['I2P'].disable()
  //     this.feedbackgroupForm.controls['None'].disable()
  //   } else if (item === 'SRM' && e.target.checked) {
  //     this.checkIfNoneSelected = true
  //     this.feedbackgroupForm.controls['STC'].disable()
  //     this.feedbackgroupForm.controls['PTP'].disable()
  //     this.feedbackgroupForm.controls['SCM'].disable()
  //     this.feedbackgroupForm.controls['PUE'].disable()
  //     this.feedbackgroupForm.controls['I2P'].disable()
  //     this.feedbackgroupForm.controls['None'].disable()
  //   } else if (item === 'PTP' && e.target.checked) {
  //     this.checkIfNoneSelected = true
  //     this.feedbackgroupForm.controls['STC'].disable()
  //     this.feedbackgroupForm.controls['SRM'].disable()
  //     this.feedbackgroupForm.controls['SCM'].disable()
  //     this.feedbackgroupForm.controls['PUE'].disable()
  //     this.feedbackgroupForm.controls['I2P'].disable()
  //     this.feedbackgroupForm.controls['None'].disable()
  //   } else if (item === 'SCM' && e.target.checked) {
  //     this.checkIfNoneSelected = true
  //     this.feedbackgroupForm.controls['STC'].disable()
  //     this.feedbackgroupForm.controls['SRM'].disable()
  //     this.feedbackgroupForm.controls['PTP'].disable()
  //     this.feedbackgroupForm.controls['PUE'].disable()
  //     this.feedbackgroupForm.controls['I2P'].disable()
  //     this.feedbackgroupForm.controls['None'].disable()
  //   } else if (item === 'PUE' && e.target.checked) {
  //     this.checkIfNoneSelected = true
  //     this.feedbackgroupForm.controls['STC'].disable()
  //     this.feedbackgroupForm.controls['SRM'].disable()
  //     this.feedbackgroupForm.controls['PTP'].disable()
  //     this.feedbackgroupForm.controls['SCM'].disable()
  //     this.feedbackgroupForm.controls['I2P'].disable()
  //     this.feedbackgroupForm.controls['None'].disable()
  //   } else if (item === 'I2P' && e.target.checked) {
  //     this.checkIfNoneSelected = true
  //     this.feedbackgroupForm.controls['STC'].disable()
  //     this.feedbackgroupForm.controls['SRM'].disable()
  //     this.feedbackgroupForm.controls['PTP'].disable()
  //     this.feedbackgroupForm.controls['SCM'].disable()
  //     this.feedbackgroupForm.controls['PUE'].disable()
  //     this.feedbackgroupForm.controls['None'].disable()
  //   } else if (item === 'None' || item === 'STC' ||
  //     item === 'SRM' || item === 'PTP' || item === 'SCM' ||
  //     item === 'PUE' || item === 'I2P' && e.target.checked === false) {
  //     this.checkIfNoneSelected = false
  //     this.feedbackgroupForm.controls['STC'].enable()
  //     this.feedbackgroupForm.controls['SRM'].enable()
  //     this.feedbackgroupForm.controls['PTP'].enable()
  //     this.feedbackgroupForm.controls['SCM'].enable()
  //     this.feedbackgroupForm.controls['PUE'].enable()
  //     this.feedbackgroupForm.controls['I2P'].enable()
  //     this.feedbackgroupForm.controls['None'].enable()
  //   }

  //   this.categoryNextButton = !(this.feedbackgroupForm.controls['STC'].value || this.feedbackgroupForm.controls['SRM'].value ||
  //     this.feedbackgroupForm.controls['PTP'].value || this.feedbackgroupForm.controls['SCM'].value || this.feedbackgroupForm.controls['PUE'].value ||
  //     this.feedbackgroupForm.controls['I2P'].value || this.feedbackgroupForm.controls['None'].value)
  // }

  public starclick1() {
    this.starbackroundColor1 = true;
    this._service.getStarRatingData(1);
    this.nextButton = false;
  }
  public starclick2() {
    this.starbackroundColor1 = true;
    this.starbackroundColor2 = true;
    this._service.getStarRatingData(2);
    this.nextButton = false;
  }
  public starclick3() {
    this.starbackroundColor1 = true;
    this.starbackroundColor2 = true;
    this.starbackroundColor3 = true;
    this._service.getStarRatingData(3);
    this.nextButton = false;
  }
  public starclick4() {
    this.starbackroundColor1 = true;
    this.starbackroundColor2 = true;
    this.starbackroundColor3 = true;
    this.starbackroundColor4 = true;
    this._service.getStarRatingData(4);
    this.nextButton = false;
  }
  public starclick5() {
    this.starbackroundColor1 = true;
    this.starbackroundColor2 = true;
    this.starbackroundColor3 = true;
    this.starbackroundColor4 = true;
    this.starbackroundColor5 = true;
    this._service.getStarRatingData(5);
    this.nextButton = false;
  }

  public feedbackRating1() {
    this.starRatingScreen = false;
    this.checkboxScreen = true;
    this.textareaScreen = false;
    this.ratingSuccessScreen = false;
    this.feedbackcheckboxForm = true;
  }

  public feedbackRating2() {
    this.starRatingScreen = false;
    this.checkboxScreen = false;
    this.textareaScreen = true;
    this.ratingSuccessScreen = false;
  }

  public feedbackRating3() {
    this.starRatingScreen = false;
    this.checkboxScreen = false;
    this.textareaScreen = false;
    this.checkboxSubmit();
    this.ratingSuccessScreen = true;
    if (this.logoutlinkClicked) {
      setTimeout(() => {
        // this.feedbackcheckboxForm = false;
        window.location.href = this.logoutURL;
      }, 1000);

    }


  }

  public checkboxSubmit() {
    this.feedbackgroupForm.patchValue({
      STC: this.feedbackgroupForm.value.STC,
      SRM: this.feedbackgroupForm.value.SRM,
      PTP: this.feedbackgroupForm.value.PTP,
      SCM: this.feedbackgroupForm.value.SCM,
      PUE: this.feedbackgroupForm.value.PUE,
      I2P: this.feedbackgroupForm.value.I2P,
      OP: this.feedbackgroupForm.value.OP,
      KH: this.feedbackgroupForm.value.KH,
      None: this.feedbackgroupForm.value.None,
      // comment: this.feedbackgroupForm.value.comment.value
    })

    let checkboxData = "";
    if (this.feedbackgroupForm.value.STC) {
      let checkBoxData1 = "Source to Contract, "
      // alert("STC is working fine");
      checkboxData += checkBoxData1;
    }
    if (this.feedbackgroupForm.value.SRM) {
      let checkBoxData2 = "Supplier Relationship Management, "
      checkboxData += checkBoxData2;
      // alert("SRM is working fine");
    }
    if (this.feedbackgroupForm.value.PTP) {
      let checkBoxData3 = "Procure to Pay, "
      checkboxData += checkBoxData3;
      // alert("PTP is working fine");
    }
    if (this.feedbackgroupForm.value.SCM) {
      let checkBoxData4 = "Supply Chain Management, "
      checkboxData += checkBoxData4;
      // alert("SCM is working fine");
    }
    if (this.feedbackgroupForm.value.PUE) {
      let checkBoxData5 = "Portal User Experience, "
      checkboxData += checkBoxData5;
      // alert("PUE is working fine");
    }
    if (this.feedbackgroupForm.value.I2P) {
      let checkBoxData6 = "Issue to Prevention, "
      checkboxData += checkBoxData6;
      // alert("I2P is working fine");
    }
    if (this.feedbackgroupForm.value.OP) {
      let checkBoxData7 = "Open Innovation, "
      checkboxData += checkBoxData7;
      // alert("OP is working fine");
    }
    if (this.feedbackgroupForm.value.KH) {
      let checkBoxData8 = "Knowledge Hub"
      checkboxData += checkBoxData8;
      // alert("KH is working fine");
    }
    if (this.feedbackgroupForm.value.None) {
      let checkBoxData5 = "None"
      checkboxData = checkBoxData5;
      // alert("None is working fine");
    }
    if (this.commentForm.value) {
      sessionStorage.setItem('feedbackcomment', JSON.stringify(this.commentForm.value));
      console.log("feedbackcomment", this.commentForm.value)
    }
    if (checkboxData.charAt(0) === ',')
      checkboxData = checkboxData.slice(1);
    sessionStorage.setItem('feedbackcheckboxlist', JSON.stringify(checkboxData));
    console.log("sanjaycheck", this.feedbackgroupForm.value.STC, checkboxData);
    this._service.getCheckBoxCommentData();
    // if(firstCheckBox.checked){
    //   alert("firstcheckboxclicked");
    // }
  }

  public feedbackWindow() {
    if (this.logoutlinkClicked) {
      if (!this.nextButton) {
        this.showstarsuccessmessage = true;
      }
      setTimeout(() => {

        window.location.href = this.logoutURL;
        this.feedbackwindowClose = false;
      }, 1000);
    }
    this.close()
  }

  public close(): void {
    this.feedbackwindowClose = false;
    this.feedbackcheckboxForm = false;
    this.closedDialog.emit(this.feedbackwindowClose)
  }

  public closeCheckBox() {
    this.feedbackcheckboxForm = false;
  }


}
