import { Component, Input, OnInit, OnChanges, ChangeDetectionStrategy, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '../../vendor.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-ssp-srm',
  templateUrl: './ssp-srm.component.html',
  styleUrls: ['./ssp-srm.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SspSrmComponent implements OnInit, OnChanges {
  public eSourceClicked = false;
  public companyClicked = false;
  public contractClicked = false;
  public actionPlanClicked = false;
  public supplierQualificationClicked = false;
  public myAccValue: any;
  public myGenValue: any;
  public MyAccountDocument!: string;
  public GeneralDocuments!: string;
  public eSourcingLatestCount = '0';
  public performanceDashboard!: string;
  public zeroCarbonDashboard!: string;
  public spcnDashboard!: string;
  public homeContentFromAPI: any = [];
  public selectedTab = 'Supplier Relationship Management';
  public AdminHome = [];
  public AdminHomeS2C = [];
  public AdminHomeSSPSRM = [];
  public AdminHomeP2P = [];
  public dataLoaded = false
  actionPlanDataFlag: any;

  @Input() portalTranslate: any;
  @Output() isTileClicked = new EventEmitter()
  ActionPlanData = [];

  constructor(private _service: VendorService, public translate: TranslateService, private ref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.MyAccountDocument = environment.MyAccountDocument;
    this.GeneralDocuments = environment.GeneralDocuments;
    this.performanceDashboard = environment.performanceDashboard;
    this.zeroCarbonDashboard = environment.zeroCarbonUrl;
    this.spcnDashboard = environment.SPCNUrl;
    this._service.accountAPIData.subscribe(data => {
      let response: any = data;
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.myAccValue = response?.data?.getMyDocumentData?.unreadCount
      }
    })
    this._service.generalDocumentData.subscribe(data => {
      let response: any = data;
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.myGenValue = response?.data?.getGeneralDocumentData?.unreadCount
      }
    })
    // this._service.eSourceData.subscribe((data) => {
    //   let response: any = data
    //   if (response.latestCount > 0) {
    //     this.eSourcingLatestCount = response.latestCount;
    //   }
    // });


    this._service.actionPlan.subscribe((data) => {

      // if (data.length > 0) {
      //   this.ActionPlanData = data;
      // }

      let response: any = data;

      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.actionPlanDataFlag = response?.getActionPlanData?.isNotification;
        sessionStorage.setItem('EsurveyData', JSON.stringify(data))
        // console.log("ActionPlan Compo", this.actionPlanDataFlag)
      }

      // console.log("Action Plan Data", response)

    })
    this._service.getHomeContentAPI.subscribe((data) => {
      if (data) {
        this.homeContentFromAPI = JSON.parse(sessionStorage.getItem('homeContent') || '{}');
        this.AdminHomeS2C = this.homeContentFromAPI[2].modules;
        this.AdminHomeSSPSRM = this.homeContentFromAPI[0].modules;
        this.AdminHomeP2P = this.homeContentFromAPI[1].modules;
        this.tabClick(1);
        this.ref.detectChanges();
      }
    });
  }

  public tabClick(tabNum?: number): void {
    if (tabNum === 1) {
      this.AdminHome = this.AdminHomeS2C;
      this.selectedTab = 'Source to Contract';
    } else if (tabNum === 2) {
      this.AdminHome = this.AdminHomeSSPSRM;
      this.selectedTab = 'Supplier Relationship Management';
    } else if (tabNum === 3) {
      this.AdminHome = this.AdminHomeP2P;
      this.selectedTab = 'Procure to Pay & Supply Chain Management';
    } else {
      this.AdminHome = this.AdminHomeS2C;
      this.selectedTab = 'Source to Contract';
    }
  }



  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  // public onESourcingClick(): void {
  //   this.eSourceClicked = !this.eSourceClicked;
  //   this.contractClicked = false;
  //   this.isTileClicked.emit(true)
  // }

  public onActionPlanClicked(): void {
    this.actionPlanClicked = !this.actionPlanClicked;
    // this.eSourceClicked = false;
  }

  public chipSelect(chip: any): void {
    const eles = document.getElementById("chip-nav-btns") as HTMLElement;
    Array.from(eles.children).forEach((scroll) => {
      scroll.setAttribute('class', '');
    });
  }

}
