import calculateSlope from './calculate-slope';
function linearTrendline(context) {
  const {
    options,
    categoryAxis,
    seriesValues
  } = context;
  const {
    data
  } = getData({
    seriesValues,
    categoryAxis,
    options
  });
  if (data) {
    return Object.assign({}, options, {
      type: 'line',
      data,
      categoryField: 'category',
      field: 'value'
    });
  }
  return null;
}
const valueGetter = fieldName => ({
  categoryIx,
  valueFields
}) => ({
  xValue: categoryIx + 1,
  yValue: valueFields[fieldName]
});
function getData({
  seriesValues,
  categoryAxis,
  options
}) {
  const {
    slope,
    intercept,
    count
  } = calculateSlope(seriesValues(), valueGetter(options.field));
  if (count > 0) {
    const data = [];
    const totalRange = categoryAxis.totalRangeIndices();
    const currentRange = categoryAxis.currentRangeIndices();
    const range = {
      min: Math.floor(Math.max(currentRange.min - 1, totalRange.min)),
      max: Math.ceil(Math.min(currentRange.max + 2, totalRange.max))
    };
    for (let i = range.min; i < range.max; i++) {
      data[i] = {
        category: categoryAxis.categoryAt(i, true),
        value: slope * (i + 1) + intercept
      };
    }
    return {
      data
    };
  }
  return {
    data: null
  };
}
export default linearTrendline;