import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '../../vendor.service';

@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ContactFormComponent implements OnInit, OnChanges {
  @Input() isOpen!: boolean;
  @Input() portalTranslate: any
  @Input() message: any;
  @Output() canCloseDialog = new EventEmitter;
  selectedFile!: File;
  productForm!: FormGroup;


  public listItems = [
    { textEn: "Source to Contract (SSP-SRM)", textCh: "采购到合同 (SSP-SRM)", value: "SOURCE TO CONTRACT" },
    { textEn: "Supplier Relationship Management (SSP-SRM)", textCh: "供应商关系管理 (SSP-SRM)", value: "SUPPLIER RELATIONSHIP MANAGEMENT" },
    { textEn: "Supply Chain Management (SSP-SCM)", textCh: "供应链管理 (SSP-SCM)", value: "SUPPLY CHAIN MANAGEMENT" },
    { textEn: "Open Innovation", textCh: "开放式创新", value: "CO-INNOVATION" },
    { textEn: "General (Supplier Portal)", textCh: "常规（供应商门户）", value: "GENERAL EMAIL" },
  ];

  // public listItemsCh = [
  //   { text: "采购到合同 (SSP-SRM)", value: "1" },
  //   { text: "供应商关系管理 (SSP-SRM)", value: "2" },
  //   { text: "供应链管理 (SSP-SCM)", value: "3" },
  //   { textEn: "共同创新", value: "4" },
  //   { text: "常规（与门户有关)", value: "5" },
  // ];

  userName = '';
  mailId = '';
  isSubmitted = false;
  selectedCategory: any;
  validFileSize = true;
  isUploadAttachmentSuccess = false
  isFileupload: boolean = false;
  loader = false

  constructor(private fb: FormBuilder, private _service: VendorService, public translate: TranslateService) { }

  ngOnInit() {
    this._service.loginDetails.subscribe(userDetials => {
      let loggedInDetails;
      if (userDetials.length === 0) {
        loggedInDetails = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
      }
      console.log(loggedInDetails)
      this.userName = loggedInDetails.firstName + ' ' + loggedInDetails.lastName;
      this.mailId = loggedInDetails.email
    })
    this.createForm();
    this._service.uploadAttachmen.subscribe(result => {
      let response: any = result
      console.log(response.status)
      if (Object.keys(response).length === 0 && response.constructor === Object) {
        this.isFileupload = false;
      } else {
        this.loader = false;
        this.isFileupload = true;
        if (response.status == 200) {
          this.isUploadAttachmentSuccess = true
        } else {
          this.isUploadAttachmentSuccess = false
        }
      }
    }, error => {
      console.log(error)
      this.loader = false;
      this.isUploadAttachmentSuccess = false
    })

  }

  get category() { return this.productForm.get('category')!; }
  get issueDesc() { return this.productForm.get('issueDesc')!; }
  get type() { return this.productForm.get('type')!; }
  get file() { return this.productForm.get('file')!; }

  createForm() {
    this.productForm = this.fb.group({
      name: [this.userName],
      email: [this.mailId],
      phone: [
        null,
      ],
      issueDesc: ['', Validators.required],
      category: ['', Validators.required],
      type: [''],
      file: [''],
    });

  }

  ngOnChanges(changes: SimpleChanges): void {
    // if (changes['portalTranslate']) {
    //   this.portalTranslate = changes['portalTranslate'].currentValue;
    //   this.translate.use(this.portalTranslate ? 'en' : 'cn');
    // }
  }

  close(fromSubmit: boolean) {
    this.isOpen = false;
    this.canCloseDialog.emit(fromSubmit)
  }

  onFileSelected(event: any) {
    this.selectedFile = <File>event.target.files[0];
    this.validFileSize = this.fileSizeValidator(this.selectedFile.size);
    if (this.validFileSize) {
      this.loader = true
      this._service.getUploadUrl(this.selectedFile)
    }
    console.log(this.validFileSize)
  }

  fileSizeValidator(fileSize: number) {
    // let maxFileSize = 10485760; //for 10MB
    let maxFileSize = 5242880 // for 5MB        
    if (fileSize <= maxFileSize) {
      return true
    } else {
      return false;
    }

  }

  changeCategory(event: any) {
    this.selectedCategory = this.productForm.value.category
    if (this.productForm.value.category === 'GENERAL' || this.productForm.value.category === '常规') {
      this.productForm.controls['type'].setValidators([Validators.required]);
    } else {
      this.productForm.controls['type'].clearValidators();
    }

  }

  onSubmit() {
    console.log(this.productForm);
    if (this.productForm.invalid || !this.validFileSize || !this.isUploadAttachmentSuccess) {
      return;
    } else {
      this.close(true)
      this._service.submitContactUsForm(this.productForm.value)
      this.isUploadAttachmentSuccess = false
      this.isFileupload = false;
    }
    this.isSubmitted = true;
  }


  closeTicketDailog() {
    this.resetForm();
    this.canCloseDialog.emit("");

  }


  resetForm(): void {
    this.productForm.reset({
      name: this.userName,
      email: this.mailId,
      phone: null,
      issueDesc: '',
      category: '',
      type: '',
      file: '',
    })
    this.isSubmitted = false;
  }

}
