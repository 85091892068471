import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-welcome-text',
  templateUrl: './welcome-text.component.html',
  styleUrls: ['./welcome-text.component.scss']
})
export class WelcomeTextComponent implements OnInit {
  public showMobileScreenNav = true;
  public portalTranslate = false;
  @Input() welcomeTranslate!: boolean;
  constructor() {

  }

  ngOnInit(): void {
    this.portalTranslate = this.welcomeTranslate;
    setTimeout(() => {

      this.showMobileScreenNav = false;


    }, 10000);
  }

}
