import { Component, Input, OnInit } from '@angular/core';
import { VendorService } from '../../../vendor.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-guides-repo',
  templateUrl: './user-guides-repo.component.html',
  styleUrl: './user-guides-repo.component.scss'
})
export class UserGuidesRepoComponent implements OnInit {

  @Input() portalTranslate: any;
  getRepoList: any;
  repoURL: any;
  key: any;
  showDafaults = true;
  selectedTemplate = '';

  constructor(public translate: TranslateService, public _service: VendorService, private router: Router) { }
  ngOnInit(): void {
    this._service.getRepoData();
  }

  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  getInnerHTML(val: string) {
    return val.replace(/\r?\n|\r/g, '<br/>');
  }

  onOpenUrl(url: string) {
    window.open(url);
  }

  onEsourcingClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'esourcing';
  }

  onContractsClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'contracts';
  }

  onActionPlanClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'actionplan';
  }

  onSPCNRepoClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'spcnrepo';
  }

  onBusinessReviewClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'businessreview';
  }

  onGeneralClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'generalfunctions';
  }

  onRepositoryClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'repository';
  }

}
