<se-block class="tab-panel action-table" id="tabPanel1">
    <div class="tableArrow"></div>
    <se-table height="500px" class="tableVew block-content-border">
        <p class="deleteIcon">
            <se-icon class="delete-icon-color" (click)="onActionPlanCancelClicked()">action_delete_cross</se-icon>
        </p>
        <p class="tableHeader mobileFont">
            <span *ngIf="!portalTranslate">{{ 'home.openActivities' | translate }}</span>
            <span *ngIf="portalTranslate">公开活动</span>
        </p>
        <div class="tableContent desktopTable">
            <div class="float-end pb-2">
                <a class="buttonAnchor text-white" kendoTooltip tooltipClass="my-class" position="top"
                    title="{{ActionPlan}}" href="{{ActionPlan}}" target="_blank">
                    <se-button *ngIf="!portalTranslate" color="primary"> {{ 'home.allActionPlan' | translate }}
                    </se-button>
                    <se-button *ngIf="portalTranslate" color="primary"> 所有行动计划 </se-button>
                </a>
            </div>
            <div class="table-data-fields">
                <kendo-grid [kendoGridBinding]="tooltipGridData" [sortable]="true" [height]="530" class="k-button-group"
                    [pageSize]="pageSize" [skip]="skip" [sort]="sort" [pageable]="true"
                    (pageChange)="onPageChange($event)" class="mobileGrid">
                    <kendo-grid-column field="Activity_ID_external_ID" title="{{ 'home.activityID' | translate }}"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="padding-left: 10px" *ngIf="!portalTranslate">{{ 'home.activityID' |
                                translate}}</span>
                            <span style="padding-left: 10px" *ngIf="portalTranslate">活动编号</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-gridView>
                            <span style=" padding-left: 10px;">{{ gridView.Activity_ID_external_ID }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Action_Plan_Name" *ngIf="!portalTranslate"
                        title="{{ 'home.actionPlanName' | translate }}" [headerClass]="'gridColumnStyle'">
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="portalTranslate" field="Action_Plan_Name" title="行动计划名称"
                        [headerClass]="'gridColumnStyle'">
                    </kendo-grid-column>
                    <kendo-grid-column field="Activity_Name" title="{{ 'home.activityName' | translate }}"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="padding-left: 10px" *ngIf="!portalTranslate">{{ 'home.activityName' | translate
                                }}</span>
                            <span style="padding-left: 10px" *ngIf="portalTranslate">活动名称</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Status" title="{{ 'home.status' | translate }}"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="padding-left: 25px">{{ 'home.status' | translate }}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="SE_Responsible_activty_responsible"
                        title="{{ 'home.seResponsible' | translate }}" [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="margin-left: 2px !important" *ngIf="!portalTranslate">{{ 'home.seResponsible' |
                                translate }}</span>
                            <span style="margin-left: 2px !important" *ngIf="portalTranslate">施耐德负责人</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Deadline" title="{{ 'home.deadline' | translate }}"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="padding-left: 10px" *ngIf="!portalTranslate">{{ 'home.deadline' | translate
                                }}</span>
                            <span style="padding-left: 10px" *ngIf="portalTranslate">截至日期</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-gridView>
                            {{ gridView.Deadline | date: 'dd MMM yyyy' }}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="!portalTranslate" field="Supplier_name_company"
                        title="{{ 'home.supplierName' | translate }}" [headerClass]="'gridColumnStyle'">
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="portalTranslate" field="Supplier_name_company" title="供应商公司"
                        [headerClass]="'gridColumnStyle'">
                    </kendo-grid-column>
                    <ng-template kendoGridNoRecordsTemplate>
                        <span *ngIf="!portalTranslate">There is currently no action plan.</span>
                        <span *ngIf="portalTranslate">目前没有行动计划.</span>
                    </ng-template>
                </kendo-grid>
            </div>
        </div>
    </se-table>
</se-block>