import LineChart from '../line-chart/line-chart';
import SplineSegment from '../line-chart/spline-segment';
import LineSegment from '../line-chart/line-segment';
import { Point, Box } from '../../core';
import { SMOOTH, INTERPOLATE } from '../constants';
import { setDefaultOptions, last } from '../../common';
function groupBySeriesIx(segments) {
  const seriesSegments = [];
  for (let idx = 0; idx < segments.length; idx++) {
    const segment = segments[idx];
    seriesSegments[segment.seriesIx] = seriesSegments[segment.seriesIx] || [];
    seriesSegments[segment.seriesIx].push(segment);
  }
  return seriesSegments;
}
class RadarLineChart extends LineChart {
  pointSlot(categorySlot, valueSlot) {
    const valueRadius = categorySlot.center.y - valueSlot.y1;
    const slot = Point.onCircle(categorySlot.center, categorySlot.middle(), valueRadius);
    return new Box(slot.x, slot.y, slot.x, slot.y);
  }
  renderSegments() {
    super.renderSegments();
    if (this._segments && this._segments.length > 1) {
      const seriesSegments = groupBySeriesIx(this._segments);
      for (let idx = 0; idx < seriesSegments.length; idx++) {
        const segments = seriesSegments[idx];
        if (segments && segments.length > 1) {
          const firstPoint = segments[0].linePoints[0];
          const lastSegment = last(segments);
          const lastPoint = last(lastSegment.linePoints);
          const isFirstDataPoint = firstPoint.categoryIx === 0;
          const isLastDataPoint = lastPoint.categoryIx === lastPoint.categoriesCount - 1;
          if (isFirstDataPoint && isLastDataPoint) {
            last(segments).linePoints.push(firstPoint);
          }
        }
      }
    }
  }
  createSegment(linePoints, currentSeries, seriesIx) {
    const style = currentSeries.style;
    let pointType;
    if (style === SMOOTH) {
      pointType = SplineSegment;
    } else {
      pointType = LineSegment;
    }
    const segment = new pointType(linePoints, currentSeries, seriesIx);
    const missingValues = this.seriesMissingValues(currentSeries);
    if (linePoints.length === currentSeries.data.length || missingValues === INTERPOLATE) {
      segment.options.closed = true;
    }
    return segment;
  }
}
setDefaultOptions(RadarLineChart, {
  clip: false,
  limitPoints: false
});
export default RadarLineChart;