<div class="container">
    <div class="row">
        <div class="col-lg-12">
            <div class="supplyon-order mb-3">
                <ng-container *ngIf="!noData || supplyOnDropdown?.length">
                    <button class="btn" [disabled]="SCM || noData" style="background-color: #3dcd58 !important;border:#3dcd58 !important;color:#ffffff;margin-right: 20px;
                    margin-left: -10px;
                    "><span *ngIf="!portalTranslate">Supply Chain Management</span><span *ngIf="portalTranslate">供应链管理
                        </span></button>
                </ng-container>
            </div>
            <div class="dropdown-select">
                <ng-container *ngIf="!noData || supplyOnDropdown?.length">
                    <div style="text-align:left;margin-bottom: 2%;" *ngIf="SCM">
                        <div style="float: left;padding-right:20px;padding-top:4px">Select Company</div>
                        <kendo-dropdownlist [data]="supplyOnDropdown" [style.width.px]="300" valueField="sma"
                            textField="com" [valuePrimitive]="true" (valueChange)="supplyOnDropdownList($event)"
                            [(ngModel)]="supplyOnDefaultDL"></kendo-dropdownlist>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
    <div style="text-align: center; margin-bottom: 5%;color:#8c8686; font-size: 32px;" *ngIf="noData">No data to display
    </div>
    <ng-container *ngIf="!noData">
        <div class="row">
            <div class="col-md-4">
                <div style="margin-left: 15px; margin-bottom: 15px;">
                    <span>
                        <ul class="list-group">
                            <li class="list-group-item" onclick="supplyOnButton()"><b> {{ "home.orders" | translate
                                    }}
                                </b>
                                <span style="margin-left: 72%;"> <span class="k-icon k-i-arrow-60-right"></span>
                                </span>
                            </li>
                            <div class="list-group-item" style="color:#33A2FF">
                                <span style="font-size:20px;font-weight:bold;color:#007bff"><a
                                        href="{{orderNewValueLink}}" target="_blank">{{orderNewValue}}</a></span>
                                <div style="color: black;font-size:14px;">Total new orders & Updates</div>
                            </div>
                            <style>
                                .k-progressbar .k-state-selected {
                                    background-color: aqua !important;
                                }
                            </style>
                            <div class="list-group-item">
                                <b style="Padding-bottom:10px">{{ "home.orderStatus" |
                                    translate }} </b>
                                <div class="container">
                                    <a href="{{withOutUpdateValueLink}}" target="_blank">
                                        <div class="progress" style="height:10px; margin-top: 15px;">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="overDueAsnBar"
                                                aria-valuemin="20" aria-valuemax="100"
                                                [style.width.%]="newOrderBarValue" style="background-color:#007bff">
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div>
                                    <div style="color: black;font-size:14px;float:left">New Orders</div>
                                    <div style="color: #42b4e6;float: right;"><b><a href="{{withOutUpdateValueLink}}"
                                                target="_blank">{{withOutUpdateValue}}</a></b></div>
                                </div>
                                <br />
                                <div class="container">
                                    <a href="{{newUpdatesValueLink}}" target="_blank">
                                        <div class="progress" style="height:10px; margin-top: 15px;">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="overDueAsnBar"
                                                aria-valuemin="20" aria-valuemax="100"
                                                [style.width.%]="orderUpdateBarValue" style="background-color:#007bff">
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div>
                                    <div style="color: black;font-size:14px;float:left">Order Update </div>
                                    <div style="color: #42b4e6;float:right;"><b><a href="{{newUpdatesValueLink}}"
                                                target="_blank">{{newUpdatesValue}}</a></b></div>
                                </div>
                            </div>
                        </ul>
                    </span>
                </div>
            </div>
            <div class="col-md-4">
                <div style="margin-left: 15px; margin-bottom: 15px;">
                    <span>
                        <ul class="list-group">
                            <li class="list-group-item "><b>{{ "home.confirmableOrders" | translate }}</b>
                                <span style="margin-left: 33%;"> <span class="k-icon k-i-arrow-60-right"></span>
                                </span>
                            </li>
                            <div class="list-group-item" style="color:#33A2FF"><span
                                    style="font-size:20px;font-weight:bold;color:#007bff"><a
                                        href="{{orderUnconfirmedVauleLink}}"
                                        target="_blank">{{orderUnconfirmedVaule}}</a></span>
                                <div style="color: black;">Total Confirmable Orders</div>
                            </div>
                            <div class="list-group-item"><b>{{ "home.orderStatus" | translate }}</b>
                                <div class="container">
                                    <a href="{{orderDatedVauleLink}}" target="_blank">
                                        <div class="progress" style="height:10px; margin-top: 15px;">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="overDueAsnBar"
                                                aria-valuemin="20" aria-valuemax="100"
                                                [style.width.%]="orderDateBarValue" style="background-color:#ff1076;">
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div>
                                    <div style="color: black;font-size:14px;float:left">Detected</div>
                                    <div style="color: #42b4e6;float: right;"><a href="{{orderDatedVauleLink}}"
                                            target="_blank"><b>{{orderDatedVaule}}</b></a></div>
                                </div>
                                <br />
                                <div class="container">
                                    <a href="{{partlyConfirmedVauleLink}}" target="_blank">
                                        <div class="progress" style="height:10px; margin-top: 15px;">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="overDueAsnBar"
                                                aria-valuemin="20" aria-valuemax="100"
                                                [style.width.%]="orderPartlyConfirmedBarValue"
                                                style="background-color:#ff1076;">
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div>
                                    <div style="color: black;font-size:14px;float:left">Partly Confirmed</div>
                                    <div style="color: #42b4e6;float: right;"><a href="{{partlyConfirmedVauleLink}}"
                                            target="_blank"><b>{{partlyConfirmedVaule}}</b></a></div>
                                </div>
                                <br />
                                <div class="container">
                                    <a href="{{orderOpenVauleLink}}" target="_blank">
                                        <div class="progress" style="height:10px; margin-top: 15px;">
                                            <div class="progress-bar" role="progressbar" aria-valuenow="overDueAsnBar"
                                                aria-valuemin="20" aria-valuemax="100"
                                                [style.width.%]="orderOpenBarVaule" style="background-color:#ff1076;">
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div>
                                    <div style="color: black;font-size:14px;float:left">Open</div>
                                    <div style="color: #42b4e6;float: right;"><a href="{{orderOpenVauleLink}}"
                                            target="_blank"><b>{{orderOpenVaule}}</b></a></div>
                                </div>
                            </div>
                        </ul>
                    </span>
                </div>
            </div>
        </div>
    </ng-container>
</div>