import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { VendorService } from '../../vendor.service';

@Component({
  selector: 'app-traffic-monitoring-monthly',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './traffic-monitoring-monthly.component.html',
  styleUrls: ['./traffic-monitoring-monthly.component.scss']
})
export class TrafficMonitoringMonthlyComponent implements OnInit {

  constructor(private _service: VendorService) { }
  public seriesMonthlyDataNew: any;

  public categories = [];
  public portalTranslate!: boolean;
  @Input() trafficMonthlyTranslate!: boolean;

  ngOnInit(): void {
    this._service.TrafficMonthlyNewAPI.subscribe(data => {
      if (data) {
        this.seriesMonthlyDataNew = JSON.parse(sessionStorage.getItem('TrafficMonthlyQueryDataNew') || '{}');
        this.categories = JSON.parse(sessionStorage.getItem('TrafficMonthlyQueryDatesNew') || '{}');
      }
    })


  }
  ngOnChanges() {
    this.portalTranslate = this.trafficMonthlyTranslate;
  }

}
