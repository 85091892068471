<div *ngIf="!portalTranslate">
    <kendo-chart [categoryAxis]='{ categories: categories }'>
        <kendo-chart-title text="Country Wise chart" font="20pt bold">
        </kendo-chart-title>
        <kendo-chart-value-axis>
            <kendo-chart-value-axis-item [title]="{ text: 'Number of users logged in' }" [min]="0">
            </kendo-chart-value-axis-item>
        </kendo-chart-value-axis>
        <kendo-chart-legend position='bottom' orientation='horizontal'></kendo-chart-legend>
        <kendo-chart-series>
            <kendo-chart-series-item [stack]="true" *ngFor='let item of seriesMonthlyDataNew' type='column'
                [data]='item.data' [name]='item.nameEn' [color]="item.color">
                <kendo-chart-series-item-tooltip>
                    <ng-template let-dataItem="dataItem" let-series="series">
                        <div>{{series.name}} : {{dataItem}}</div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>
        </kendo-chart-series>
    </kendo-chart>
</div>
<div *ngIf="portalTranslate">
    <kendo-chart [categoryAxis]='{ categories: categories }'>
        <kendo-chart-title text="Country Wise chart" font="20pt bold">
        </kendo-chart-title>
        <kendo-chart-value-axis>
            <kendo-chart-value-axis-item [title]="{ text: '登录用户数量' }" [min]="0">
            </kendo-chart-value-axis-item>
        </kendo-chart-value-axis>
        <kendo-chart-legend position='bottom' orientation='horizontal'></kendo-chart-legend>
        <kendo-chart-series>
            <kendo-chart-series-item [stack]="true" *ngFor='let item of seriesMonthlyDataNew' type='column'
                [data]='item.data' [name]='item.nameCh' [color]="item.color">
                <kendo-chart-series-item-tooltip>
                    <ng-template let-dataItem="dataItem" let-series="series">
                        <div>{{series.name}} : {{dataItem}}</div>
                    </ng-template>
                </kendo-chart-series-item-tooltip>
            </kendo-chart-series-item>
        </kendo-chart-series>
    </kendo-chart>
</div>