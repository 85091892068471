import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from './vendor.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  title = 'supplier-portal';
  nonceValue!: string;
  @ViewChild('widgetsContent', { read: ElementRef })
  public widgetsContent!: ElementRef<any>;

  public isCookieSettingsDialogOpen = false;
  public showMobileScreenNav = true;

  public scrollRight(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft + 150,
      behavior: 'smooth',
    });
  }

  public scrollLeft(): void {
    this.widgetsContent.nativeElement.scrollTo({
      left: this.widgetsContent.nativeElement.scrollLeft - 150,
      behavior: 'smooth',
    });
  }

  public dynamicData =

    { "aboutMe": null, "access_token": "00D040000004eNs!AQoAQMhh7epzkVnLKB0rvQUkxJEofdFgvTBSAjgvdM_Rckm5pOvJrRhPcZ0ADKyZ9wjEE.D4SVPupNknLV4er5DOVCQ_WAWa", "accountGoldenID": null, "accountId": null, "additionalAddress": null, "ail": "(Application;Synertrade)", "ailApplications": "Synertrade", "ailFeatures": null, "ailPrograms": null, "annualRevenue": "0", "businessUnit": null, "city": null, "classLevel1": null, "classLevel2": null, "companyAdditionalAddress": null, "companyCity": null, "companyCountryCode": null, "companyCounty": null, "companyFederationId": "gb00709d-d9a0-3711-d2b8-c8e55fcc619e", "companyName": null, "companyPOBox": null, "companyPhone": null, "companyStateOrProvinceCode": null, "companyStreet": null, "companyWebsite": null, "companyZipCode": null, "contactGoldenID": null, "contactId": null, "countryCode": "FR", "county": null, "currency": null, "department": null, "division": null, "email": "genericsupplier@yopmail.com", "emailOptIn": "U", "employeeSize": null, "fax": null, "federatedId": "gb00709d-d9a0-3711-d2b8-c8e55fcc619e", "firstName": "IrineTest", "hashedPin": null, "headquarter": "false", "identityType": "Email", "idmsFederatedId": "gb00c955-e28c-72fc-7ebb-2941827a68a4", "isInternal": "FALSE", "jobDescription": null, "jobFunction": null, "jobTitle": null, "languageCode": "EN", "lastName": "UAT2807", "marketSegment": null, "marketServed": null, "marketSubSegment": null, "middleName": null, "mobilePhone": null, "pOBox": null, "persona": "EMP", "refresh_token": "5Aep861Tst.ahKyP5qvlzvbBoccH7McEHoRkQYu7hJl.2dDn_eoHJjSqTUxmL4VV8rAOqu.e.ZvtqOjvbkvnHhd", "rejectionComment": null, "rejectionReason": null, "salutation": null, "stateOrProvinceCode": null, "street": null, "suffix": null, "taxIdentificationNumber": null, "title": null, "trustLevel": "IsPrivate", "trustStatus": null, "userContext": "Supplier", "userId": "005040000015H6KAAU", "workPhone": null, "zipCode": null, "__typename": "getIDMSUserDetailsOutput" }
  public key = 'aPrxpStJufBbj70UqpD7YN0KwEy9WfhFYa3Bi8QdO8nnxvksryNOtHFrEqjq9AbHH5hcpiUlNw'


  constructor(public translate: TranslateService, public router: Router, private _service: VendorService) {


    const locationUrl = window.location.href;
    if (locationUrl.includes('localhost')) {

      sessionStorage.setItem("Idmscode", this.key);
      sessionStorage.setItem("UserLoggedInDetails", JSON.stringify(this.dynamicData));


    }

  }

  ngOnInit(): void {
    this._service.isUserLoggedIn();
    this.nonceValue = this.generateRandomNonce();

    // Initial redirection to IDMS
    window.scrollTo(0, 0);
    // const testURL: string = window.location.search;
    // if (testURL === '') {
    //   window.location.replace(environment.IDMSLoginURL);
    // }
    // this.translate.addLangs(['en', 'cn']);
    // this.translate.setDefaultLang('en');

    // const browserLang = this.translate.getBrowserLang();
    // this.translate.use(browserLang.match(/en|cn/) ? browserLang : 'en');

    // console.log(browserLang);

    setTimeout(() => {
      // const x = document.getElementById('myTopnav');
      this.showMobileScreenNav = false;
      window.scrollTo(0, 0);
      // this.myFunction();;
    }, 1000);


  }

  private generateRandomNonce(): string {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    const nonceLength = 16;
    let result = '';

    for (let i = 0; i < nonceLength; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.charAt(randomIndex);
    }

    return result;
  }


  openCookieSettingsPopup(): void {
    this.isCookieSettingsDialogOpen = true;
  }
}
