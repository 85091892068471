<div id="user-guide-repo">
    <div class="container">
        <div class="row" *ngIf="showDafaults">
            <a class="my-4" (click)="onRepositoryClick($event)">
                << Back</a>
                    <div class="col-md-2">
                        <div class="card-group">
                            <div class="card bg-transparent border-0" (click)="onEsourcingClick($event)">
                                <img src="../assets/images/repository/esourcing-repo.png" class="card-img-top">
                                <div class="card-body pb-2">
                                    <h5 class="card-title text-center">{{ "home.esource" | translate }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card-group">
                            <div class="card bg-transparent border-0" (click)="onContractsClick($event)">
                                <img src="../assets/images/repository/contract-repo.png" class="card-img-top">
                                <div class="card-body pb-2">
                                    <h5 class="card-title text-center">{{ "home.contract" | translate }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card-group">
                            <div class="card bg-transparent border-0" (click)="onActionPlanClick($event)">
                                <img src="../assets/images/repository/actionplan-repo.png" class="card-img-top">
                                <div class="card-body pb-2">
                                    <h5 class="card-title text-center">{{ "home.ActionPlan" | translate }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card-group">
                            <div class="card bg-transparent border-0" (click)="onSPCNRepoClick($event)">
                                <img src="../assets/images/repository/spcn-repo.png" class="card-img-top">
                                <div class="card-body pb-2">
                                    <h5 class="card-title text-center">{{ "home.spcn" | translate }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card-group">
                            <div class="card bg-transparent border-0" (click)="onBusinessReviewClick($event)">
                                <img src="../assets/images/repository/business-review.png" class="card-img-top">
                                <div class="card-body pb-2">
                                    <h5 class="card-title text-center">{{ "home.businessReview" | translate }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card-group">
                            <div class="card bg-transparent border-0" (click)="onGeneralClick($event)">
                                <img src="../assets/images/repository/general-repo.png" class="card-img-top">
                                <div class="card-body pb-2">
                                    <h5 class="card-title text-center">{{ "home.generalFunctions" | translate }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
    </div>
</div>

<div *ngIf="selectedTemplate === 'esourcing'">
    <app-esourcing-repo></app-esourcing-repo>
</div>

<div *ngIf="selectedTemplate === 'contracts'">
    <app-contracts-repo></app-contracts-repo>
</div>

<div *ngIf="selectedTemplate === 'actionplan'">
    <app-actionplan-repo></app-actionplan-repo>
</div>

<div *ngIf="selectedTemplate === 'spcnrepo'">
    <app-spcn-repo></app-spcn-repo>
</div>

<div *ngIf="selectedTemplate === 'businessreview'">
    <app-business-review></app-business-review>
</div>

<div *ngIf="selectedTemplate === 'generalfunctions'">
    <app-general-repo></app-general-repo>
</div>

<div *ngIf="selectedTemplate === 'repository'">
    <app-online-repository></app-online-repository>
</div>