import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VendorService } from '../../vendor.service';
import { Subject, takeUntil, timer } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';

export interface Message {
  type: string;
  message: string;
}

@Component({
  selector: 'app-supplier-chatbot',
  templateUrl: './supplier-chatbot.component.html',
  styleUrl: './supplier-chatbot.component.scss'
})
export class SupplierChatbotComponent implements OnInit {

  isOpen = false;
  loading = false;
  messages: Message[] = [];
  chatForm = new FormGroup({
    message: new FormControl('', [Validators.required]),
  });
  @ViewChild('scrollMe') private myScrollContainer: any;
  private destroy$ = new Subject<void>();
  initialResponseReceived = false;
  isExpanded: boolean = false;
  feedbackButtonsVisible: boolean = false;
  feedbackFormVisible: boolean = false;
  feedbackSubmitted: boolean = false;
  feedbackSubmittedTimer: any;

  constructor(private chatBotService: VendorService, private sanitizer: DomSanitizer) {

  }
  ngOnInit(): void {

    this.messages.push({ type: 'client', message: 'Hi! How can I assist you today?' });

    this.chatBotService.loggedInDetails$.subscribe(loggedInDetails => {
      // Check if loggedInDetails is not null or undefined
      if (loggedInDetails) {
        this.chatBotService.getKbotChatResults("Hi");
      }
    });

    this.chatBotService.getKbotChatData
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (response: any) => {
          this.loading = false;
          if (response.displayResponse &&
            (this.initialResponseReceived ||
              response.displayResponse.trim() !== "Hi! How can I assist you today?")) {
            this.messages.push({
              type: 'client',
              message: response.displayResponse,
            });
            this.initialResponseReceived = true;

            // Commenting the realted documents code for adding KnowledgeHub

            // if (response.related && response.related.length > 0) {
            //   let relatedMessage = "";
            //   response.related.forEach((item: any, index: number) => {

            //     if (item.title) {
            //       relatedMessage += `<a href="${item.referenceLink}" target="_blank">${item.title}</a>, Page: ${item.pageNumber}\n\n`;
            //     } else if (item.fileName) {
            //       relatedMessage += `<a href="${item.referenceLink}" target="_blank">${item.fileName}</a>, Page: ${item.pageNumber}\n\n`;
            //     }

            //     if (index < response.related.length - 1) {
            //       relatedMessage += '<hr>';
            //     }
            //   });

            //   const safeRelatedMessage = this.sanitizer.bypassSecurityTrustHtml(relatedMessage) as string;
            //   this.messages.push({ type: 'client', message: safeRelatedMessage });
            // }

            // Filter knowledge_hub_sources based on chatQuery (sentMessage in your sendMessage function)
            if (response.knowledge_hub_sources && response.knowledge_hub_sources.length > 0) {
              let relatedKnowledgeHubFiles = "";
              const chatQuery = response.userQuery;
              const queryToLower = chatQuery ? chatQuery.toLowerCase() : "";
              const filteredSources = response.knowledge_hub_sources.filter((source: any) =>
                source.fileName.toLowerCase().includes(queryToLower)
              );


              if (filteredSources.length > 0) {
                filteredSources.forEach((item: any, index: number) => {
                  relatedKnowledgeHubFiles += `<a href="${item.preSignedURL}" target="_blank">${item.fileName}</a>\n\n`;

                  if (index < filteredSources.length - 1) {
                    relatedKnowledgeHubFiles += '<hr>';
                  }
                });
                const safeRelatedMessage = this.sanitizer.bypassSecurityTrustHtml(relatedKnowledgeHubFiles) as string;
                this.messages.push({ type: 'client', message: safeRelatedMessage });
              }
            }

            this.scrollToBottom();
          }
        },
        error: (error) => {
          this.loading = false;
          this.messages.push({
            type: 'client',
            message: 'Oops! There seems to be an issue. Please try again later.'
          });
          console.error('Error fetching bot response:', error);
        }
      });
  }


  feedbackForm = new FormGroup({
    reason: new FormControl('', Validators.required),
    others: new FormControl('', Validators.required),
    rightdoc: new FormControl(''),
  });

  reasonOptions = [
    "Incorrect Response",
    "Incomplete response",
    "Correct response but with additional incorrect data",
    "Wrong document or wrong document section",
    "Speed and usability",
    "Others",
  ];

  openSupportPopup() {
    this.isOpen = !this.isOpen;
  }

  extendWindow() {
    document.getElementById('supplierchatbot-popup')?.classList.add('expanded');
    this.isExpanded = true;
  }

  compressWindow() {
    document.getElementById('supplierchatbot-popup')?.classList.remove('expanded');
    this.isExpanded = false;
  }

  sendMessage() {
    const sentMessage = this.chatForm.value.message!;
    // console.log('sentMessage:', sentMessage);
    this.loading = true;
    this.messages.push({
      type: 'user',
      message: sentMessage,
    });
    this.chatForm.reset();
    this.scrollToBottom();
    this.chatBotService.getKbotChatResults(sentMessage);
    this.feedbackButtonsVisible = false;
  }

  showFeedbackSubmittedMessage() {
    this.feedbackSubmitted = true;

    this.feedbackSubmittedTimer = timer(9000).subscribe(() => {
      this.feedbackSubmitted = false;
    });
  }

  sendPositiveFeedback() {
    this.chatBotService.setKbotPositiveFeedback();
    this.feedbackFormVisible = false;
    this.feedbackSubmitted = true;
    this.showFeedbackSubmittedMessage();
  }

  sendNegativeFeedback() {
    this.feedbackFormVisible = true;
    this.scrollToBottom();
  }

  submitNegativeFeedback() {
    if (this.feedbackForm.valid) {
      const feedbackData = this.feedbackForm.value;
      this.chatBotService.setKbotNegativeFeedback(feedbackData);
      this.feedbackForm.reset();
      this.feedbackFormVisible = false;
      this.feedbackSubmitted = true;
      this.showFeedbackSubmittedMessage();
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();

    if (this.feedbackSubmittedTimer) {
      this.feedbackSubmittedTimer.unsubscribe();
    }
  }

  scrollToBottom() {
    setTimeout(() => {
      try {
        if (this.myScrollContainer && this.myScrollContainer.nativeElement) {
          this.myScrollContainer.nativeElement.scrollTop = this.myScrollContainer.nativeElement.scrollHeight + 150;

          if (!this.loading) {
            this.feedbackButtonsVisible = true;
          }
        }
      } catch (err) { }
    }, 150);
  }

}
