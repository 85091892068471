export const directions = {
  'southeast': {
    x: 1,
    y: 1
  },
  'east': {
    x: 1,
    y: 0
  },
  'south': {
    x: 0,
    y: 1
  },
  'north': {
    x: 0,
    y: -1
  },
  'west': {
    x: -1,
    y: 0
  },
  'southwest': {
    x: -1,
    y: 1
  },
  'northwest': {
    x: -1,
    y: -1
  },
  'northeast': {
    x: 1,
    y: -1
  } // top right
};

export const handles = Object.keys(directions);