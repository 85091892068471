import { Class, grep } from '../../common';
import { proxy, noop, applyEventMap, getEventMap, on, off, now, getSupportedFeatures } from '../utils';
import { Observable } from './observable';
const extend = Object.assign;
const preventDefault = e => {
  e.preventDefault();
};
let DEFAULT_MIN_HOLD = 800,
  CLICK_DELAY = 300,
  // DEFAULT_THRESHOLD = support.browser.msie ? 5 : 0,
  DEFAULT_THRESHOLD = 0,
  PRESS = 'press',
  HOLD = 'hold',
  SELECT = 'select',
  START = 'start',
  MOVE = 'move',
  END = 'end',
  CANCEL = 'cancel',
  TAP = 'tap',
  DOUBLETAP = 'doubleTap',
  RELEASE = 'release',
  GESTURESTART = 'gesturestart',
  GESTURECHANGE = 'gesturechange',
  GESTUREEND = 'gestureend',
  GESTURETAP = 'gesturetap';
let THRESHOLD = {
  'api': 0,
  'touch': 0,
  'mouse': 9,
  'pointer': 9
};
function touchDelta(touch1, touch2) {
  let x1 = touch1.x.location,
    y1 = touch1.y.location,
    x2 = touch2.x.location,
    y2 = touch2.y.location,
    dx = x1 - x2,
    dy = y1 - y2;
  return {
    center: {
      x: (x1 + x2) / 2,
      y: (y1 + y2) / 2
    },
    distance: Math.sqrt(dx * dx + dy * dy)
  };
}
function getTouches(e) {
  const support = getSupportedFeatures();
  let touches = [],
    originalEvent = e.originalEvent || e,
    currentTarget = e.currentTarget,
    idx = 0,
    length,
    changedTouches,
    touch;
  if (e.api) {
    touches.push({
      id: 2,
      event: e,
      target: e.target,
      currentTarget: e.target,
      location: e,
      type: 'api'
    });
  } else if (e.type.match(/touch/)) {
    changedTouches = originalEvent ? originalEvent.changedTouches : [];
    for (length = changedTouches.length; idx < length; idx++) {
      touch = changedTouches[idx];
      touches.push({
        location: touch,
        event: e,
        target: touch.target,
        currentTarget: currentTarget,
        id: touch.identifier,
        type: 'touch'
      });
    }
  } else if (support.pointers || support.msPointers) {
    touches.push({
      location: originalEvent,
      event: e,
      target: e.target,
      currentTarget: currentTarget,
      id: originalEvent.pointerId,
      type: 'pointer'
    });
  } else {
    touches.push({
      id: 1,
      event: e,
      target: e.target,
      currentTarget: currentTarget,
      location: e,
      type: 'mouse'
    });
  }
  return touches;
}
export class TouchAxis extends Class {
  constructor(axis, location) {
    super();
    let that = this;
    that.support = getSupportedFeatures();
    that.invalidZeroEvents = this.support.mobileOS && this.support.mobileOS.android;
    that.axis = axis;
    that._updateLocationData(location);
    that.startLocation = that.location;
    that.velocity = that.delta = 0;
    that.timeStamp = now();
  }
  move(location) {
    let that = this,
      offset = location['page' + that.axis],
      timeStamp = now(),
      timeDelta = timeStamp - that.timeStamp || 1;
    if (!offset && this.invalidZeroEvents) {
      return;
    }
    that.delta = offset - that.location;
    that._updateLocationData(location);
    that.initialDelta = offset - that.startLocation;
    that.velocity = that.delta / timeDelta;
    that.timeStamp = timeStamp;
  }
  _updateLocationData(location) {
    let that = this,
      axis = that.axis;
    that.location = location['page' + axis];
    that.client = location['client' + axis];
    that.screen = location['screen' + axis];
  }
}
export class Touch extends Class {
  constructor(userEvents, target, touchInfo) {
    super();
    extend(this, {
      x: new TouchAxis('X', touchInfo.location),
      y: new TouchAxis('Y', touchInfo.location),
      type: touchInfo.type,
      useClickAsTap: userEvents.useClickAsTap,
      threshold: userEvents.threshold || THRESHOLD[touchInfo.type],
      userEvents: userEvents,
      target: target,
      currentTarget: touchInfo.currentTarget,
      initialTouch: touchInfo.target,
      id: touchInfo.id,
      pressEvent: touchInfo,
      _clicks: userEvents._clicks,
      supportDoubleTap: userEvents.supportDoubleTap,
      _moved: false,
      _finished: false
    });
  }
  press() {
    // this._holdTimeout = setTimeout($.proxy(this, '_hold'), this.userEvents.minHold);
    this._holdTimeout = setTimeout(proxy(this._hold, this), this.userEvents.minHold);
    this._trigger(PRESS, this.pressEvent);
  }
  _tap(touchInfo) {
    let that = this;
    that.userEvents._clicks++;
    if (that.userEvents._clicks === 1) {
      that._clickTimeout = setTimeout(function () {
        if (that.userEvents._clicks === 1) {
          that._trigger(TAP, touchInfo);
        } else {
          that._trigger(DOUBLETAP, touchInfo);
        }
        that.userEvents._clicks = 0;
      }, CLICK_DELAY);
    }
  }
  _hold() {
    this._trigger(HOLD, this.pressEvent);
  }

  /* eslint-disable consistent-return */
  move(touchInfo) {
    let that = this;
    let preventMove = touchInfo.type !== 'api' && that.userEvents._shouldNotMove;
    if (that._finished || preventMove) {
      return;
    }
    that.x.move(touchInfo.location);
    that.y.move(touchInfo.location);
    if (!that._moved) {
      if (that._withinIgnoreThreshold()) {
        return;
      }
      if (!UserEvents.current || UserEvents.current === that.userEvents) {
        that._start(touchInfo);
      } else {
        return that.dispose();
      }
    }
    if (!that._finished) {
      that._trigger(MOVE, touchInfo);
    }
  }
  /* eslint-enable consistent-return */

  end(touchInfo) {
    this.endTime = now();
    if (this._finished) {
      return;
    }
    this._finished = true;
    this._trigger(RELEASE, touchInfo);
    if (this._moved) {
      this._trigger(END, touchInfo);
    } else {
      if (!this.useClickAsTap) {
        if (this.supportDoubleTap) {
          this._tap(touchInfo);
        } else {
          this._trigger(TAP, touchInfo);
        }
      }
    }
    clearTimeout(this._holdTimeout);
    this.dispose();
  }
  dispose() {
    let userEvents = this.userEvents,
      activeTouches = userEvents.touches || [];
    this._finished = true;
    this.pressEvent = null;
    clearTimeout(this._holdTimeout);
    // activeTouches.splice($.inArray(this, activeTouches), 1);
    const activeTouchIndex = activeTouches.indexOf(this);
    activeTouches.splice(activeTouchIndex, 1);
  }
  skip() {
    this.dispose();
  }
  cancel() {
    this.dispose();
  }
  isMoved() {
    return this._moved;
  }
  _start(touchInfo) {
    clearTimeout(this._holdTimeout);
    this.startTime = now();
    this._moved = true;
    this._trigger(START, touchInfo);
  }
  _trigger(name, touchInfo) {
    let that = this,
      jQueryEvent = touchInfo.event,
      data = {
        touch: that,
        x: that.x,
        y: that.y,
        target: that.target,
        event: jQueryEvent
      };
    if (that.userEvents.notify(name, data)) {
      jQueryEvent.preventDefault();
    }
  }
  _withinIgnoreThreshold() {
    let xDelta = this.x.initialDelta,
      yDelta = this.y.initialDelta;
    return Math.sqrt(xDelta * xDelta + yDelta * yDelta) <= this.threshold;
  }
}
function withEachUpEvent(callback) {
  const eventMap = getEventMap(navigator.userAgent);
  let downEvents = eventMap.up.split(' '),
    idx = 0,
    length = downEvents.length;
  for (; idx < length; idx++) {
    callback(downEvents[idx]);
  }
}
export class UserEvents extends Observable {
  constructor(element, options) {
    super();
    let that = this;
    let filter;
    const support = getSupportedFeatures();
    this.support = support;

    /* eslint-disable no-param-reassign */
    options = options || {};
    /* eslint-enable no-param-reassign */
    this.options = options;
    filter = that.filter = options.filter;
    that.threshold = options.threshold || DEFAULT_THRESHOLD;
    that.minHold = options.minHold || DEFAULT_MIN_HOLD;
    that.touches = [];
    that._maxTouches = options.multiTouch ? 2 : 1;
    that.allowSelection = options.allowSelection;
    that.captureUpIfMoved = options.captureUpIfMoved;
    that.useClickAsTap = !options.fastTap && !support.delayedClick();
    that._clicks = 0;
    that.supportDoubleTap = options.supportDoubleTap;
    const enableGlobalSurface = !support.touch || support.mouseAndTouchPresent;
    extend(that, {
      element: element,
      surface: options.global && enableGlobalSurface ? element.ownerDocument.documentElement : options.surface || element,
      stopPropagation: options.stopPropagation,
      pressed: false
    });
    this._surfaceMoveHandler = proxy(this._move, this);
    on(that.surface, applyEventMap('move'), this._surfaceMoveHandler);
    this._surfaceEndHandler = proxy(this._end, this);
    on(that.surface, applyEventMap('up cancel'), this._surfaceEndHandler);
    this._elementStartHandler = proxy(this._start, this);
    on(element, applyEventMap('down'), filter, this._elementStartHandler);
    if (that.useClickAsTap) {
      this._elementClickHandler = proxy(this._click, this);
      on(element, applyEventMap('click'), filter, this._elementClickHandler);
    }
    if (support.pointers || support.msPointers) {
      if (support.browser.version < 11) {
        let defaultAction = 'pinch-zoom double-tap-zoom';
        element.style['-ms-touch-action'] = options.touchAction && options.touchAction !== 'none' ? defaultAction + ' ' + options.touchAction : defaultAction;
      } else {
        element.style['touch-action'] = options.touchAction || 'none';
      }
    }
    if (options.preventDragEvent) {
      this._elementDragStartHandler = preventDefault;
      on(element, applyEventMap('dragstart'), this._elementDragStartHandler);
    }

    // element.on(kendo.applyEventMap('mousedown'), filter, {
    //     root: element
    // } '_select');

    // todo: use root
    this._elementSelectHandler = proxy(this._select, this);
    on(element, applyEventMap('mousedown'), filter, this._elementSelectHandler);
    if (that.captureUpIfMoved && support.eventCapture) {
      let surfaceElement = that.surface,
        preventIfMovingProxy = proxy(that.preventIfMoving, that);
      withEachUpEvent(function (eventName) {
        surfaceElement.addEventListener(eventName, preventIfMovingProxy, true);
      });
    }
    that.bind([PRESS, HOLD, TAP, DOUBLETAP, START, MOVE, END, RELEASE, CANCEL, GESTURESTART, GESTURECHANGE, GESTUREEND, GESTURETAP, SELECT], options);
  }
  preventIfMoving(e) {
    if (this._isMoved()) {
      e.preventDefault();
    }
  }
  destroy() {
    let that = this;
    const options = this.options;
    const element = this.element;
    if (that._destroyed) {
      return;
    }
    that._destroyed = true;
    if (that.captureUpIfMoved && this.support.eventCapture) {
      let surfaceElement = that.surface;
      withEachUpEvent(function (eventName) {
        surfaceElement.removeEventListener(eventName, that.preventIfMoving);
      });
    }
    off(that.surface, applyEventMap('move'), this._surfaceMoveHandler);
    off(that.surface, applyEventMap('up cancel'), this._surfaceEndHandler);
    off(element, applyEventMap('down'), this._elementStartHandler);
    if (that.useClickAsTap) {
      off(element, applyEventMap('click'), this._elementClickHandler);
    }
    if (options.preventDragEvent) {
      off(element, applyEventMap('dragstart'), this._elementDragStartHandler);
    }
    off(element, applyEventMap('mousedown'), this._elementSelectHandler);
    that._disposeAll();
    that.unbind();
    delete that.surface;
    delete that.element;
    delete that.currentTarget;
  }
  capture() {
    UserEvents.current = this;
  }
  cancel() {
    this._disposeAll();
    this.trigger(CANCEL);
  }

  /* eslint-disable indent */
  notify(event, data) {
    let that = this,
      touches = that.touches;
    let eventName = event;
    if (this._isMultiTouch()) {
      switch (eventName) {
        case MOVE:
          eventName = GESTURECHANGE;
          break;
        case END:
          eventName = GESTUREEND;
          break;
        case TAP:
          eventName = GESTURETAP;
          break;
        default:
          break;
      }
      extend(data, {
        touches: touches
      }, touchDelta(touches[0], touches[1]));
    }
    return this.trigger(eventName, extend(data, {
      type: eventName
    }));
  }
  /* eslint-enable indent */

  press(x, y, target) {
    this._apiCall('_start', x, y, target);
  }
  move(x, y) {
    this._apiCall('_move', x, y);
  }
  end(x, y) {
    this._apiCall('_end', x, y);
  }
  _isMultiTouch() {
    return this.touches.length > 1;
  }
  _maxTouchesReached() {
    return this.touches.length >= this._maxTouches;
  }
  _disposeAll() {
    let touches = this.touches;
    while (touches.length > 0) {
      touches.pop().dispose();
    }
  }
  _isMoved() {
    return grep(this.touches, function (touch) {
      return touch.isMoved();
    }).length;
  }
  _select(e) {
    if (!this.allowSelection || this.trigger(SELECT, {
      event: e
    })) {
      e.preventDefault();
    }
  }
  _start(e) {
    let that = this,
      idx = 0,
      filter = that.filter,
      target,
      touches = getTouches(e),
      length = touches.length,
      touch,
      which = e.which;
    if (which && which > 1 || that._maxTouchesReached()) {
      return;
    }
    UserEvents.current = null;
    that.currentTarget = e.currentTarget;
    if (that.stopPropagation) {
      e.stopPropagation();
    }
    for (; idx < length; idx++) {
      if (that._maxTouchesReached()) {
        break;
      }
      touch = touches[idx];
      if (filter) {
        target = touch.currentTarget;
      } else {
        target = that.element;
      }
      if (target && target.length === 0) {
        continue;
      }
      touch = new Touch(that, target, touch);
      that.touches.push(touch);
      touch.press();
      if (that._isMultiTouch()) {
        that.notify('gesturestart', {});
      }
    }
  }
  _move(e) {
    this._eachTouch('move', e);
  }
  _end(e) {
    this._eachTouch('end', e);
  }
  _click(e) {
    let data = {
      touch: {
        initialTouch: e.target,
        target: e.currentTarget,
        endTime: now(),
        x: {
          location: e.pageX,
          client: e.clientX
        },
        y: {
          location: e.pageY,
          client: e.clientY
        }
      },
      x: e.pageX,
      y: e.pageY,
      target: e.currentTarget,
      event: e,
      type: 'tap'
    };
    if (this.trigger('tap', data)) {
      e.preventDefault();
    }
  }
  _eachTouch(methodName, e) {
    let that = this,
      dict = {},
      touches = getTouches(e),
      activeTouches = that.touches,
      idx,
      touch,
      touchInfo,
      matchingTouch;
    for (idx = 0; idx < activeTouches.length; idx++) {
      touch = activeTouches[idx];
      dict[touch.id] = touch;
    }
    for (idx = 0; idx < touches.length; idx++) {
      touchInfo = touches[idx];
      matchingTouch = dict[touchInfo.id];
      if (matchingTouch) {
        matchingTouch[methodName](touchInfo);
      }
    }
  }
  _apiCall(type, x, y, target) {
    this[type]({
      api: true,
      pageX: x,
      pageY: y,
      clientX: x,
      clientY: y,
      target: target || this.element,
      stopPropagation: noop,
      preventDefault: noop
    });
  }
  static defaultThreshold(value) {
    DEFAULT_THRESHOLD = value;
  }
  static minHold(value) {
    DEFAULT_MIN_HOLD = value;
  }
}