import { Class } from '../../../common';
import { EncodingResult } from '../encoding-result';
import { DataModeInstances } from '../data-modes/data-mode-instances';
import { extend } from '../../utils';
import { toBitsString } from '../../utils';
import { getVersion } from '../encoding';
const BYTE = "byte";
export class Utf8Encoder extends Class {
  constructor() {
    super();
    this.initProperties();
    this.mode = DataModeInstances[this.encodingMode];
  }
  initProperties() {
    extend(this, {
      encodingMode: BYTE,
      utfBOM: "111011111011101110111111",
      initialModeCountStringLength: 20,
      ranges: [128, 2048, 65536, 2097152, 67108864]
    });
  }
  getEncodingResult(inputString, errorCorrectionLevel) {
    let data = this.encode(inputString),
      dataCodewordsCount = this.getDataCodewordsCount(data),
      version = getVersion(dataCodewordsCount, errorCorrectionLevel),
      dataString = this.mode.getModeCountString(data.length / 8, version) + data;
    return new EncodingResult(dataString, version);
  }
  getDataCodewordsCount(data) {
    let dataLength = data.length,
      dataCodewordsCount = Math.ceil((this.initialModeCountStringLength + dataLength) / 8);
    return dataCodewordsCount;
  }
  encode(str) {
    let result = this.utfBOM;
    for (let i = 0; i < str.length; i++) {
      result += this.encodeCharacter(str.charCodeAt(i));
    }
    return result;
  }
  encodeCharacter(code) {
    let bytesCount = this.getBytesCount(code),
      bc = bytesCount - 1,
      result = "";
    if (bytesCount === 1) {
      result = toBitsString(code, 8);
    } else {
      let significantOnes = 8 - bytesCount;
      for (let i = 0; i < bc; i++) {
        result = toBitsString(code >> i * 6 & 63 | 128, 8) + result;
      }
      result = (code >> bc * 6 | 255 >> significantOnes << significantOnes).toString(2) + result;
    }
    return result;
  }
  getBytesCount(code) {
    let ranges = this.ranges;
    for (let i = 0; i < ranges.length; i++) {
      if (code < ranges[i]) {
        return i + 1;
      }
    }
  }
}