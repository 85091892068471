<div class="coupa-nodata d-block text-center" *ngIf="noData">
    No data to display
</div>
<div class="float-end" *ngIf="!noData">
    <div class="dropDown mobileDropDownHeader">
        <div style="padding-right: 5px;">Select Customer</div>
        <kendo-dropdownlist class="float-end" [data]="coupaDropdown" class="DesktopDropdownWidth mobileDropdownWidth"
            valueField="id" textField="displayName" [valuePrimitive]="false" (valueChange)="ptpDropdown($event)"
            [(ngModel)]="ptpDefaultDL"></kendo-dropdownlist>
    </div>
    <div class="grid-wrapper" *ngIf="!noData">
        <se-table height="500px" class="coupa-table block-content-border">
            <div class="d-flex flex-column justify-content-end align-items-end">
                <div class="mobileTableWidth">
                    <kendo-grid [kendoGridBinding]="coupaTableDataValue" [sortable]="true" [height]="530"
                        class="k-button-group" [pageSize]="pageSize" [skip]="skip" [sort]="sort" [pageable]="true"
                        (pageChange)="onPageChange($event)" class="mobileTable">
                        <kendo-grid-column field="Contract_Title" title="Contract Title" [headerStyle]="{
                border: 'none',
                'background-color': '#ededed',
                padding: '15px 0px',
                'font-weight': 'bold',
                color: '#97989b',width: '13%'
                }" [style]="{ border: 'none', padding: '12px 5px' ,width: '13%'}">
                            <ng-template kendoGridHeaderTemplate let-gridView>
                                <span class="span-header" *ngIf="!portalTranslate">PO number</span>
                                <span class="span-header" *ngIf="portalTranslate">采购订单编号</span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-gridView>
                                <span class="span-header" *ngIf="!portalTranslate">
                                    <a class="link-decor" href="{{gridView.url}}" target=" ">{{gridView.poNumber}}</a>
                                </span>
                                <span class="span-header-cn" *ngIf="portalTranslate">
                                    <a class="link-decor" href="{{gridView.url}}" target=" ">{{gridView.poNumber}}</a>
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="Contract_ID" title="Contract ID" [headerStyle]="{
                border: 'none',
                'background-color': '#ededed',
                padding: '15px 0px',
                'font-weight': 'bold',
                color: '#97989b',
                width:'13.5%'
                }" [style]="{
                width: '10%',
                border: 'none',
                padding: '12px 0px'
                }">
                            <ng-template kendoGridHeaderTemplate let-gridView>
                                <span class="pl-3" *ngIf="!portalTranslate">Requistion Id</span>
                                <span class="pl-3" *ngIf="portalTranslate">申请编号</span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-gridView>
                                <span class="owner-name pl-3">{{ gridView.id}}</span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="Owner" title="Created At" [headerStyle]="{
                border: 'none',
                'background-color': '#ededed',
                padding: '12px 0px',
                'font-weight': 'bold',
                color: '#97989b',
                width:'10%'
                }" [style]="{ border: 'none', padding: '8px 0px',width:'11%' }">
                            <ng-template kendoGridHeaderTemplate let-gridView>
                                <span *ngIf="!portalTranslate">Created At</span>
                                <span *ngIf="portalTranslate">创建于</span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-gridView>
                                <span class="created-at" style="width:11%; padding-left: 15px;">{{ gridView.createdAt |
                                    date: 'dd MMM
                                    yyyy'}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="Owner" title="Acknowledged At" [headerStyle]="{
                border: 'none',
                'background-color': '#ededed',
                padding: '12px 0px',
                'font-weight': 'bold',
                color: '#97989b',
                width:'15.2%'
                }" [style]="{ border: 'none', padding: '8px 0px',width:'16%' }">
                            <ng-template kendoGridHeaderTemplate let-gridView>
                                <span style="padding-left: 10px" *ngIf="!portalTranslate">Acknowledged At</span>
                                <span style="padding-left: 10px" *ngIf="portalTranslate">确认于</span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-gridView>
                                <span style="width:11%; padding-left: 15px;">{{ gridView.acknowledgedAt | date: 'dd MMM
                                    yyyy' }}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="Status" title="Status" [headerStyle]="{
                border: 'none',
                'background-color': '#ededed',
                padding: '12px 0px',
                'font-weight': 'bold',
                color: '#97989b',
                width:'7%'
                }" [style]="{ border: 'none', padding: '8px 0px',width:'7%' }">
                            <ng-template kendoGridHeaderTemplate let-gridView>
                                <span *ngIf="!portalTranslate">Status</span>
                                <span style="padding-left: 10px" *ngIf="portalTranslate">状态</span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-gridView>
                                <span style="width:7%;">{{ gridView.status}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="Status" title="Total" [headerStyle]="{
                border: 'none',
                'background-color': '#ededed',
                padding: '12px 0px',
                'font-weight': 'bold',
                color: '#97989b',
                width:'14%'
                }" [style]="{ border: 'none', padding: '8px 0px', width:'14%' }">
                            <ng-template kendoGridHeaderTemplate let-gridView>
                                <span class="pl-3" *ngIf="!portalTranslate">Total</span>
                                <span class="pl-3" *ngIf="portalTranslate">总</span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-gridView>
                                <span class="total-width">{{ gridView.total}}</span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="Status" title="Assigned To" [headerStyle]="{
                border: 'none',
                'background-color': '#ededed',
                padding: '12px 0px',
                'font-weight': 'bold',
                color: '#97989b',
                width:'10%'
                }" [style]="{ border: 'none', padding: '8px 0px',width:'10%' }">
                            <ng-template kendoGridHeaderTemplate let-gridView>
                                <span *ngIf="!portalTranslate">Assigned To</span>
                                <span style="padding-left: 10px" *ngIf="portalTranslate">分配给</span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-gridView>
                                <span>{{ gridView.assignedTo}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="Status" title="Payment Term" [headerStyle]="{
                border: 'none',
                'background-color': '#ededed',
                padding: '12px 0px',
                'font-weight': 'bold',
                color: '#97989b'
                }" [style]="{ border: 'none', padding: '8px 0px', width:'16%' }">
                            <ng-template kendoGridHeaderTemplate let-gridView>
                                <span *ngIf="!portalTranslate">Payment Term</span>
                                <span class="pl-3" *ngIf="portalTranslate">付款期限</span>
                            </ng-template>
                            <ng-template kendoGridCellTemplate let-gridView>
                                <span>{{ gridView.paymentTerm}}
                                </span>
                            </ng-template>
                        </kendo-grid-column>
                        <ng-template kendoGridNoRecordsTemplate>
                            <span *ngIf="!portalTranslate">
                                <div
                                    style="text-align: center; margin-bottom: 5%;margin-top:14%;color:#8c8686; font-size: 32px;">
                                    No data to display
                                </div>
                            </span>
                            <span *ngIf="portalTranslate">目前没有未完成合同，如果查看已完成合同请点击所有合同按钮。
                            </span>
                        </ng-template>
                    </kendo-grid>
                </div>
            </div>
        </se-table>
        <div *ngIf="loadspinner" class="k-i-loading"></div>
    </div>
</div>