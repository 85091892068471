<div class="d-flex justify-content-center mt-4" *ngIf="showDafaults">
    <div class="main-container">
        <div class="knowledgehub-bg-img">
            <img src="../assets/images/Desk/welcome.png" alt="knowledgeHub">
        </div>
        <div class="knowledgehub-bg-text">
            <h3>{{ "home.welcomeKnowledgeHub" | translate }}</h3>
            <h1>{{ "home.knowledgeHubHeading" | translate }}</h1>
            <p>{{ "home.knowledgeHubText" | translate }}</p>
        </div>
    </div>
</div>

<div class="d-flex justify-content-center mt-2" *ngIf="showDafaults">
    <div class="row justify-content-between mt-4" style="width: 1190px; margin-bottom: 4rem;">
        <div class="col-12 px-0">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
                <li [ngbNavItem]="1">
                    <button ngbNavLink>{{ "home.userGuide" | translate }}</button>
                    <ng-template ngbNavContent>
                        <div class="user-guide">
                            <div class="row">
                                <div class=" col-md-2 my-3">
                                    <div class="surround20" style="
                                /* background-color: white; */
                                color: #2dcd58;
                                font-size: 22px;
                                padding-left: 18% !important;
                                padding-top: 3% !important;
                                padding-bottom: 1% !important;
                                font-family: Nunito;
                                background-color: #fafafa;
                                font-weight: 500;
                                ">
                                        <span>SSP-SRM</span>
                                    </div>
                                    <div class="card-group mt-2 pb-3">
                                        <div class="card bg-transparent border-0" (click)="onSspSrmClick($event)">
                                            <img src="../assets/images/repository/SSP-SRM.jpg" class="card-img-top">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-2 my-3">
                                    <div class="surround20" style="
                                /* background-color: white; */
                                color: #2dcd58;
                                font-size: 22px;
                                padding-left: 18% !important;
                                padding-top: 3% !important;
                                padding-bottom: 1% !important;
                                font-family: Nunito;
                                background-color: #fafafa;
                                font-weight: 500;
                                ">
                                        <span>SSP-SCM</span>
                                    </div>
                                    <div class="card-group mt-2 pb-3">
                                        <div class="card bg-transparent border-0" (click)="onSspScmClick($event)">
                                            <img src="../assets/images/repository/SSP-SCM.jpg" class="card-img-top">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2 my-3">
                                    <div class="surround20" style="
                                /* background-color: white; */
                                color: #2dcd58;
                                font-size: 22px;
                                padding-left: 37% !important;
                                padding-top: 3% !important;
                                padding-bottom: 1% !important;
                                font-family: Nunito;
                                background-color: #fafafa;
                                font-weight: 500;
                                ">
                                        <span>I2P</span>
                                    </div>
                                    <div class="card-group mt-2 pb-3">
                                        <div class="card bg-transparent border-0" (click)="onI2PClick($event)">
                                            <img src="../assets/images/repository/I2P.jpg" class="card-img-top">
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-2 my-3">
                                    <div class="surround20" style="
                                /* background-color: white; */
                                color: #2dcd58;
                                font-size: 22px;
                                padding-top: 3% !important;
                                padding-bottom: 1% !important;
                                font-family: Nunito;
                                background-color: #fafafa;
                                font-weight: 500;
                                ">
                                        <span>Open Innovation</span>
                                    </div>
                                    <div class="card-group mt-2 pb-3">
                                        <div class="card bg-transparent border-0" (click)="onCoinnovationClick($event)">
                                            <img src="../assets/images/repository/Co-Innovation.jpg"
                                                class="card-img-top">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
                <li [ngbNavItem]="2">
                    <button ngbNavLink>{{ "home.importandInformation" | translate}}</button>
                    <ng-template ngbNavContent>
                        <div class="important-info">
                            <div class="row">
                                <div class="col-md-2 my-3">
                                    <div class="surround20" style="
                            /* background-color: white; */
                            color: #2dcd58;
                            font-size: 22px;
                            padding-top: 3% !important;
                            padding-bottom: 1% !important;
                            font-family: Nunito;
                            background-color: #fafafa;
                            font-weight: 500;
                            ">
                                        <span>Latest Updates</span>
                                    </div>
                                    <div class="card-group mt-2 pb-3">
                                        <div class="card bg-transparent border-0" (click)="onImportantRepClick($event)">
                                            <img src="../assets/images/repository/important-info.png"
                                                class="card-img-top">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </li>
            </ul>

            <div [ngbNavOutlet]="nav" class="mt-2"></div>

        </div>
    </div>

</div>

<div *ngIf="selectedTemplate === 'ssp-srm'">
    <app-user-guides-repo></app-user-guides-repo>
</div>

<div *ngIf="selectedTemplate === 'ssp-scm'">
    <app-user-guides-scm></app-user-guides-scm>
</div>

<div *ngIf="selectedTemplate === 'i2p'">
    <app-i2p-repo></app-i2p-repo>
</div>

<div *ngIf="selectedTemplate === 'co-innovation'">
    <app-coinnovation-repo></app-coinnovation-repo>
</div>

<div *ngIf="selectedTemplate === 'important-rep'">
    <app-important-info-repo></app-important-info-repo>
</div>