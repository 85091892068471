<div>
    <form name="newForm">
        <div class="form-group" style="width: 90%; margin: 2% auto;">
            <label for="address">
                <h4 style="color:#3dcd58">{{ "home.home" | translate }}</h4>
            </label>
            <div>
                <div id="tabHeader">
                    <button id="tabPanel1" [ngClass]="{ active: selectedTab === 'Source to Contract' }"
                        (click)="tabClick(1);cancelHandlerHome()">{{ "home.a" | translate
                        }}</button>
                    <button id="tabPanel2" [ngClass]="{ active: selectedTab === 'Supplier Relationship Management' }"
                        (click)="tabClick(2);cancelHandlerHome()">{{ "home.b" | translate
                        }}</button>
                    <button id="tabPanel3" [ngClass]="{ active: selectedTab === 'Procure to Pay' }"
                        (click)="tabClick(3);cancelHandlerHome()">{{ "home.ProcuretoPay" |
                        translate
                        }}</button>
                    <button id="tabPanel4" [ngClass]="{ active: selectedTab === 'Supply Chain Management' }"
                        (click)="tabClick(4);cancelHandlerHome()">{{ "home.SCM" | translate
                        }}</button>
                    <button id="tabPanel5" [ngClass]="{ active: selectedTab === 'Co-Innovation' }"
                        (click)="tabClick(5);cancelHandlerHome()">{{ "home.coInnovations" |
                        translate
                        }}</button>
                    <button id="tabPanel6" [ngClass]="{ active: selectedTab === 'I2P' }"
                        (click)="tabClick(6);cancelHandlerHome()">{{ "home.i2p" | translate
                        }}</button>
                </div>
                <se-block class="tab-panel" id="tabPanel1">
                    <se-table class="block-content-border">
                        <kendo-grid [sortable]="true" style="height:450px !important;" class="k-button-group"
                            [pageSize]="pageSize" [skip]="skip" [sort]="sort" [pageable]="true"
                            (pageChange)="onPageChange($event)" (edit)="cellClickHandlerHome($event)"
                            [kendoGridBinding]="AdminHome || []" (cancel)="cancelHandlerHome()"
                            (save)="saveCurrentHome()">
                            <ng-template kendoGridToolbarTemplate style="padding-bottom: 1%">
                            </ng-template>
                            <kendo-grid-column *ngIf="!portalTranslate" [headerStyle]="{
                   border: 'none',
                   'background-color': '#ededed',
                   'font-weight': 'bold',
                   color: '#97989b',
                   'padding-left': '10px !important'
                   }" [style]="{height: '50px', 'text-align': 'start'}" field="nameEn"
                                title='{{ "home.Module" | translate }}'>
                            </kendo-grid-column>
                            <kendo-grid-column *ngIf="portalTranslate" [headerStyle]="{
                   border: 'none',
                   'background-color': '#ededed',
                   'font-weight': 'bold',
                   color: '#97989b',
                   'padding-left': '10px !important'
                   }" [style]="{height: '50px', 'text-align': 'start'}" field="nameCh" title='模块'></kendo-grid-column>
                            <kendo-grid-column maxlength="300" *ngIf="!portalTranslate" [headerStyle]="{
                   border: 'none',
                   'background-color': '#ededed',
                   'font-weight': 'bold',
                   color: '#97989b',
                   'padding-left': '10px !important'
                   }" [style]="{height: '50px', 'text-align': 'start'}" maxlength="300" field="descriptionEn"
                                title='{{ "home.description" | translate }}'>
                                <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup"
                                    let-isNew="isNew">
                                    <input #input class="k-textbox k-input k-rounded-md"
                                        [formControl]="formGroup.get(column.field)">
                                    <div *ngIf="formGroup.get(column.field).value?.length<300"
                                        style="color: darkorange;font-weight: bold;">
                                        {{ 300-formGroup.get(column.field).value?.length}} out of 300 character left.
                                    </div>
                                    <div *ngIf="formGroup.get(column.field).value?.length>300"
                                        style="color: red;font-weight: bold;">
                                        Maximum size limit is 300 character.
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column *ngIf="portalTranslate" [headerStyle]="{
                   border: 'none',
                   'background-color': '#ededed',
                   'font-weight': 'bold',
                   color: '#97989b',
                   'padding-left': '10px !important'
                   }" [style]="{height: '50px', 'text-align': 'start'}" field="descriptionCh" title='描述'>
                                <ng-template kendoGridEditTemplate let-column="column" let-formGroup="formGroup"
                                    let-isNew="isNew">
                                    <input #input class="k-textbox k-input k-rounded-md"
                                        [formControl]="formGroup.get(column.field)">
                                    <div *ngIf="formGroup.get(column.field).value.length<300"
                                        style="color: darkorange;font-weight: bold;">
                                        剩余 300 个字符中的 {{ 300-formGroup.get(column.field).value.length}} 个。
                                    </div>
                                    <div *ngIf="formGroup.get(column.field).value.length>300"
                                        style="color: red;font-weight: bold;">
                                        最大大小限制为 300 个字符。
                                    </div>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-command-column *ngIf="!portalTranslate" title='{{ "home.action1" | translate }}'
                                [headerStyle]="{border: 'none',
                   'background-color': '#ededed',
                   'font-weight': 'bold',
                   color: '#97989b',
                   'padding-left': '10px !important'}">
                                <ng-template kendoGridCellTemplate let-isNew="isNew">
                                    <se-icon kendoGridEditCommand class="icon-style">
                                        action_editor
                                    </se-icon>
                                    <se-icon kendoGridSaveCommand class="icon-style">
                                        action_save
                                    </se-icon>
                                    <se-icon kendoGridCancelCommand class="icon-style">
                                        action_close
                                    </se-icon>
                                </ng-template>
                                <div kendoTooltip style="text-align: center; padding-top: 10px;">
                                    <button kendoButton title="Discards all changes">Cancel</button>
                                </div>
                            </kendo-grid-command-column>
                            <kendo-grid-command-column *ngIf="portalTranslate" title='行动' [headerStyle]="{border: 'none',
                   'background-color': '#ededed',
                   'font-weight': 'bold',
                   color: '#97989b',
                   'padding-left': '10px !important'}">
                                <ng-template kendoGridCellTemplate let-isNew="isNew">
                                    <se-icon kendoGridEditCommand class="icon-style">
                                        action_editor
                                    </se-icon>
                                    <se-icon kendoGridSaveCommand class="icon-style">
                                        action_save
                                    </se-icon>
                                    <se-icon kendoGridCancelCommand class="icon-style">
                                        action_close
                                    </se-icon>
                                </ng-template>
                            </kendo-grid-command-column>
                        </kendo-grid>
                    </se-table>
                </se-block>
            </div>
        </div>
    </form>
</div>
<kendo-dialog title="Success Message" *ngIf="homeEditSave || editPPsave" (close)="close()">
    <p style="font-size: 17px;font-weight:6500;font-family: Arial, Helvetica, sans-serif;">{{ "home.dataUploadSuccess" |
        translate }}
    </p>
    <kendo-dialog-actions>
        <button kendoButton (click)="close()" themeColor="primary">ok</button>
    </kendo-dialog-actions>
</kendo-dialog>