import { Node, Fragment } from 'prosemirror-model';
import { TextSelection, AllSelection, NodeSelection } from 'prosemirror-state';
import { parseContent } from './source';
export const changeStylesString = (styleText, newStyle) => {
  const styleToChange = newStyle.style;
  const regExp = newStyle.value;
  const newValue = newStyle.newValue;
  if (!styleText) {
    return {
      changed: false,
      style: null
    };
  }
  const styles = styleText.split(/\s*;\s*/).filter(s => Boolean(s));
  const filtered = styles.filter(s => {
    const nameValue = s.split(/\s*:\s*/);
    return !(nameValue[0].toLowerCase() === styleToChange && regExp.test(nameValue[1]));
  });
  if (newValue) {
    filtered.push(`${styleToChange}: ${newValue}`);
  }
  return {
    style: filtered.join('; ') + (filtered.length ? ';' : ''),
    changed: Boolean(newValue) || filtered.length !== styles.length
  };
};
const reAnyValue = /^.+$/;
export function setNodeStyle(nodeAttrs, styleType, value) {
  let attrs;
  if (new RegExp('[^-]?' + styleType + ':').test(nodeAttrs.style || '')) {
    const {
      style
    } = changeStylesString(nodeAttrs.style || '', {
      style: styleType,
      value: reAnyValue,
      newValue: value
    });
    attrs = Object.assign(Object.assign({}, nodeAttrs), {
      style
    });
  } else if (nodeAttrs.style) {
    attrs = Object.assign(Object.assign({}, nodeAttrs), {
      style: nodeAttrs.style.replace(/;$/, '') + '; ' + styleType + ': ' + value + ';'
    });
  } else {
    attrs = Object.assign(Object.assign({}, nodeAttrs), {
      style: styleType + ': ' + value + ';'
    });
  }
  return attrs;
}
/**
 * Determines if a given node type can be inserted at the current cursor position.
 */
export const canInsert = (state, nodeType) => {
  let $from = state.selection.$from;
  for (let d = $from.depth; d >= 0; d--) {
    let index = $from.index(d);
    if ($from.node(d).canReplaceWith(index, index, nodeType)) {
      return true;
    }
  }
  return false;
};
const getTypeName = n => {
  return n instanceof Node ? n.type.name : n.name;
};
const findNthParentNode = (predicate, depth = 1) => {
  return selection => {
    const {
      $from
    } = selection;
    for (let i = $from.depth; i > 0; i--) {
      const node = $from.node(i);
      if (predicate(node)) {
        depth = depth - 1;
        if (depth === 0) {
          return {
            depth: i,
            node
          };
        }
      }
    }
  };
};
export const findNthParentNodeOfType = (nodeType, depth = 1) => {
  return selection => {
    return findNthParentNode(node => getTypeName(node) === getTypeName(nodeType), depth)(selection);
  };
};
export function parentNode(pos, predicate) {
  for (let depth = pos.depth; depth > 0; depth--) {
    let node = pos.node(depth);
    if (predicate(node)) {
      return {
        node,
        depth
      };
    }
  }
  return null;
}
const filterEmptyAttrs = attrs => {
  const result = {};
  for (let attr in attrs) {
    if (attr && (attrs[attr] || attrs[attr] === 0)) {
      result[attr] = attrs[attr];
    }
  }
  return result;
};
/**
 * Inserts the given node at the place of current selection.
 */
export const insertNode = (node, scrollIntoView) => (state, dispatch) => {
  const {
    selection,
    tr
  } = state;
  if (selection instanceof NodeSelection && selection.node.type.name === node.type.name && node.isLeaf && !node.isText && node.nodeSize === 1) {
    tr.setNodeMarkup(selection.from, null, Object.assign(Object.assign({}, filterEmptyAttrs(selection.node.attrs)), filterEmptyAttrs(node.attrs)));
    tr.setSelection(NodeSelection.create(tr.doc, selection.from));
  } else {
    tr.replaceSelectionWith(node);
  }
  if (scrollIntoView) {
    tr.scrollIntoView();
  }
  dispatch(tr);
};
export const hasSameMarkup = (dom1, dom2, schema, parseOptions) => {
  const fragment1 = Fragment.from(parseContent(dom1, schema, parseOptions));
  const fragment2 = Fragment.from(parseContent(dom2, schema, parseOptions));
  return fragment1.eq(fragment2);
};
export const getSelectionText = state => {
  const sel = state.selection;
  if (sel instanceof TextSelection || sel instanceof AllSelection) {
    const fragment = sel.content().content;
    return fragment.textBetween(0, fragment.size);
  }
  return '';
};
export const getNodeFromSelection = state => {
  if (state.selection instanceof NodeSelection) {
    return state.selection.node;
  }
};
/**
 * Returns the text from the selection if only text is selected on a single line.
 * If selection contains leaf nodes (br, image) between text elements or
 * text from multiple block nodes, the function will return empty string.
 *
 * Useful for values of the inputs of Link and Find&Replace dialogs where the inputs value has been retrieved from the selection and
 * should be single line text only.
 */
export const selectedLineTextOnly = state => {
  let result = '',
    hasLeafs = false;
  const {
    selection,
    doc
  } = state;
  const {
    $from,
    $to,
    from,
    to
  } = selection;
  if ($from.sameParent($to)) {
    doc.nodesBetween(from, to, node => {
      hasLeafs = hasLeafs || node.isLeaf && !node.isText;
    });
    if (!hasLeafs) {
      result = getSelectionText(state);
    }
  }
  return result;
};
/**
 * Used by ViewHtml/ViewSource dialogs for making the HTML more readable.
 */
export const indentHtml = content => {
  return content.replace(/<\/(p|li|ul|ol|h[1-6]|table|tr|td|th)>/ig, '</$1>\n').replace(/<(ul|ol)([^>]*)><li/ig, '<$1$2>\n<li').replace(/<br \/>/ig, '<br />\n').replace(/\n$/, '');
};
export const shallowEqual = (object1, object2) => {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  return keys1.length === keys2.length && keys1.every(k => object1[k] === object2[k]);
};
const applyToWordDefault = {
  before: /[^ !,?.\[\]{}()]+$/i,
  after: /^[^ !,?.\[\]{}()]+/i
};
/**
 * if options.applyToWord is set, expands the selection to the word where the cursor is and
 * returns modified state and dispatch.
 */
export const expandSelection = (state, dispatch, options) => {
  if (!options.applyToWord || !state.selection.empty) {
    return {
      state,
      dispatch
    };
  }
  const applyToWordOptions = options.applyToWord === true ? applyToWordDefault : options.applyToWord;
  let initialPosition = null;
  const tr = state.tr;
  const selection = state.selection;
  const before = selection.$head.nodeBefore;
  const after = selection.$head.nodeAfter;
  if (before && before.type.name === 'text' && before.text && after && after.type.name === 'text' && after.text) {
    const children = [];
    selection.$head.parent.descendants((node, pos) => {
      children.push({
        node,
        pos
      });
      return false;
    });
    let cursor = selection.$head.parentOffset;
    const nodeIndex = children.findIndex(({
      node,
      pos
    }) => pos <= cursor && pos + node.nodeSize >= cursor);
    let text = children[nodeIndex].node.text;
    let skip = false;
    for (let i = nodeIndex - 1; i >= 0; i--) {
      const element = children[i];
      if (!skip && element && element.node.type.name === 'text') {
        text = element.node.text + text;
      } else {
        skip = true;
        cursor -= element.node.nodeSize;
      }
    }
    for (let i = nodeIndex + 1; i < children.length; i++) {
      const element = children[i];
      if (element && element.node.type.name === 'text') {
        text = text + element.node.text;
      } else {
        break;
      }
    }
    const textBefore = text.substring(0, cursor);
    const textAfter = text.substring(cursor);
    const matchBefore = applyToWordOptions.before.exec(textBefore);
    const matchAfter = applyToWordOptions.after.exec(textAfter);
    if (matchBefore && matchAfter) {
      const extendLeft = matchBefore[0].length;
      const extendRight = matchAfter[0].length;
      const pos = initialPosition = selection.from;
      tr.setSelection(TextSelection.create(state.doc, pos - extendLeft, pos + extendRight));
      const cmdState = {
        tr,
        selection: tr.selection,
        doc: tr.doc,
        storedMarks: null,
        schema: tr.doc.type.schema
      };
      const cmdDispatch = tran => {
        tran.setSelection(TextSelection.create(tran.doc, initialPosition));
        dispatch(tran);
      };
      return {
        state: cmdState,
        dispatch: cmdDispatch
      };
    }
  }
  return {
    state,
    dispatch
  };
};
/**
 * if options.applyToWord is set, expands the selection to the word where the cursor is and
 * use the modified state for the passed command.
 * Designed to work with toggleInlineFormat, applyInlineStyle and applyLink functions.
 *
 * Example:
 * const applyToWord: boolean|{before: RegExp, after: RegExp} = true;
 * // or applyToWord = { before: /[^ !,?.\[\]{}()]+$/i, after: /^[^ !,?.\[\]{}()]+/i }};
 *
 * const command = expandToWordWrap(toggleInlineFormat, {...bold, applyToWord );
 * command(view.state, view.dispatch);
 */
export const expandToWordWrap = (command, options) => {
  return (state, dispatch, _view) => {
    const {
      state: cmdState,
      dispatch: cmdDispatch
    } = expandSelection(state, dispatch, options);
    return command(options)(cmdState, cmdDispatch);
  };
};
export const parseStyle = styleText => {
  const styles = (styleText || '').split(/\s*;\s*/).filter(Boolean).map(s => {
    const nameValue = s.split(/\s*:\s*/);
    return {
      [nameValue[0]]: nameValue[1]
    };
  }).reduce((acc, val) => Object.assign(Object.assign({}, acc), val), {});
  return styles;
};
export const applyStyle = (styleText, styleType, styleValue) => {
  const styles = parseStyle(styleText);
  styles[styleType] = styleValue;
  const result = Object.keys(styles).map(name => styles[name] ? `${name}: ${styles[name]}` : null).filter(Boolean).join('; ');
  return result ? result + ';' : null;
};
const setStyleAttr = (element, styleString) => {
  const styles = parseStyle(styleString);
  for (let style in styles) {
    if (style && typeof element.style[style] !== 'undefined') {
      element.style[style] = styles[style];
    }
  }
};
export const setAttribute = (node, attrName, value) => {
  const current = node.getAttribute(attrName);
  if (value !== undefined && value !== current) {
    if (attrName === 'style') {
      node.removeAttribute(attrName);
      setStyleAttr(node, value);
    } else {
      node.setAttribute(attrName, value);
    }
  } else if (value === undefined) {
    node.removeAttribute(attrName);
  }
};