export function ssecMiddleware(options) {
  return next => async args => {
    let input = {
      ...args.input
    };
    const properties = [{
      target: "SSECustomerKey",
      hash: "SSECustomerKeyMD5"
    }, {
      target: "CopySourceSSECustomerKey",
      hash: "CopySourceSSECustomerKeyMD5"
    }];
    for (const prop of properties) {
      const value = input[prop.target];
      if (value) {
        const valueView = ArrayBuffer.isView(value) ? new Uint8Array(value.buffer, value.byteOffset, value.byteLength) : typeof value === "string" ? options.utf8Decoder(value) : new Uint8Array(value);
        const encoded = options.base64Encoder(valueView);
        const hash = new options.md5();
        hash.update(valueView);
        input = {
          ...input,
          [prop.target]: encoded,
          [prop.hash]: options.base64Encoder(await hash.digest())
        };
      }
    }
    return next({
      ...args,
      input
    });
  };
}
export const ssecMiddlewareOptions = {
  name: "ssecMiddleware",
  step: "initialize",
  tags: ["SSE"],
  override: true
};
export const getSsecPlugin = config => ({
  applyToStack: clientStack => {
    clientStack.add(ssecMiddleware(config), ssecMiddlewareOptions);
  }
});