import { Component, OnInit, Input, OnChanges, TemplateRef, inject, ViewEncapsulation } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '../vendor.service';
import { NgbCarouselConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-portal-principal',
  templateUrl: './portal-principal.component.html',
  styleUrls: ['./portal-principal.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [NgbCarouselConfig]
})
export class PortalPrincipalComponent implements OnInit, OnChanges {
  @Input() portalTranslate: any
  portalpDataEN: any;
  portalpDataCH: any;
  userPortalData: any;
  preSigned: any;
  ppVideo: any;
  //GetRepoList
  getRepoList: any;
  repoURL: any;
  key: any;
  showDafaults = true;
  selectedView: string = 'default';
  public contractClicked = false;
  public companyClicked = false;
  public eSourcingClicked = false;
  public supplierQualificationClicked = false;
  public actionPlanClicked = false;
  public eSurveyClicked = false;

  private modalService = inject(NgbModal);

  // keyValue: [any, any] = key.split("/");
  // repoCategory: any = keyValue[0];
  // repoFileName: any = keyValue[1];

  images = ['700.png', '800.png', '900.png'].map((n) => `/assets/images/sample/repo/${n}`);

  constructor(public translate: TranslateService, public _service: VendorService, config: NgbCarouselConfig, private router: Router) {
    // customize default values of carousels used by this component tree
    config.interval = 2000;
    config.wrap = false;
    config.keyboard = false;
    config.pauseOnHover = true;
  }

  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }



  ngOnInit(): void {
    this._service.GetImageAPI.subscribe((data) => {
      if (data) {
        this.preSigned = JSON.parse(sessionStorage.getItem('GetImage') || '{}');
        this.ppVideo = this.preSigned.URL_VDO;
      }
    });
    this._service.PortalAPI.subscribe((data) => {
      if (data) {
        this.userPortalData = JSON.parse(sessionStorage.getItem('portalData') || '{}');
        this.portalpDataEN = this.userPortalData.textEn === null ? '' : this.userPortalData.textEn;
        this.portalpDataCH = this.userPortalData.textCh === null ? '' : this.userPortalData.textCh;
      }
    });
    this._service.getRepoData();
  }

  getInnerHTML(val: string) {
    return val.replace(/\r?\n|\r/g, '<br/>');
  }

  onOpenUrl(url: string) {
    window.open(url);
  }

  onKnldgClick(e: any) {
    this.showDafaults = !this.showDafaults;

  }

  communicationClick(e: any) {

  }

  openVerticallyCentered(content: TemplateRef<any>) {
    this.modalService.open(content, { centered: true, size: 'lg' });
  }

  srmTileClicked() {
    this.contractClicked = true;
    this.companyClicked = true;
    this.eSourcingClicked = true;
    this.supplierQualificationClicked = true;
    this.actionPlanClicked = true;
    this.eSurveyClicked = true;
  }

}
