
import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '../../vendor.service';
import { Router } from '@angular/router';

interface Item {
  text: string;
  value: string | number;
}

@Component({
  selector: 'app-admin-view',
  templateUrl: './admin-view.component.html',
  styleUrls: ['./admin-view.component.scss']
})
export class AdminViewComponent implements OnInit, OnChanges {
  public loggedInCode: any;
  public loggedInlanguage: any;
  portalDetails: any;
  public editPPsave = false;
  userPortalDataLatest: any;
  public userPersonaPortalUpdateData: any;
  public opened = false;
  public emptyImage = false;
  public file: any;
  public overWrite: any;
  preSignedEn: any;
  preSignedCh: any;
  ppVideo: any;
  public imageExist!: boolean;
  fileSize: any;
  public fileExtention: any;
  public fileExtValidation = false;
  baseImageURL!: string | ArrayBuffer | null;
  public csTablePopupMsg = false;
  public homeEditSave = false;
  public cspopupmsg = false;
  public feedbackForm = false;
  public ratingCounts: any;
  public star1!: number;
  public star2!: number;
  public star3!: number;
  public star4!: number;
  public star5!: number;
  public normalVerticalline = true;
  public adminVerticalline = false;
  public fileNameEn: any;
  public fileNameCh: any;
  public barColor: any;
  contactDetails: any;
  portalpDataEN: any = "";
  portalpDataCH: any = "";
  public feedbackdownloadData: any;
  public totalnumberofuser!: number;
  public star1color!: number;
  public star2color!: number;
  public star3color!: number;
  public star4color!: number;
  public star5color!: number;
  public userPortalData: any;
  public userContactData: any;
  contactNewDataEn = "";
  contactNewDataCh = "";
  userContactUpdateData: any;
  public userPersonaContactUpdateData: any;

  public newForm: FormGroup;
  public contactForm: FormGroup;

  @Input() portalTranslate!: boolean;
  // @Input() IDMSPersona;
  // @Input() isadminView;


  public listItemsEnglish: Array<Item> = [
    { text: "Home", value: 'scrollisHome' },
    { text: "Portal Principal", value: 'scrollisPortal' },
    { text: "Contact Support", value: 'scrollisContact' },
    { text: "Usage Statistics", value: 'scrollisFeedback' },
    { text: "Feedback", value: 5 },
  ];

  public listItemsChina: Array<Item> = [
    { text: "主页", value: 1 },
    { text: "平台原则", value: 2 },
    { text: "联系支持团队", value: 3 },
    { text: "使用情况统计", value: 4 },
    { text: "反馈", value: 5 },
  ];

  public listItems = this.listItemsEnglish
  public selectedItem: Item = this.listItems[0];

  public switchLanguage($event: { target: { value: any; }; }): void {
    const value = $event.target.value;
    if (value == "Ch") {
      this.translate.use('cn');
      this.portalTranslate = true;
      this.listItems = [...this.listItemsChina]
    }
    else {
      this.translate.use('en');
      this.portalTranslate = false;
      this.listItems = [...this.listItemsEnglish]
    }

  }

  public selectionChange(event: any): void {
    this.scroll(event.text, event.value)
  }

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  constructor(public translate: TranslateService, private fb: FormBuilder, private _service: VendorService, private router: Router) {
    this.newForm = fb.group({
      address: new FormControl("The Schneider Supplier Portal aims to consolidate your navigation and work experience through the multiple digital applications of Schneider Electric. The principle is to provide one place to access at a glance to end to end interactions with you."),
      address1: new FormControl("施耐德供应商门户旨在加强您在施耐德多个电子平台间导航和工作体验。原则是提供一个平台以便与您端到端的交互。")

    });
    this.contactForm = this.fb.group({
      contact: new FormControl("In case of any help and support required, please reach out to the respective support contacts as listed below by selecting the correct Application that is affected:"),
      contact1: new FormControl("若需任何帮助和支持，请联系如下相关支持团队。"),

    });
  }

  ngOnInit(): void {
    this._service.getTrafficMonthlyDataNew();
    this.adminView()
    this._service.PortalAPI.subscribe(data => {
      if (data) {
        this.userPortalData = JSON.parse(sessionStorage.getItem('portalData') || '{}');
        this.portalpDataEN = this.userPortalData.textEn === null ? '' : this.userPortalData.textEn;
        this.portalpDataCH = this.userPortalData.textCh === null ? '' : this.userPortalData.textCh;
      }
    })
    this._service.ContactAPI.subscribe(data => {
      console.log(this.userContactData, "contact")
      if (data) {
        this.userContactData = JSON.parse(sessionStorage.getItem('ContactData') || '{}');
        console.log(this.userContactData, "contact")

        this.contactNewDataEn = this.userContactData.textEn === null ? '' : this.userContactData.textEn;
        this.contactNewDataCh = this.userContactData.textCh === null ? '' : this.userContactData.textCh;
      }
    })
  }

  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  public scroll(event: any, scrollItem: string): void {
    if (scrollItem) {
      const element = document.getElementById(scrollItem) as HTMLElement;

      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }

  getInnerHTML(val: string) {
    return val.replace(/\r?\n|\r/g, '<br/>');
  }

  public update(): void {

    if (!this.portalTranslate) {
      const addressValue = {
        textCh: this.newForm.controls['address1'].value,
        textEn: this.newForm.controls['address'].value
      }

      addressValue.textCh = this.portalDetails.textCh

      sessionStorage.setItem('portalData', JSON.stringify(addressValue));

    }

    if (this.portalTranslate) {
      const addressValue = {
        textCh: this.newForm.controls['address1'].value,
        textEn: this.newForm.controls['address'].value
      }

      addressValue.textEn = this.portalDetails.textEn;

      sessionStorage.setItem('portalData', JSON.stringify(addressValue));
    }

    if (sessionStorage.getItem('portalData')) {
      this.editPPsave = true;
      this._service.getportalupdatePrincipal();
      this.userPortalDataLatest = JSON.parse(sessionStorage.getItem('portalData') || '{}');
      this.userPersonaPortalUpdateData = this.userPortalDataLatest.textEn;
      this.newForm.controls['address'].get('portalData');
      this.newForm.controls['address1'].get('portalData');
    }

    else {

      this._service.getportalupdatePrincipal();
      this._service.PortalupdateAPI.subscribe((data) => {
        if (data) {
          this.editPPsave = true;
          this.userPortalDataLatest = JSON.parse(sessionStorage.getItem('portalData') || '{}');
          this.userPersonaPortalUpdateData = this.userPortalDataLatest.textEn;
          this.newForm.controls['address'].get('portalData');
          this.newForm.controls['address1'].get('portalData');
        }
      });
    }
  }

  // public imageSubmit(): void {
  //   this._service.getUploadImage();
  //   this._service.UploadImageAPI.subscribe((data) => {
  //     this.preSigned = JSON.parse(sessionStorage.getItem('UploadImageURL') || '{}');
  //     if (data) {
  //       // this.getImageSubmit()
  //       if(!this.file){
  //         this.opened = false;
  //         this.emptyImage=true;
  //       }

  //       else if(this.overWrite)
  //         {
  //           this.http.put(this.preSigned, this.file).subscribe(res => {

  //             this.getImageSubmit();

  //           });
  //           this.opened = true;
  //           this.emptyImage=false;
  //         }

  //     }
  //   }
  //   );
  // }

  // public onFileChange(event): void {
  //   if (event.target.files.length > 0) {
  //     this.imageExist = true;
  //     this.file = event.target.files[0];
  //     this.fileSize = Math.round(event.target.files[0].size / 1024);
  //     // 20 MB = 20480 KB

  //     this.fileExtention = this.file.name.split('.').pop().toLowerCase();

  //     if (this.fileExtention !== 'jpg' && this.fileExtention !== 'jpeg' && this.fileExtention !== 'png') {
  //       this.fileExtValidation = true;

  //     } else {
  //       this.fileExtValidation = false;
  //     }

  //     this.myForm.patchValue({
  //       fileSource: this.file.name
  //     });
  //     const reader = new FileReader();
  //     reader.readAsDataURL(this.file);
  //     reader.onload = () => {
  //       this.baseImageURL = reader.result;
  //     };
  //   }
  //   sessionStorage.setItem('UploadImage', JSON.stringify(this.file.name));
  //   sessionStorage.setItem('Language', JSON.stringify(this.portalTranslate));
  // }

  public close(): void {
    this.opened = false;
    this.emptyImage = false;
    this.imageExist = false;
    this.homeEditSave = false;
    this.editPPsave = false;
    this.cspopupmsg = false;
    this.csTablePopupMsg = false;
    this.feedbackForm = false;
  }

  // public overWriteImage(){
  //   this.overWrite = true;
  //   this.imageExist = false;
  //  }

  public adminView(): void {
    this.feedbackdownloadlinkbutton();
    this.normalVerticalline = false;
    this.adminVerticalline = true;

    // this.preSigned = JSON.parse(sessionStorage.getItem('GetImage') || '{}');
    // this.preSignedEn = this.preSigned.URL_EN;
    // this.preSignedCh = this.preSigned.URL_CH;
    // this.ppVideo = this.preSigned.URL_VDO;
    // image name popup starts here
    // const url = new URL(this.preSignedEn);
    // this.fileNameEn = url.pathname.replace(/^.*[\\\/]/, "");
    // console.log("imageurl", this.fileNameEn);
    // const urlCh = new URL(this.preSignedCh);
    // this.fileNameCh = urlCh.pathname.replace(/^.*[\\\/]/, "");
    // console.log("imageurl", this.fileNameCh);
    // this.preSignedCh = this.preSigned.URL_CH;
    // this.isadminView = true;
    this.barColor = "#3dcd58";
    // this.getImageSubmit();

    // this.isnormalView = false;
    // this.getPortalData();
    // this.getContactData();
    // this.getImageSubmit();
    this.ratingSummany();
    this.portalDetails = JSON.parse(sessionStorage.getItem('portalData') || '{}');
    this.contactDetails = JSON.parse(sessionStorage.getItem('ContactData') || '{}');
    if (this.portalDetails.textEn === null && this.portalDetails.textCh === null) {
      this.newForm = this.fb.group({
        address: new FormControl("The Schneider Supplier Portal aims to consolidate your navigation and work experience through the multiple digital applications of Schneider Electric. The principle is to provide one place to access at a glance to end to end interactions with you."),
        address1: new FormControl("施耐德供应商门户旨在加强您在施耐德多个电子平台间导航和工作体验。原则是提供一个平台以便与您端到端的交互。")

      });

    } else {
      this.newForm = this.fb.group({
        address: new FormControl(this.portalDetails.textEn),
        address1: new FormControl(this.portalDetails.textCh)

      });
    }
    if (this.contactDetails.textEn === null && this.contactDetails.textCh === null) {
      this.contactForm = this.fb.group({
        contact: new FormControl("In case of any help and support required, please reach out to the respective support contacts as listed below by selecting the correct Application that is affected:"),
        contact1: new FormControl("若需任何帮助和支持，请联系如下相关支持团队。"),

      });
    }
    else {
      this.contactForm = this.fb.group({
        contact: new FormControl(this.contactDetails.textEn),
        contact1: new FormControl(this.contactDetails.textCh),

      });
    }

  }

  public feedbackdownloadlinkbutton() {
    this._service.getfeedbackDownloadLink();
    this.feedbackdownloadData = JSON.parse(sessionStorage.getItem('feedbackDownloadLink') || '{}')
  }

  public ratingSummany() {
    this._service.ratingSummary();
    this.ratingCounts = JSON.parse(sessionStorage.getItem('ratingSummary') || '{}');
    this.ratingCounts.getRatingSummary.map((ele: { rateIndex: number; count: any; }) => {
      if (ele.rateIndex == 1) {
        this.star1 = ele.count;

      }
      if (ele.rateIndex == 2) {
        this.star2 = ele.count;
      }
      if (ele.rateIndex == 3) {
        this.star3 = ele.count;
      }
      if (ele.rateIndex == 4) {
        this.star4 = ele.count;
      }
      if (ele.rateIndex == 5) {
        this.star5 = ele.count;
      }
      this.totalnumberofuser = this.star1 + this.star2 + this.star3 + this.star4 + this.star5;
      this.star1color = (this.star1 / this.totalnumberofuser) * 100;
      this.star2color = (this.star2 / this.totalnumberofuser) * 100;
      this.star3color = (this.star3 / this.totalnumberofuser) * 100;
      this.star4color = (this.star4 / this.totalnumberofuser) * 100;
      this.star5color = (this.star5 / this.totalnumberofuser) * 100;

    })
  }

  onSubmit(): void {
    console.log(this.contactDetails, this.contactForm.controls['contact1'].value, this.contactForm.controls['contact'].value)

    if (!this.portalTranslate) {
      const contactValue = {
        textCh: this.contactForm.controls['contact1'].value,
        textEn: this.contactForm.controls['contact'].value
      }

      contactValue.textCh = this.contactDetails.textCh;

      sessionStorage.setItem('ContactData', JSON.stringify(contactValue));

    }

    if (this.portalTranslate) {
      const contactValue = {
        textCh: this.contactForm.controls['contact1'].value,
        textEn: this.contactForm.controls['contact'].value
      }

      contactValue.textEn = this.contactDetails.textEn;

      sessionStorage.setItem('ContactData', JSON.stringify(contactValue));
    }

    if (sessionStorage.getItem('ContactData')) {
      this._service.getContactupdate();
      this.cspopupmsg = true;
      this.userContactUpdateData = JSON.parse(sessionStorage.getItem('ContactData') || '{}');
      this.userPersonaContactUpdateData = this.userContactUpdateData.textEn;
      this.contactForm.controls['contact'].get('ContactData');
      this.contactForm.controls['contact1'].get('ContactData');
    }

    else {
      this._service.getContactupdate();
      this._service.ContactupdateAPI.subscribe((data) => {
        if (data) {
          this.cspopupmsg = true;
          this.userPersonaContactUpdateData = JSON.parse(sessionStorage.getItem('ContactData') || '{}');
          this.userPersonaContactUpdateData = this.userContactUpdateData.textEn;
          this.contactForm.controls['contact'].get('ContactData');
          this.contactForm.controls['contact1'].get('ContactData');
        }
      });
    }
  }

}
