import { SYMBOL_ITERATOR } from "./symbols.mjs";

/* eslint-disable no-redeclare */
// $FlowFixMe
var arrayFrom = Array.from || function (obj, mapFn, thisArg) {
  if (obj == null) {
    throw new TypeError('Array.from requires an array-like object - not null or undefined');
  } // Is Iterable?

  var iteratorMethod = obj[SYMBOL_ITERATOR];
  if (typeof iteratorMethod === 'function') {
    var iterator = iteratorMethod.call(obj);
    var result = [];
    var step;
    for (var i = 0; !(step = iterator.next()).done; ++i) {
      result.push(mapFn.call(thisArg, step.value, i)); // Infinite Iterators could cause forEach to run forever.
      // After a very large number of iterations, produce an error.

      /* istanbul ignore if */

      if (i > 9999999) {
        throw new TypeError('Near-infinite iteration.');
      }
    }
    return result;
  } // Is Array like?

  var length = obj.length;
  if (typeof length === 'number' && length >= 0 && length % 1 === 0) {
    var _result = [];
    for (var _i = 0; _i < length; ++_i) {
      if (Object.prototype.hasOwnProperty.call(obj, _i)) {
        _result.push(mapFn.call(thisArg, obj[_i], _i));
      }
    }
    return _result;
  }
  return [];
};
export default arrayFrom;