import { chainCommands, exitCode, newlineInCode, createParagraphNear, liftEmptyBlock, splitBlockKeepMarks, deleteSelection, joinBackward, selectNodeBackward } from 'prosemirror-commands';
import { undo, redo } from 'prosemirror-history';
import { undoInputRule } from 'prosemirror-inputrules';
import { splitListItemKeepMarks } from '../lists';
import { bold, italic, underline } from './commands';
import { toggleInlineFormat } from './../inline-style';
import { expandToWordWrap } from '../utils';
const mac = typeof navigator !== 'undefined' ? /Mac/.test(navigator.platform) : false;
// https://github.com/ProseMirror/prosemirror-example-setup/blob/master/src/keymap.js
export const buildKeymap = (schema, options) => {
  const keys = {};
  const applyToWord = options && options.applyToWord || false;
  keys['Mod-z'] = undo;
  keys['Shift-Mod-z'] = redo;
  // tslint:disable-next-line:no-string-literal
  keys['Backspace'] = chainCommands(undoInputRule, deleteSelection, joinBackward, selectNodeBackward);
  // tslint:disable-next-line:no-string-literal
  keys['Enter'] = chainCommands(newlineInCode, createParagraphNear, liftEmptyBlock, splitBlockKeepMarks);
  if (!mac) {
    keys['Mod-y'] = redo;
  }
  if (schema.marks.strong) {
    keys['Mod-b'] = expandToWordWrap(toggleInlineFormat, Object.assign(Object.assign({}, bold), {
      applyToWord
    }));
  }
  if (schema.marks.em) {
    keys['Mod-i'] = expandToWordWrap(toggleInlineFormat, Object.assign(Object.assign({}, italic), {
      applyToWord
    }));
  }
  if (schema.marks.u) {
    keys['Mod-u'] = expandToWordWrap(toggleInlineFormat, Object.assign(Object.assign({}, underline), {
      applyToWord
    }));
  }
  if (schema.nodes.hard_break) {
    const br = schema.nodes.hard_break;
    const cmd = chainCommands(exitCode, (state, dispatch) => {
      dispatch(state.tr.replaceSelectionWith(br.create()).scrollIntoView());
      return true;
    });
    keys['Shift-Enter'] = cmd;
  }
  return keys;
};
export const buildListKeymap = schema => {
  const keys = {};
  if (schema.nodes.list_item) {
    // tslint:disable-next-line:no-string-literal
    keys['Enter'] = splitListItemKeepMarks(schema.nodes.list_item);
  }
  return keys;
};