<div id="user-guide-scm-repo">
    <div class="container">
        <div class="row" *ngIf="showDafaults">
            <a class="my-4" (click)="onRepositoryClick($event)">
                << Back</a>
                    <div class="col-md-2">
                        <div class="card-group">
                            <div class="card bg-transparent border-0" (click)="onDeliveryClick($event)">
                                <img src="../assets/images/repository/Delivery.jpg" class="card-img-top">
                                <div class="card-body pb-2">
                                    <h5 class="card-title text-center">{{ "home.delivery" | translate }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card-group">
                            <div class="card bg-transparent border-0" (click)="ongenralFunClick($event)">
                                <img src="../assets/images/repository/GeneralFunctionSCM.jpg" class="card-img-top">
                                <div class="card-body pb-2">
                                    <h5 class="card-title text-center">{{ "home.generalFunctions" | translate }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card-group">
                            <!-- <div class="card bg-transparent border-0"> -->
                            <div class="card bg-transparent border-0" (click)="onOrderClick($event)">
                                <img src="../assets/images/repository/Order.jpg" class="card-img-top">
                                <div class="card-body pb-2">
                                    <h5 class="card-title text-center">{{ "home.order" | translate }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
    </div>
</div>

<div *ngIf="selectedTemplate === 'delivery'">
    <app-delivery></app-delivery>
</div>

<div *ngIf="selectedTemplate === 'genralfunctions'">
    <app-general-functions></app-general-functions>
</div>

<div *ngIf="selectedTemplate === 'order'">
    <app-order></app-order>
</div>

<div *ngIf="selectedTemplate === 'repository'">
    <app-online-repository></app-online-repository>
</div>