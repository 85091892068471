<div id="supplierchatbot">
    <button id="supplierchatbot-popup-button" (click)="openSupportPopup()">
        Supplier Portal Virtual Assistance<se-icon class="arrow-icon">arrow2_up</se-icon>
    </button>
    <div id="supplierchatbot-popup" [style.display]="isOpen ? 'block' : 'none'">
        <div id="supplierchatbot-popup-header">
            Supplier Portal Virtual Assistance
            <button id="supplierchatbot-popup-close-button" (click)="openSupportPopup()">
                <i class="fa fa-times-circle" style="font-size: 1.5em;" aria-hidden="true"></i>
            </button>
            <button id="supplierchatbot-popup-expand-button" (click)="extendWindow()" *ngIf="!isExpanded">
                <i class="fa fa-expand" aria-hidden="true"></i>
            </button>
            <button id="supplierchatbot-popup-expand-button" (click)="compressWindow()" *ngIf="isExpanded">
                <i class="fa fa-compress" aria-hidden="true"></i>
            </button>
        </div>
        <div id="supplierchatbot-popup-body">
            <div class="messages" #scrollMe>
                <div *ngFor="let message of messages" class="message">
                    <div [class]="message.type">
                        <div *ngIf="message.type === 'client'" [innerHTML]="message.message"></div>
                        <div *ngIf="message.type !== 'client'">{{ message.message }}</div>
                    </div>
                </div>
                <div *ngIf="feedbackButtonsVisible  && !feedbackSubmitted" class="d-flex gap-2">
                    <button class="btn btn-success" (click)="sendPositiveFeedback()"><i class="fa fa-thumbs-up"></i>
                        Helpful</button>
                    <button class="btn btn-danger" (click)="sendNegativeFeedback()"><i class="fa fa-thumbs-down"></i>
                        Not Helpful</button>
                </div>
                <div *ngIf="feedbackSubmitted" class="feedback-submitted-message mb-3">
                    Your feedback has been submitted successfully.
                </div>
                <div *ngIf="feedbackFormVisible" id="feedbackForm" class="mt-3" style="width: 17rem;">
                    <form [formGroup]="feedbackForm" (ngSubmit)="submitNegativeFeedback()">
                        <div class="form-group">
                            <label for="reason">Reason</label>
                            <span class="required"> * </span>
                            <select id="reason" class="form-select" formControlName="reason">
                                <option *ngFor="let option of reasonOptions" [value]="option">{{ option }}</option>
                            </select>
                        </div>

                        <div class="form-group">
                            <label for="others">Comments</label>
                            <span class="required"> * </span>
                            <textarea id="others" class="form-control" style="height: 100px;"
                                placeholder="Please enter the expected response or correct source of response or any other feedback"
                                formControlName="others"></textarea>
                        </div>

                        <button type="submit" class="mt-3 btn btn-primary">Submit Feedback</button>
                    </form>
                </div>
                <div *ngIf="loading" class="message loading-message" style="width: 100%; display: block">
                    <div class="client loading-dots"><span>.</span><span>.</span><span>.</span></div>
                </div>
            </div>
        </div>
        <form id="supplierchatbot-popup-footer" [formGroup]="chatForm">
            <input formControlName="message" type="text" id="supplierchatbot-popup-input"
                placeholder="Type your message here..." />
            <button id="supplierchatbot-popup-submit-button" [disabled]="!chatForm.valid" (click)="sendMessage()">
                Send
            </button>
        </form>
    </div>
</div>