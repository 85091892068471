<div class="mobileView" id="mobilebackground">
    <div class="mobile-dropdown-blk">
        <div class="mobile-viewport">
            <div class="mobile-bgcolor">
                <div class="d-flex flex-row mobile-background">
                    <div class="link-blk">
                        <a class="link-help" id="help" href="https://www.se.com" target="_blank">
                            <img src="../assets/images/SE_logotype_lifeison_white.svg" class="se-icon-white" />
                        </a>
                    </div>
                    <div class="mobile-header-blk">
                        <div class="d-flex flex-row">
                            <div>
                                <se-icon size="large" class="web-icon-color">
                                    web_sdk
                                </se-icon>
                            </div>
                            <div class="lang-trans-blk">
                                <select id="ddlViewBy" class="border border-0" (change)="switchLanguage($event)">
                                    <option value="en" selected="selected">En</option>
                                    <option value="Ch">中文</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="icon-blk">
                        <span class="icon-holder">
                            <div class="icon-set-blk">
                                <se-icon (click)="bellIconClick()" class="notification-icon" size="large">
                                    action_notification_default
                                </se-icon>
                                <span *ngIf="notificationCheck" class="badge">{{bellNotificatinArrayValue()}}</span>
                            </div>
                            <!-- <span (click)="onUsernameClick($event)" style="display: inline;"> <se-icon size="large" style="font-size:30px;padding-top: 0.3% !important;color:#000001; margin-right:-20px;">user_standard</se-icon></span> -->
                            <span class="position-absolute top-0 left-0" *ngIf="bellIconNotification">
                                <div>
                                    <a *ngIf="bellIconNotification">
                                        <div class="d-flex flex-row">
                                            <div>
                                                <div class="notificationIconDropDown" id="menu2">
                                                    <div class="dropdown-content lh-h2" id="myDropdown2">
                                                        <div class="d-flex flex-row lh-h2">
                                                            <div *ngIf=" notificationCheck" class="notfication-alert">
                                                                Notifications
                                                                ({{bellNotificatinArrayValue()}})</div>
                                                        </div>
                                                        <a class="dropdownitems" id="portalPrinciple">
                                                            <span *ngIf="contractDynamicDataFlag===true">
                                                                <ul (click)="notificationClicked('contract')">
                                                                    <li><b class="contract-text">{{
                                                                            "home.contracts" | translate
                                                                            }}</b></li>
                                                                    <div class="d-block marginleft-20">{{
                                                                        "home.contractNotification" | translate
                                                                        }}</div>
                                                                </ul>
                                                            </span>
                                                        </a>
                                                        <se-divider id="divider"
                                                            *ngIf="contractDynamicDataFlag===true"></se-divider>
                                                        <a class="dropdownitems" id="portalPrinciple">
                                                            <span *ngIf="myAccValue>0">
                                                                <ul (click)="notificationClicked('myAccount')">
                                                                    <li><b class="notification-clicked">{{
                                                                            "home.myacount" | translate
                                                                            }}</b></li>
                                                                    <div *ngIf="!portalTranslate"
                                                                        class="d-block marginleft-20">You have
                                                                        {{myAccValue}} document(s) to
                                                                        read</div>
                                                                    <div *ngIf="portalTranslate"
                                                                        class="d-block marginleft-20">我的帐户文档 -
                                                                        您有
                                                                        {{myAccValue}} 份文件要阅读</div>
                                                                </ul>
                                                            </span>
                                                        </a>
                                                        <se-divider id="divider" *ngIf="myAccValue>0"></se-divider>
                                                        <a class="dropdownitems" id="portalPrinciple">
                                                            <span *ngIf="esurveyDynamicDataFlag === true">
                                                                <ul (click)="notificationClicked('eSurvey')">
                                                                    <li><b class="notfication-clicked">{{
                                                                            "home.eSurvey" | translate
                                                                            }}</b></li>
                                                                    <div class="d-block marginleft-20">{{
                                                                        "home.eNotification" | translate }}
                                                                    </div>
                                                                </ul>
                                                            </span>
                                                        </a>
                                                        <se-divider id="divider"
                                                            *ngIf="esurveyDynamicDataFlag === true"></se-divider>
                                                        <a class="dropdownitems" id="portalPrinciple">
                                                            <span *ngIf="myGenValue>0">
                                                                <ul (click)="notificationClicked('generalDoc')">
                                                                    <li><b class="notification-clicked">{{
                                                                            "home.GeneralDocuments" |
                                                                            translate }}</b></li>
                                                                    <div *ngIf="!portalTranslate"
                                                                        class="d-block marginleft-20">You have
                                                                        {{myGenValue}} document(s) to
                                                                        read</div>
                                                                    <div *ngIf="portalTranslate"
                                                                        class="d-block marginleft-20">您有
                                                                        {{myGenValue}}
                                                                        个文档要阅读</div>
                                                                </ul>
                                                            </span>
                                                        </a>
                                                        <se-divider id="divider" *ngIf="myGenValue>0"></se-divider>
                                                        <a class="dropdownitems" id="portalPrinciple">
                                                            <span *ngIf="esourcingDynamicDataFlag === true">
                                                                <ul (click)="notificationClicked('eSource')">
                                                                    <li><b class="notfication-clicked">{{
                                                                            "home.esource" | translate
                                                                            }}</b></li>
                                                                    <div class="d-block marginleft-20">{{
                                                                        "home.eNotification" | translate }}
                                                                    </div>
                                                                </ul>
                                                            </span>
                                                        </a>
                                                        <se-divider id="divider"
                                                            *ngIf="esourcingDynamicDataFlag === true"></se-divider>
                                                        <a class="dropdownitems" id="portalPrinciple">
                                                            <span *ngIf="companyDynamicDataFlag===true">
                                                                <ul (click)="notificationClicked('companyInfo')">
                                                                    <li><b class="notification-clicked">{{
                                                                            "home.CompanyInformation" |
                                                                            translate }}</b></li>
                                                                    <div *ngIf="!portalTranslate"
                                                                        class="d-block marginleft-20 lh19">
                                                                        New Record Found</div>
                                                                    <div *ngIf="portalTranslate"
                                                                        class="d-block marginleft-20">
                                                                        发现新纪录</div>
                                                                </ul>
                                                            </span>
                                                        </a>
                                                        <se-divider id="divider"
                                                            *ngIf="companyDynamicDataFlag===true"></se-divider>
                                                        <a class="dropdownitems" id="portalPrinciple">
                                                            <span *ngIf="ActionPlanData?.length>0">
                                                                <ul (click)="notificationClicked('actionPlan')">
                                                                    <li><b class="notification-clicked">{{
                                                                            "home.ActionPlan" |
                                                                            translate }}</b></li>
                                                                    <div class="d-block marginleft-20">{{
                                                                        "home.actionPlanNotification" |
                                                                        translate }} </div>
                                                                </ul>
                                                            </span>
                                                        </a>
                                                        <se-divider id="divider"
                                                            *ngIf="ActionPlanData?.length>0"></se-divider>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <div style="margin-top: -4%;" (click)="bellIconClick()">
                                                    <se-icon size="large"
                                                        class="action-close-icon">action_close</se-icon>
                                                </div>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </span>
                        </span>
                    </div>
                    <div class="user-clicked" (click)="onUsernameClick($event)">
                        <se-icon size="medium">user_standard</se-icon>
                        <span class="position-absolute left-0 top-0" *ngIf="isUsernameDropdown">
                            <div *ngIf="userName && !hideInPortalPrinciple">
                                <div class="d-flex flex-row">
                                    <div class="welcomeDashBoard">
                                        <div class="mt8">
                                            <div class="float-start">
                                                <div class="d-flex flex-row">
                                                    <div class="" style="padding-left:10px;margin-top:-11%">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            style="color:#2dcd58; vertical-align: top;float: left; padding-left:3%; font-size: 14px; line-height: 19px;">
                                        </div>
                                        <div style="clear:both;"></div>
                                        <!-- <div style="padding-left: 7%; padding-top: 8%;"><span *ngIf="!portalTranslate">Company</span><span *ngIf="portalTranslate">公司名称
                                  </span>: <span style="color: #2dcd58; font-size: 18px; padding-left:20px">{{companyName}}</span> </div> -->
                                        <div style="padding-left: 7%; padding-top: 5%;color:#3dcd58">
                                            <!-- <span *ngIf="!portalTranslate">Role</span>
                                     <span *ngIf="portalTranslate">角色</span>: 
                                     <span style="color: #2dcd58; font-size: 18px; padding-left:20px" *ngIf="!portalTranslate">{{role}}</span>
                                      <span *ngIf="portalTranslate">{{roleCH}}</span> -->
                                            <span
                                                style="font-size: 14px; line-height: 19px;color:#3dcd58; font-weight: bold;">
                                                {{
                                                "home.welcome" | translate }}</span>
                                            <br />
                                            <span style="font-size: 14px; line-height: 16px;"> {{userName}}</span><br />
                                            <span *ngIf="companyName"
                                                style="color:#333333;font-size: 12px; line-height: 19px;">{{ "home.comp"
                                                | translate }} :<span style="color:#3dcd58;">
                                                    {{companyName}}</span></span> <br *ngIf="companyName" />
                                            <span style="color:#333333;font-size: 14px; line-height: 19px;"> {{
                                                "home.role" | translate }}
                                                :</span>
                                            <span
                                                style=" font-size: 18px; padding-left:20px;font-size: 14px; line-height: 19px;"
                                                *ngIf="!portalTranslate">{{role}}</span>
                                            <span *ngIf="portalTranslate"
                                                style="font-size: 14px; line-height: 19px;">{{roleCH}}</span>
                                            <a href="{{editProfile}}" target="_blank"
                                                style="text-decoration: underline;font-size: 16px;color:#0087CD; display: block;">{{"home.EditProfile"
                                                | translate}}</a>
                                            <a class="dropdownitems" id="portalPrinciple" style="display: block;"
                                                (click)="normalView();" *ngIf="IDMSPersona ==='EMP' && !isnormalView">
                                                <span [ngClass]="{'verticalLine':normalVerticalline}"></span> <span
                                                    style="text-decoration: underline;font-size: 16px;color:#0087CD"> {{
                                                    "home.normal" | translate
                                                    }}</span>
                                            </a>
                                            <a class="dropdownitems" id="portalPrinciple" (click)="adminView()"
                                                *ngIf="IDMSPersona ==='EMP' && isnormalView && hideDropDown"
                                                style="display: block;">
                                                <span [ngClass]="{'verticalLine':adminVerticalline}"></span>
                                                <span style="text-decoration: underline;font-size: 16px;color:#0087CD">
                                                    {{ "home.admin" | translate }}</span>
                                            </a>
                                        </div>
                                        <div style="padding-left: 7%;">
                                            <a (click)="logout()" target="_self"
                                                style="color: #F27676; text-decoration: underline;">
                                                <span> {{ "home.Logout" | translate }}</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div (click)="onUsernameClick($event)">
                                        <se-icon size="large" style="    font-size: 30px;
                                  background-color: #3dcd58;
                                  margin-right: -20px;
                                  color: #ffffff;
                                  text-align: center;z-index: 100;
                                  position: absolute;">action_close</se-icon>
                                    </div>
                                </div>
                            </div>
                        </span>
                    </div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
        <!-- sub header -->
        <div *ngIf="isnormalView" class="header">
            <div style="flex:20%;width: 105px;height:50px" [ngClass]="{'activeMenu':activeHome}">
                <a id="supplier" class="header2">
                    <span (mouseover)="onPortalDropdownClick($event)" (click)="sspsrmInego();" class="mobileTabs">
                        {{"home.c" | translate }}
                    </span>
                </a>
            </div>
            <div style="flex:30%" class="vl" [ngClass]="{'activeMenu':activePortalPrinciple}">
                <a class="mobileTabs" (click)="navigateTosspsrm()" id="portalPrinciple">
                    <span> {{ 'home.knowledgeHub' | translate }}</span>
                </a>
            </div>
            <div style="flex:30%" class="vl" [ngClass]="{'activeMenu':activeSupplierAtSE}">
                <a (click)="onSupplierAtSEDropdownClickleave($event);onPortalDropdownClickleave($event)"
                    style="margin-top: 30px; margin-left: 3%; margin-right: 1%;">
                    <a [ngClass]="{'activeMenu':activeSupplierAtSE}"
                        href="https://www.se.com/ww/en/about-us/suppliers/#Useful%20Information" target="_blank"
                        style="color:#ffffff ;text-transform: initial !important;">
                        <span (click)="onSupplierAtSEDropdownClick($event)" class="menuitems" style="font-size: 12px;">
                            {{ 'home.d' | translate }}
                        </span>
                    </a>
                </a>
            </div>
            <div [ngClass]="{'activeMenu':activeSupport}" style="flex:20%" class="vl">
                <a id="supplier" class="mobileTabs" (click)="navigateTosspinego()">
                    <span> {{ 'home.f' | translate }}</span>
                </a>
            </div>
        </div>
        <!-- sub header end -->
        <div *ngIf="isnormalView && activeHome">
            <div id="newposition" class="dropDownMenu">
                <div class="loginchipMenu">
                    <kendo-dropdownlist (valueChange)="valueChange($event)" (selectionChange)="selectionChange($event)"
                        [data]="listItems" textField="text" valueField="value" [(ngModel)]="selectedItem"
                        style="width: 100%; font-weight: bold;">
                    </kendo-dropdownlist>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isnormalView" fxFlex fxLayout="column" (scroll)="scrollHandler($event)">
        <div style="max-width:1498px !important ; margin: 0 auto;">
            <div>
                <div *ngIf="isnormalView">
                    <!-- for home page -->
                    <div *ngIf="activeHome" id="newposition"
                        style=" overflow:hidden;display: flex; flex-direction: column; margin-left:7.3% !important;">
                        <ng-container *ngIf="sourceToContract">
                            <se-block style="width: 99%; ">
                                <div style="background-color: #fafafa;">
                                    <div class="item-wrapper">
                                        <div style="display:flex ; flex-direction: column">
                                            <!-- s2c- company tile -->
                                            <div style="padding-bottom: 15px;">
                                                <a id="Contact" (click)="accountSSPSRM()" style="cursor:pointer;">
                                                    <div style="position: relative;">
                                                        <img src="../assets/images/mob/mob_companyinformation.png"
                                                            height="auto" width="300" />
                                                    </div>
                                                    <div class="tilesubtextarea">
                                                        <div>
                                                            <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                                homeContentFromAPI[3]?.modules[0].nameEn
                                                                }}
                                                            </p>
                                                            <p *ngIf="portalTranslate" class="tileHeader">{{
                                                                homeContentFromAPI[3]?.modules[0].nameCh
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                                homeContentFromAPI[3]?.modules[0].descriptionEn }}
                                                            </p>
                                                            <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                                homeContentFromAPI[3]?.modules[0].descriptionCh }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <!-- company clicked -s2c -->
                                                <div id="companyInfo">
                                                    <div class="surround20"
                                                        style="padding-bottom: 5%;padding-right: 7%;"
                                                        *ngIf="companyClicked">
                                                        <app-company-info [portalTranslate]="portalTranslate"
                                                            (canCloseDialog)="onCompanyClick()">
                                                        </app-company-info>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- s2c- supplier qualification -->
                                            <div style="padding-bottom: 15px;">
                                                <a id="Contact" style="cursor: pointer;"
                                                    (click)="onsupplierQualificationClick()">
                                                    <img src="../assets/images/mob/mob_supplierqulalifcation.png"
                                                        height="auto" width="300" />
                                                    <div class="tilesubtextarea">
                                                        <div>
                                                            <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                                homeContentFromAPI[3]?.modules[1].nameEn
                                                                }}
                                                            </p>
                                                            <p *ngIf="portalTranslate" class="tileHeader">{{
                                                                homeContentFromAPI[3]?.modules[1].nameCh
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                                homeContentFromAPI[3]?.modules[1].descriptionEn }}
                                                            </p>
                                                            <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                                homeContentFromAPI[3]?.modules[1].descriptionCh }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <!-- s2c- esurvey -->
                                            <div id="eSurvey" style="padding-bottom: 15px;">
                                                <a id="Contact" (click)="onSurveyClick()" style="cursor: pointer;">
                                                    <img src="../assets/images/mob/mob_esurvey.png" height="auto"
                                                        width="300" />
                                                    <div class="tilesubtextarea">
                                                        <div>
                                                            <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                                homeContentFromAPI[3]?.modules[3].nameEn
                                                                }}
                                                            </p>
                                                            <p *ngIf="portalTranslate" class="tileHeader">{{
                                                                homeContentFromAPI[3]?.modules[3].nameCh
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                                homeContentFromAPI[3]?.modules[3].descriptionEn }}
                                                            </p>
                                                            <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                                homeContentFromAPI[3]?.modules[3].descriptionCh }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <!-- s2c - e-survey clicked content -->
                                                <div style="padding-bottom: 5%; padding-right:6%; width: 320px;"
                                                    *ngIf="eSurveyClicked">
                                                    <app-e-survey [portalTranslate]="portalTranslate"
                                                        (canCloseDialog)="onSurveyClick()">
                                                    </app-e-survey>
                                                </div>
                                            </div>
                                            <span>
                                                <div class="item-wrapper" style="padding-bottom: 5%">
                                                    <div style="position:absolute;" class="desktopNotification">
                                                        <p *ngIf="eSourcingLatestCount !== '0'"
                                                            style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                                                            kendoTooltip tooltipClass="my-class" position="top"
                                                            title="You have contract(s) awaiting your signature">
                                                            <img class="notificationIcon"
                                                                src="../assets/images/white1_bell.jpg" />
                                                        </p>
                                                    </div>
                                                    <a id="Contact" (click)="onESourcingClick()"
                                                        style="cursor:pointer;">
                                                        <div style="position: relative;">
                                                            <img src="../assets/images/mob/mob_esourcing.png"
                                                                height="auto" width="300" />
                                                        </div>
                                                        <div class="tilesubtextarea">
                                                            <div>
                                                                <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                                    homeContentFromAPI[3]?.modules[2].nameEn
                                                                    }}
                                                                </p>
                                                                <p *ngIf="portalTranslate" class="tileHeader">{{
                                                                    homeContentFromAPI[3]?.modules[2].nameCh }}
                                                                </p>
                                                            </div>
                                                            <div>
                                                                <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                                    homeContentFromAPI[3]?.modules[2].descriptionEn }}
                                                                </p>
                                                                <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                                    homeContentFromAPI[3]?.modules[2].descriptionCh }}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                                <div id="eSource">
                                                    <div style="padding-bottom: 5%; padding-right:6%; width: 320px;"
                                                        *ngIf="eSourcingClicked">
                                                        <app-e-source [portalTranslate]="portalTranslate"
                                                            (canCloseDialog)="onESourcingClick()">
                                                        </app-e-source>
                                                    </div>
                                                </div>
                                            </span>
                                            <!-- s2c - Auctions -->
                                            <div style="padding-bottom: 15px;">
                                                <a id="Contact" href="{{Auctions}}" target="_blank">
                                                    <img src="../assets/images/mob/mob_auctions.png" height="auto"
                                                        width="300" />
                                                    <div class="tilesubtextarea">
                                                        <div>
                                                            <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                                homeContentFromAPI[3]?.modules[4].nameEn
                                                                }}
                                                            </p>
                                                            <p *ngIf="portalTranslate" class="tileHeader">{{
                                                                homeContentFromAPI[3]?.modules[4].nameCh
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                                homeContentFromAPI[3]?.modules[4].descriptionEn }}
                                                            </p>
                                                            <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                                homeContentFromAPI[3]?.modules[4].descriptionCh }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                            <!-- s2c - contract -->
                                            <div style="padding-bottom: 15px;">
                                                <a id="Contact" (click)="onContractClick()" style="cursor:pointer;">
                                                    <div style="position: relative;">
                                                        <img src="../assets/images/mob/mob_contract.png" height="auto"
                                                            width="300" />
                                                    </div>
                                                    <div class="tilesubtextarea">
                                                        <div>
                                                            <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                                homeContentFromAPI[3]?.modules[5].nameEn
                                                                }}
                                                            </p>
                                                            <p *ngIf="portalTranslate" class="tileHeader">{{
                                                                homeContentFromAPI[3]?.modules[5].nameCh
                                                                }}
                                                            </p>
                                                        </div>
                                                        <div>
                                                            <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                                homeContentFromAPI[3]?.modules[5].descriptionEn }}
                                                            </p>
                                                            <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                                homeContentFromAPI[3]?.modules[5].descriptionCh }}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </a>
                                                <div id="contract">
                                                    <div style="padding-bottom: 5%;padding-right: 6%; width: 320px;"
                                                        *ngIf="contractClicked">
                                                        <app-contract [portalTranslate]="portalTranslate"
                                                            (canCloseDialog)="onContractClick()">
                                                        </app-contract>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style="position: absolute;" class="desktopNotification">
                                            <p *ngIf="companyDynamicDataFlag===true && !portalTranslate"
                                                style="display: inline;z-index: 1;left: 12.7%;position: absolute;top:-28px"
                                                kendoTooltip tooltipClass="my-class" position="top"
                                                title="Your profile has not been updated for 90 days.Please ensure your latest information  available on the application.">
                                                <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                                            </p>
                                            <p *ngIf="companyDynamicDataFlag===true && portalTranslate"
                                                style="display: inline;z-index: 1;left: 12.7%;position: absolute;top:-28px"
                                                kendoTooltip tooltipClass="my-class" position="top"
                                                title="您的个人信息已有90天未更新，请在应用里更新您的信息。">
                                                <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </se-block>
                        </ng-container>
                        <!-- start of srm -->
                        <ng-container *ngIf="supplierReleationManagement">
                            <se-block style=" padding-bottom: 0%; width: 99%;">
                                <span id="scrollsrm" #scrollsrm></span>
                                <div style="padding-bottom: 2%; background-color: #fafafa;">
                                    <div id="myAccount" class="item-wrapper" style="padding-bottom: 5%">
                                        <div style="position: absolute;" class="desktopNotification">
                                            <p *ngIf="myAccValue>0 && !portalTranslate"
                                                style="display: inline;z-index: 1;left: 11%;position: absolute;top:-28px"
                                                kendoTooltip tooltipClass="my-class" position="top"
                                                title="You have {{myAccValue}} document(s) to read">
                                                <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                                            </p>
                                            <p *ngIf="myAccValue>0 && portalTranslate"
                                                style="display: inline;z-index: 1;left: 11%;position: absolute;top:-28px"
                                                kendoTooltip tooltipClass="my-class" position="top"
                                                title="您有未读文档 {{myAccValue}}">
                                                <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                                            </p>
                                        </div>
                                        <a id="Contact" href="{{MyAccountDocument}}" target="_blank">
                                            <div style="position: relative;">
                                                <img src="../assets/images/mob/mob_myaccountdocument.png" height="auto"
                                                    width="300" />
                                            </div>
                                            <div class="tilesubtextarea">
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[0]?.modules[0].nameEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[0]?.modules[0].nameCh }}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[0]?.modules[0].descriptionEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[0]?.modules[0].descriptionCh }}
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <span>
                                        <div id="generalDoc" class="item-wrapper" style="padding-bottom: 5%">
                                            <div style="position: absolute;" class="desktopNotification">
                                                <p *ngIf="myGenValue>0 && !portalTranslate"
                                                    style="display: inline;z-index: 1;left: 32.5%;position: absolute;top:-28px"
                                                    kendoTooltip tooltipClass="my-class" position="top"
                                                    title="You have {{myGenValue}} document(s) to read">
                                                    <img class="notificationIcon"
                                                        src="../assets/images/white1_bell.jpg" />
                                                </p>
                                                <p *ngIf="myGenValue>0 && portalTranslate"
                                                    style="display: inline;z-index: 1;left: 32.5%;position: absolute;top:-28px"
                                                    kendoTooltip tooltipClass="my-class" position="top"
                                                    title="您有未读文档 {{myGenValue}}">
                                                    <img class="notificationIcon"
                                                        src="../assets/images/white1_bell.jpg" />
                                                </p>
                                            </div>
                                            <a id="Contact" href="{{GeneralDocuments}}" target="_blank">
                                                <div style="position: relative;">
                                                    <img src="../assets/images/mob/mob_generaldocument.png"
                                                        height="auto" width="300" />
                                                </div>
                                                <div class="tilesubtextarea">
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[0]?.modules[1].nameEn
                                                            }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[0]?.modules[1].nameCh }}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[0]?.modules[1].descriptionEn }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[0]?.modules[1].descriptionCh }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </span>
                                    <span>
                                        <div class="item-wrapper" style="padding-bottom: 5%">
                                            <a id="Contact" href="{{performanceDashboard}}" target="_blank">
                                                <div style="position:relative;">
                                                    <img src="../assets/images/mob/mob_performancedashboard.png"
                                                        height="auto" width="300" />
                                                </div>
                                                <div class="tilesubtextarea">
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[0]?.modules[2].nameEn
                                                            }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[0]?.modules[2].nameCh }}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[0]?.modules[2].descriptionEn }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[0]?.modules[2].descriptionCh }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </span>
                                    <span>
                                        <div class="item-wrapper" style="padding-bottom: 5%">
                                            <div style="position: absolute;" class="desktopNotification">
                                                <p *ngIf="ActionPlanData?.length>0 && !portalTranslate"
                                                    style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                                                    kendoTooltip tooltipClass="my-class" position="top"
                                                    title="New records found">
                                                    <img class="notificationIcon"
                                                        src="../assets/images/white1_bell.jpg" />
                                                </p>
                                                <p *ngIf="ActionPlanData?.length>0 && portalTranslate"
                                                    style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                                                    kendoTooltip tooltipClass="my-class" position="top" title="找到的新记录">
                                                    <img class="notificationIcon"
                                                        src="../assets/images/white1_bell.jpg" />
                                                </p>
                                            </div>
                                            <a id="Contact" (click)="onActionPlanClicked()" style="cursor: pointer;">
                                                <div style="position: relative;">
                                                    <img src="../assets/images/mob/mob_actionplan.png" height="auto"
                                                        width="300" />
                                                </div>
                                                <div class="tilesubtextarea">
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[0]?.modules[3].nameEn
                                                            }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[0]?.modules[3].nameCh }}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[0]?.modules[3].descriptionEn }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[0]?.modules[3].descriptionCh }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </span>
                                    <!-- SPCN Tile -->
                                    <span>
                                        <div class="item-wrapper" style="padding-bottom: 5%">
                                            <a id="Contact" href="{{spcnDashboard}}" target="_blank">
                                                <div style="position:relative;">
                                                    <img src="../assets/images/mob/mob_SPCN.png" height="auto"
                                                        width="300" />
                                                </div>
                                                <div class="tilesubtextarea">
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[0]?.modules[4].nameEn
                                                            }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[0]?.modules[4].nameCh }}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[0]?.modules[4].descriptionEn }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[0]?.modules[4].descriptionCh }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </span>
                                    <!-- Zero Carbon Tile -->
                                    <span>
                                        <div class="item-wrapper" style="padding-bottom: 5%">
                                            <a id="Contact" href="{{zeroCarbonDashboard}}" target="_blank">
                                                <div style="position:relative;">
                                                    <img src="../assets/images/mob/mob_zerocarbonproject.png"
                                                        height="auto" width="300" />
                                                </div>
                                                <div class="tilesubtextarea">
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[0]?.modules[5].nameEn
                                                            }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[0]?.modules[5].nameCh }}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[0]?.modules[5].descriptionEn }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[0]?.modules[5].descriptionCh }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </span>
                                </div>
                            </se-block>
                        </ng-container>
                        <div id="actionPlan">
                            <div style="padding-bottom: 5%; padding-right:6%; width: 320px;" *ngIf="actionPlanClicked">
                                <app-action-plan [portalTranslate]="portalTranslate"
                                    (canCloseDialog)="onActionPlanClicked()">
                                </app-action-plan>
                            </div>
                        </div>
                        <!-- p2p -->
                        <ng-container *ngIf="procureToPayBtn">
                            <se-block style="width: 99%; padding-bottom: 20px;">
                                <span id="scrollp2p" #scrollp2p></span>
                                <div style="background-color: #fafafa;">
                                    <div class="item-wrapper">
                                        <a id="Contact" href="{{CoupaAccount}}" target="_blank"
                                            style="cursor: pointer;">
                                            <img src="../assets/images/mob/mob_coupa.png" height="auto" width="300" />
                                            <div class="tilesubtextarea">
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[3]?.modules[0].nameEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[3]?.modules[0].nameCh }}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[3]?.modules[0].descriptionEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[3]?.modules[0].descriptionCh }}
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="item-wrapper">
                                        <a id="Contact" (click)="ptponOrderClick()" style="cursor: pointer;">
                                            <img src="../assets/images/mob/mob_procuretopayorder.png" height="auto"
                                                width="300" />
                                            <div class="tilesubtextarea">
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[3]?.modules[1].nameEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[3]?.modules[1].nameCh }}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[3]?.modules[1].descriptionEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[3]?.modules[1].descriptionCh }}
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </se-block>
                        </ng-container>
                        <!-- Co-Innovations -->
                        <ng-container *ngIf="coinnovations">
                            <se-block style="width: 99%; padding-bottom: 20px;">
                                <span id="coinnovation" #coinnovation></span>
                                <div style="background-color: #fafafa;">

                                    <div class="item-wrapper">

                                        <a id="Contact" href="{{coInnovationAccount}}" target="_blank"
                                            style="cursor: pointer;">

                                            <img src="../assets/images/mob/mob_coinnovation.png" height="auto"
                                                width="300" />
                                            <div class="tilesubtextarea">
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[5]?.modules[0].nameEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[5]?.modules[0].nameCh }}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[5]?.modules[0].descriptionEn }}</p>
                                                    <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[5]?.modules[0].descriptionCh }}</p>
                                                </div>
                                            </div>

                                        </a>
                                    </div>

                                </div>
                            </se-block>
                        </ng-container>
                        <!-- I2P -->
                        <ng-container *ngIf="i2p">
                            <se-block style="width: 99%; padding-bottom: 20px;">
                                <span id="i2p" #i2p></span>
                                <div style="background-color: #fafafa;">
                                    <div class="item-wrapper">
                                        <a id="Contact" href="{{i2pUrl}}" target="_blank" style="cursor: pointer;">
                                            <img src="../assets/images/mob/mob_i2p.png" height="auto" width="300" />
                                            <div class="tilesubtextarea">
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[4]?.modules[0].nameEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[4]?.modules[0].nameCh }}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[4]?.modules[0].descriptionEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[4]?.modules[0].descriptionCh }}
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </se-block>
                        </ng-container>
                        <!-- p2p - order clicked -->
                        <div class="surround20" style="padding-bottom: 5%;padding-right:6%;width: 320px;"
                            *ngIf="ptporderClicked">
                            <se-block class="tab-panel" id="tabPanel1">
                                <div style="background-color: #fafafa;">
                                    <div style="
                            width: 0;
                            height: 0;
                            border-left: 20px solid transparent;
                            border-right: 20px solid transparent;
                            border-bottom: 20px solid #3dcd58;
                            margin-left: 29%;
                            "></div>
                                </div>
                                <se-table height="500px" class="block-content-border" style="border: 2px solid #3dcd58; padding-top: 10px; padding-left: 20px; padding-right: 15px;
                         ">
                                    <p
                                        style="text-align: right !important;padding-right: 0% !important;margin-top: 0% !important">
                                        <se-icon style="color:#707070;cursor: pointer;"
                                            (click)="ptponOrderCencel()">action_delete_cross
                                        </se-icon>
                                    </p>
                                    <p style="
                            color: #3dcd58;
                            font-size: 25px;
                            margin: 0 !important;
                            padding: 0 !important;
                            ">
                                        <span *ngIf="!portalTranslate"> </span>
                                        <span *ngIf="portalTranslate"> </span>
                                    </p>
                                    <app-coupa-order [procuretopayTranslate]="portalTranslate"></app-coupa-order>
                                    <!-- <app-coupa-order-mob [procuretopayTranslate]="portalTranslate"></app-coupa-order-mob> -->
                                    <!-- <ng-container *ngIf="noData">
                            <app-procure-to-pay [procuretopayTranslate]="portalTranslate"></app-procure-to-pay>
                            </ng-container> -->
                                </se-table>
                            </se-block>
                        </div>
                        <ng-container *ngIf="supplierChain">
                            <se-block style="padding-bottom: 6%; width: 99%;">
                                <span id="scrollp2p" #scrollp2p></span>
                                <div style="background-color:#fafafa ;">
                                    <div class="item-wrapper">
                                        <a id="Contact" href="{{SPCNAccount}}" target="_blank" style="cursor: pointer;">
                                            <img src="../assets/images/mob/Mob_suppyon.png" height="auto" width="300" />
                                            <div class="tilesubtextarea">
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[1]?.modules[2].nameEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[1]?.modules[2].nameCh }}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[1]?.modules[2].descriptionEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[1]?.modules[2].descriptionCh }}
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <div class="item-wrapper">
                                        <a id="Contact" (click)="onOrderClick()" style="cursor: pointer;">
                                            <img src="../assets/images/mob/mob_supplyonorder.png" height="auto"
                                                width="300" />
                                            <div class="tilesubtextarea">
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[1]?.modules[0].nameEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileHeader">{{
                                                        homeContentFromAPI[1]?.modules[0].nameCh }}
                                                    </p>
                                                </div>
                                                <div>
                                                    <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[1]?.modules[0].descriptionEn }}
                                                    </p>
                                                    <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                        homeContentFromAPI[1]?.modules[0].descriptionCh }}
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                    <!-- SCM order clicked -->
                                    <div class="surround20"
                                        style="    margin-top: -3%;margin-left:2.5% !important; width: 90%;padding-bottom: 5%;"
                                        *ngIf="orderClicked">
                                        <se-block class="tab-panel" id="tabPanel1">
                                            <div style="background-color:#fafafa ;">
                                                <div style="
                                     width: 0;
                                     height: 0;
                                     border-left: 20px solid transparent;
                                     border-right: 20px solid transparent;
                                     border-bottom: 20px solid #3dcd58;
                                     margin-left: 6%;
                                     "></div>
                                            </div>
                                            <se-table height="100px" class="block-content-border" style="
                                  border: 2px solid #3dcd58;
                                  padding-top: 10px;
                                  padding-left: 10px;
                                  padding-right: 15px;
                                  ">
                                                <p
                                                    style="text-align: right !important;padding-right: 0% !important;margin-top: 0% !important">
                                                    <se-icon style="color:#707070;cursor: pointer;"
                                                        (click)="onOrderCencel()">action_delete_cross
                                                    </se-icon>
                                                </p>
                                                <p style="
                                     color: #3dcd58;
                                     font-size: 25px;
                                     margin: 0 !important;
                                     padding: 0 !important;
                                     ">
                                                    <span *ngIf="!portalTranslate"> </span>
                                                    <span *ngIf="portalTranslate"> </span>
                                                </p>
                                                <app-supplyon-order
                                                    [supplyOnOrderTranslate]="portalTranslate"></app-supplyon-order>
                                                <ng-container *ngIf="noData">
                                                    <!-- <app-procure-to-pay [procuretopayTranslate]="portalTranslate"></app-procure-to-pay> -->
                                                    <div style="text-align: center; margin-bottom: 5%;color:#8c8686; font-size: 32px;"
                                                        *ngIf="noData">No data to display</div>
                                                </ng-container>
                                            </se-table>
                                        </se-block>
                                    </div>
                                    <span>
                                        <div class="item-wrapper">
                                            <a id="Contact" style="cursor: pointer;" (click)="onDeliveryClick()">
                                                <img src="../assets/images/Delivery-28-10-22.png" height="auto"
                                                    width="300" />
                                                <div class="tilesubtextarea">
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[1].modules[1].nameEn }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileHeader">{{
                                                            homeContentFromAPI[1].modules[1].nameCh }}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <p *ngIf="!portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[1].modules[1].descriptionEn }}
                                                        </p>
                                                        <p *ngIf="portalTranslate" class="tileSubtext">{{
                                                            homeContentFromAPI[1].modules[1].descriptionCh }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </a>
                                        </div>
                                    </span>
                                </div>
                            </se-block>
                        </ng-container>
                        <!-- SCM delivery clicked -->
                        <div class="surround20"
                            style="    margin-top: -3%;margin-left: 2.5% !important; width: 90%;padding-bottom: 5%;"
                            *ngIf="deliveryClicked">
                            <se-block class="tab-panel" id="tabPanel1" style="margin-top:-6.8%">
                                <div style="background-color: #fafafa;">
                                    <div style="
                            width: 0;
                            height: 0;
                            border-left: 20px solid transparent;
                            border-right: 20px solid transparent;
                            border-bottom: 20px solid #3dcd58;
                            margin-left: 29%;
                            "></div>
                                </div>
                                <se-table height="100px" class="block-content-border" style="
                         border: 2px solid #3dcd58;
                         padding-top: 10px;
                         padding-left: 20px;
                         padding-right: 15px;
                         ">
                                    <p
                                        style="text-align: right !important;padding-right: 0% !important;margin-top: 0% !important">
                                        <se-icon style="color:#707070;cursor: pointer;"
                                            (click)="onDeliveryCencel()">action_delete_cross
                                        </se-icon>
                                    </p>
                                    <app-supplyon-delivery [supplyOnDeliveryTranslate]="portalTranslate">
                                    </app-supplyon-delivery>
                                </se-table>
                            </se-block>
                        </div>
                    </div>
                    <!-- For portal principal -->
                    <div *ngIf="activePortalPrinciple " class="surround20"
                        style="overflow: hidden; display: flex; flex-direction: column; padding: 0px 9%;">
                        <app-online-repository [portalTranslate]=portalTranslate></app-online-repository>
                    </div>
                    <!-- for contact support -->
                    <div *ngIf="activeSupport " class="surround20"
                        style="overflow: hidden; display: flex; flex-direction: column;height:1000px; width: 90%;margin: auto;">
                        <div class="surround20" style="
                   color: #2dcd58;
                   font-size: 25px;
                   padding-top: 2.5% !important;
                   font-family: Nunito;
                   ">
                            <span> {{ "home.ContactSupport" | translate }}</span>
                        </div>
                        <se-block color="none">
                            <div>
                                <span *ngIf="!portalTranslate" [innerHTML]="getInnerHTML(contactNewDataEn)"></span>
                                <span *ngIf="portalTranslate" [innerHTML]="getInnerHTML(contactNewDataCh)"></span>
                                <br />
                                <div style="padding-top:20px">
                                    <div class="container" style="padding-right: 0% !important; margin-left: unset;">
                                        <app-contact-support-table
                                            [portalTranslate]=portalTranslate></app-contact-support-table>
                                    </div>
                                </div>
                            </div>
                        </se-block>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="isadminView && IDMSPersona=='EMP'" class="container surround24">
        <app-admin-view [portalTranslate]="portalTranslate"></app-admin-view>
    </div>
</div>
<div *ngIf="feedbackwindowClose">
    <app-feedback-rating [feedbackwindowValue]="feedbackwindowClose" [logoutlinkClicked]="logoutlinkClicked">
    </app-feedback-rating>
</div>
<div *ngIf="isnormalView" class="mobileView" (click)="starClicked()" style="position: fixed;
 bottom: 2%;
 right: 10px;
 width: 50px;
 border: 2px solid #3dcd58;
 height: 50px;
 border-radius: 50%; cursor: pointer;z-index: 9;">
    <se-icon size="large" style="font-size: 35px;
    padding-top: 0;
    padding-left: 5px;width: 100%;
    ">thumb_up</se-icon>
</div>