import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { SortDescriptor } from '@progress/kendo-data-query';
import { VendorService } from '../../vendor.service';
// import { LoaderService } from '../Loader/loader.service';
import { DataBindingDirective } from '@progress/kendo-angular-grid';


@Component({
  selector: 'app-coupa-order',
  templateUrl: './coupa-order.component.html',
  styleUrls: ['./coupa-order.component.scss']
})
export class CoupaOrderComponent implements OnInit {
  @ViewChild(DataBindingDirective) dataBinding!: DataBindingDirective;
  constructor(private _service: VendorService) {
  }

  public portalTranslate = false;
  public pageSize = 10;
  public skip = 0;
  public coupaDropdown!: string | any[];
  public dropDown: any[] = [];
  public defaultDropdown: any;
  public id: any;
  public coupaTableDataValue!: any[];
  public ptpOrdreNoRecords!: boolean;
  public showSpinner!: boolean;
  public show!: boolean;
  public ptpDefaultDL: any;
  public coupaDropdonwInitialId: any;
  public loadspinner!: boolean;
  public noData!: boolean;
  public showProcuretopayButton!: boolean;
  @Input() orderCoupaDropdown!: [{}];
  @Input() orderTableData: any;
  @Input() procuretopayTranslate!: boolean;
  public sort: SortDescriptor[] = [
    {
      field: 'End_date',
      dir: 'asc'
    }
  ];



  public tooltipGridContractValue: any;

  ngOnChanges() {
    this.portalTranslate = this.procuretopayTranslate;
    console.log("portal", this.portalTranslate);
  }

  ngOnInit(): void {
    this.loadspinner = false;
    this._service.procureToPayDropdown.subscribe(data => {
      if (data.length) {
        let procureToPayDropdownList: any = data;
        this.coupaDropdown = procureToPayDropdownList
      }
    })
    if (this.coupaDropdown.length === 0) {
      this.noData = true;
      this.loadspinner = false;
    }
    else {
      this.noData = false;
      this.ptpDefaultDL = this.coupaDropdown[0];
    }

    this._service.coupaTableDataList.subscribe(data => {
      this.loadspinner = false;
      if (data.length) {
        if (this.coupaTableDataValue == undefined || this.coupaTableDataValue.length == 0) {
          this.coupaTableDataValue = data
        } else {
          this.coupaTableDataValue.push(...data)
        }
        this.coupaTableDataValue = [...this.coupaTableDataValue];
      }
    })
  }

  public ptpDropdown(event: any) {
    this.dataBinding.skip = 0;
    this.loadspinner = false;
    this.coupaTableDataValue = []
    this._service.coupaTableData(event.id);
  }

  public onPageChange(state: any): void {
    this.pageSize = state.take;
  }


}