<app-supplier-chatbot></app-supplier-chatbot>
<se-tabbar color="Anthracite Grey" direction="row" class="main-footer">
    <div class="footer-content">
        <div class="example-footer flexDisplay felxSpacebetween flexColumnMobile desktopContent">
            <div style="width: 60%; " class="flexDisplay flexColumnMobile">
                <a class="footerContent footerContentMobile" href="https://www.se.com/in/en/" target="_blank">
                    <span> {{ "home.e" | translate }}</span>
                </a>
                <a class="footerContent footerContentMobile"
                    href="https://www.se.com/us/en/about-us/legal/data-privacy.jsp" target="_blank">
                    <span> {{ "home.g" | translate }}</span>
                </a>
                <a class="footerContent footerContentMobile"
                    href="https://www.se.com/us/en/about-us/legal/terms-of-use.jsp" target="_blank">
                    <span> {{ "home.h" | translate }}</span>
                </a>
            </div>
            <div class="mobileCopyRight">
                <a>
                    <span style="color: #fff;">Copyright &#169; {{formattedDate}} {{ "home.name" | translate }}</span>
                </a>
            </div>
        </div>
    </div>
</se-tabbar>