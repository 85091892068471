import { Component, Input, OnChanges } from '@angular/core';
import { VendorService } from '../../vendor.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-important-info-repo',
  templateUrl: './important-info-repo.component.html',
  styleUrl: './important-info-repo.component.scss'
})
export class ImportantInfoRepoComponent implements OnChanges {

  @Input() portalTranslate: any;
  showDafaults = true;
  selectedTemplate = '';

  constructor(public translate: TranslateService, public _service: VendorService, private router: Router) { }

  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  onCommunicationsClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'communications';
  }

  onNewsletterClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'newsletter';
  }

  onRepositoryClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'repository';
  }

}
