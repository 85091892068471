import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '../../vendor.service';
@Component({
  selector: 'app-contact-support-table',
  templateUrl: './contact-support-table.component.html',
  styleUrls: ['./contact-support-table.component.scss']
})
export class ContactSupportTableComponent implements OnInit, OnChanges {
  @Input() portalTranslate: boolean | undefined;
  openForm: boolean = false

  module = ['Company Information', 'Supplier Qualification', 'E-Survey', 'Auctions', 'Contract']
  p2pModule = ['Order']
  scmModule = ['Order', 'Delivery']
  srmModule = ['My Accounts Documents	', 'General Documents', 'E-Sourcing', 'Performance Dashboard', 'Action Plan']
  module_ch = ['公司信息', '供应商资格', '电子问卷调查', '拍卖', '合同']
  p2pModule_ch = ['公司信息']
  scmModule_ch = ['公司信息', '供应商审核']
  srmModule_ch = ['我的文档', '一般文档', '电子采购', '绩效报表', '行动计划']
  loggedInCode!: string;
  loggedInlanguage: any;
  ticketNumber = "";
  message!: string;
  constructor(public translate: TranslateService, private _service: VendorService) { }

  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  ngOnInit(): void {
    this._service.ticketCreartion.subscribe(result => {
      if (result != "error") {
        let response: any = result;
        let data = response?.data?.setServiceNowRequestNew;
        if (data?.status.toLowerCase() === 'success') {
          if (data?.uploadId !== null) {
            this.message = "Ticket created Successfully. Your ticket number is" + " " + data?.number
          } else {
            this.message = "Mail has been sent to the respective SPOC"
          }
          this.ticketNumber = data.number
        }
      } else {
        // for handling error
        this.ticketNumber = "1234"
        this.message = "Something went wrong. Please try again."
      }

    })
  }

  openContactForm() {
    this.openForm = true;
  }

  canCloseDialog(event: any) {
    this.openForm = false;
  }

  handleDialogClose(e: any) {
    this.ticketNumber = e;

  }

}
