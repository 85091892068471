import { Component, OnInit, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '../../../vendor.service';

@Component({
  selector: 'app-supplyon-order',
  templateUrl: './supplyon-order.component.html',
  styleUrls: ['./supplyon-order.component.scss']
})
export class SupplyonOrderComponent implements OnInit {
  SCMData: any = [];

  constructor(private _service: VendorService, public translate: TranslateService) {
  }
  public SCM: boolean = true;
  public supplyOnDropdown: any;
  public noData = false;
  public singleDataValue: any;
  public orderNewValue!: number;
  public newUpdatesValue!: number;
  public color: any;
  public orderUnconfirmedVaule!: number;
  public withOutUpdateValue!: number;
  public orderDatedVaule!: number;
  public partlyConfirmedVaule!: number;
  public orderOpenVaule!: number;
  public orderUnconfirmedVauleLink: any;
  public withOutUpdateValueLink: any;
  public orderDatedVauleLink: any;
  public partlyConfirmedVauleLink: any;
  public orderOpenVauleLink: any;
  public orderNewValueLink: any;
  public newUpdatesValueLink: any;
  public asnPendingVauleLink: any;
  public asnPendingVaule: any;
  public newOrderBarValue!: number;
  public orderUpdateBarValue!: number;
  public orderPartlyConfirmedBarValue!: number;
  public orderOpenBarVaule!: number;
  public orderDateBarValue!: number;
  public overDueAsnBar: any;
  public dueTodayAsnBar: any;
  public supplyOnData: any;
  // public portalTranslate = false;
  public supplyOnDefaultDL: any;
  public id: any;

  @Input() supplyOnOrderTranslate!: boolean;
  @Input() portalTranslate!: boolean;


  ngOnInit(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }

    this._service.getSupplyOnDataDropdownApi.subscribe(data => {
      let response: any = data
      if (response.length) {
        this.supplyOnDefaultDL = response[0].sma
        this.supplyOnDropdown = response
      }

    })
    this._service.getSupplyOnContentAPI.subscribe(data => {
      if (data) {
        this.getOrderData()
      }
    })
  }

  ngOnChanges() {
    // create header using child_id
    this.portalTranslate = this.supplyOnOrderTranslate;
  }

  getOrderData() {
    let data = this._service.getSCMData();
    this.SCMData = data
    data.forEach((element: { smaId: any; }) => {
      if (element.smaId == this.supplyOnDefaultDL) {
        this.supplyOnButton(element)
      }
    });

  }

  public supplyOnButton(data: any) {
    this.singleDataValue = data;
    if (this.singleDataValue) {
      if (this.singleDataValue.dataPoints.length !== 0) {
        this.SCM = true;
        this.noData = false;
        let isOrderNew = false;
        let isOrdersUnfirmed = false;
        this.singleDataValue.dataPoints.map((ele: { identifier: string; value: any; link: any; }) => {
          // order starts here
          if (ele.identifier === "orders_new") {
            this.orderNewValue = ele.value;
            this.orderNewValueLink = ele.link;
            isOrderNew = true;
          }
          else if (ele.identifier === "orders_new_without_updates") {
            this.withOutUpdateValue = ele.value;
            this.withOutUpdateValueLink = ele.link;
          }
          else if (ele.identifier === "orders_new_updates") {
            this.newUpdatesValue = ele.value;
            this.newUpdatesValueLink = ele.link;
          }
          // order ends here
          //--- confirmable order starts here 
          else if (ele.identifier === "orders_unconfirmed") {
            this.orderUnconfirmedVaule = ele.value;
            this.orderUnconfirmedVauleLink = ele.link;
            isOrdersUnfirmed = true;
          }
          else if (ele.identifier === "orders_dated") {
            this.orderDatedVaule = ele.value;
            this.orderDatedVauleLink = ele.link;
          }
          else if (ele.identifier === "orders_ordersPartlyConfirmed") {
            this.partlyConfirmedVaule = ele.value;
            this.partlyConfirmedVauleLink = ele.link;
          }
          else if (ele.identifier === "orders_ordersOpen") {
            this.orderOpenVaule = ele.value;
            this.orderOpenVauleLink = ele.link;
          }
          //---confirmable order ends here

          //Advance shipping No will starts here
          else if (ele.identifier === "asn_pending") {
            this.asnPendingVaule = ele.value;
            this.asnPendingVauleLink = ele.link;
          }
          //Advance shipping No will ends here

        })
        if (!isOrderNew && !isOrdersUnfirmed) {
          this.noData = true
        }
      }

      else {
        this.noData = true;
      }
      this.orderColors()
    } else {
      this.noData = true;
    }
  }


  public orderColors() {
    this.newOrderBarValue = ((this.withOutUpdateValue / this.orderNewValue) * 100);
    this.newOrderBarValue = (this.newOrderBarValue > 90) ? Math.floor((this.withOutUpdateValue / this.orderNewValue) * 100) : Math.ceil((this.withOutUpdateValue / this.orderNewValue) * 100);
    this.newOrderBarValue ? NaN : this.newOrderBarValue = 0;
    this.orderUpdateBarValue = ((this.newUpdatesValue / this.orderNewValue) * 100);
    this.orderUpdateBarValue = (this.orderUpdateBarValue > 90) ? Math.floor((this.newUpdatesValue / this.orderNewValue) * 100) : Math.ceil((this.newUpdatesValue / this.orderNewValue) * 100);
    this.orderUpdateBarValue ? NaN : this.orderUpdateBarValue = 0;
    this.orderDateBarValue = ((this.orderDatedVaule / this.orderUnconfirmedVaule) * 100);
    this.orderDateBarValue = (this.orderDateBarValue > 90) ? Math.floor((this.orderDatedVaule / this.orderUnconfirmedVaule) * 100) : Math.ceil((this.orderDatedVaule / this.orderUnconfirmedVaule) * 100);
    this.orderDateBarValue ? NaN : this.orderDateBarValue = 0;
    this.orderPartlyConfirmedBarValue = ((this.partlyConfirmedVaule / this.orderUnconfirmedVaule) * 100);
    this.orderPartlyConfirmedBarValue = (this.orderPartlyConfirmedBarValue > 90) ? Math.floor((this.partlyConfirmedVaule / this.orderUnconfirmedVaule) * 100) : Math.ceil((this.partlyConfirmedVaule / this.orderUnconfirmedVaule) * 100);
    this.orderPartlyConfirmedBarValue ? NaN : this.orderPartlyConfirmedBarValue = 0;
    this.orderOpenBarVaule = ((this.orderOpenVaule / this.orderUnconfirmedVaule) * 100);
    this.orderOpenBarVaule = (this.orderOpenBarVaule > 90) ? Math.floor((this.orderOpenVaule / this.orderUnconfirmedVaule) * 100) : Math.ceil((this.orderOpenVaule / this.orderUnconfirmedVaule) * 100);
    this.orderOpenBarVaule ? NaN : this.orderOpenBarVaule = 0;
  }



  public supplyOnDropdownList(event: any) {
    let smaList = this.SCMData.map((data: { smaId: any; }) => {
      return data.smaId;
    })
    if (smaList.indexOf(this.supplyOnDefaultDL) === -1) {
      this._service.supplyOn(event);
    } else {
      this.SCMData.map((data: { smaId: any; }) => {
        if (data.smaId == event) {
          this.supplyOnButton(data)
        }
      })

    }
  }


}
