import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-region-wise-pie-chart',
  templateUrl: './region-wise-pie-chart.component.html',
  styleUrls: ['./region-wise-pie-chart.component.scss']
})
export class RegionWisePieChartComponent implements OnInit {

  @Input() data: any;
  color = ["#9400D3", "#4B0082", "#0000FF", "#00FF00", "#FFFF00", "#FF7F00", "#FF0000"];
  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges() {
    for (let i = 0; i < this.data.length; i++) {
      this.data[i].color = this.color[i]
    }
  }

  public labelContent(e: any): string {
    return e.dataItem.region + ": " + (e.percentage * 100).toFixed(2) + "%";
  }

}
