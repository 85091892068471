import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { VendorService } from '../../../vendor.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-e-source',
  templateUrl: './e-source.component.html',
  styleUrls: ['./e-source.component.scss']
})
export class ESourceComponent implements OnInit, OnChanges {

  tooltipGridValue: any;
  public pageSize = 10;
  public skip = 0;

  @Input() portalTranslate: any;

  public sort: SortDescriptor[] = [
    {
      field: 'End_date',
      dir: 'asc'
    }
  ];
  tooltipGridData: any;
  ESurvey!: string;
  @Output() canCloseDialog = new EventEmitter;
  constructor(private _service: VendorService, public translate: TranslateService) { }

  ngOnInit(): void {
    this._service.eSourceData.subscribe(data => {
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        let response: any = data;
        this.tooltipGridData = response.getEsourcingData.esourcingList;
      }
    })
    this.ESurvey = environment.ESurvey
  }


  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  onESourcingcancelClick() {
    this.canCloseDialog.emit(false)
  }

  public onPageChange(state: any): void {
    this.pageSize = state.take;
  }


}
