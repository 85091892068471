import Node from './node';
import { defined, isTransparent } from '../util';
import { DASH_ARRAYS, SOLID, BUTT } from '../core/constants';
import { NONE, POINT_DIGITS } from './constants';
import renderAllAttr from './utils/render-all-attributes';
import renderAttr from './utils/render-attribute';
const ATTRIBUTE_MAP = {
  "fill.opacity": "fill-opacity",
  "stroke.color": "stroke",
  "stroke.width": "stroke-width",
  "stroke.opacity": "stroke-opacity"
};
class PathNode extends Node {
  geometryChange() {
    this.attr("d", this.renderData());
    this.invalidate();
  }
  optionsChange(e) {
    switch (e.field) {
      case "fill":
        if (e.value) {
          this.allAttr(this.mapFill(e.value));
        } else {
          this.removeAttr("fill");
        }
        break;
      case "fill.color":
        this.allAttr(this.mapFill({
          color: e.value
        }));
        break;
      case "stroke":
        if (e.value) {
          this.allAttr(this.mapStroke(e.value));
        } else {
          this.removeAttr("stroke");
        }
        break;
      case "transform":
        this.transformChange(e.value);
        break;
      default:
        const name = ATTRIBUTE_MAP[e.field];
        if (name) {
          this.attr(name, e.value);
        }
        break;
    }
    this.accessibilityOptionsChange(e);
    super.optionsChange(e);
  }
  content() {
    if (this.element) {
      this.element.textContent = this.srcElement.content();
    }
  }
  renderData() {
    return this.srcElement.toString(POINT_DIGITS) || undefined;
  }
  mapStroke(stroke) {
    const attrs = [];
    if (stroke && !isTransparent(stroke.color)) {
      attrs.push(["stroke", stroke.color]);
      attrs.push(["stroke-width", stroke.width]);
      attrs.push(["stroke-linecap", this.renderLinecap(stroke)]);
      attrs.push(["stroke-linejoin", stroke.lineJoin]);
      if (defined(stroke.opacity)) {
        attrs.push(["stroke-opacity", stroke.opacity]);
      }
      if (defined(stroke.dashType)) {
        attrs.push(["stroke-dasharray", this.renderDashType(stroke)]);
      }
    } else {
      attrs.push(["stroke", NONE]);
    }
    return attrs;
  }
  renderStroke() {
    return renderAllAttr(this.mapStroke(this.srcElement.options.stroke));
  }
  renderDashType(stroke) {
    const {
      dashType,
      width = 1
    } = stroke;
    if (dashType && dashType !== SOLID) {
      const dashArray = DASH_ARRAYS[dashType.toLowerCase()];
      const result = [];
      for (let i = 0; i < dashArray.length; i++) {
        result.push(dashArray[i] * width);
      }
      return result.join(" ");
    }
  }
  renderLinecap(stroke) {
    const {
      dashType,
      lineCap
    } = stroke;
    return dashType && dashType !== "solid" ? BUTT : lineCap;
  }
  mapFill(fill) {
    const attrs = [];
    if (!(fill && fill.nodeType === "Gradient")) {
      if (fill && !isTransparent(fill.color)) {
        attrs.push(["fill", fill.color]);
        if (defined(fill.opacity)) {
          attrs.push(["fill-opacity", fill.opacity]);
        }
      } else {
        attrs.push(["fill", NONE]);
      }
    }
    return attrs;
  }
  renderFill() {
    return renderAllAttr(this.mapFill(this.srcElement.options.fill));
  }
  template() {
    return `<path ${this.renderId()} ${this.renderStyle()} ${this.renderOpacity()} ${renderAttr('d', this.renderData())}` + `${this.renderStroke()}${this.renderFill()}${this.renderDefinitions()}${this.renderTransform()}` + `${this.renderClassName()} ${this.renderRole()}` + `${this.renderAriaLabel()} ${this.renderAriaRoleDescription()}` + `${this.renderAriaChecked()} ></path>`;
  }
}
export default PathNode;