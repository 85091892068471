<div class="surround20 sticky-top-custom" *ngIf="isHome">
    <div class="loginchipMenu" style="width: 100%;
       max-width: 1150px;
       margin: 0 auto !important;
       align-items: center;
       padding-bottom: 0.5%;
       ">
        <div class="chip_nav_width" id="chip-nav-btns">
            <div class="row">
                <div class="col-lg-2 col-md-2">
                    <button [ngClass]="isClick ? 'header-button-item active-button-header':'header-button-item'"
                        class="" (click)="chipSelect('s2c');scroll($event, 'scrolls2c')" style="width: 100%;" id="s2c">
                        {{ "home.a" | translate }}
                    </button>
                </div>
                <div class="col-lg-2 col-md-2">
                    <button [ngClass]="isClickSrm ? 'header-button-item active-button-header':'header-button-item'"
                        (click)="chipSelect('srm');scroll($event, 'scrollsrm')" id="srm" style="width: 100%;">
                        {{ "home.b" | translate }}
                    </button>
                </div>
                <div class="col-lg-2 col-md-2">
                    <button [ngClass]="isClick1 ? 'header-button-item active-button-header':'header-button-item'"
                        style="width: 100%;" (click)="chipSelect('p2p');scroll($event, 'scrollp2p')" id="p2p">
                        {{ "home.ProcuretoPay" | translate }}
                    </button>
                </div>
                <div class="col-lg-2 col-md-2"> <button
                        [ngClass]="isClickScm ? 'header-button-item active-button-header':'header-button-item'"
                        (click)="chipSelect('scm');scroll($event, 'scrollscm')" style="width: 100%;" id="scm">
                        {{ "home.SCM" | translate }}
                    </button>
                </div>
                <div class="col col-lg-2 col-md-2"> <button
                        [ngClass]="isClickCoinnovation ? 'header-button-item active-button-header':'header-button-item'"
                        (click)="chipSelect('coinnovation');scroll($event, 'scrollcoinnovation')" style="width: 100%;"
                        id="coinnovation">
                        {{ "home.coInnovations" | translate }}
                    </button>
                </div>
                <div class="col-lg-2 col-md-2"> <button
                        [ngClass]="isClicki2p ? 'header-button-item active-button-header':'header-button-item'"
                        (click)="chipSelect('i2p');scroll($event, 'scrolli2p')" style="width: 100%;" id="i2p">
                        {{ "home.i2p" | translate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div *ngIf="isHome" id="newposition" class="surround24" style=" overflow:hidden;display: flex; flex-direction: column;">
    <div id="scrolls2c" #scrolls2c class="surround20 tileHeaderText">
        <span> {{ "home.a" | translate }}</span>
    </div>
    <!-- source to contract tiles -->
    <se-block style="padding-top: 4%;padding-bottom: 1%;width: 99%">
        <div style="background-color: #fafafa;">
            <div class="item-wrapper" style="margin-left:2% !important;" (click)="chipSelect('s2c')">
                <div style="position: absolute;" class="desktopNotification">
                    <p *ngIf="companyDynamicDataFlag===true && !portalTranslate"
                        style="display: inline;z-index: 1;left: 12.7%;position: absolute;top:-28px" kendoTooltip
                        tooltipClass="my-class" position="top"
                        title="Your profile has not been updated for 90 days.Please ensure your latest information  available on the application.">
                        <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                    </p>
                    <p *ngIf="companyDynamicDataFlag===true && portalTranslate"
                        style="display: inline;z-index: 1;left: 12.7%;position: absolute;top:-28px" kendoTooltip
                        tooltipClass="my-class" position="top" title="您的个人信息已有90天未更新，请在应用里更新您的信息。">
                        <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                    </p>
                </div>
                <a id="Contact" (click)="accountSSPSRM()" style="margin-left: 2%">
                    <img src="../assets/images/Desk/Desk_companyInformation.png" height="175" width="175" />
                    <div class="text-block">
                        <se-icon style="
                      width: 15%;
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                      margin-top: 10%;
                      ">information_stroke</se-icon>
                        <ng-container *ngIf="homeContentFromAPI">
                            <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[3]?.modules[0].nameEn }}
                            </p>
                            <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[3]?.modules[0].nameCh }}
                            </p>
                            <div>
                                <p>
                                    <se-icon size="medium" style="padding-top:4%">information_stroke</se-icon>
                                    <span *ngIf="!portalTranslate" style="font-size: 15px;font-weight:600;font-family: Arial, Helvetica, 
                               sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[3]?.modules[0].nameEn
                                        }}</span>
                                    <span *ngIf="portalTranslate" style="font-size: 17px;font-weight:600;font-family: Arial, Helvetica, 
                               sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[3]?.modules[0].nameCh
                                        }}</span>
                                </p>
                            </div>
                            <p *ngIf="!portalTranslate"
                                style="text-align: left;margin-top: 9.5%;font-weight:normal;font-size:11px !important">
                                {{
                                homeContentFromAPI[3]?.modules[0].descriptionEn }}
                            </p>
                            <p *ngIf="portalTranslate"
                                style="text-align: left;margin-top: 10%;font-weight:normal;font-size:8px !important">{{
                                homeContentFromAPI[3]?.modules[0].descriptionCh }}
                            </p>
                        </ng-container>
                    </div>
                </a>
            </div>
            <span>
                <div class="item-wrapper">
                    <a id="Contact" style="margin-left: 2%" (click)="onsupplierQualificationClick()">
                        <img src="../assets/images/Desk/Desk_supplierqualification.png" height="175" width="175" />
                        <div class="text-block">
                            <se-icon style="
                         width: 15%;
                         display: block;
                         margin-left: auto;
                         margin-right: auto;
                         margin-top: 10%;
                         ">action_search</se-icon>
                            <ng-container *ngIf="homeContentFromAPI">
                                <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[3]?.modules[1].nameEn
                                    }}
                                </p>
                                <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[3]?.modules[1].nameCh
                                    }}
                                </p>
                                <div>
                                    <p>
                                        <se-icon size="medium" style="padding-top:4%">action_search</se-icon>
                                        <span *ngIf="!portalTranslate" style="font-size: 15px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;
                                  position:absolute;">{{ homeContentFromAPI[3]?.modules[1].nameEn }}</span>
                                        <span *ngIf="portalTranslate" style="padding-top:2%;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;
                                  position:absolute;">{{ homeContentFromAPI[3]?.modules[1].nameCh }}</span>
                                    </p>
                                </div>
                                <p *ngIf="!portalTranslate"
                                    style="text-align: left;margin-top:9.5%;font-weight:normal;font-size:11px !important">
                                    {{
                                    homeContentFromAPI[3]?.modules[1].descriptionEn }}
                                </p>
                                <p *ngIf="portalTranslate"
                                    style="text-align: left;margin-top:10%;font-weight:normal;font-size:8px !important">
                                    {{
                                    homeContentFromAPI[3]?.modules[1].descriptionCh }}
                                </p>
                            </ng-container>
                        </div>
                    </a>
                </div>
            </span>
            <span>
                <div class="item-wrapper" (click)="chipSelect('s2c')">
                    <div style="position:absolute;" class="desktopNotification">
                        <p *ngIf="esurveyDynamicDataFlag===true && !portalTranslate"
                            style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                            kendoTooltip tooltipClass="my-class" position="top"
                            title="You have e-survey request(s) awaiting your response">
                            <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                        </p>
                        <p *ngIf="esurveyDynamicDataFlag===true && portalTranslate"
                            style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                            kendoTooltip tooltipClass="my-class" position="top" title="您有电子调查请求正在等待您的答复">
                            <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                        </p>
                    </div>
                    <!-- Notification configeration need to be added for Esourcing here -->
                    <a id="Contact" (click)="onSurveyClick()" style="margin-left: 2%;cursor: pointer;">
                        <img src="../assets/images/Desk/Desk_esurvey.png" height="175" width="175" />
                        <div class="text-block">
                            <se-icon style="
                         width: 15%;
                         display: block;
                         margin-left: auto;
                         margin-right: auto;
                         margin-top: 10%;
                         ">suitcase
                            </se-icon>
                            <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[3]?.modules[3].nameEn }}
                            </p>
                            <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[3]?.modules[3].nameCh }}
                            </p>
                            <div>
                                <p>
                                    <se-icon size="medium" style="padding-top:4%">suitcase</se-icon>
                                    <span *ngIf="!portalTranslate" style="font-size: 15px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;position:absolute;  
                               margin-top: 6%;">{{ homeContentFromAPI[3]?.modules[3].nameEn }}</span>
                                    <span *ngIf="portalTranslate" style="font-size: 17px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;position:absolute;  
                               margin-top: 6%;">{{ homeContentFromAPI[3]?.modules[3].nameCh }}</span>
                                </p>
                            </div>
                            <p *ngIf="!portalTranslate"
                                style="text-align: left;margin-top: 8%;font-weight:normal;font-size:11px !important">{{
                                homeContentFromAPI[3]?.modules[3].descriptionEn }}
                            </p>
                            <p *ngIf="portalTranslate"
                                style="text-align: left;margin-top: 8%;font-weight:normal;font-size:8px !important">{{
                                homeContentFromAPI[3]?.modules[3].descriptionCh }}
                            </p>
                        </div>
                    </a>
                </div>
            </span>
            <span>
                <div class="item-wrapper" (click)="chipSelect('s2c')">
                    <div style="position:absolute;" class="desktopNotification">
                        <p *ngIf="esourcingDynamicDataFlag===true && !portalTranslate"
                            style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                            kendoTooltip tooltipClass="my-class" position="top"
                            title="You have sourcing request(s) awaiting your response">
                            <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                        </p>
                        <p *ngIf="esourcingDynamicDataFlag===true && portalTranslate"
                            style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                            kendoTooltip tooltipClass="my-class" position="top" title="您有采购请求等待您的回复">
                            <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                        </p>
                    </div>
                    <a id="Contact" (click)="onESourcingClick()" style="cursor:pointer;">
                        <img src="../assets/images/Desk/Desk_esourcing.png" height="175" width="175" />
                        <div class="text-block">
                            <se-icon style="
                         width: 15%;
                         display: block;
                         margin-left: auto;
                         margin-right: auto;
                         margin-top: 10%;
                         ">mail_replyall</se-icon>
                            <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[3]?.modules[2].nameEn }}
                            </p>
                            <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[3]?.modules[2].nameCh }}
                            </p>
                            <div>
                                <p>
                                    <se-icon size="medium" style="padding-top:4%">mail_replyall</se-icon>
                                    <span *ngIf="!portalTranslate" style="padding-top:3%;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, 
                               sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[3]?.modules[2].nameEn
                                        }}</span>
                                    <span *ngIf="portalTranslate" style="padding-top:2%;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, 
                               sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[3]?.modules[2].nameCh
                                        }}</span>
                                </p>
                            </div>
                            <p *ngIf="!portalTranslate"
                                style="text-align: left;margin-top: 9.5%;font-weight:normal;font-size:11px !important">
                                {{
                                homeContentFromAPI[3]?.modules[2].descriptionEn }}
                            </p>
                            <p *ngIf="portalTranslate"
                                style="text-align: left;margin-top: 10%;font-weight:normal;font-size:8px !important">{{
                                homeContentFromAPI[3]?.modules[2].descriptionCh }}
                            </p>
                        </div>
                    </a>
                </div>
            </span>
            <span>
                <div class="item-wrapper">
                    <!-- Auction notification needs to be configer here -->
                    <div style="position:absolute;" class="desktopNotification">
                        <p *ngIf="auctionLatestCount !== '0' && !portalTranslate"
                            style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                            kendoTooltip tooltipClass="my-class" position="top"
                            title="You have sourcing request(s) awaiting your response">
                            <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                        </p>
                        <p *ngIf="auctionLatestCount !== '0' && portalTranslate"
                            style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                            kendoTooltip tooltipClass="my-class" position="top" title="您有采购请求等待您的回复">
                            <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                        </p>
                    </div>
                    <a id="Contact" style="margin-left: 2%" (click)="auctionsClick()" style="cursor:pointer;">
                        <img src="../assets/images/Desk/Desk_auctions.png" height="175" width="175" />
                        <div class="text-block">
                            <se-icon style="
                         width: 15%;
                         display: block;
                         margin-left: auto;
                         margin-right: auto;
                         margin-top: 10%;
                         ">pricing_eur</se-icon>
                            <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[3]?.modules[4].nameEn }}
                            </p>
                            <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[3]?.modules[4].nameCh }}
                            </p>
                            <div>
                                <p>
                                    <se-icon size="medium" style="padding-top:4%">pricing_eur</se-icon>
                                    <span *ngIf="!portalTranslate"
                                        style="font-size: 15px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;position:absolute;    margin-top: 5%;">{{
                                        homeContentFromAPI[3]?.modules[4].nameEn }}</span>
                                    <span *ngIf="portalTranslate"
                                        style="font-size: 17px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;position:absolute;    margin-top: 5%;">{{
                                        homeContentFromAPI[3]?.modules[4].nameCh }}</span>
                                </p>
                            </div>
                            <p *ngIf="!portalTranslate"
                                style="text-align: left;margin-top: 8%;font-weight:normal;font-size:11px !important">{{
                                homeContentFromAPI[3]?.modules[4].descriptionEn }}
                            </p>
                            <p *ngIf="portalTranslate"
                                style="text-align: left;margin-top: 8%;font-weight:normal;font-size:8px !important">{{
                                homeContentFromAPI[3]?.modules[4].descriptionCh }}
                            </p>
                        </div>
                    </a>
                </div>
            </span>
            <span>
                <div class="item-wrapper" (click)="chipSelect('s2c')">
                    <div style="position:absolute;" class="desktopNotification">
                        <p *ngIf="contractDynamicDataFlag===true && !portalTranslate"
                            style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                            kendoTooltip tooltipClass="my-class" position="top"
                            title="You have contract(s) awaiting your signature">
                            <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                        </p>
                        <p *ngIf="contractDynamicDataFlag===true && portalTranslate"
                            style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                            kendoTooltip tooltipClass="my-class" position="top" title="您有待签合同">
                            <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                        </p>
                    </div>
                    <a id="Contact" (click)="onContractClick()" style="cursor:pointer;">
                        <img src="../assets/images/Desk/Desk_contract.png" height="175" width="175" />
                        <div class="text-block">
                            <se-icon size="medium" style="
                         width: 15%;
                         display: block;
                         margin-left: auto;
                         margin-right: auto;
                         margin-bottom: 0% !important;
                         margin-top: 10%;
                         ">body_handshake</se-icon>
                            <p *ngIf="!portalTranslate" class="context" style="margin-bottom:0%;margin-top:1%">{{
                                homeContentFromAPI[3]?.modules[5].nameEn }}
                            </p>
                            <p *ngIf="portalTranslate" class="context" style="margin-bottom:0%;margin-top:1%">{{
                                homeContentFromAPI[3]?.modules[5].nameCh }}
                            </p>
                            <div>
                                <p>
                                    <se-icon size="medium"
                                        style="padding-top:4%;font-size:38px">body_handshake</se-icon>
                                    <span *ngIf="!portalTranslate"
                                        style="font-size: 15px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;position:absolute;    margin-top: 6%;">{{
                                        homeContentFromAPI[3]?.modules[5].nameEn }}</span>
                                    <span *ngIf="portalTranslate"
                                        style="font-size: 17px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;position:absolute;    margin-top: 6%;">{{
                                        homeContentFromAPI[3]?.modules[5].nameCh }}</span>
                                </p>
                            </div>
                            <p *ngIf="!portalTranslate"
                                style="text-align: left;margin-top: 0.5%;font-weight:normal;font-size:11px !important">
                                {{
                                homeContentFromAPI[3]?.modules[5].descriptionEn }}
                            </p>
                            <p *ngIf="portalTranslate"
                                style="text-align: left;margin-top: 0.5%;font-weight:normal;font-size:8px !important">{{
                                homeContentFromAPI[3]?.modules[5].descriptionCh }}
                            </p>
                        </div>
                    </a>
                </div>
            </span>
        </div>
    </se-block>
    <!-- source to contract e-survey clicked content -->
    <div class="surround20" style="margin-left: 2.5% !important; width: 90%;padding-bottom: 5%;display:block ;"
        *ngIf="eSurveyClicked">
        <app-e-survey [portalTranslate]="portalTranslate" (canCloseDialog)="onSurveyClick()"></app-e-survey>
    </div>
    <!-- source to contract company clicked content -->
    <div class="surround20" style="margin-left: 2.5% !important; width: 90%;padding-bottom: 5%;display: block;"
        *ngIf="companyClicked">
        <app-company-info [portalTranslate]="portalTranslate" (canCloseDialog)="onCompanyClick()"></app-company-info>
    </div>
    <!-- source to contract - E-Sourcing clicked content -->
    <div class="surround20"
        style="margin-top: 1% !important;margin-left: 2.5% !important; width: 95%;padding-bottom: 5%; background-color: #fafafa;"
        *ngIf="eSourceClicked">
        <app-e-source [portalTranslate]="portalTranslate" (canCloseDialog)="onESourcingClick()"></app-e-source>
    </div>
    <!-- source to Auctions - Auctions clicked content -->
    <div class="surround20"
        style="margin-top: 1% !important;margin-left: 2.5% !important; width: 95%; padding-bottom: 5%; background-color: #fafafa;"
        *ngIf="auctionsClicked">
        <app-auctions [portalTranslate]="portalTranslate" (canCloseDialog)="auctionsClick()"></app-auctions>
    </div>
    <!-- source to contract - contract clicked content -->
    <div class="surround20" style="margin-left: 2.5% !important; width: 90%;padding-bottom: 5%;"
        *ngIf="contractClicked">
        <app-contract [portalTranslate]="portalTranslate" (canCloseDialog)="onContractClick()"></app-contract>
    </div>
    <!-- Start of supplier relationship manager -->
    <div class="surround20 tileHeaderText">
        <span> {{ "home.b" | translate }}</span>
    </div>
    <a></a>
    <se-block style="width: 100%;background-color:#fafafa ;">
        <app-ssp-srm [portalTranslate]="portalTranslate" (isTileClicked)="srmTileClicked()"></app-ssp-srm>
    </se-block>
    <ng-container>
        <!-- start of procure to pay -->
        <div class="surround20 tileHeaderText">
            <span> {{ "home.ProcuretoPay" | translate }}</span>
        </div>
        <se-block style="padding-top: 4%; ; width: 99%;">
            <span id="scrollp2p" #scrollp2p></span>
            <div style="background-color: #fafafa;">
                <!-- Account Tile -->
                <div class="item-wrapper" (click)="chipSelect('p2p')" style="margin-left:2% !important;">
                    <a id="Contact" href="{{CoupaAccount}}" target="_blank" style="margin-left: 2%; cursor: pointer;">
                        <img src="../assets/images/Desk/Desk_coupa.png" height="175" width="175" />
                        <div class="text-block">
                            <se-icon size="medium" style="
                         width: 15%;
                         display: block;
                         margin-left: auto;
                         margin-right: auto;
                         margin-top: 2%;
                         ">information_stroke</se-icon>
                            <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[2]?.modules[1].nameEn }}
                            </p>
                            <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[2]?.modules[1].nameCh }}
                            </p>
                            <div>
                                <p>
                                    <se-icon size="medium" style="padding-top:4%">information_stroke</se-icon>
                                    <span *ngIf="!portalTranslate" style="margin-top:13px;font-size: 15px;font-weight:600;font-family: Arial, Helvetica, 
                               sans-serif;margin-left:5%;position:absolute">{{
                                        homeContentFromAPI[2]?.modules[1].nameEn
                                        }}</span>
                                    <span *ngIf="portalTranslate" style="margin-top:13px;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, 
                               sans-serif;margin-left:5%;position:absolute">{{
                                        homeContentFromAPI[2]?.modules[1].nameCh
                                        }}</span>
                                </p>
                            </div>
                            <p *ngIf="!portalTranslate"
                                style="text-align: left;margin-top: 5.5%;font-weight:normal;font-size:11px !important; padding-left: 13px;">
                                {{
                                homeContentFromAPI[2]?.modules[1].descriptionEn }}
                            </p>
                            <p *ngIf="portalTranslate"
                                style="text-align: left;margin-top: 10%;font-weight:normal;font-size:8px !important">{{
                                homeContentFromAPI[2]?.modules[1].descriptionCh }}
                            </p>
                        </div>
                    </a>
                </div>
                <div class="item-wrapper" (click)="chipSelect('p2p')" style="margin-left:2% !important;">
                    <a id="Contact" (click)="ptponOrderClick()" style="margin-left: 2%; cursor: pointer;">
                        <img src="../assets/images/Desk/Desk_procuretopayorder.png" height="175" width="175" />
                        <div class="text-block">
                            <se-icon size="medium" style="
                         width: 15%;
                         display: block;
                         margin-left: auto;
                         margin-right: auto;
                         margin-top: 2%;
                         ">segment_retail</se-icon>
                            <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[2]?.modules[0].nameEn }}
                            </p>
                            <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[2]?.modules[0].nameCh }}
                            </p>
                            <div>
                                <p>
                                    <se-icon size="medium" style="padding-top:4%">segment_retail</se-icon>
                                    <span *ngIf="!portalTranslate" style="margin-top:13px;font-size: 15px;font-weight:600;font-family: Arial, Helvetica, 
                               sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[2]?.modules[0].nameEn
                                        }}</span>
                                    <span *ngIf="portalTranslate" style="margin-top:13px;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, 
                               sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[2]?.modules[0].nameCh
                                        }}</span>
                                </p>
                            </div>
                            <p *ngIf="!portalTranslate"
                                style="text-align: left;margin-top: 5.5%;font-weight:normal;font-size:11px !important; padding-left: 13px;">
                                {{
                                homeContentFromAPI[2]?.modules[0].descriptionEn }}
                            </p>
                            <p *ngIf="portalTranslate"
                                style="text-align: left;margin-top: 10%;font-weight:normal;font-size:8px !important">{{
                                homeContentFromAPI[2]?.modules[0].descriptionCh }}
                            </p>
                        </div>
                    </a>
                </div>
            </div>
        </se-block>
    </ng-container>
    <div class="surround20" style="margin-left:2.5% !important; width: 95.5%;padding-bottom: 5%;"
        *ngIf="ptporderClicked">
        <se-block class="tab-panel" id="tabPanel1">
            <div style="background-color: #fafafa;">
                <div style="
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-bottom: 20px solid #3dcd58;
                margin-left: 23%;
                "></div>
            </div>
            <se-table height="100px" class="block-content-border" style="
             border: 2px solid #3dcd58;
             padding-top: 10px;
             padding-left: 20px;
             padding-right: 15px;
             ">
                <p style="text-align: right !important;padding-right: 0% !important;margin-top: 0% !important">
                    <se-icon style="color:#707070;cursor: pointer;"
                        (click)="ptponOrderCencel()">action_delete_cross</se-icon>
                </p>
                <p style="
                color: #3dcd58;
                font-size: 25px;
                margin: 0 !important;
                padding: 0 !important;
                ">
                </p>
                <app-coupa-order [procuretopayTranslate]="portalTranslate"></app-coupa-order>
            </se-table>
        </se-block>
    </div>
    <!-- start of SCM  -->
    <ng-container>
        <div class="surround20 tileHeaderText">
            <span> {{ "home.SCM" | translate }}</span>
        </div>
        <se-block style="padding-top: 4%; ;padding-bottom: 2%; width: 99%;">
            <span id="scrollscm" #scrollscm></span>
            <div style="background-color: #fafafa;">
                <!-- Account Tile Supplyon -->
                <span>
                    <div class="item-wrapper" (click)="chipSelect('scm')">
                        <a id="Contact" style="margin-left: 2%; cursor: pointer;" href="{{SPCNAccount}}"
                            target="_blank">
                            <img src="../assets/images/Desk/Desk_supplyon.png" height="175" width="175" />
                            <div class="text-block">
                                <se-icon size="medium" style="
                            width: 15%;
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 3%;
                            ">information_stroke</se-icon>
                                <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[1]?.modules[2].nameEn
                                    }}
                                </p>
                                <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[1]?.modules[2].nameCh
                                    }}
                                </p>
                                <div>
                                    <p>
                                        <se-icon size="medium" style="padding-top:4%">information_stroke</se-icon>
                                        <span *ngIf="!portalTranslate" style="margin-top:13px;font-size: 15px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;
                                  position:absolute;">{{ homeContentFromAPI[1]?.modules[2].nameEn }}</span>
                                        <span *ngIf="portalTranslate" style="margin-top:13px;padding-top:2%;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;
                                  position:absolute;">{{ homeContentFromAPI[1]?.modules[2].nameCh }}</span>
                                    </p>
                                </div>
                                <p *ngIf="!portalTranslate"
                                    style="text-align: left;margin-top:2.5%;font-weight:normal;font-size:11px !important">
                                    {{
                                    homeContentFromAPI[1]?.modules[2].descriptionEn }}
                                </p>
                                <p *ngIf="portalTranslate"
                                    style="text-align: left;margin-top:10%;font-weight:normal;font-size:8px !important">
                                    {{
                                    homeContentFromAPI[1]?.modules[2].descriptionCh }}
                                </p>
                            </div>
                        </a>
                    </div>
                </span>
                <div class="item-wrapper" (click)="chipSelect('scm')" style="margin-left:2% !important;">
                    <a id="Contact" (click)="onOrderClick()" style="margin-left: 2%; cursor: pointer;">
                        <img src="../assets/images/Desk/Desk_scmorder.png" height="175" width="175" />
                        <div class="text-block">
                            <se-icon size="medium" style="
                         width: 15%;
                         display: block;
                         margin-left: auto;
                         margin-right: auto;
                         margin-top: 2%;
                         ">segment_retail</se-icon>
                            <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[1]?.modules[0].nameEn }}
                            </p>
                            <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[1]?.modules[0].nameCh }}
                            </p>
                            <div>
                                <p>
                                    <se-icon size="medium" style="padding-top:4%">segment_retail</se-icon>
                                    <span *ngIf="!portalTranslate" style="margin-top:13px;font-size: 15px;font-weight:600;font-family: Arial, Helvetica, 
                               sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[1]?.modules[0].nameEn
                                        }}</span>
                                    <span *ngIf="portalTranslate" style="margin-top:13px;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, 
                               sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[1]?.modules[0].nameCh
                                        }}</span>
                                </p>
                            </div>
                            <p *ngIf="!portalTranslate"
                                style="text-align: left;margin-top: 5.5%;font-weight:normal;font-size:11px !important; padding-left: 13px;">
                                {{
                                homeContentFromAPI[1]?.modules[0].descriptionEn }}
                            </p>
                            <p *ngIf="portalTranslate"
                                style="text-align: left;margin-top: 10%;font-weight:normal;font-size:8px !important">{{
                                homeContentFromAPI[1]?.modules[0].descriptionCh }}
                            </p>
                        </div>
                    </a>
                </div>
                <span>
                    <div class="item-wrapper" (click)="chipSelect('scm')">
                        <a id="Contact" style="margin-left: 2%; cursor: pointer;" (click)="onDeliveryClick()">
                            <img src="../assets/images/Delivery-13-06-22.jpg" height="175" width="175" />
                            <div class="text-block">
                                <se-icon size="medium" style="
                            width: 15%;
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 3%;
                            ">box2</se-icon>
                                <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[1]?.modules[1].nameEn
                                    }}
                                </p>
                                <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[1]?.modules[1].nameCh
                                    }}
                                </p>
                                <div>
                                    <p>
                                        <se-icon size="medium" style="padding-top:4%">box2</se-icon>
                                        <span *ngIf="!portalTranslate" style="margin-top:13px;font-size: 15px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;
                                  position:absolute;">{{ homeContentFromAPI[1]?.modules[1].nameEn }}</span>
                                        <span *ngIf="portalTranslate" style="margin-top:13px;padding-top:2%;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;
                                  position:absolute;">{{ homeContentFromAPI[1]?.modules[1].nameCh }}</span>
                                    </p>
                                </div>
                                <p *ngIf="!portalTranslate"
                                    style="text-align: left;margin-top:2.5%;font-weight:normal;font-size:11px !important">
                                    {{
                                    homeContentFromAPI[1]?.modules[1].descriptionEn }}
                                </p>
                                <p *ngIf="portalTranslate"
                                    style="text-align: left;margin-top:10%;font-weight:normal;font-size:8px !important">
                                    {{
                                    homeContentFromAPI[1]?.modules[1].descriptionCh }}
                                </p>
                            </div>
                        </a>
                    </div>
                </span>
            </div>
        </se-block>
    </ng-container>
    <!-- SCM order clicked -->
    <div class="surround20" style="    margin-top: -3%;margin-left:2.5% !important; width: 95.5%;padding-bottom: 5%;"
        *ngIf="orderClicked">
        <se-block class="tab-panel" id="tabPanel1" style="margin-top:-1.8%">
            <div style="background-color:#fafafa ;">
                <div style="
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-bottom: 20px solid #3dcd58;
                margin-left: 23%;
                "></div>
            </div>
            <se-table height="100px" class="block-content-border" style="
             border: 2px solid #3dcd58;
             padding-top: 10px;
             padding-left: 20px;
             padding-right: 15px;
             ">
                <p style="text-align: right !important;padding-right: 0% !important;margin-top: 0% !important">
                    <se-icon style="color:#707070;cursor: pointer;"
                        (click)="onOrderCencel()">action_delete_cross</se-icon>
                </p>
                <p style="
                color: #3dcd58;
                font-size: 25px;
                margin: 0 !important;
                padding: 0 !important;
                ">
                    <span *ngIf="!portalTranslate"> </span>
                    <span *ngIf="portalTranslate"> </span>
                </p>
                <app-supplyon-order [supplyOnOrderTranslate]="portalTranslate"></app-supplyon-order>
                <!-- <ng-container *ngIf="noData">
                <div style="text-align: center; margin-bottom: 5%;color:#8c8686; font-size: 32px;" *ngIf="noData">No data to display</div>
                </ng-container> -->
            </se-table>
        </se-block>
    </div>
    <!-- SCM delivery clicked -->
    <div class="surround20" style="    margin-top: -3%;margin-left: 2.5% !important; width: 90%;padding-bottom: 5%;"
        *ngIf="deliveryClicked">
        <se-block class="tab-panel" id="tabPanel1" style="margin-top:-1.8%">
            <div style="background-color: #fafafa;">
                <div style="
                width: 0;
                height: 0;
                border-left: 20px solid transparent;
                border-right: 20px solid transparent;
                border-bottom: 20px solid #3dcd58;
                margin-left: 43%;
                "></div>
            </div>
            <se-table height="100px" class="block-content-border" style="
             border: 2px solid #3dcd58;
             padding-top: 10px;
             padding-left: 20px;
             padding-right: 15px;
             ">
                <p style="text-align: right !important;padding-right: 0% !important;margin-top: 0% !important">
                    <se-icon style="color:#707070;cursor: pointer;"
                        (click)="onDeliveryCencel()">action_delete_cross</se-icon>
                </p>
                <p style="
                color: #3dcd58;
                font-size: 25px;
                margin: 0 !important;
                padding: 0 !important;
                ">
                    <span *ngIf="!portalTranslate"> </span>
                    <span *ngIf="portalTranslate"> </span>
                </p>
                <app-supplyon-delivery [supplyOnDeliveryTranslate]="portalTranslate"></app-supplyon-delivery>
            </se-table>
        </se-block>
    </div>
    <!-- start of Co-Innovation  -->
    <ng-container>
        <div class="surround20 tileHeaderText">
            <span> {{ "home.coInnovations" | translate }}</span>
        </div>
        <se-block style="padding-top: 4%; ;padding-bottom: 2%; width: 99%;">
            <span id="scrollcoinnovation" #scrollcoinnovation></span>
            <div style="background-color: #fafafa;">

                <span>

                    <div class="item-wrapper" (click)="chipSelect('coinnovation')">

                        <a id="Contact" style="margin-left: 2%; cursor: pointer;" href="{{coInnovationAccount}}"
                            target="_blank">

                            <img src="../assets/images/Desk/Desk_coinnovation.png" height="175" width="175" />
                            <div class="text-block">
                                <se-icon size="medium" style="
                                     width: 15%;
                                     display: block;
                                     margin-left: auto;
                                     margin-right: auto;
                                     margin-top: 3%;
                                   ">information_stroke</se-icon>

                                <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[5]?.modules[0].nameEn
                                    }}</p>
                                <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[5]?.modules[0].nameCh
                                    }}</p>


                                <div>
                                    <p>
                                        <se-icon size="medium" style="padding-top:4%">information_stroke</se-icon>
                                        <span *ngIf="!portalTranslate" style="margin-top:13px;font-size: 15px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;
                                       position:absolute;">{{ homeContentFromAPI[5]?.modules[0].nameEn }}</span>
                                        <span *ngIf="portalTranslate" style="margin-top:13px;padding-top:2%;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;
                                       position:absolute;">{{ homeContentFromAPI[5]?.modules[0].nameCh }}</span>
                                    </p>
                                </div>

                                <p *ngIf="!portalTranslate"
                                    style="text-align: left;margin-top:2.5%;font-weight:normal;font-size:11px !important">
                                    {{
                                    homeContentFromAPI[5]?.modules[0].descriptionEn }}</p>
                                <p *ngIf="portalTranslate"
                                    style="text-align: left;margin-top:10%;font-weight:normal;font-size:8px !important">
                                    {{
                                    homeContentFromAPI[5]?.modules[0].descriptionCh }}</p>


                            </div>

                        </a>
                    </div>
                </span>
            </div>
        </se-block>
    </ng-container>
    <!-- start of I2P  -->
    <ng-container>
        <div class="surround20 tileHeaderText">
            <span> {{ "home.i2p" | translate }}</span>
        </div>
        <se-block style="padding-top: 4%; ;padding-bottom: 6%; width: 99%;">
            <span id="scrolli2p" #scrolli2p></span>
            <div style="background-color: #fafafa;">
                <span>
                    <div class="item-wrapper" (click)="chipSelect('i2p')">
                        <a id="Contact" style="margin-left: 2%; cursor: pointer;" href="{{i2pUrl}}" target="_blank">
                            <img src="../assets/images/Desk/Desk_i2p.png" height="175" width="175" />
                            <div class="text-block">
                                <se-icon size="medium" style="
                            width: 15%;
                            display: block;
                            margin-left: auto;
                            margin-right: auto;
                            margin-top: 3%;
                            ">action_settings1</se-icon>
                                <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[4]?.modules[0].nameEn
                                    }}
                                </p>
                                <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[4]?.modules[0].nameCh
                                    }}
                                </p>
                                <div>
                                    <p>
                                        <se-icon size="medium" style="padding-top:2%">action_settings1</se-icon>
                                        <span *ngIf="!portalTranslate" style="margin-top:5px;font-size: 15px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;
                                  position:absolute;">{{ homeContentFromAPI[4]?.modules[0].nameEn }}</span>
                                        <span *ngIf="portalTranslate" style="margin-top:5px;padding-top:2%;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, sans-serif;margin-left:5%;
                                  position:absolute;">{{ homeContentFromAPI[4]?.modules[0].nameCh }}</span>
                                    </p>
                                </div>
                                <p *ngIf="!portalTranslate"
                                    style="text-align: left;margin-top:8.5%;font-weight:normal;font-size:11px !important">
                                    {{
                                    homeContentFromAPI[4]?.modules[0].descriptionEn }}
                                </p>
                                <p *ngIf="portalTranslate"
                                    style="text-align: left;margin-top:10%;font-weight:normal;font-size:8px !important">
                                    {{
                                    homeContentFromAPI[4]?.modules[0].descriptionCh }}
                                </p>
                            </div>
                        </a>
                    </div>
                </span>
            </div>
        </se-block>
    </ng-container>
</div>
<!-- For portal principal -->
<div *ngIf="isActivePortalPrinciple" class="">
    <app-portal-principal [portalTranslate]=portalTranslate></app-portal-principal>
</div>
<!-- For Online Repository -->
<div *ngIf="isActiveOnlineRepo" class="">
    <app-online-repository [portalTranslate]=portalTranslate></app-online-repository>
</div>
<!-- for contact support -->
<div *ngIf="isactiveSupport" class="surround20 subHeaderText">
    <div class="surround20 contactHeader">
        <span> {{ "home.ContactSupport" | translate }}</span>
    </div>
    <div class="contactSupportSeBlock">
        <div>
            <span *ngIf="!portalTranslate" [innerHTML]="getInnerHTML(contactNewDataEn)"></span>
            <span *ngIf="portalTranslate" [innerHTML]="getInnerHTML(contactNewDataCh)"></span>
            <br />
            <div style="padding-top:20px">
                <div class="container" style="width: 110%;
                margin-left: -5%;">
                    <app-contact-support-table [portalTranslate]=portalTranslate></app-contact-support-table>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- </div> -->