import filterSeriesByType from '../utils/filter-series-by-type';
import { Class } from '../../common';
import { TRENDLINE_SERIES } from '../constants';
class PlotAreaFactory extends Class {
  constructor() {
    super();
    this._registry = [];
  }
  register(type, seriesTypes) {
    this._registry.push({
      type: type,
      seriesTypes: seriesTypes
    });
  }
  create(srcSeries, options, chartService) {
    const registry = this._registry;
    let match = registry[0];
    let series;
    for (let idx = 0; idx < registry.length; idx++) {
      const entry = registry[idx];
      series = filterSeriesByType(srcSeries, entry.seriesTypes);
      const trendlines = filterSeriesByType(srcSeries, TRENDLINE_SERIES);
      if (series.length - trendlines.length > 0) {
        match = entry;
        break;
      }
    }
    return new match.type(series, options, chartService);
  }
}
PlotAreaFactory.current = new PlotAreaFactory();
export default PlotAreaFactory;