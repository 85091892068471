<div id="important-info-repo">
    <div class="container">
        <div class="row" *ngIf="showDafaults">
            <a class="my-4" (click)="onRepositoryClick($event)">
                << Back</a>
                    <div class="col-md-2">
                        <div class="card-group">
                            <div class="card bg-transparent border-0" (click)="onCommunicationsClick($event)">
                                <img src="../assets/images/repository/Communication.jpg" class="card-img-top">
                                <div class="card-body pb-2">
                                    <h5 class="card-title text-center">{{ "home.communications" | translate }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="card-group">
                            <div class="card bg-transparent border-0" (click)="onNewsletterClick($event)">
                                <img src="../assets/images/repository/newsletter.png" class="card-img-top">
                                <div class="card-body pb-2">
                                    <h5 class="card-title text-center">{{ "home.newsletter" | translate }}</h5>
                                </div>
                            </div>
                        </div>
                    </div>
        </div>
    </div>
</div>

<div *ngIf="selectedTemplate === 'communications'">
    <app-communications-repo></app-communications-repo>
</div>

<div *ngIf="selectedTemplate === 'newsletter'">
    <app-newsletter-repo></app-newsletter-repo>
</div>

<div *ngIf="selectedTemplate === 'repository'">
    <app-online-repository></app-online-repository>
</div>