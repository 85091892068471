import { Component, OnInit, HostListener, Renderer2 } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { VendorService } from '../../vendor.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { environment } from '../../../environments/environment';
import { FormGroup } from '@angular/forms';

const hasClass = (el: any, className: any) => new RegExp(className).test(el.className);

const isChildOf = (el: any, className: any) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

interface Item {
  text: string;
  value: number;
}

@Component({
  selector: 'app-mobile-section',
  templateUrl: './mobile-section.component.html',
  styleUrls: ['./mobile-section.component.scss'],

  host: {
    '(window:click)': 'onClick()'

  }
})
export class MobileSectionComponent implements OnInit {

  public formGroup!: FormGroup;
  public HomeFormGroup!: FormGroup;

  public view: any[] = [];

  uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint
  public opened = false;
  public userPortalData: any;
  public userContactData: any;
  public contactData: any;
  public portalData: any;
  public portalpData: any;
  public userPersonaPortalUpdateData: any;
  public newForm!: FormGroup;
  public contactForm!: FormGroup;
  public userPersonaContactUpdateData: any;
  public userPersonaContactTableAPIData: any;
  public showEditButton = true;
  public companyDynamicDataFlag: boolean = false;
  public contractDynamicDataFlag: boolean = false;
  public emptyImage = false;
  public barColor: any;
  public supplyOnData: any;
  public orderNewValue: any;
  public newUpdatesValue: any;
  public procureToPay: boolean = false;
  public SCM: boolean = true;
  public asnPendingVauleLink: any;
  public asnPendingVaule: any;
  public value = 0;
  public indeterminate = false;
  public barWidth: number = 20;
  public newOrderBarValue: any;
  public orderUpdateBarValue: any;
  public orderPartlyConfirmedBarValue: any;
  public orderOpenBarVaule: any;
  public orderDateBarValue: any;
  public asnCreationDue: any;
  public dueNext7DaysAsnBar: any;
  public overDueAsnBar: any;
  public dueTodayAsnBar: any;
  public asnCreationDueLink: any;
  public overDueAsnLink: any;
  public dueTodayAsnLink: any;
  public dueNext7DaysAsnLink: any;
  public seriesYearlyData2022: any;
  public count: any;
  public year: any;
  public yearlyBarColor: any;
  public seriesYearlyData2021: any;
  public seriesYearlyData2020: any
  public count2021: any;
  public count2022: any;
  public count2020: any;
  public editedValue: any;
  public homeEditSave = false;
  public editPPsave = false;
  public csTablePopupMsg = false;
  public startIndex: any;
  public endIndex: any;
  public fileName: any;
  public fileNameEn: any;
  public supplyOnDropdown: any;
  public supplyOnDropdownData: any;
  public id: any;
  public supplyOnDL: any[] = [];
  public supplyOnDefaultDL: any;
  send_date = new Date();
  public myGenValue: any;
  public noData = false;
  public singleDataValue: any;
  public noDeliveryData!: boolean;
  public order: any;
  public noOrderData: any;
  public delivery: any;
  public noDelivery: any;
  public deliveryProcureToPay: any;
  public singleArrayValue: any;
  public coupaDropdown: any;
  public coupaDropdownId: any;
  public coupaTableDataValue: any;
  public role!: string;
  public companyName: any;
  public errorPopUp = false;
  public adminHomeTableTranslate!: boolean;
  public supplyOnOrderTranslate!: boolean;
  public supplyOnDeliveryTranslate!: boolean;
  public trafficMonitoringTranslate!: boolean;
  public performanceDashboard!: string;
  public hideInPortalPrinciple: boolean = false;
  public roleCH!: string;
  public activeHome = true;
  public activePortalPrinciple = false;
  public activeSupplierAtSE = false;
  public activeSupport = false;
  public normalVerticalline = true;
  public adminVerticalline = false;
  public hideDropDown = true;
  public firstName: any;
  public parent!: HTMLElement;
  public sourceToContract = true;
  public supplierReleationManagement = false;
  public procureToPayBtn = false;
  public userMyaccountData: any;
  public tooltipGridMyaccountValue: any;
  public userGeneralData: any;
  public tooltipGridGeneralValue: any;
  public companyDynamicDataview: any;
  public companyDynamicData: any;
  public companyDynamicDatalastUpdate: any;
  public myAccValue: any;
  public supplierChain!: boolean;
  public coinnovations!: boolean;
  public i2p!: boolean;
  public myAccValueData: boolean = false;
  public generalValueData: boolean = false;
  public companyDynamicDataviewMessage: any;
  public seriesWeeklyData = [];
  public seriesMonthlyData = [];
  public welcomeTranslate!: boolean;
  public seriesMonthlyDataNew: any;
  public bellIconNotification = false;
  public companyInformation!: string | URL;
  notificationCheck = false;
  CoupaAccount!: string;
  coInnovationAccount!: string;
  i2pUrl!: string;
  SPCNAccount!: string;
  public zeroCarbonDashboard!: string;
  public spcnDashboard!: string;
  esurveyDynamicDataFlag: any;

  public listItemsEnglish: Array<Item> = [
    { text: "Source to Contract (SSP-SRM)", value: 1 },
    { text: "Supplier Relationship Management (SSP-SRM)", value: 2 },
    { text: "Procure to Pay (Coupa)", value: 3 },
    { text: "Supply Chain Management (SSP-SCM)", value: 4 },
    { text: "Open Innovation", value: 5 },
    { text: "I2P", value: 6 },
  ];

  public listItemsChina: Array<Item> = [
    { text: "采购到合同 (SSP-SRM)", value: 1 },
    { text: "供应商关系管理 (SSP-SRM)", value: 2 },
    { text: "采购至支付 (Coupa)", value: 3 },
    { text: "供应链管理 (SSP-SCM)", value: 4 },
    { text: "开放式创新", value: 5 },
    { text: "I2P", value: 6 },
  ];

  public listItems = this.listItemsEnglish
  public selectedItem: Item = this.listItems[0];
  userContactUpdateData: any;
  userPortalDataLatest: any;
  portalpDataEN: any;
  portalpDataCH: any;
  contactNewDataEn: any;
  contactNewDataCh: any;

  baseImageURL!: string | ArrayBuffer | null;
  fileSize: any;
  userPersonaSurveyData1: any;
  feedbackwindowClose: boolean = false;
  logoutlinkClicked: boolean = false;

  public valueChange(value: any): void {
    if (value.value == 1) {
      this.sourceButton();
    }
    if (value.value == 2) {
      this.supplierButton();
    } if (value.value == 3) {
      this.procureToPayBut();
    } if (value.value == 4) {
      this.supplierChainManagement();
    } if (value.value == 5) {
      this.coinnovationBtn();
    } if (value.value == 6) {
      this.i2pBtn();
    }
    console.log("san1", value)
  }

  public selectionChange(value: any): void {
    console.log("san1", value)
  }

  public sourceButton() {
    this.supplierReleationManagement = false;
    this.sourceToContract = true;
    this.procureToPayBtn = false;
    this.supplierChain = false;
    this.actionPlanClicked = false;
    this.eSourcingClicked = false;
    this.coinnovations = false;
    this.i2p = false;
  }

  public supplierButton() {
    this.supplierReleationManagement = true;
    this.sourceToContract = false;
    this.procureToPayBtn = false;
    this.supplierChain = false;
    this.actionPlanClicked = false;
    this.eSourcingClicked = false;
    this.coinnovations = false;
    this.i2p = false;
  }

  public procureToPayBut() {
    this.supplierReleationManagement = false;
    this.sourceToContract = false;
    this.procureToPayBtn = true;
    this.supplierChain = false;
    this.actionPlanClicked = false;
    this.eSourcingClicked = false;
    this.coinnovations = false;
    this.i2p = false;
  }

  public coinnovationBtn() {
    this.supplierReleationManagement = false;
    this.ptporderClicked = false;
    this.sourceToContract = false;
    this.procureToPayBtn = false;
    this.supplierChain = false;
    this.actionPlanClicked = false;
    this.eSourcingClicked = false;
    this.coinnovations = true;
    this.i2p = false;
  }

  public i2pBtn() {
    this.supplierReleationManagement = false;
    this.ptporderClicked = false;
    this.sourceToContract = false;
    this.procureToPayBtn = false;
    this.supplierChain = false;
    this.actionPlanClicked = false;
    this.eSourcingClicked = false;
    this.coinnovations = false;
    this.i2p = true;
  }

  public supplierChainManagement() {
    this.supplierReleationManagement = false;
    this.ptporderClicked = false;
    this.sourceToContract = false;
    this.procureToPayBtn = false;
    this.supplierChain = true;
    this.actionPlanClicked = false;
    this.eSourcingClicked = false;
    this.coinnovations = false;
  }

  public logout() {
    this.feedbackwindowClose = true;
    this.logoutlinkClicked = true;
    setTimeout(() => {
      sessionStorage.removeItem('UserLoggedInDetails');
      window.location.href = this.logoutURL;
    }, 60000);

  }



  constructor(public translate: TranslateService, public router: Router, private _service: VendorService, private renderer: Renderer2) {

  }
  public flag = false;
  public userPersona: any;
  public userPersonaEsourceingData: any[] = [];
  public userPersonaSurveyData: any[] = [];
  public personaOne = true;
  public personaChips = true;
  public personaTwo = false;
  public personaThree = false;
  public sspsrm = true;
  public sspinego = true;
  public personafour = false;
  public personafive = false;
  public personasix = false;
  public personaseven = false;
  public finalEsource = [];
  public pageSize = 10;
  public skip = 0;
  public portalImage = false;
  public portalTranslate = false;
  public loggedInlanguage: any;
  public loggedInCode: any;
  public tooltipGridData: any;
  public tooltipGridValue: any;
  public tooltipGridContractValue: any;
  public eSourcingClicked = false;
  public pClicked = false;
  public isSupplierDropdownShown = false;
  public isHomeDropdownShown = false;
  public isHomeDropdownPortal = false;
  public showMobileScreenNav = true;
  public eSourcingsspClicked = false;
  public eSourcinginegoClicked = false;
  public eSurveyClicked = false;
  public eSurveySSPClicked = false;
  public eSourcingpersonaClicked = true;
  public companyClicked = false;
  public contractClicked = false;
  public myaccountClicked = false;
  public mygeneralClicked = false;
  public orderClicked = false;
  public confirmorderClicked = false;
  public confirmDeliveryClicked = false
  public deliveryClicked = false;
  public confirmPartlyClicked = false;
  public confirmOpenClicked = false;
  public confirmDueClicked = false;
  public confirmNextClicked = false;
  public confirmNextValueClicked = false;
  public confirmDueValueClicked = false;
  public weekly = true;
  public Monthly = false;
  public yearly = false;
  public color: any;
  public imageExist: any;
  public overWrite: any;
  public orderUnconfirmedVaule: any;
  public withOutUpdateValue: any;
  public orderDatedVaule: any;
  public partlyConfirmedVaule: any;
  public orderOpenVaule: any;
  public orderUnconfirmedVauleLink: any;
  public withOutUpdateValueLink: any;
  public orderDatedVauleLink: any;
  public partlyConfirmedVauleLink: any;
  public orderOpenVauleLink: any;
  public orderNewValueLink: any;
  public newUpdatesValueLink: any;
  public overDueASN: any;
  public dueTodayASN: any;
  public dueNext7DaysASN: any;
  public asnScmButton = true;
  public asnProcureToPayButton = false;
  public cspopupmsg = false;
  public dropDownlistData: any;
  public editProfile!: string;
  public fileNameCh: any;
  public seriesWeeklyDataNew: any;
  public languageText: any;
  public ptporderClicked = false;
  esourcingDynamicDataFlag: any


  public userName: any;
  public sort: SortDescriptor[] = [
    {
      field: 'End_date',
      dir: 'asc'
    }
  ];
  public logoutURL = '';
  public IDMSPersona = '';
  public code = '';
  public eSourcingLatestCount = '0';
  public surveyLatestCount = '0';
  public mySelection: any[] = [];
  public homeContentFromAPI: any = [];
  public AdminHome = [];
  public AdminHomeS2C = [];
  public AdminHomeSSPSRM = [];
  public AdminHomeP2P = [];
  public AdminHomeCOI = [];
  public AdminHomeI2P = [];
  public AdminHomeSupplyOn = [];
  public selectedTab = 'Supplier Relationship Management';
  private docClickSubscription: any;
  public isUsernameDropdown = false;
  public isnormalView = true;
  public isadminView = false;
  public actionPlanClicked = false;
  public ActionPlanData: any;
  public supplierQualificationClicked = false;
  public SupplierQualificationData: any;
  public Contracts!: string;
  public ActionPlan!: string;
  public Auctions!: string;
  public MyAccountDocument!: string;
  public GeneralDocuments!: string;
  public SupplierQualification!: string | URL;
  public CompanyInformation!: string;
  public ESurvey!: string;
  actionPlanDataFlag: any;
  @HostListener('window:scroll', ['$event']) scrollHandler(event: any) {
    this.onClick();
  }

  public ptponOrderCencel(): void {
    this.orderClicked = false;
    this.confirmorderClicked = false;
    this.SCM = false;
    this.procureToPay = false;
    this.ptporderClicked = false;
  }


  public bellIconClick() {
    this.bellIconNotification = !this.bellIconNotification;
  }


  public ptponOrderClick(): void {
    this.ptporderClicked = true;
    this.orderClicked = false;
    this.deliveryClicked = false;
    this.supplyOnDropdown = JSON.parse(sessionStorage.getItem('supplyOnDropdown') || '{}');
    if (this.supplyOnDropdown) {
      this.confirmorderClicked = false;
      this.confirmPartlyClicked = false;
      this.confirmOpenClicked = false;
      this.deliveryClicked = false;
      this.confirmDeliveryClicked = false;
      this.confirmDueClicked = false;
      this.confirmNextClicked = false;
      this.procureToPay = false;
      this.SCM = true;
      this.noData = false;
    }
    else {
      this.SCM = false;
      this.noData = true;
    }

  }

  ngOnInit(): void {
    this._service.IdmsInfo.subscribe(data => {
      if (data) {
        this.loggedInlanguage = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
        if (this.loggedInlanguage.languageCode === "EN") {
          this.editProfile = environment.editProfileEN;
        }
        else {
          this.editProfile = environment.editProfileCH;
        }
        this.IDMSPersona = this.loggedInlanguage.persona;
        this.firstName = this.loggedInlanguage.firstName;
        this.userName = this.loggedInlanguage.firstName + ' ' + this.loggedInlanguage.lastName;
        this.code = sessionStorage.getItem('Idmscode') || '';
        this.role = this.loggedInlanguage.portalUserType;
        this.roleCH = this.loggedInlanguage.portalUserTypeCH;
        if (this.role !== "Admin User" || this.roleCH !== "管理员") {
          this.hideDropDown = true;
        }
        this.companyName = this.loggedInlanguage.companyName;
        if (!this.companyName) {
          this.companyDynamicData = JSON.parse(sessionStorage.getItem('dynamicData') || '{}');
          this.companyName = this.companyDynamicData.supplierName;
        }

      }
    })
    this._service.eSourceData.subscribe((data) => {
      let response: any = data
      // if (response.latestCount > 0) {
      //   this.eSourcingLatestCount = response.latestCount;
      // }
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.esourcingDynamicDataFlag = response?.getEsourcingData?.isNotification
      }
    });
    this._service.ESurvey.subscribe((data) => {
      let response: any = data
      // if (response.latestCount > 0) {
      //   this.surveyLatestCount = response.latestCount;
      // }
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.esurveyDynamicDataFlag = response?.getEsourcingData?.isNotification
      }
    });
    this._service.contractData.subscribe((data) => {
      let response: any = data;
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.contractDynamicDataFlag = response?.getContractData?.isNotification
      }

    })
    this._service.accountAPIData.subscribe(data => {
      let response: any = data;
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.myAccValue = response?.data?.getMyDocumentData?.unreadCount
      }

    })
    this._service.generalDocumentData.subscribe(data => {
      let response: any = data;
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.myGenValue = response?.data?.getGeneralDocumentData?.unreadCount
      }
    })
    this._service.companyInfo.subscribe(data => {
      let response: any = data;
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.companyDynamicDataFlag = response?.data?.getCompanyInfo?.showNotification
        this.companyName = response?.data?.getCompanyInfo?.supplierName;
      }
    })
    this._service.actionPlan.subscribe(data => {
      // if (data.length > 0) {
      //   this.ActionPlanData = data;
      // }
      let response: any = data;

      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.ActionPlanData = response?.getActionPlanData?.actionPlanData;
      }
    })
    this.zeroCarbonDashboard = environment.zeroCarbonUrl;
    this.spcnDashboard = environment.SPCNUrl;
    this.CoupaAccount = environment.coupaMyAccount;
    this.SPCNAccount = environment.supplyOnMyAccount;
    this.coInnovationAccount = environment.coInnovationAccount;
    this.i2pUrl = environment.i2pUrl;
    this.Contracts = environment.Contracts;
    this.ActionPlan = environment.ActionPlan;
    this.companyInformation = environment.CompanyInformation;
    this.Auctions = environment.Auctions;
    this.MyAccountDocument = environment.MyAccountDocument;
    this.GeneralDocuments = environment.GeneralDocuments;
    this.SupplierQualification = environment.SupplierQualification;
    this.CompanyInformation = environment.CompanyInformation;
    this.ESurvey = environment.ESurvey;
    this.performanceDashboard = environment.performanceDashboard;
    this._service.getHomePageContent();
    this.renderer.listen('document', 'click', ({ target }) => {
      if (!isChildOf(target, 'k-grid')) {

      }
    });

    this.docClickSubscription = this.renderer.listen(
      'document',
      'click',
      this.onDocumentClick.bind(this)
    );


    this.loggedInlanguage = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
    this.role = this.loggedInlanguage.portalUserType;
    this.roleCH = this.loggedInlanguage.portalUserTypeCH;
    this.companyName = this.loggedInlanguage.companyName;
    if (this.loggedInlanguage.languageCode === 'EN') {
      this.loggedInCode = 'en';
      this.portalImage = true;
      this.portalTranslate = false;

    }
    else if (this.loggedInlanguage.languageCode === 'CN') {
      this.loggedInCode = 'cn';
      this.portalImage = false;
      this.portalTranslate = true;



    }
    else {
      this.loggedInCode = 'en';
      this.portalImage = true;
      this.portalTranslate = false;
    }
    this.logoutURL = environment.logoutURL;
    this.translate.addLangs(['en', 'cn']);
    this.translate.setDefaultLang(this.loggedInCode);
    const browserLang = this.translate.getBrowserLang();
    this.translate.use(browserLang?.match(/en|cn/) ? browserLang : this.loggedInCode);

    setTimeout(() => {

      this.showMobileScreenNav = false;


    }, 10000);

    this.onInegoESourcingClick();
    this.onsspESourcingClick();
    this.sspsrmInego();


    this._service.getHomeContentAPI.subscribe((data) => {
      if (data) {
        this.homeContentFromAPI = JSON.parse(sessionStorage.getItem('homeContent') || '{}');
        this.AdminHomeS2C = this.homeContentFromAPI[3].modules;
        this.AdminHomeSSPSRM = this.homeContentFromAPI[0].modules;
        this.AdminHomeP2P = this.homeContentFromAPI[2].modules;
        this.AdminHomeCOI = this.homeContentFromAPI[5].modules;
        this.AdminHomeI2P = this.homeContentFromAPI[4].modules;
        this.AdminHomeSupplyOn = this.homeContentFromAPI[1].modules;
        this.tabClick(1);
      }
    });

    // this.getPortalData()
    this.getContactData()
    this.notificationCheck = true;
  }





  getInnerHTML(val: string) {
    return val.replace(/\r?\n|\r/g, '<br/>');
  }



  public tabClick(tabNum?: number): void {
    if (tabNum === 1) {
      this.AdminHome = this.AdminHomeS2C;
      this.selectedTab = 'Source to Contract';
    } else if (tabNum === 2) {
      this.AdminHome = this.AdminHomeSSPSRM;
      this.selectedTab = 'Supplier Relationship Management';
    } else if (tabNum === 3) {
      this.AdminHome = this.AdminHomeP2P;
      this.selectedTab = 'Procure to Pay & Supply Chain Management';
    } else if (tabNum === 4) {
      this.AdminHome = this.AdminHomeSupplyOn;
      this.selectedTab = 'Supply Chain Management';
    } else if (tabNum === 5) {
      this.AdminHome = this.AdminHomeCOI;
      this.selectedTab = 'Co-Innovation'
    } else if (tabNum === 6) {
      this.AdminHome = this.AdminHomeI2P;
      this.selectedTab = 'I2P';
    } else {
      this.AdminHome = this.AdminHomeS2C;
      this.selectedTab = 'Source to Contract';
    }
  }







  private onDocumentClick(e: any): void {

  }

  public scroll(event: string, scrollItem: string): void {
    console.log(scrollItem)
    if (scrollItem) {
      const element = document.getElementById(scrollItem) as HTMLElement;

      element.scrollIntoView({ behavior: 'smooth' });
    }
  }



  public getContactData(): void {

    if (sessionStorage.getItem('ContactData')) {

      this.userContactData = JSON.parse(sessionStorage.getItem('ContactData') || '{}');

      this.contactNewDataEn = this.userContactData.textEn === null ? '' : this.userContactData.textEn;
      this.contactNewDataCh = this.userContactData.textCh === null ? '' : this.userContactData.textCh;

    }

    else {
      this._service.getContact();
      this._service.ContactAPI.subscribe((data) => {
        if (data) {
          this.userContactData = JSON.parse(sessionStorage.getItem('ContactData') || '{}');

          this.contactNewDataEn = this.userContactData.textEn === null ? '' : this.userContactData.textEn;
          this.contactNewDataCh = this.userContactData.textCh === null ? '' : this.userContactData.textCh;


        }
      });
    }
  }


  public onESourcingClick(): void {
    this.eSourcingClicked = !this.eSourcingClicked;
    this.contractClicked = false;
    this.companyClicked = false;
    this.supplierQualificationClicked = false;
    this.actionPlanClicked = false;
  }

  public onsupplierQualificationClick(): void {
    this.eSourcingClicked = false;
    this.contractClicked = false;
    this.companyClicked = false;
    // this.supplierQualificationClicked = !this.supplierQualificationClicked;
    this.actionPlanClicked = false;
    console.log("supplierURL", this.SupplierQualification);
    window.open(this.SupplierQualification, '_blank');
  }

  public onCompanyClick(): void {
    this.companyClicked = !this.companyClicked;
    this.contractClicked = false;
    this.eSourcingClicked = false;
    this.supplierQualificationClicked = false;
    this.actionPlanClicked = false;
  }

  //SSP-SRM Account Redirection
  public accountSSPSRM(): void {
    this.companyClicked = !this.companyClicked;
    this.eSourcingClicked = false;
    this.contractClicked = false;
    this.actionPlanClicked = false;
    console.log("accountSSPURL", this.companyInformation);
    window.open(this.companyInformation, '_blank');
  }

  public onOrderClick(): void {
    this.orderClicked = true;
    this.ptporderClicked = false;
    this.deliveryClicked = false;
    this.supplyOnDropdown = JSON.parse(sessionStorage.getItem('supplyOnDropdown') || '{}');
    if (this.supplyOnDropdown) {
      this.orderClicked = true;
      this.confirmorderClicked = false;
      this.confirmPartlyClicked = false;
      this.confirmOpenClicked = false;
      this.deliveryClicked = false;
      this.confirmDeliveryClicked = false;
      this.confirmDueClicked = false;
      this.confirmNextClicked = false;
      this.procureToPay = false;
      this.SCM = true;
      this.noData = false;
    }
    else {
      this.SCM = false;
      this.noData = true;
    }
  }


  public onOrderCencel(): void {
    this.orderClicked = false;
    this.confirmorderClicked = false;
    this.SCM = true;
    this.procureToPay = false;
  }

  public onDeliveryCencel(): void {
    this.deliveryClicked = false;
    this.confirmDeliveryClicked = false;
  }


  public onDeliveryClick(): void {
    this.deliveryClicked = true;
    this.ptporderClicked = false;
    this.confirmDeliveryClicked = false
    this.orderClicked = false
    this.confirmorderClicked = false;
    this.confirmDueClicked = false;
    this.confirmNextClicked = false;
    this.confirmPartlyClicked = false;
    this.confirmOpenClicked = false;
    this.confirmNextValueClicked = false;
    this.confirmDueValueClicked = false;
    this.asnScmButton = true;
    this.asnProcureToPayButton = false;
    this.supplyOnDropdown = JSON.parse(sessionStorage.getItem('supplyOnDropdown') || '{}');
    if (this.supplyOnDropdown) {
      this.noDeliveryData = false;
      this.asnScmButton = true;
    }
    else {
      this.SCM = false;
      this.noDeliveryData = true;
      this.asnScmButton = false;
    }

  }


  public onContractClick(): void {
    this.contractClicked = !this.contractClicked;
    this.companyClicked = false;
    this.eSourcingClicked = false;
    this.supplierQualificationClicked = false;
    this.actionPlanClicked = false;
  }


  public onsupplierQualificationcancelClick() {
    this.supplierQualificationClicked = false;
  }


  public onSurveyClick(): void {
    this.eSurveyClicked = !this.eSurveyClicked;

    this.myaccountClicked = false;
    this.mygeneralClicked = false;

    this.contractClicked = false;
    this.companyClicked = false;
    this.eSourcingClicked = false;
    this.supplierQualificationClicked = false;
    this.actionPlanClicked = false;

  }


  public onInegoESourcingClick(): void {
    this.eSourcinginegoClicked = !this.eSourcinginegoClicked;
  }

  public onsspESourcingClick(): void {
    this.eSourcingsspClicked = !this.eSourcingsspClicked;
  }

  public onActionPlanClicked(): void {
    this.actionPlanClicked = !this.actionPlanClicked;
    this.eSourcingClicked = false;
    this.contractClicked = false;
    this.companyClicked = false;
    this.supplierQualificationClicked = false;
    this.eSurveyClicked = false;
    this.myaccountClicked = false;
    this.mygeneralClicked = false;
  }

  public onSupplierAtSEDropdownClick($event: any): void {
    $event.stopPropagation();
    this.isUsernameDropdown = false;
    this.isSupplierDropdownShown = true;
    this.isHomeDropdownShown = false;
    this.isHomeDropdownPortal = false;
  }

  public onSupplierAtSEDropdownClickleave($event: any): void {
    this.activePortalPrinciple = false;
    this.activeHome = false;
    this.activeSupplierAtSE = true
    this.activeSupport = false;
    $event.stopPropagation();
    this.isUsernameDropdown = false;
    this.isSupplierDropdownShown = false;
    this.isHomeDropdownShown = false;
    this.isHomeDropdownPortal = false;
  }



  public onPortalDropdownClick($event: any): void {
    $event.stopPropagation();
    this.isUsernameDropdown = false;
    this.isHomeDropdownPortal = true;
    this.isSupplierDropdownShown = false;
    this.isHomeDropdownShown = false;
  }

  public onPortalDropdownClickleave($event: any): void {
    $event.stopPropagation();
    this.isUsernameDropdown = false;
    this.isHomeDropdownPortal = false;
    this.isSupplierDropdownShown = false;
    this.isHomeDropdownShown = false;
  }

  public onClick(): void {
    this.isUsernameDropdown = false;
    this.isSupplierDropdownShown = false;
    this.isHomeDropdownShown = false;
    this.isHomeDropdownPortal = false;

  }

  public switchLanguage($event: any): void {
    const value = $event.target.value;
    if (value == "Ch") {
      this.translate.use('cn');
      this.portalTranslate = true;
      this.listItems = [...this.listItemsChina]
    }
    else {
      this.translate.use('en');
      this.portalTranslate = false;
      this.listItems = [...this.listItemsEnglish]
    }

  }
  public navigateTosspsrm(): void {
    this.activePortalPrinciple = true;
    this.activeHome = false;
    this.activeSupplierAtSE = false;
    this.activeSupport = false;
    this.onActivate();

  }

  public onActivate(): void {
    window.scroll({
      top: 800,
      left: 100,
      behavior: 'smooth'
    });
  }




  public navigateTosspinego(): void {
    this.activePortalPrinciple = false;
    this.activeHome = false;
    this.activeSupplierAtSE = false;
    this.activeSupport = true;
    // this.activeSupport = true;
    this.onActivate();
    this.getContactData();

  }


  public sspsrmInego(): void {
    this.activePortalPrinciple = false;
    this.activeHome = true;
    this.activeSupplierAtSE = false;
    this.activeSupport = false;
    this.hideInPortalPrinciple = false;
  }



  public autofit = true;
  public onUsernameClick($event: any): void {
    $event.stopPropagation();
    this.isUsernameDropdown = !this.isUsernameDropdown;
    this.isHomeDropdownPortal = false;
    this.isSupplierDropdownShown = false;
    this.isHomeDropdownShown = false;
    this.bellIconNotification = false;
    this.avoidbackground();
  }

  // public avoidbackground() {
  //   this.parent = document.getElementById("mobilebackground");
  //   this.parent.disabled = true;
  // }

  public avoidbackground() {
    const element = document.getElementById("mobilebackground") as HTMLElement;

    if (element) { // Check if the element exists before accessing its properties
      if (element instanceof HTMLInputElement || element instanceof HTMLSelectElement ||
        element instanceof HTMLTextAreaElement || element instanceof HTMLButtonElement) {
        element.disabled = true;
      } else {
        console.warn("The 'disabled' property is not applicable for the element with ID 'mobilebackground'.");
      }
    }
  }

  public adminView(): void {
    this.normalVerticalline = false;
    this.adminVerticalline = true;
    this.isadminView = true;
    this.isnormalView = false;
  }

  public starClicked() {
    this.feedbackwindowClose = true
    this.logoutlinkClicked = false;
  }

  public normalView(): void {
    this.normalVerticalline = true;
    this.adminVerticalline = false;
    this.getContactData();

    this.hideInPortalPrinciple = false;
    window.location.reload();

  }

  bellNotificatinArrayValue() {
    let count = 0;
    if (this.contractDynamicDataFlag === true) {
      count++;
    }
    if (this.myAccValue > 0) {
      count++;
    }
    if (this.surveyLatestCount !== '0') {
      count++;
    }
    if (this.myGenValue > 0) {
      count++;
    }
    if (this.eSourcingLatestCount !== '0') {
      count++;
    }
    if (this.companyDynamicDataFlag === true) {
      count++;
    }
    if (this.ActionPlanData?.length > 0) {
      count++;
    }
    return count

  }

  notificationClicked(tileId: any) {
    this.bellIconClick();
    this.sspsrmInego();
    switch (tileId) {
      case 'actionPlan':
        this.valueChange(this.listItems[1])
        this.selectedItem = this.listItems[1]
        setTimeout(() => {
          this.scroll('', tileId);
          this.onActionPlanClicked();
          this.actionPlanClicked = true;
        }, 0);

        break;
      case 'contract':
        this.valueChange(this.listItems[0])
        this.selectedItem = this.listItems[0]
        setTimeout(() => {
          this.scroll('', tileId);
          this.onContractClick();
          this.contractClicked = true;
        })

        break;
      case 'eSurvey':
        this.valueChange(this.listItems[0])
        this.selectedItem = this.listItems[0]
        setTimeout(() => {
          this.scroll('', tileId);
          this.onSurveyClick();
          this.eSurveyClicked = true;
        })

        break;
      case 'eSource':
        this.valueChange(this.listItems[1])
        this.selectedItem = this.listItems[1]
        setTimeout(() => {
          this.scroll('', tileId);
          this.onESourcingClick();
          this.eSourcingClicked = true;
        })

        break;
      case 'companyInfo':
        this.valueChange(this.listItems[0])
        this.selectedItem = this.listItems[0]
        setTimeout(() => {
          this.scroll('', tileId);
          this.onCompanyClick();
          this.companyClicked = true;
        })

        break;
      case 'generalDoc':
        this.valueChange(this.listItems[1])
        this.selectedItem = this.listItems[1]
        setTimeout(() => {
          this.scroll('', tileId);
        })

        break;
      case 'myAccount':
        this.valueChange(this.listItems[1])
        this.selectedItem = this.listItems[1]
        setTimeout(() => {
          this.scroll('', tileId);
        })

        break;

      default:
        break;
    }

  }

  public ngOnDestroy(): void {
    this.docClickSubscription();
  }

}
