import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '../../../vendor.service';
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})
export class CompanyInfoComponent implements OnInit, OnChanges {

  @Input() portalTranslate: any;
  companyDynamicDataview: any;
  companyDynamicDataviewMessage: any;
  companyDynamicDatalastUpdate: any;
  CompanyInformation: any;
  @Output() canCloseDialog = new EventEmitter;
  constructor(private _service: VendorService, public translate: TranslateService) { }

  ngOnInit(): void {
    this._service.companyInfo.subscribe(data => {
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        let companyData: any = data
        this.companyDynamicDataview = companyData?.completion;
        this.companyDynamicDatalastUpdate = companyData.lastUpdateDate;
        this.companyDynamicDataviewMessage = companyData.message;
      }
    })
    this.CompanyInformation = environment.CompanyInformation;

  }


  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  onCompanycancelClick() {
    this.canCloseDialog.emit(false)
  }
}
