import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { VendorService } from "../../../vendor.service";
import { TranslateService } from "@ngx-translate/core";
import { environment } from "../../../../environments/environment";
import { SortDescriptor } from "@progress/kendo-data-query";

@Component({
  selector: "app-action-plan",
  templateUrl: "./action-plan.component.html",
  styleUrls: ["./action-plan.component.scss"]
})

export class ActionPlanComponent implements OnInit {
  @Input() portalTranslate: any;
  @Output() canCloseDialog = new EventEmitter();
  tooltipGridValue: any;
  ActionPlan!: string;
  public pageSize = 10;
  public skip = 0;

  constructor(private _service: VendorService, public translate: TranslateService) { }

  public sort: SortDescriptor[] = [
    {
      field: "End_date",
      dir: "asc"
    }
  ];
  tooltipGridData: any;
  ngOnInit(): void {
    this._service.actionPlan.subscribe(data => {
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        let response: any = data
        this.tooltipGridData = response.getActionPlanData.actionPlanData;
      }
    });
    this.ActionPlan = environment.ActionPlan;
  }

  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use("cn");
    } else {
      this.translate.use("en");
    }
  }

  onActionPlanCancelClicked() {
    this.canCloseDialog.emit(false);
  }

  public onPageChange(state: any): void {
    this.pageSize = state.take;
  }
}
