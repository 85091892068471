import { Class } from '../../../common';
import { EncodingResult } from '../encoding-result';
import { getModes, getVersion, getDataCodewordsCount, getDataString } from '../encoding';
export class IsoEncoder extends Class {
  getEncodingResult(inputString, errorCorrectionLevel) {
    let modes = getModes(inputString),
      dataCodewordsCount = getDataCodewordsCount(modes),
      version = getVersion(dataCodewordsCount, errorCorrectionLevel),
      dataString = getDataString(modes, version);
    return new EncodingResult(dataString, version);
  }
}