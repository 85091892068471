<se-block class="contract-table tab-panel" id="tabPanel1">
    <div class="tableArrow"></div>
    <se-table height="500px" class="tableVew block-content-border">
        <p class="deleteIcon">
            <se-icon class="delete-icon-color" (click)="onContractcancelClick()">action_delete_cross
            </se-icon>
        </p>
        <p class="tableHeader mobileFont">
            <span *ngIf="!portalTranslate"> Contracts-Awaiting Actions </span>
            <span *ngIf="portalTranslate"> 待处理合同 </span>
        </p>
        <div class="tableContent desktopTable">
            <div class="float-end pb-2">
                <a class="text-white buttonAnchor" kendoTooltip tooltipClass="my-class" position="top"
                    title="{{Contracts}}" href="{{Contracts}}" target="_blank">
                    <se-button *ngIf="!portalTranslate" color="primary"> All Contracts</se-button>
                    <se-button *ngIf="portalTranslate" color="primary"> 所有合同</se-button>
                </a>
            </div>
            <div class="table-context">
                <kendo-grid [kendoGridBinding]="tooltipGridContractValue" [sortable]="true" [height]="530"
                    class="k-button-group" [pageSize]="pageSize" [skip]="skip" [sort]="sort" [pageable]="true"
                    (pageChange)="onPageChange($event)" class="mobileGrid">
                    <kendo-grid-column field="Contract_ID" title="Contract ID" [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span class="spanStyle" *ngIf="!portalTranslate">Contract ID</span>
                            <span class="spanStyle" *ngIf="portalTranslate">合同编号</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-gridView>
                            <a *ngIf="gridView.Deep_Link !== null" href="{{gridView.Deep_Link}}" target="_blank">
                                <span class="contract-id">{{gridView.Contract_ID }}</span>
                            </a>
                            <span *ngIf="gridView.Deep_Link === null" style="width:20%; padding-left: 10px;">{{
                                gridView.Contract_ID }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Contract_Title" title="Contract Title" [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span class="contract-title" *ngIf="!portalTranslate">Contract
                                Title</span>
                            <span class="contract-title" *ngIf="portalTranslate">合同标题</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Status" title="Status" [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span *ngIf="!portalTranslate">Status</span>
                            <span *ngIf="portalTranslate">状态</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="!portalTranslate" field="Owner" title="Owner"
                        [headerClass]="'gridColumnStyle'">
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="portalTranslate" field="Owner" title="负责人
             " [headerClass]="'gridColumnStyle'">
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="!portalTranslate" field="Date_of_Creation" title="Date of Creation"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridCellTemplate let-gridView>
                            {{ gridView.Date_of_Creation | date: 'dd MMM yyyy' }}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="portalTranslate" field="Date_of_Creation" title="创建日期
             " [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridCellTemplate let-gridView>
                            {{ gridView.Date_of_Creation | date: 'dd MMM yyyy' }}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="!portalTranslate" field="Validity_Start" title="Validity Start"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridCellTemplate let-gridView>
                            {{ gridView.Validity_Start | date: 'dd MMM yyyy' }}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="portalTranslate" field="Validity_Start" title="生效日期"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridCellTemplate let-gridView>
                            {{ gridView.Validity_Start | date: 'dd MMM yyyy' }}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="!portalTranslate" field="Supplier_name_company" title="Supplier Company"
                        [headerClass]="'gridColumnStyle'">
                    </kendo-grid-column>
                    <kendo-grid-column *ngIf="portalTranslate" field="Supplier_name_company" title="供应商公司"
                        [headerClass]="'gridColumnStyle'">
                    </kendo-grid-column>
                    <ng-template kendoGridNoRecordsTemplate>
                        <span *ngIf="!portalTranslate"> There is currently no ongoing events, to see past events you can
                            click on All Contracts.</span>
                        <span *ngIf="portalTranslate">目前没有未完成合同，如果查看已完成合同请点击所有合同按钮。
                        </span>
                    </ng-template>
                </kendo-grid>
            </div>
        </div>
    </se-table>
</se-block>