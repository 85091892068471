<!-- <div *ngIf="isOpen">
   <kendo-dialog title="" [minWidth]="350" [height]="700">
     <div class="dialogWidth">
       <div (click)="close(false)" class="closeIcon">
         <se-icon size="small">action_close</se-icon>
       </div>
       <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
         <div class="form-group">
           <label>{{ 'home.categoryRequest' | translate }}</label>
           <span class="required"> * </span>
           <select class="custom-select" (change)="changeCategory($event)" formControlName="category">
             <option value="">{{ 'home.chooseCategory' | translate }}</option>
             <ng-container *ngIf="!portalTranslate">
               <option *ngFor="let category of listItemsEnglish" [ngValue]="category.text">{{category.text}}</option>
             </ng-container>
             <ng-container *ngIf="portalTranslate">
               <option *ngFor="let category of listItemsCh" [ngValue]="category.text">{{category.text}}</option>
             </ng-container>
           </select>
           <div *ngIf="isSubmitted && category.invalid" class="alert alert-danger">
             <div *ngIf="category.errors?.required">{{ 'home.categoryReq' | translate }}.</div>
           </div>
         </div>
         <div *ngIf="selectedCategory === 'General'  || selectedCategory == '常规' " class="form-group">
           <label>{{ 'home.selectType' | translate }}</label>
           <span class="required"> * </span>
           <div class="custom-control custom-radio">
             <input id="request" type="radio" class="custom-control-input" value="REQUEST" name="type"
               formControlName="type">
             <label class="custom-control-label" for="request">{{ 'home.request' | translate }}</label>
           </div>
   
           <div class="custom-control custom-radio">
             <input id="issue" type="radio" class="custom-control-input" value="ISSUE" name="type"
               formControlName="type">
             <label class="custom-control-label" for="issue">{{ 'home.issue' | translate }}</label>
           </div>
           <div *ngIf="isSubmitted && type.invalid" class="alert alert-danger">
             <div *ngIf="type.errors?.required">{{ 'home.selectVal' | translate }}.</div>
           </div>
         </div>
         <div class="form-group">
           <label>{{ 'home.SupplierName' | translate }}</label>
           <input type="text" formControlName="name" class="form-control" readonly />
         </div>
   
         <div class="form-group">
           <label>{{ 'home.email' | translate }}</label>
           <input type="email" formControlName="email" class="form-control" readonly />
         </div>
         <div class="form-group">
           <label>{{ 'home.phone' | translate }}</label>
           <input type="tel" formControlName="phone" class="form-control" />
         </div>
   
         <div class="form-group">
           <label>{{ 'home.issueDesc' | translate }}</label>
           <span class="required"> * </span> <br>
           <textarea formControlName="issueDesc" rows="5" style="width: 100%;">
                   </textarea>
           <div *ngIf="isSubmitted && issueDesc.invalid" class="alert alert-danger">
             <div *ngIf="issueDesc.errors?.required">{{ 'home.issueDescReq' | translate }}.</div>
           </div>
         </div>
         <div class="form-group">
           <label for="file">{{ 'home.attachment' | translate }} </label>
           <span class="required"> * </span>&nbsp;
           <input (change)="onFileSelected($event)" type="file" id="file" formControlName="file">
           <div>{{ 'home.note' | translate }}:{{ 'home.fileSize' | translate }}</div>
           <div *ngIf="isSubmitted && file.invalid" class="alert alert-danger">
             <div *ngIf="file.errors?.required">{{ 'home.fileReq' | translate }}.</div>
           </div>
           <div *ngIf="!validFileSize" class="alert alert-danger">{{ 'home.sizeNotAllowed' | translate }}.</div>
         </div>
         <div *ngIf="isFileupload && validFileSize && isUploadAttachmentSuccess && file.valid"
           class="alert alert-success">{{ 'home.uploadSuccess' | translate }}.</div>
         <div *ngIf="isFileupload && validFileSize && !isUploadAttachmentSuccess" class="alert alert-danger"> {{
           'home.uploadFail' | translate }}.</div>
         <div class="form-group btnAlign">
           <button type="submit" class="btn btn-primary ">{{ 'home.submit' | translate }}</button>
         </div>
       </form>
     </div>
     <div *ngIf="loader" class="wrapper">
       <se-icon class="spin" size="large">spinner</se-icon>
     </div>
   </kendo-dialog>
   </div> -->
<div class="contact-form">
  <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
    <div class="row mb-5">
      <div class="col-12 col-md-6 my-2">
        <div class="form-group">
          <label>{{ 'home.categoryRequest' | translate }}</label>
          <span class="required"> * </span>
          <select class="form-select" (change)="changeCategory($event)" formControlName="category">
            <option value="">{{ 'home.chooseCategory' | translate }}</option>
            <option *ngFor="let category of listItems" [value]="category.value">
              {{ translate.currentLang == 'en' ? category.textEn : category.textCh }}
            </option>
          </select>
          <div *ngIf="isSubmitted && category.invalid" class="alert alert-danger">
            <div *ngIf="category.errors?.['required']">{{ 'home.categoryReq' | translate }}.</div>
          </div>
        </div>
        <div *ngIf="selectedCategory === 'GENERAL'  || selectedCategory == '常规' " class="form-group mt-3  ">
          <label>{{ 'home.selectType' | translate }}</label>
          <span class="required"> * </span>
          <div class="custom-control custom-radio">
            <input id="request" type="radio" class="custom-control-input" value="REQUEST" name="type"
              formControlName="type">
            <label class="custom-control-label" for="request">{{ 'home.request' | translate }}</label>
          </div>
          <div class="custom-control custom-radio">
            <input id="issue" type="radio" class="custom-control-input" value="ISSUE" name="type"
              formControlName="type">
            <label class="custom-control-label" for="issue">{{ 'home.issue' | translate }}</label>
          </div>
          <div *ngIf="isSubmitted && type.invalid" class="alert alert-danger">
            <div *ngIf="type.errors?.['required']">{{ 'home.selectVal' | translate }}.</div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6 my-2">
        <div class="form-group">
          <label>{{ 'home.SupplierName' | translate }}</label>
          <input type="text" formControlName="name" class="form-control" readonly />
        </div>
      </div>
      <div class="col-12 col-md-6 my-2">
        <div class="form-group">
          <label>{{ 'home.email' | translate }}</label>
          <input type="email" formControlName="email" class="form-control" readonly />
        </div>
      </div>
      <div class="col-12 col-md-6 my-2">
        <div class="form-group">
          <label>{{ 'home.phone' | translate }}</label>
          <input type="tel" formControlName="phone" class="form-control" />
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label>{{ 'home.issueDesc' | translate }}</label>
          <span class="required"> * </span> <br>
          <textarea formControlName="issueDesc" rows="5" style="width: 100%;" placeholder="{{'home.contactPlaceholder' |
                translate}}">
                       </textarea>
          <div *ngIf="isSubmitted && issueDesc.invalid" class="alert alert-danger">
            <div *ngIf="issueDesc.errors?.['required']">{{ 'home.issueDescReq' | translate }}.</div>
          </div>
        </div>
      </div>
      <div class="col-12">
        <div class="form-group">
          <label for="file">{{ 'home.attachment' | translate }} </label>
          <!-- <span class="required"> * </span>&nbsp; -->
          <input (change)="onFileSelected($event)" type="file" id="file" formControlName="file" class="ml-2">
          <div>{{ 'home.note' | translate }}:{{ 'home.fileSize' | translate }}</div>
          <div *ngIf="isSubmitted && file.invalid" class="alert alert-danger">
            <div *ngIf="file.errors?.['required']">{{ 'home.fileReq' | translate }}.</div>
          </div>
          <div *ngIf="!validFileSize" class="alert alert-danger">{{ 'home.sizeNotAllowed' | translate }}.
          </div>
        </div>
        <div *ngIf="isFileupload && validFileSize && isUploadAttachmentSuccess && file.valid"
          class="alert alert-success">{{
          'home.uploadSuccess' | translate }}.
        </div>
        <div *ngIf="isFileupload && validFileSize && !isUploadAttachmentSuccess" class="alert alert-danger"> {{
          'home.uploadFail' | translate }}.
        </div>
      </div>
      <div class="col-12">
        <div class="form-group btnAlign">
          <button type="submit" class="btn btn-primary ">{{ 'home.submit' | translate }}</button>
        </div>
      </div>
      <div *ngIf="loader" class="wrapper">
        <se-icon class="spin" size="large">spinner</se-icon>
      </div>
    </div>
  </form>
</div>
<kendo-dialog *ngIf="isSubmitted && message?.length >= 1">
  <div class="mt-4">{{message}}</div>
  <div class="form-group btnAlign">
    <button class="btn btn-primary " (click)="closeTicketDailog()">Ok</button>
  </div>
</kendo-dialog>
<ng-template #showSpinner>
  <div class="wrapper">
    <se-icon class="spin" size="large">spinner</se-icon>
  </div>
</ng-template>