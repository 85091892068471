let keys = {
  INSERT: 45,
  DELETE: 46,
  BACKSPACE: 8,
  TAB: 9,
  ENTER: 13,
  ESC: 27,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
  END: 35,
  HOME: 36,
  SPACEBAR: 32,
  PAGEUP: 33,
  PAGEDOWN: 34,
  F2: 113,
  F10: 121,
  F12: 123,
  NUMPAD_PLUS: 107,
  NUMPAD_MINUS: 109,
  NUMPAD_DOT: 110
};
export default keys;