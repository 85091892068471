<div id="general-repo">
    <div class="container" *ngIf="showDafaults">
        <div class="row">
            <a class="my-4" (click)="onSspSrmClick($event)">
                << Back</a>
                    <div class="col-12">
                        <div class="row">
                            <div *ngIf="showFileUpload" class="col-md-2 my-1">
                                <div class="file-upload">
                                    <div class="card p-3" (click)="openVerticallyCentered(content)">
                                        <div class="d-flex">
                                            <se-icon>action_add_stroke</se-icon>
                                            <h6 class="ms-2 fw-semibold"
                                                style="color: #0087CD; text-decoration: underline;">
                                                Import file
                                            </h6>
                                        </div>
                                    </div>
                                </div>

                                <ng-template #content let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title">Select General file</h4>
                                        <button type="button" class="btn-close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')"></button>
                                    </div>
                                    <div class="modal-body my-3">
                                        <input *ngIf="!showUploadMessage" class="form-control" type="file"
                                            (change)="onFileSelected($event)" />
                                        <div *ngIf="showUploadMessage" class="alert alert-success mt-2">
                                            File uploaded successfully!
                                        </div>
                                        <div class="d-flex justify-content-end">
                                            <button *ngIf="showUploadButton && !loadingbutton"
                                                class="btn btn-primary mt-3" type="submit"
                                                (click)="uploadFile()">Upload</button>
                                            <button *ngIf="loadingbutton && !showUploadMessage"
                                                class="btn btn-primary mt-3" type="button" disabled>
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Uploading...
                                            </button>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3 my-4" *ngFor="let repo of filteredGeneralList | async">
                        <div class="card">
                            <div class="d-flex">
                                <img [src]="getFileTypeIcon(repo)" (click)="onOpenUrl(repo.preSignedURL)"
                                    class="card-img-top"
                                    alt="{{repo['key'] && repo['key'].split('UserGuides/SSP-SRM/GeneralFunctions/')[1]}}">
                                <div *ngIf="showFileUpload" class="flex-shrink-1 d-flex align-items-start mt-2">
                                    <se-icon>other_vertical</se-icon>
                                    <div class="hover-icons d-none flex-column align-items-center position-absolute">
                                        <se-icon class="top-icon"
                                            (click)="openModalVerticallyCenter(deleteFile)">action_delete</se-icon>
                                        <se-icon class="bottom-icon"
                                            (click)="downloadFile(repo)">action_download_stroke</se-icon>
                                    </div>
                                </div>

                                <ng-template #deleteFile let-modal>
                                    <div class="modal-header">
                                        <h4 class="modal-title">Delete General Function file</h4>
                                        <button type="button" class="btn-close" aria-label="Close"
                                            (click)="modal.dismiss('Cross click')"></button>
                                    </div>
                                    <div class="modal-body my-2">
                                        <p *ngIf="showPromptText"> Are you sure to delete the file <span
                                                style="color: #0087CD;">{{repo['key'] &&
                                                repo['key'].split('UserGuides/SSP-SRM/GeneralFunctions/')[1]}} ?</span>
                                        </p>
                                        <div class="d-flex justify-content-end">
                                            <button *ngIf="showDeleteButton && !loadingbutton"
                                                class="btn btn-outline-secondary mt-3 me-2" type="button"
                                                (click)="modal.close('Close click')">Cancel</button>
                                            <button *ngIf="showDeleteButton && !loadingbutton"
                                                class="btn btn-danger mt-3" type="submit"
                                                (click)="deleteRepoFile(repo.key)">Delete</button>
                                            <button *ngIf="loadingbutton" class="btn btn-danger mt-3" type="button"
                                                disabled>
                                                <span class="spinner-border spinner-border-sm" role="status"
                                                    aria-hidden="true"></span>
                                                Deleting...
                                            </button>
                                        </div>
                                        <div *ngIf="showSuccessMessage" class="alert alert-success mt-3" role="alert">
                                            File has been successfully deleted!
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                            <div class="card-body">
                                <h6 class="card-title" (click)="onOpenUrl(repo.preSignedURL)">{{repo['key'] &&
                                    repo['key'].split('UserGuides/SSP-SRM/GeneralFunctions/')[1]}}
                                </h6>
                                <p class="card-text"><small class="text-muted">{{repo.lastModified | date: 'dd MMM yyyy'
                                        }}</small>
                                </p>
                            </div>
                        </div>
                    </div>
        </div>
    </div>
</div>

<div *ngIf="selectedTemplate === 'sspsrmrepo'">
    <app-user-guides-repo></app-user-guides-repo>
</div>