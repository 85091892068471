import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { VendorService } from '../../../vendor.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { SortDescriptor } from '@progress/kendo-data-query';

@Component({
  selector: 'app-auctions',
  templateUrl: './auctions.component.html',
  styleUrls: ['./auctions.component.scss'],
})
export class AuctionsComponent implements OnInit, OnChanges {

  tooltipGridValue: any;
  public pageSize = 10;
  public skip = 0;

  @Input() portalTranslate: any;

  public sort: SortDescriptor[] = [
    {
      field: 'End_date',
      dir: 'asc'
    }
  ];
  AuctionData: any;
  Auctions!: string;
  @Output() canCloseDialog = new EventEmitter;
  constructor(private _service: VendorService, public translate: TranslateService) { }

  ngOnInit(): void {

    this._service.getAuctionsData.subscribe(data => {
      this.AuctionData = data;
    });

    this._service.getAuctions();

    this.Auctions = environment.Auctions
  }


  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  onAuctionCancelClicked() {
    this.canCloseDialog.emit(false)
  }

  public onPageChange(state: any): void {
    this.pageSize = state.take;
  }

}
