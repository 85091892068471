import { Component, Input, OnInit, OnChanges, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VendorService } from '../../vendor.service';
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-normal-view',
  templateUrl: './normal-view.component.html',
  styleUrls: ['./normal-view.component.scss']
})
export class NormalViewComponent implements OnInit, OnChanges {
  public isClick: boolean = true;
  public isClick1: boolean = false;
  public isClickSrm: boolean = false;
  public isClickScm: boolean = false;
  public isClickCoinnovation: boolean = false;
  public isClicki2p: boolean = false;
  public companyClicked = false;
  public contractClicked = false;
  public eSourceClicked = false;
  public auctionsClicked = false;
  public activeHome = true;
  public activePortalPrinciple = false;
  public activeSupplierAtSE = false;
  public activeSupport = false;
  public activeOnlineRepo = false;
  public companyDynamicDataFlag: boolean = false;
  eSourcingClicked = false;
  supplierQualificationClicked = false;
  actionPlanClicked = false;
  eSurveyClicked = false;
  ptporderClicked = false;
  orderClicked = false;
  deliveryClicked = false;
  SCM = false;
  public SupplierQualification!: string | URL;
  public companyInformation!: string | URL;
  public companyName: any;
  public eSourcingLatestCount = '0';
  public auctionLatestCount = '0';
  public selectedTab = 'Supplier Relationship Management';
  public homeContentFromAPI: any = [];
  public AdminHome = [];
  public AdminHomeS2C = [];
  public AdminHomeSSPSRM = [];
  public AdminHomeP2P = [];
  public AdminHomeCOI = [];
  public AdminHomeI2P = [];
  public AdminHomeSupplyOn = [];
  @Input() portalTranslate: any;
  @Input() isHome: any
  @Input() isActivePortalPrinciple: any;
  @Input() isactiveSupport: any;
  @Input() isActiveOnlineRepo: any;
  @Output() isTileClicked = new EventEmitter()
  Auctions!: string;
  CoupaAccount!: string;
  SPCNAccount!: string;
  coInnovationAccount!: string;
  i2pUrl!: string;
  userContactData: any;
  contactNewDataEn: any;
  contactNewDataCh: any;
  isContractNotification = false;
  contractDynamicDataFlag: any;
  esourcingDynamicDataFlag: any;
  esurveyDynamicDataFlag: any;
  public surveyLatestCount = '0';



  constructor(private _service: VendorService, public translate: TranslateService) { }


  ngOnInit(): void {
    this.SupplierQualification = environment.SupplierQualification;
    this.Auctions = environment.Auctions;
    this.CoupaAccount = environment.coupaMyAccount;
    this.SPCNAccount = environment.supplyOnMyAccount;
    this.coInnovationAccount = environment.coInnovationAccount;
    this.i2pUrl = environment.i2pUrl;
    this.companyInformation = environment.CompanyInformation;
    this._service.getSupplyOnDataDropdownApi.subscribe(data => {
      let response: any = data
      if (response?.length) {
        this._service.supplyOn(response[0].sma);
      }


    })
    this._service.getHomeContentAPI.subscribe((data) => {
      if (data) {
        this.homeContentFromAPI = JSON.parse(sessionStorage.getItem('homeContent') || '{}');
        this.AdminHomeS2C = this.homeContentFromAPI[3].modules;
        this.AdminHomeSSPSRM = this.homeContentFromAPI[0].modules;
        this.AdminHomeP2P = this.homeContentFromAPI[2].modules;
        this.AdminHomeCOI = this.homeContentFromAPI[5].modules;
        this.AdminHomeI2P = this.homeContentFromAPI[4].modules;
        this.AdminHomeSupplyOn = this.homeContentFromAPI[1].modules;
        this.tabClick(1);
      }
    });
    this._service.contractData.subscribe((data) => {
      let response: any = data;
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.contractDynamicDataFlag = response?.getContractData?.isNotification
      }
    })

    this._service.ESurvey.subscribe((res) => {
      let response: any = res
      // console.log("Esurvey Component", response)
      // if (response.latestCount > 0) {
      //   this.surveyLatestCount = response.latestCount;
      // }
      if (Object.keys(res).length !== 0 && res.constructor === Object) {
        this.esurveyDynamicDataFlag = response?.getEsourcingData?.isNotification
      }
    });
    this._service.eSourceData.subscribe((res) => {
      let response: any = res
      // console.log("Esource Component", response)
      // if (response.latestCount > 0) {
      //   this.eSourcingLatestCount = response.latestCount;
      // }
      if (Object.keys(res).length !== 0 && res.constructor === Object) {
        this.esourcingDynamicDataFlag = response?.getEsourcingData?.isNotification
      }
    });
    this._service.auctionData.subscribe((data) => {
      let response: any = data
      if (response.latestCount > 0) {
        this.auctionLatestCount = response.latestCount;
      }
    });
    this._service.ContactAPI.subscribe((data) => {
      if (data) {
        this.userContactData = JSON.parse(sessionStorage.getItem('ContactData') || '{}');

        this.contactNewDataEn = this.userContactData.textEn === null ? '' : this.userContactData.textEn;
        this.contactNewDataCh = this.userContactData.textCh === null ? '' : this.userContactData.textCh;


      }
    });

    this._service.companyInfo.subscribe(data => {
      let response: any = data;
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        this.companyDynamicDataFlag = response?.data?.getCompanyInfo?.showNotification
        this.companyName = response?.data?.getCompanyInfo?.supplierName;
      }
    })
  }

  getInnerHTML(val: string) {
    return val.replace(/\r?\n|\r/g, '<br/>');
  }

  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
    if (this.isActivePortalPrinciple) {
      this.navigateTosspsrm()
    }
    if (this.isHome) {
      this.sspsrmInego()
    }
    if (this.isactiveSupport) {
      this.navigateTosspinego()
    }
    if (this.isActiveOnlineRepo) {
      this.navigateToOnlineRepo()
    }
  }

  public chipSelect(chip: any): void {
    if (chip === 's2c') {
      this.isClick = true;
      this.isClick1 = false;
      this.isClickSrm = false;
      this.isClickScm = false;
      this.isClickCoinnovation = false;
      this.isClicki2p = false;
    } else if (chip === 'p2p') {
      this.isClick = false;
      this.isClick1 = true;
      this.isClickSrm = false;
      this.isClickScm = false;
      this.isClickCoinnovation = false;
      this.isClicki2p = false;
    } else if (chip === 'scm') {
      this.isClick = false;
      this.isClick1 = false;
      this.isClickSrm = false;
      this.isClickScm = true;
      this.isClickCoinnovation = false;
      this.isClicki2p = false;
    } else if (chip === 'srm') {
      this.isClick = false;
      this.isClick1 = false;
      this.isClickSrm = true;
      this.isClickScm = false;
      this.isClickCoinnovation = false;
      this.isClicki2p = false;
    } else if (chip === 'coinnovation') {
      this.isClick = false;
      this.isClick1 = false;
      this.isClickSrm = false;
      this.isClickScm = false;
      this.isClickCoinnovation = true;
      this.isClicki2p = false;
    } else if (chip === 'i2p') {
      this.isClick = false;
      this.isClick1 = false;
      this.isClickSrm = false;
      this.isClickScm = false;
      this.isClickCoinnovation = false;
      this.isClicki2p = true;
    }

  }

  public scroll(event: any, scrollItem: string): void {
    if (scrollItem) {
      const element = document.getElementById(scrollItem) as HTMLElement;

      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }

  public onCompanyClick(): void {
    this.companyClicked = !this.companyClicked;
    this.contractClicked = false;
    this.eSourcingClicked = false;
    this.supplierQualificationClicked = false;
    this.actionPlanClicked = false;
    this.eSurveyClicked = false;
  }

  //SSP-SRM Account Redirection
  public accountSSPSRM(): void {
    this.companyClicked = !this.companyClicked;
    this.eSourcingClicked = false;
    this.contractClicked = false;
    this.actionPlanClicked = false;
    this.auctionsClicked = false;
    console.log("accountSSPURL", this.companyInformation);
    window.open(this.companyInformation, '_blank');
  }

  public onsupplierQualificationClick(): void {
    this.eSourcingClicked = !this.eSourcingClicked;
    this.contractClicked = false;
    this.companyClicked = false;
    this.actionPlanClicked = false;
    this.auctionsClicked = false;
    console.log("supplierURL", this.SupplierQualification);
    window.open(this.SupplierQualification, '_blank');
  }

  public onSurveyClick(): void {
    this.eSurveyClicked = !this.eSurveyClicked;
    this.contractClicked = false;
    this.companyClicked = false;
    this.eSourcingClicked = false;
    this.supplierQualificationClicked = false;
    this.actionPlanClicked = false;
    this.auctionsClicked = false;
  }

  public onContractClick(): void {
    this.contractClicked = !this.contractClicked;
    this.companyClicked = false;
    this.eSourcingClicked = false;
    this.supplierQualificationClicked = false;
    this.actionPlanClicked = false;
    this.eSurveyClicked = false;
    this.auctionsClicked = false;
  }

  public onESourcingClick(): void {
    this.eSourceClicked = !this.eSourceClicked;
    this.auctionsClicked = false;
    this.contractClicked = false;
    this.companyClicked = false;
    this.supplierQualificationClicked = false;
    this.actionPlanClicked = false;
    this.eSurveyClicked = false;
    // this.isTileClicked.emit(true)
  }

  public auctionsClick(): void {
    this.eSourceClicked = false;
    this.auctionsClicked = !this.auctionsClicked;
    this.contractClicked = false;
    this.companyClicked = false;
    this.supplierQualificationClicked = false;
    this.actionPlanClicked = false;
    this.eSurveyClicked = false;
    // this.isTileClicked.emit(true)
  }

  public ptponOrderClick(): void {
    this.ptporderClicked = !this.ptporderClicked;
    this.orderClicked = false;
    this.deliveryClicked = false;
    this.eSourcingClicked = false;
    this.SCM = true;
  }

  public ptponOrderCencel(): void {
    this.ptporderClicked = false;
  }

  public onOrderClick(): void {
    this.orderClicked = !this.orderClicked;
    this.ptporderClicked = false;
    this.deliveryClicked = false;
    this.SCM = true;
  }

  public onDeliveryClick(): void {
    this.deliveryClicked = !this.deliveryClicked;
    this.ptporderClicked = false;
    this.orderClicked = false
  }

  public onOrderCencel(): void {
    this.orderClicked = false;
    this.SCM = true;
  }

  srmTileClicked() {
    this.contractClicked = false;
    this.companyClicked = false;
    this.eSourcingClicked = false;
    this.supplierQualificationClicked = false;
    this.actionPlanClicked = false;
    this.eSurveyClicked = false;
  }

  public onDeliveryCencel(): void {
    this.deliveryClicked = false;
  }

  public onActivate(): void {
    window.scroll({
      top: 800,
      left: 100,
      behavior: 'smooth'
    });
  }

  public navigateTosspsrm(): void {
    this.activePortalPrinciple = true;
    this.activeHome = false;
    this.activeSupplierAtSE = false;
    this.activeSupport = false;
    this.activeOnlineRepo = false;
    this.onActivate();

  }

  public sspsrmInego(): void {
    this.activePortalPrinciple = false;
    this.activeHome = true;
    this.activeSupplierAtSE = false;
    this.activeSupport = false;
    this.activeOnlineRepo = false;
  }

  public navigateToOnlineRepo(): void {
    this.activeOnlineRepo = true;
    this.activePortalPrinciple = false;
    this.activeHome = false;
    this.activeSupplierAtSE = false;
    this.activeSupport = false;
    this.onActivate();
  }

  public navigateTosspinego(): void {
    this.activePortalPrinciple = false;
    this.activeHome = false;
    this.activeSupplierAtSE = false;
    this.activeOnlineRepo = false;
    // this.activeSupport = true;
    this.onActivate();

  }
  public tabClick(tabNum?: number): void {
    if (tabNum === 1) {
      this.AdminHome = this.AdminHomeS2C;
      this.selectedTab = 'Source to Contract';
    } else if (tabNum === 2) {
      this.AdminHome = this.AdminHomeSSPSRM;
      this.selectedTab = 'Supplier Relationship Management';
    } else if (tabNum === 3) {
      this.AdminHome = this.AdminHomeP2P;
      this.selectedTab = 'Procure to Pay & Supply Chain Management';
    } else if (tabNum === 4) {
      this.AdminHome = this.AdminHomeSupplyOn;
      this.selectedTab = 'Supply Chain Management';
    } else if (tabNum === 5) {
      this.AdminHome = this.AdminHomeCOI;
      this.selectedTab = 'Co-Innovation';
    } else if (tabNum === 6) {
      this.AdminHome = this.AdminHomeI2P;
      this.selectedTab = 'I2P';
    } else {
      this.AdminHome = this.AdminHomeS2C;
      this.selectedTab = 'Source to Contract';
    }
  }



}
