import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { VendorService } from '../../../vendor.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { SortDescriptor } from '@progress/kendo-data-query';
@Component({
  selector: 'app-e-survey',
  templateUrl: './e-survey.component.html',
  styleUrls: ['./e-survey.component.scss']
})
export class ESurveyComponent implements OnInit, OnChanges {
  tooltipGridValue: any;
  ESurvey: any;
  public pageSize = 10;
  public skip = 0;
  public eSurveyClicked = false;
  @Output() canCloseDialog = new EventEmitter;

  @Input() portalTranslate: any;

  public sort: SortDescriptor[] = [
    {
      field: 'End_date',
      dir: 'asc'
    }
  ];

  constructor(private _service: VendorService, public translate: TranslateService) { }

  ngOnInit(): void {
    this._service.ESurvey.subscribe(data => {
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        let response: any = data;
        this.tooltipGridValue = response.getEsourcingData.esourcingList;
      }
    })
    this.ESurvey = environment.ESurvey;
  }


  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  public onESurveycancelClick(): void {
    this.eSurveyClicked = false;
    this.canCloseDialog.emit(this.eSurveyClicked)
  }

  public onPageChange(state: any): void {
    this.pageSize = state.take;
  }

}
