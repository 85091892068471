import { Apollo, QueryRef, } from 'apollo-angular';
import { Injectable } from '@angular/core';
import gql from 'graphql-tag';
import { BehaviorSubject, Subject, map } from 'rxjs';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from '@apollo/client/utilities';

interface IRepoFileList {
  "key": string,
  "lastModified": string,
  "preSignedURL": string,
  "size": number,
  "__typename": string,

}

interface GetAuctionsData {
  "Auc_Status": string,
  "Auction_ID": string,
  "Auction_Name": string,
  "Auction_Type": string,
  "DeepLink": string,
  "Email_ID__of_responsible_user": string,
  "End_Date": string,
  "Fed_ID__of_responsible_user": string,
  "Processed_at": string,
  "Project_Leader": string,
  "Start_Date": string,
  "UID": string,

}

interface RelatedItem {
  "sectionID": string,
  "pageNumber": string,
  "fileName": string,
  "referenceLink": string,
  "title": string | null,
  "content": string,
}

interface KnowledgeHubSource {
  "fileName": string,
  "preSignedURL": string,
}

interface GetKbotChatData {
  "userQuery": string,
  "displayResponse": string,
  related: RelatedItem[];
  knowledge_hub_sources: KnowledgeHubSource[];
}

interface KbotFeedbackResponse {
  status: string;
}
interface SetRepoFileResult {
  fileName: string;
  uploadURL: string;
}
interface RepoFileUploadResult {
  setRepoFileList?: SetRepoFileResult;
  httpPutResponse?: any;
}

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  public IdmsInfo = new BehaviorSubject(false);
  public SurveyAPI = new BehaviorSubject(false);
  public getHomeContentAPI = new BehaviorSubject(false);
  public setHomeContentAPI = new BehaviorSubject(false);
  public PortalAPI = new BehaviorSubject(false);
  public PortalupdateAPI = new BehaviorSubject(false);
  public ContactAPI = new BehaviorSubject(false);
  public ContactupdateAPI = new BehaviorSubject(false);
  public ContactTableAPI = new BehaviorSubject(false);
  public ContactTableupdateAPI = new BehaviorSubject(false);
  public ConcractAPI = new BehaviorSubject(false);
  public MyaccountAPI = new BehaviorSubject(false);
  public GeneralAPI = new BehaviorSubject(false);
  public getActionPlanAPI = new BehaviorSubject(false);
  public getSupplierQualificationAPI = new BehaviorSubject(false);
  public DynamicAPI = new BehaviorSubject(false);
  public UploadImageAPI = new BehaviorSubject(false);
  public GetImageAPI = new BehaviorSubject(false);
  public TrafficWeeklyAPI = new BehaviorSubject(false);
  public TrafficWeeklyNewAPI = new BehaviorSubject(false);
  public TrafficMonthlyAPI = new BehaviorSubject(false);
  public TrafficYearlyAPI2022 = new BehaviorSubject(false);
  public TrafficYearlyAPI2021 = new BehaviorSubject(false);
  public getSupplyOnContentAPI = new BehaviorSubject(false);
  public TrafficYearlyAPI2020 = new BehaviorSubject(false);
  public coupaDropdownListAPI = new BehaviorSubject(false);
  public coupaTableDataAPI = new BehaviorSubject([]);
  public coupaTableDataList = this.coupaTableDataAPI.asObservable()
  public weekExcelUrl = new BehaviorSubject("");
  public weekExcelUrlDownload = this.weekExcelUrl.asObservable()
  public weekExcelDetails = new BehaviorSubject({});
  public weekExcel = this.weekExcelDetails.asObservable()
  public getsupplyOnDropdownContentAPI = new BehaviorSubject(false);
  public EsourcingAPI = new BehaviorSubject(false);
  public EsourcingAPIList = this.EsourcingAPI.asObservable()
  public TrafficMonthlyNewAPI = new BehaviorSubject(false);
  public getKbotChatData = new BehaviorSubject<GetKbotChatData>({
    "userQuery": "",
    "displayResponse": "",
    related: [],
    knowledge_hub_sources: []
  });
  public kbotPositiveFeedbackData = new BehaviorSubject<KbotFeedbackResponse>({ "status": "" });
  public kbotNegativeFeedbackData = new BehaviorSubject<KbotFeedbackResponse>({ "status": "" });
  public starRatingDataAPI = new BehaviorSubject(false);
  public checkboxCommentDataAPI = new BehaviorSubject(false);
  public ratingSummaryAPI = new BehaviorSubject(false);
  public feedbackDownloadLinkAPI = new BehaviorSubject(false);
  public getRepoListData = new BehaviorSubject<IRepoFileList[]>([]);
  public getAuctionsData = new BehaviorSubject<GetAuctionsData[]>([]);
  public ESurveyAPI = new BehaviorSubject({});
  public ESurvey = this.ESurveyAPI.asObservable()
  public companyInfoApi = new BehaviorSubject({});
  public companyInfo = this.companyInfoApi.asObservable()
  public contractAPI = new BehaviorSubject({});
  public contractData = this.contractAPI.asObservable()
  public procureToPayAPI = new BehaviorSubject([]);
  public procureToPayDropdown = this.procureToPayAPI.asObservable()
  public loginDetailsAPI = new BehaviorSubject([]);
  public loginDetails = this.loginDetailsAPI.asObservable()
  public ticketCreartionAPI = new BehaviorSubject({});
  public ticketCreartion = this.ticketCreartionAPI.asObservable()
  public eSourceAPI = new BehaviorSubject({});
  public eSourceData = this.eSourceAPI.asObservable()
  public actioPlanAPI = new BehaviorSubject({});
  public actionPlan = this.actioPlanAPI.asObservable();
  public getAuctionAPI = new BehaviorSubject(false);
  public AutionAPI = new BehaviorSubject([]);
  public auctionData = this.AutionAPI.asObservable();
  public uploadAttachmentAPI = new BehaviorSubject({});
  public uploadAttachmen = this.uploadAttachmentAPI.asObservable()
  public weeklyRegionCountAPI = new BehaviorSubject([]);
  public weeklyRegionCount = this.weeklyRegionCountAPI.asObservable()
  public monthlyRegionCountAPI = new BehaviorSubject([]);
  public monthlyRegionCount = this.monthlyRegionCountAPI.asObservable()
  public yearlyRegionCountAPI = new BehaviorSubject([]);
  public yearlyRegionCount = this.yearlyRegionCountAPI.asObservable();
  public accountAPI = new BehaviorSubject({});
  public accountAPIData = this.accountAPI.asObservable()
  public generalDocumentDataAPI = new BehaviorSubject({});
  public generalDocumentData = this.generalDocumentDataAPI.asObservable()
  public getSupplyOnDataDropdownApi = new BehaviorSubject([]);
  public getSupplyOnDataDropdownData = this.getSupplyOnDataDropdownApi.asObservable()
  public scmApi = new BehaviorSubject({});
  public scmApiData = this.scmApi.asObservable();
  public repoFileUploadResultAPI = new BehaviorSubject<RepoFileUploadResult>({});
  public repoFileUploadResult = this.repoFileUploadResultAPI.asObservable();
  public deleteRepoFileResultAPI = new BehaviorSubject(false);


  public latestUser: any = {};
  public surveytUser: any = {};
  public contractUser: any = {};
  private valueData: any = [];

  public portalDetails: any = {};
  public contactDetails: any;
  public tableData: any = [];
  public myaccountUser: any = {};
  public generaltUser: any = {};
  public dynamicData: any = {};
  public homeDetails: any = {};
  portalDetailsEn: any;
  portalDetailsCh: any;
  listRowsFromTable: any;
  moduleArray: any;
  rowData: any;
  public san: any;
  public preSignedEn: any;
  public preSignedCh: any;
  public uid: any;
  public descriptionCh: any;
  public descriptionEn: any;
  public nameCh: any;
  public nameEn: any;
  public startIndex: any;
  public endIndex: any;
  public fileName: any;
  public imageName: any;
  public supplyonArrayvalues: any[] = [];
  public obj = {};
  public loading!: boolean;
  public userfeedbackComment: any;
  public usercheckBoxlist: any;
  public currentYear = new Date().getFullYear();
  public lastoneyear = this.currentYear - 1;
  public lasttwoyear = this.currentYear - 2;
  public ratingFeedback: any;
  public loggedInDetails: any;
  public getRepoList: any;
  public getActionsList: any;
  public repoURL: any;
  encryptedFileName: any;
  scmData: any = [];
  previuosIdmsCode: any;
  thumbnailKey: any;
  public loggedInDetailsSubject = new Subject<any>();
  public loggedInDetails$ = this.loggedInDetailsSubject.asObservable();

  // uploadFileUrl: any;

  constructor(private apollo: Apollo, public router: Router, public http: HttpClient) {

  }
  public isUserLoggedIn(): void {

    const locationUrl = window.location.href;
    if (locationUrl.includes('localhost')) {
      this.loggedInDetails = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
      this.loggedInDetailsSubject.next(this.loggedInDetails);
      this.IdmsInfo.next(true);
    } else {
      this.previuosIdmsCode = sessionStorage.getItem('Idmscode');
      this.getParams();
    }

  }

  private getParams(): void {
    let param = '';
    let ISS = 'false';
    const urlParam: string = window.location.search;
    const splitUrlArray = urlParam.split('=');
    if (splitUrlArray[0] === '?code') {
      param = splitUrlArray[1].split('&')[0];
      param = param.replace('%3D%3D', '');
      if (splitUrlArray[1].includes('&iss')) {
        ISS = 'true';
      }
    }
    sessionStorage.setItem('Idmscode', param);
    sessionStorage.setItem('ISS', ISS);
    if (param === undefined || param === null || param === '') {
      sessionStorage.setItem('isUserLoggedIn', '0');
      sessionStorage.setItem('Idmscode', param);
      sessionStorage.setItem('ISS', ISS);
    } else {
      if (param != this.previuosIdmsCode) {
        sessionStorage.removeItem('UserLoggedInDetails');
        this.getUserDetails();
      } else {
        this.loggedInDetails = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
        this.IdmsInfo.next(true);
      }
    }
  }
  private getUserDetails(): void {
    const codeParam = sessionStorage.getItem('Idmscode');
    let issValue;
    if (sessionStorage.getItem('ISS') === 'true') {
      issValue = true;
    }
    if (sessionStorage.getItem('ISS') === 'false') {
      issValue = false;
    }
    let userLoginQuery: QueryRef<any>;
    // let idmsHardcoded = {
    //   "data": {
    //     "getIDMSUserDetails":
    //       { "aboutMe": null, "access_token": "00D040000004eNs!AQoAQMhh7epzkVnLKB0rvQUkxJEofdFgvTBSAjgvdM_Rckm5pOvJrRhPcZ0ADKyZ9wjEE.D4SVPupNknLV4er5DOVCQ_WAWa", "accountGoldenID": null, "accountId": null, "additionalAddress": null, "ail": "(Application;Synertrade)", "ailApplications": "Synertrade", "ailFeatures": null, "ailPrograms": null, "annualRevenue": "0", "businessUnit": null, "city": null, "classLevel1": null, "classLevel2": null, "companyAdditionalAddress": null, "companyCity": null, "companyCountryCode": null, "companyCounty": null, "companyFederationId": null, "companyName": null, "companyPOBox": null, "companyPhone": null, "companyStateOrProvinceCode": null, "companyStreet": null, "companyWebsite": null, "companyZipCode": null, "contactGoldenID": null, "contactId": null, "countryCode": "FR", "county": null, "currency": null, "department": null, "division": null, "email": "irinetestuat2807@yopmail.com", "emailOptIn": "U", "employeeSize": null, "fax": null, "federatedId": "gb00c955-e28c-72fc-7ebb-2941827a68a4", "firstName": "IrineTest", "hashedPin": null, "headquarter": "false", "identityType": "Email", "idmsFederatedId": "gb00c955-e28c-72fc-7ebb-2941827a68a4", "isInternal": "FALSE", "jobDescription": null, "jobFunction": null, "jobTitle": null, "languageCode": "EN", "lastName": "UAT2807", "marketSegment": null, "marketServed": null, "marketSubSegment": null, "middleName": null, "mobilePhone": null, "pOBox": null, "persona": "EMP",portalUserType: 'SE User', "refresh_token": "5Aep861Tst.ahKyP5qvlzvbBoccH7McEHoRkQYu7hJl.2dDn_eoHJjSqTUxmL4VV8rAOqu.e.ZvtqOjvbkvnHhd", "rejectionComment": null, "rejectionReason": null, "salutation": null, "stateOrProvinceCode": null, "street": null, "suffix": null, "taxIdentificationNumber": null, "title": null, "trustLevel": "IsPrivate", "trustStatus": null, "userContext": "Supplier", "userId": "005040000015H6KAAU", "workPhone": null, "zipCode": null, "__typename": "getIDMSUserDetailsOutput" }
    //   }
    // };
    // sessionStorage.setItem('UserLoggedInDetails', JSON.stringify(idmsHardcoded.data.getIDMSUserDetails));
    userLoginQuery = this.apollo.watchQuery({
      query: gql`query MyQuery {
        getIDMSUserDetails(input: {
              ISS: ${issValue},
              code: "${codeParam}"
        }) {
          aboutMe
          access_token
          accountGoldenID
          accountId
          additionalAddress
          ail
          ailApplications
          ailFeatures
          ailPrograms
          annualRevenue
          businessUnit
          city
          classLevel1
          classLevel2
          companyAdditionalAddress
          companyCity
          companyCountryCode
          companyCounty
          companyFederationId
          companyName
          companyPOBox
          companyPhone
          companyStateOrProvinceCode
          companyStreet
          companyWebsite
          companyZipCode
          contactGoldenID
          contactId
          countryCode
          county
          currency
          department
          division
          email
          emailOptIn
          employeeSize
          fax
          federatedId
          firstName
          hashedPin
          headquarter
          identityType
          idmsFederatedId
          isInternal
          jobDescription
          jobFunction
          jobTitle
          languageCode
          lastName
          marketSegment
          marketServed
          marketSubSegment
          middleName
          mobilePhone
          pOBox
          persona
          portalUserType
          portalUserTypeCH
          refresh_token
          rejectionComment
          rejectionReason
          salutation
          stateOrProvinceCode
          street
          suffix
          taxIdentificationNumber
          title
          trustLevel
          trustStatus
          userContext
          userId
          workPhone
          zipCode
        }
      }
       `,
    });
    userLoginQuery.valueChanges.subscribe((result) => {
      if (result.data.getIDMSUserDetails !== null) {
        sessionStorage.setItem('UserLoggedInDetails', JSON.stringify(result.data.getIDMSUserDetails));
        this.loggedInDetails = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
        this.loggedInDetailsSubject.next(this.loggedInDetails);
        this.IdmsInfo.next(true);
        // this.loginDetailsAPI.next(JSON.parse(result.data.getIDMSUserDetails))

      }
      else {
        alert("message: invalid authorization code");
      }

    }
      , (err) => {
        console.log("san12", err);
        this.IdmsInfo.next(false);
        // alert( "message: invalid authorization code");
      }
    );
  }

  //Get ActionPlan Data

  public getActionPlan(): void {
    let getActionPlanQuery: QueryRef<any>;
    getActionPlanQuery = this.apollo.watchQuery({
      query: gql`
          query MyQuery {
          getActionPlanData(input: {fedId: "${this.loggedInDetails.federatedId}"}) {
            actionPlanData {
                Activity_ID_external_ID
                Action_Plan_Name
                Activity_Name
                Status
                SE_Responsible_activty_responsible
                Deadline
                Supplier_name_company
                Email_user
                Fed_ID
                UID
            }
            isNotification
        }
      }
  `,
    });
    getActionPlanQuery.valueChanges.subscribe((result) => {
      const data = result;
      const dataValue = data && result.data;
      const finalValue = dataValue && dataValue.getActionPlanData;
      const finalValueOne = finalValue && finalValue.actionPlanData;
      const finlaValueTwo = finalValue && finalValue.isNotification;
      sessionStorage.setItem('actionPlanData', JSON.stringify(finalValue));
      sessionStorage.setItem('actionPlanDataOne', JSON.stringify(finalValueOne));
      sessionStorage.setItem('actionPlanDataTwo', JSON.stringify(finlaValueTwo));
      this.getActionPlanAPI.next(true);
      this.actioPlanAPI.next(result?.data)
    });
  }

  public getpersonaName(): any {
    console.log(this.loggedInDetails.email, "hell")
    if (this.loggedInDetails.email !== undefined) {
      let quicklinkProfileQuery: QueryRef<any>;
      quicklinkProfileQuery = this.apollo.watchQuery({
        query: gql`
        query MyQuery {
          getEsourcingData(input: {
            email:"${this.loggedInDetails.email}",
            persona:"ST",
            template:"e-sourcing",
            fedId: "${this.loggedInDetails.federatedId}"
          }) {
            esourcingList {
            isLatest
              Supplier_Company
              Status
              Start_date
              Project_template_name
              Project_name
              Plateform
              Fed_ID
              ID
              End_date
              Email_user
              Deeplink
            }
            isNotification
          }
        }

    `,
      });
      quicklinkProfileQuery.valueChanges.subscribe((result) => {
        const data = result;
        const dataValue = data && result.data;
        const finalValue = dataValue && dataValue.getEsourcingData;
        const finalValueOne = finalValue && finalValue.esourcingList;
        const finalValueTwo = finalValue && finalValue.isNotification;
        sessionStorage.setItem('eSourcingData', JSON.stringify(finalValue));
        sessionStorage.setItem('eSourcingList', JSON.stringify(finalValueOne));
        sessionStorage.setItem('eSourcingNotification', JSON.stringify(finalValueTwo));
        this.EsourcingAPI.next(true);
        this.eSourceAPI.next(result?.data)
      });
    }
  }

  public getTrafficWeeklyDataNew(): any {
    let TrafficWeeklyQueryNew: QueryRef<any>;
    TrafficWeeklyQueryNew = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getLoginCountWeeklyNew {
          country {
            code
            color
            data
            nameCh
            nameEn
          }
          dates
        }
      }
      
  `,
    });
    TrafficWeeklyQueryNew.valueChanges.subscribe((result) => {
      const data = result;
      const dataValue = data && result.data;
      const finalValue = dataValue && dataValue.getLoginCountWeeklyNew.country;
      const datesFinalValue = dataValue && dataValue.getLoginCountWeeklyNew.dates;
      sessionStorage.setItem('TrafficWeeklyQueryDatesNew', JSON.stringify(datesFinalValue));
      sessionStorage.setItem('TrafficWeeklyQueryDataNew', JSON.stringify(finalValue));
      this.TrafficWeeklyNewAPI.next(true);
    });
  }

  public getTrafficMonthlyDataNew(): any {
    let TrafficMonthlyNewQuery: QueryRef<any>;
    TrafficMonthlyNewQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getLoginCountMonthlyNew {
          country {
            code
            color
            data
            nameCh
            nameEn
          }
          dates
        }
      }  
  `,
    });
    TrafficMonthlyNewQuery.valueChanges.subscribe((result) => {

      const data = result;
      const dataValue = data && result.data;
      const finalValue = dataValue && dataValue.getLoginCountMonthlyNew.country;
      const datesFinalValue = dataValue && dataValue.getLoginCountMonthlyNew.dates;
      sessionStorage.setItem('TrafficMonthlyQueryDataNew', JSON.stringify(finalValue));
      sessionStorage.setItem('TrafficMonthlyQueryDatesNew', JSON.stringify(datesFinalValue));
      this.TrafficMonthlyNewAPI.next(true);
    });
  }

  public getTrafficYearlyData2022(): any {
    let TrafficYearlyQuery: QueryRef<any>;
    this.currentYear = new Date().getFullYear();
    TrafficYearlyQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getLoginHistoryByCountry(input: {year: ${this.currentYear} }) {
          color
          count
          countryName
          countryNameCh
          year
        }
      }  
  `,
    });
    TrafficYearlyQuery.valueChanges.subscribe((result) => {
      const data = result;
      const dataValue = data && result.data;
      const finalValue = dataValue && dataValue.getLoginHistoryByCountry;
      sessionStorage.setItem('TrafficYearlyQueryData2022', JSON.stringify(finalValue));
      this.TrafficYearlyAPI2022.next(true);
    });
  }

  // survey List //

  public getpersonaSurvey(): any {
    let surveyProfileQuery: QueryRef<any>;
    surveyProfileQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getEsourcingData(input: {
          email:"${this.loggedInDetails.email}",
          persona: "ST",
          template:"survey",
          fedId: "${this.loggedInDetails.federatedId}"
        }) {
          esourcingList {
           isLatest
            Supplier_Company
            Status
            Start_date
            Project_template_name
            Project_name
            Plateform
            Fed_ID
            ID
            End_date
            Email_user
            Deeplink
          }
          isNotification
        }
      }
  `,
    });
    surveyProfileQuery.valueChanges.subscribe((result) => {
      // const dataOne = result;
      // const dataValueOne = dataOne && result.data;
      // const finalValueOne = dataValueOne && dataValueOne.getEsourcingData.data;
      // sessionStorage.setItem('surveyData', JSON.stringify(finalValueOne));
      // sessionStorage.setItem('surveyLatestCount', JSON.stringify(dataValueOne.getEsourcingData.latestCount));
      const data = result;
      const dataValue = data && result.data;
      const finalValue = dataValue && dataValue.getEsourcingData;
      const finalValueOne = finalValue && finalValue.esourcingList;
      const finalValueTwo = finalValue && finalValue.isNotification;
      sessionStorage.setItem('eSurveyData', JSON.stringify(finalValue));
      sessionStorage.setItem('eSurveyList', JSON.stringify(finalValueOne));
      sessionStorage.setItem('eSurveyNotification', JSON.stringify(finalValueTwo));
      this.SurveyAPI.next(true);
      this.ESurveyAPI.next(result?.data)
    });
  }

  // Portal Principal //

  public getportalPrincipal(): any {

    let portalPrincipalQuery: QueryRef<any>;

    portalPrincipalQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getPrincipalPortalContent(input: {
          id: "portalprincipal"
        }) {
          id
          textCh
          textEn
        }
      }
  `,
    });
    portalPrincipalQuery.valueChanges.subscribe((result) => {

      const dataOne = result;
      const dataValueOne = dataOne && result.data;
      const finalValueOne = dataValueOne && dataValueOne.getPrincipalPortalContent;
      sessionStorage.setItem('portalData', JSON.stringify(finalValueOne));

      this.PortalAPI.next(true);
    });
  }

  public getfeedbackDownloadLink(): any {

    let feedbackDownloadLinkQuery: QueryRef<any>;

    feedbackDownloadLinkQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getFeedbackDownloadURL
        }
  `,
    });
    feedbackDownloadLinkQuery.valueChanges.subscribe((result) => {

      const dataOne = result;
      const dataValueOne = dataOne && result.data;
      const finalValueOne = dataValueOne.getFeedbackDownloadURL;
      sessionStorage.setItem('feedbackDownloadLink', JSON.stringify(finalValueOne));
      this.feedbackDownloadLinkAPI.next(true);
    });
  }

  public getContract(): any {

    let contractProfileQuery: QueryRef<any>;
    contractProfileQuery = this.apollo.watchQuery({
      query: gql`
    query MyQuery {
      getContractData(input: {fedId: "${this.loggedInDetails.federatedId}"}) {
  contractList {
    Contract_ID
    Contract_Title
    Date_of_Creation
    Deep_Link
    Email_user
    Fed_ID
    Owner
    Processed_at
    Status
    Supplier_name_company
    UID
    Validity_Start
    }
    isNotification
    }
    }
`,
    });
    contractProfileQuery.valueChanges.subscribe((result) => {

      const dataOne = result;
      const dataValueOne = dataOne && result.data;
      const finalcontractValueOne = dataValueOne && dataValueOne.getContractData;
      const finalcontractValueOne1 = finalcontractValueOne && finalcontractValueOne.contractList;
      const finalcontractValueOne2 = finalcontractValueOne && finalcontractValueOne.isNotification;
      sessionStorage.setItem('finalcontractValueOne', JSON.stringify(finalcontractValueOne));
      sessionStorage.setItem('finalcontractValueOne1', JSON.stringify(finalcontractValueOne1));
      sessionStorage.setItem('finalcontractValueOne2', JSON.stringify(finalcontractValueOne2));
      this.ConcractAPI.next(true);
      // new
      this.contractAPI.next(result?.data)
    });
  }


  //Autions Data
  public getAuctions(): void {

    let auctionQuery: QueryRef<any>;
    auctionQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getAuctionData(input: {fedId: "${this.loggedInDetails.federatedId}"}) {
          Auc_Status
          Auction_ID
          Auction_Name
          Auction_Type
          DeepLink
          Email_ID__of_responsible_user
          End_Date
          Fed_ID__of_responsible_user
          Processed_at
          Project_Leader
          Start_Date
          UID
        }
      }
`,
    });
    auctionQuery.valueChanges.subscribe((result) => {
      // console.log('Auction Data', result);
      // const data = result;
      // const dataValue = data && result.data;
      // const finalValue = dataValue && dataValue.getAuctionData;
      // sessionStorage.setItem('auctionsData', JSON.stringify(finalValue));
      // this.getAuctionAPI.next(true);
      // this.AutionAPI.next(result?.data?.getAuctionData);

      const finalValueGetAuctions: GetAuctionsData[] = result?.data?.getAuctionData || [];
      // console.log(`Login User FedID ==>`, this.loggedInDetails.federatedId);
      // console.log(`Login User Email ==>`, this.loggedInDetails.email);
      console.log(`Auctions Final Data ==> `, finalValueGetAuctions);

      this.getAuctionsData.next(finalValueGetAuctions);
    });
  }

  //kbotChat Results Data
  public getKbotChatResults(user_query: string): void {
    // console.log(`received message :`, user_query);
    let teamsName = "teams-"
    let kbotChatQuery: QueryRef<any>;
    if (!this.loggedInDetails?.email) return;
    kbotChatQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getKbotChatData(input: {userQuery: "${user_query}", 
        apiUser: "${teamsName}${this.loggedInDetails?.email}", 
        userId: "${this.loggedInDetails.email}", 
        userName: "${this.loggedInDetails.firstName}${" "}${this.loggedInDetails.lastName}"}) {
          userQuery
          displayResponse
          related {
              sectionID
              pageNumber
              fileName
              referenceLink
              title
              content
            }
          knowledge_hub_sources{
              fileName
              preSignedURL
          }
        }
      }`
    });

    kbotChatQuery.valueChanges.subscribe((result) => {
      const finalValueGetKbotResults: GetKbotChatData = {
        userQuery: result?.data?.getKbotChatData?.userQuery || "",
        displayResponse: result?.data?.getKbotChatData?.displayResponse || {},
        related: result?.data?.getKbotChatData?.related || [],
        knowledge_hub_sources: result?.data?.getKbotChatData?.knowledge_hub_sources || []
      };
      // console.log(`Kbot Results Data ==> `, finalValueGetKbotResults);
      this.getKbotChatData.next(finalValueGetKbotResults);
    })
  }

  //kbotChat Positive Feedback
  public setKbotPositiveFeedback(): void {
    const feedbackType = "positive";
    let positiveFeedbackQuery: QueryRef<any>;
    positiveFeedbackQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
      setKbotPositiveFeedback(input: {feedbacktype: "${feedbackType}", 
      userId: "${this.loggedInDetails.email}"}){
      status
      }}`
    });
    positiveFeedbackQuery.valueChanges.subscribe((result) => {
      const finalValuePositiveFeedback: KbotFeedbackResponse = {
        status: result?.data?.setKbotPositiveFeedback?.status || ""
      };
      // console.log(`Kbot Positive Feedback Response ==> `, finalValuePositiveFeedback);
      this.kbotPositiveFeedbackData.next(finalValuePositiveFeedback);
    })
  }

  //kbotChat Negative Feedback
  public setKbotNegativeFeedback(feedbackData: any): void {
    const feedbackType = "negative";
    let negativeFeedbackQuery: QueryRef<any>;
    negativeFeedbackQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
      setKbotNegativeFeedback(input: {feedbacktype: "${feedbackType}", 
      userId: "${this.loggedInDetails.email}", 
      reason: "${feedbackData.reason}", 
      others: "${feedbackData.others}", 
      rightdoc: "${feedbackData.rightdoc}"}) {
    status}}`
    });
    negativeFeedbackQuery.valueChanges.subscribe((result) => {
      const finalValueNegativeFeedback: KbotFeedbackResponse = {
        status: result?.data?.setKbotNegativeFeedback?.status || ""
      };
      // console.log(`Kbot Negative Feedback Response ==> `, finalValueNegativeFeedback);
      this.kbotNegativeFeedbackData.next(finalValueNegativeFeedback);
    })
  }

  // survey List //
  public getMyaccount(): any {
    let myaccountProfileQuery: QueryRef<any>;
    myaccountProfileQuery = this.apollo.watchQuery({
      query: gql`
    query MyQuery {
      getMyDocumentData(input: {fedId: "${this.loggedInDetails.federatedId}"}) {
        unreadCount
      }
    }

`,
    });
    myaccountProfileQuery.valueChanges.subscribe((result) => {
      // const dataOne = result;
      // const dataValueOne = dataOne && result.data;
      // const finalcontractValueOne = dataValueOne && dataValueOne.getMyDocumentData;
      // sessionStorage.setItem('getMyaccountTable', JSON.stringify(finalcontractValueOne));
      // this.MyaccountAPI.next(true);
      this.accountAPI.next(result)
    });
  }

  public getGeneral(): any {

    let generalProfileQuery: QueryRef<any>;
    generalProfileQuery = this.apollo.watchQuery({
      query: gql`
    query MyQuery {
      getGeneralDocumentData(input: {fedId: "${this.loggedInDetails.federatedId}"}) {
        unreadCount
      }
    }

`,
    });
    generalProfileQuery.valueChanges.subscribe((result) => {

      // const dataOnevalue = result;
      // const dataValueOneValue = dataOnevalue && result.data;
      // const finalcontractValueOneValue = dataValueOneValue && dataValueOneValue.getGeneralDocumentData;
      // sessionStorage.setItem('getGeneralTableValue', JSON.stringify(finalcontractValueOneValue));
      // const finalcontractValueOneValueOne = finalcontractValueOneValue && finalcontractValueOneValue.unreadCount;
      // sessionStorage.setItem('getGeneralTableValueOne', JSON.stringify(finalcontractValueOneValueOne));
      // this.GeneralAPI.next(true);
      this.generalDocumentDataAPI.next(result)
    });
  }

  // Mycompany DynamicData //

  public getDynamicData(): any {
    let dynamicDataQuery: QueryRef<any>;
    dynamicDataQuery = this.apollo.watchQuery({
      query: gql`
    query MyQuery {

      getCompanyInfo(input: {fedId: "${this.loggedInDetails.federatedId}"}) {

        completion

        lastUpdateDate

        message

        showNotification
        supplierName
      }

    }
`,
    });
    dynamicDataQuery.valueChanges.subscribe((result) => {
      // const dataOne = result;
      // const dataValueOne = dataOne && result.data;
      // const dynamicDataValue = dataValueOne && dataValueOne.getCompanyInfo;
      // sessionStorage.setItem('dynamicData', JSON.stringify(dynamicDataValue));
      // this.DynamicAPI.next(true);
      // // new
      // console.log(result,"comapany")
      this.companyInfoApi.next(result?.data?.getCompanyInfo)
    });
  }
  // Mycompany DynamicData //

  public getContact(): any {

    let contactQuery: QueryRef<any>;

    contactQuery = this.apollo.watchQuery({
      query: gql`
    query MyQuery {
      getContactSupportContent(input: {
        id: "contactSupport"
      }) {
        id
        textCh
        textEn
      }
    }

`,
    });
    contactQuery.valueChanges.subscribe((result) => {

      const dataOne = result;
      const dataValueOne = dataOne && result.data;
      const finalValueOne = dataValueOne && dataValueOne.getContactSupportContent;
      sessionStorage.setItem('ContactData', JSON.stringify(finalValueOne));
      this.ContactAPI.next(true);
    });
  }

  // Portal Update Principal //

  public getportalupdatePrincipal(): any {
    this.portalDetails = JSON.parse(sessionStorage.getItem('portalData') || '{}');

    if (this.portalDetails.textEn && this.portalDetails.textEn !== '') {
      let checkDesc = this.portalDetails.textEn.replaceAll('\'', "''");
      checkDesc = checkDesc.replaceAll('\"', "''");
      this.portalDetails.textEn = checkDesc.replaceAll('\n', '<br/>');

    }
    if (this.portalDetails.textCh && this.portalDetails.textCh !== '') {
      let checkDesc = this.portalDetails.textCh.replaceAll('\'', "''");
      checkDesc = checkDesc.replaceAll('\"', "''");
      this.portalDetails.textCh = checkDesc.replaceAll('\n', '<br/>');

    }
    this.apollo.mutate({
      mutation: gql`
      mutation MyMutation {
        setPrincipalPortalContent(input: {
          id: "portalprincipal",
          textCh: "${this.portalDetails.textCh}",
          textEn: "${this.portalDetails.textEn}",
          loginEmail: "${this.loggedInDetails.email}"
        }
        )
      }
  `,
    }).subscribe((data) => {

      this.PortalupdateAPI.next(true);
    });
  }

  public getContactupdate(): any {
    this.contactDetails = JSON.parse(sessionStorage.getItem('ContactData') || '{}');

    if (this.contactDetails.textEn && this.contactDetails.textEn !== '') {
      let checkDesc = this.contactDetails.textEn.replaceAll('\'', "''");
      checkDesc = checkDesc.replaceAll('\"', "''");
      this.contactDetails.textEn = checkDesc.replaceAll('\n', '<br/>');
    }
    if (this.contactDetails.textCh && this.contactDetails.textCh !== '') {
      let checkDesc = this.contactDetails.textCh.replaceAll('\'', "''");
      checkDesc = checkDesc.replaceAll('\"', "''");
      this.contactDetails.textCh = checkDesc.replaceAll('\n', '<br/>');
    }

    this.apollo.mutate({
      mutation: gql`
       mutation MyMutation {
        setContactSupportContent(input: {
          id: "contactSupport",
         loginEmail:"${this.loggedInDetails.email}",
          textCh: "${this.contactDetails.textCh}",
          textEn: "${this.contactDetails.textEn}"
        })
      }
   `,
    }).subscribe((data) => {

      this.ContactupdateAPI.next(true);
    });
  }

  public getStarRatingData(starRatingData: number): any {
    sessionStorage.setItem('feedbackRating', JSON.stringify(starRatingData));
    this.apollo.mutate({
      mutation: gql`
       mutation MyMutation {
        setFeedback(input: {
        email: "${this.loggedInDetails.email}",
        feedbackComment: "",
        feedbackSectionList: "",
        rating: ${starRatingData}
       })

      }
   `,
    }).subscribe((data) => {

      this.starRatingDataAPI.next(true);
    });
  }

  public setRepoFileUpload(actionType: string, file: File, folderName: string, fileSize: number) {
    this.apollo.mutate({
      mutation: gql`
       mutation MyMutation {
        setRepoFileList(input:{
         actionType: "${actionType}",
         fileName: "${file.name}", 
         folderName: "${folderName}",
         fileSize: ${fileSize}
        }){
         fileName
          uploadURL
         }
      }`,
    }).subscribe((result: any) => {
      // console.log("RepoFileUpload Status", result);
      if (result && result.data && result.data.setRepoFileList) {
        const data = result.data.setRepoFileList as SetRepoFileResult;
        this.uploadRepoFile(data.uploadURL, file);

        this.repoFileUploadResultAPI.next({
          setRepoFileList: data
        });
      }
    });
  }

  public uploadRepoFile(url: string, file: File) {
    this.http.put(url, file, { observe: 'response' }).subscribe((result: any) => {
      this.repoFileUploadResultAPI.next(result)
    })
  }

  public deleteRepofile(key: string) {
    this.apollo.mutate({
      mutation: gql`
      mutation MyMutation {
        deleteRepoFile(input:{
         actionType: "deleteRepoFile",
         key: "${key}",
        })
      }`,
    }).subscribe((result: any) => {
      if (result && result.data && result.data.deleteRepoFile) {
        this.deleteRepoFileResultAPI.next(true)
      }
    })
  }



  public getCheckBoxCommentData(): any {
    this.userfeedbackComment = JSON.parse(sessionStorage.getItem('feedbackcomment') || '{}')
    this.usercheckBoxlist = JSON.parse(sessionStorage.getItem('feedbackcheckboxlist') || '{}');
    this.ratingFeedback = JSON.parse(sessionStorage.getItem('feedbackRating') || '{}');
    this.apollo.mutate({
      mutation: gql`
      mutation MyMutation {
      setFeedback(input: {
       email: "${this.loggedInDetails.email}",
       feedbackComment: "${this.userfeedbackComment.comment}",
        feedbackSectionList: "${this.usercheckBoxlist}",
        rating: ${this.ratingFeedback}
         })
        }
    
   `,
    }).subscribe((data) => {

      this.checkboxCommentDataAPI.next(true);
    });
  }

  public ratingSummary(): any {
    let ratingSummaryQuery: QueryRef<any>;
    ratingSummaryQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getRatingSummary {
          count
          rateIndex
        }
      }
`,
    });
    ratingSummaryQuery.valueChanges.subscribe((result) => {

      const dataOne = result;
      const dataValueOne = dataOne && result.data;
      const finalValueOne = dataValueOne;
      sessionStorage.setItem('ratingSummary', JSON.stringify(finalValueOne));

      this.ratingSummaryAPI.next(true);
    });
  }

  // ContactTable List List //

  public getcontactTable(): any {

    let contactTableQuery: QueryRef<any>;

    contactTableQuery = this.apollo.watchQuery({
      query: gql`
    query MyQuery {

      getContactSupportTable {

        application

        id

        moduleCh

        moduleEn

        order

        supportEmail

      }

    }
`, fetchPolicy: 'network-only'
    });
    contactTableQuery.valueChanges.subscribe((result) => {

      const dataOne = result;
      const dataValueOne = dataOne && result.data;
      const finalValueOne = dataValueOne && dataValueOne.getContactSupportTable;
      sessionStorage.setItem('contactTable', JSON.stringify(finalValueOne));

      this.ContactTableAPI.next(true);
    });
  }

  public tableList: any;
  public updateContactTable(order: number): void {
    const tableData1 = [JSON.parse(sessionStorage.getItem('updateContactTable') || '{}')];
    const newData = JSON.parse(sessionStorage.getItem('contactTable') || '{}');
    newData.map((e: any) => {
      if (e.order === order) {
        e.id = tableData1[0].id.replace("\t", "").trim();
        e.order = tableData1[0].order;
        e.application = tableData1[0].application;
        e.supportEmail = tableData1[0].supportEmail;
        e.moduleCh = tableData1[0].moduleCh.replace("\t", "").trim();
        e.moduleEn = tableData1[0].moduleEn.replace("\t", "").trim();
      }
    });
    let totalData;
    if (tableData1[0].order === '') {
      totalData = [...tableData1, ...newData];

    } else {
      totalData = newData;
    }
    this.tableList = '';
    totalData.forEach((item: any, index: number) => {
      const obj = `{
      id: "${item.application}$${item.moduleEn}",
      order: ${index},
      application: "${item.application}",
      supportEmail: "${item.supportEmail}",
      moduleCh: "${item.moduleCh}",
      moduleEn: "${item.moduleEn}",
    }`
      this.tableList += obj;
    })

    this.tableList = `[${this.tableList}]`;
    this.latestUser = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
    this.apollo.mutate({
      mutation: gql`
       mutation MyMutation {
        setContactSupportTable(input: {tableList: ${this.tableList}, loginEmail:"${this.latestUser.email}" })
      }
    `,
    }).subscribe((data: any) => {

      if (data && data.setContactSupportTable === null) {
        sessionStorage.setItem('ContactUpdateErrorMessage', JSON.stringify(data));
        this.ContactTableAPI.next(false);
        this.getcontactTable();
        this.ContactTableupdateAPI.next(true);
      }
      else {
        sessionStorage.setItem('ContactUpdateData', JSON.stringify(data));
        this.ContactTableAPI.next(false);
        this.getcontactTable();
        this.ContactTableupdateAPI.next(true);
      }

    });

  }

  /* SupplyOn - start */
  public supplyOnDropdown(): void {
    //  {email: "mohua.bhattacharjee@se.com"}
    if (this.loggedInDetails.email !== undefined && this.loggedInDetails.email !== null && this.loggedInDetails.email.trim() !== '') {
      let getsupplyOnDropdownQuery: QueryRef<any>;
      getsupplyOnDropdownQuery = this.apollo.watchQuery({
        query: gql`
        query MyQuery {
          getSupplyOnDropdownData(input: {email: "${this.loggedInDetails.email}"}) {
            com
            login
            sma
          }
        }
    `,
      });
      getsupplyOnDropdownQuery.valueChanges.subscribe((result) => {
        const data = result;
        const dataValue = data && result.data;
        const finalValue = dataValue && dataValue.getSupplyOnDropdownData;
        sessionStorage.setItem('supplyOnDropdown', JSON.stringify(finalValue));
        this.getsupplyOnDropdownContentAPI.next(true);
        this.getSupplyOnDataDropdownApi.next(result.data.getSupplyOnDropdownData)
      });
    }
  }

  /* SupplyOn - start */
  public supplyOn(sma?: any): void {
    let getSupplyOnQuery: QueryRef<any>;
    getSupplyOnQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getSupplyOnData(input: {sma: "${sma}"}) {
          asnDetails {
            dueNext7DaysASN
            dueNext7DaysASNLink
            dueTodayASN
            dueTodayASNLink
            overDueASN
            overDueASNLink
            totalASN
            totalASNLink
          }
          dataPoints {
            identifier
            link
            type
            value
          }
        }
      }
  `, fetchPolicy: 'network-only'

    });
    getSupplyOnQuery.valueChanges.subscribe((result) => {
      // console.log("service calling", result)
      const data = result;
      const dataValue = data && result.data;
      const finalValue = dataValue && dataValue.getSupplyOnData;
      Object.assign(finalValue, { smaId: sma });
      sessionStorage.setItem('supplyOn', JSON.stringify(finalValue));
      let supplyonArray = JSON.parse(sessionStorage.getItem('supplyOn') || '{}');
      this.supplyonArrayvalues.push(supplyonArray);
      sessionStorage.setItem('supplyOnCollectedData', JSON.stringify(this.supplyonArrayvalues));

      this.setSCMData(finalValue, sma)
      this.getSupplyOnContentAPI.next(true);
      this.scmApi.next(finalValue)
    });
  }

  setSCMData(data: any, sma: any) {
    if (this.scmData.length) {
      let smaList = this.scmData.map((data: any) => {
        return data.smaId;
      })
      if (smaList.indexOf(sma) === -1) {
        this.scmData.push(data)
      }
    } else {
      this.scmData.push(data)
    }

  }

  getSCMData() {
    return this.scmData;
    // this.scmApi.next(finalValue)
  }
  /* HOME PAGE GET - start */
  public getHomePageContent(): void {
    let getHomePageContentQuery: QueryRef<any>;
    getHomePageContentQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getHomeTile {
          categoryName
          modules {
            order
            descriptionCh
            descriptionEn
            nameCh
            nameEn
            uid
          }
        }
      }
  `,
    });
    getHomePageContentQuery.valueChanges.subscribe((result) => {
      // console.log('homeapidata--->', result);
      const data = result;
      const dataValue = data && result.data;
      const finalValue = dataValue && dataValue.getHomeTile;
      sessionStorage.setItem('homeContent', JSON.stringify(finalValue));
      this.getHomeContentAPI.next(true);
    });
  }
  /* HOME PAGE GET - end */

  /* HOME PAGE SET - start */
  public setHomeContent(category: string): void {
    this.homeDetails = JSON.parse(sessionStorage.getItem('homeContent') || '{}');
    const updatedRowDetails = JSON.parse(sessionStorage.getItem('updateHomeTable') || '{}');
    this.uid = updatedRowDetails.uid;
    this.descriptionCh = updatedRowDetails.descriptionCh;
    this.descriptionEn = updatedRowDetails.descriptionEn;
    this.nameCh = updatedRowDetails.nameCh;
    this.nameEn = updatedRowDetails.nameEn;

    this.homeDetails.map((item: any) => {
      if (item.categoryName === category) {
        item.modules.map((elem: any) => {
          if (elem.order === updatedRowDetails.order) {
            elem.nameEn = updatedRowDetails.nameEn;
            elem.descriptionEn = updatedRowDetails.descriptionEn;
            elem.nameCh = updatedRowDetails.nameCh;
            elem.descriptionCh = updatedRowDetails.descriptionCh;
            elem.uid = updatedRowDetails.uid;
          }
        });
      }
    });
    let categoryNumber: number = -1;
    if (category === 'Source to Contract') {
      categoryNumber = 2;
    } else if (category === 'Supplier Relationship Management') {
      categoryNumber = 0;
    } else if (category === 'Procure to Pay & Supply Chain Management') {
      categoryNumber = 1;
    }

    if (categoryNumber !== -1) {

      this.homeDetails[categoryNumber].modules?.forEach((item: any) => {

        const stringObject =
        {
          "descriptionCh": item.descriptionCh,
          "descriptionEn": item.descriptionEn,
          "nameCh": item.nameCh,
          "nameEn": item.nameEn,
          "order": item.order,
          "uid": item.uid
        }

        this.valueData.push(stringObject);

      });
    }


    this.apollo.mutate({
      mutation: gql`
      mutation MyMutation {
        setHomeTile(input: {
          uid:"${this.uid}",
          descriptionCh: "${this.descriptionCh}",
          descriptionEn: "${this.descriptionEn}",
          nameCh: "${this.nameCh}",
          nameEn: "${this.nameEn}",
          loginEmail:"${this.loggedInDetails.email}"
        })
      }
  `
    }).subscribe((data) => {
      sessionStorage.setItem('homeContent', JSON.stringify(this.homeDetails));

      this.setHomeContentAPI.next(true);
    });
  }
  /* HOME PAGE SET - end */


  public getUploadImage(): any {

    const uploadImage = JSON.parse(sessionStorage.getItem('UploadImage') || '{}');
    const language = JSON.parse(sessionStorage.getItem('Language') || '{}');
    this.latestUser = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
    const imageLanguage = language === true ? 'CH' : 'EN';
    let uploadImageQuery: QueryRef<any>;
    uploadImageQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getPreSignedImageUploadURL(input: {fileName: "${uploadImage}", language: "${imageLanguage}", loginEmail:"${this.latestUser.email}"})
        }
`
    });
    uploadImageQuery.valueChanges.subscribe((result) => {
      const dataUploadImage = result;
      const dataValueUploadImage = dataUploadImage && result.data;
      const finalValueUploadImage = dataValueUploadImage && dataValueUploadImage.getPreSignedImageUploadURL;
      sessionStorage.setItem('UploadImageURL', JSON.stringify(finalValueUploadImage));
      this.UploadImageAPI.next(true);
    });
  }

  public coupaDropdownList(): any {
    let coupaDropdownListQuery: QueryRef<any>;
    coupaDropdownListQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getCoupaDropdownData(input: {email: "${this.loggedInDetails.email}"}) {
          displayName
          id
        }
      }
`, fetchPolicy: 'network-only'
    });
    coupaDropdownListQuery.valueChanges.subscribe((result) => {
      const datacoupaDropdownList = result;
      const dataValuecoupaDropdownList = datacoupaDropdownList && result.data;
      const finalValuecoupaDropdownList = dataValuecoupaDropdownList?.getCoupaDropdownData;
      sessionStorage.setItem('coupaDropdownList', JSON.stringify(finalValuecoupaDropdownList));
      this.coupaDropdownListAPI.next(true);
      // new
      this.procureToPayAPI.next(result?.data?.getCoupaDropdownData)
    });
  }

  public coupaTableData(id: any, offset = 0): any {
    // let data = JSON.parse(sessionStorage.getItem('coupaTableData') || '{}');
    // if(offset === 0 &&  Object.keys(data).length === 0) {
    //   this.getCoupaTabaleData(id, offset)
    // }
    //   else {
    //     this.coupaTableDataAPI.next(data);
    //   }
    this.getCoupaTabaleData(id, offset)
  }
  coupaTableDataQuery: QueryRef<any> | undefined;
  public getCoupaTabaleData(id: any, offset = 0) {
    this.coupaTableDataQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getCoupaPOListoffset50(input: {id: ${id}, offset: ${offset}}) {
          acknowledgedAt
          assignedTo
          createdAt
          id
          paymentTerm
          poNumber
          requisitionId
          status
          total
          url
        }
      }
` , fetchPolicy: 'network-only'
    });
    this.coupaTableDataQuery.valueChanges.subscribe((result) => {
      this.coupaTableDataAPI.next(result?.data?.getCoupaPOListoffset50);
      if (result?.data?.getCoupaPOListoffset50.length >= 50 && offset <= 300) {
        offset += 50
        this.getCoupaTabaleData(id, offset)
      }
    });
  }

  public getImage(): any {

    // const uploadImage = JSON.parse(sessionStorage.getItem('UploadImage') || '{}');
    // const language = JSON.parse(sessionStorage.getItem('Language') || '{}');
    // const imageLanguage = language === true ? 'CH' : 'EN';
    let getImageQuery: QueryRef<any>;
    getImageQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery { 
        getPortalPrincipalImageURL { 
          URL_VDO
          URL_EN 
          URL_CH
        } 
      
      } 
`
    });
    getImageQuery.valueChanges.subscribe((result) => {
      const dataGetImage = result;
      const dataValueGetImage = dataGetImage && result.data;
      const finalValueGetImage = dataValueGetImage && dataValueGetImage.getPortalPrincipalImageURL;
      this.imageName = JSON.stringify(finalValueGetImage);
      this.preSignedEn = this.imageName.URL_EN;
      this.preSignedCh = this.imageName.URL_CH;
      sessionStorage.setItem('GetImage', JSON.stringify(finalValueGetImage));
      this.GetImageAPI.next(true);
    });
  }


  //Repository Page
  public getRepoData(): any {
    let getRepoFileListQuery: QueryRef<any>;
    getRepoFileListQuery = this.apollo.watchQuery({
      query: gql`
        query MyQuery {
          getRepoFileList {
            key
            lastModified
            preSignedURL
            size
          }
        }
`
    });
    // getRepoFileListQuery.valueChanges.subscribe((result) => {
    //   const finalValueGetRepo: IRepoFileList[] = result?.data?.getRepoFileList || [];
    //   console.log(`Final Data ==> `, finalValueGetRepo);

    //   this.getRepoListData.next(finalValueGetRepo);
    // })

    return getRepoFileListQuery.valueChanges.pipe(
      map((result) => {
        const finalValueGetRepo: IRepoFileList[] = result?.data?.getRepoFileList || [];
        // console.log(`Final Repo Data ==> `, finalValueGetRepo);
        this.getRepoListData.next(finalValueGetRepo);
        return finalValueGetRepo;
      })
    );
  }

  public getWeekDateDetails() {
    let getImageQuery: QueryRef<any>;
    getImageQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getLoginExtractWeekRange { 
          dateRangeList
          weekNumber
        }
      }
`
    });
    getImageQuery.valueChanges.subscribe((result) => {
      this.weekExcelDetails.next(result?.data)
    })
  }

  public getWeekExcelUrl(weekNumber: any) {
    let getImageQuery: QueryRef<any>;
    getImageQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery { 
        getLoginWeekExtractURL(input: {weekNumber: ${weekNumber}})   
      } 
`
    });
    getImageQuery.valueChanges.subscribe((result) => {
      this.weekExcelUrl.next(result.data.getLoginWeekExtractURL)
      return result;
    })
  }

  public getUploadUrl(file: any) {
    let getImageQuery: QueryRef<any>;
    getImageQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getUploadAttachmentURL(input: {fileName: "${file.name}"}) {
          fileName
          uploadURL
        }
      }
`
    });
    getImageQuery.valueChanges.subscribe((result) => {
      let data = result?.data?.getUploadAttachmentURL;
      this.encryptedFileName = data.fileName;
      this.uploadAttachemnt(data.uploadURL, file)

    })
  }

  uploadAttachemnt(url: string, file: any) {
    console.log(file)
    this.http.put(url, file, { observe: 'response' }).subscribe(result => {
      this.uploadAttachmentAPI.next(result)
    })
  }

  submitContactUsForm(formDetails: any) {
    this.apollo.mutate({
      mutation: gql`
      mutation MyMutation {
        setServiceNowRequestNew(input: {
          category: "${formDetails.category}",
          description: "${formDetails.issueDesc}", 
          email: "${formDetails.email}", 
          fileName: "${this.encryptedFileName}", 
          name: "${formDetails.name}", 
          phone: "${formDetails.phone}", 
          ticketType: "${formDetails.type}"
        }) {
          number
          status
          sys_id
          uploadId
        }
      }
  `
    }).subscribe((data) => {
      // if (response?.data?.setServiceNowTicket !== null) {
      this.ticketCreartionAPI.next(data)
      // } 
    }, error => {
      this.ticketCreartionAPI.next("error")
    });
  }

  getWeeklyregionCount(mode: string) {
    let getImageQuery: QueryRef<any>;
    getImageQuery = this.apollo.watchQuery({
      query: gql`
      query MyQuery {
        getRegionWiseLoginCount(input: {mode: "${mode}"}) {
          loginCount
          region
        }
      }
`
    });

    getImageQuery.valueChanges.subscribe((result) => {
      if (mode == "WEEK") {
        this.weeklyRegionCountAPI.next(result?.data?.getRegionWiseLoginCount)
      }
      if (mode == "MONTH") {
        this.monthlyRegionCountAPI.next(result?.data?.getRegionWiseLoginCount)
      }
      if (mode == "YEAR") {
        this.yearlyRegionCountAPI.next(result?.data?.getRegionWiseLoginCount)
      }
    })
  }
}
