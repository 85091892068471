<div *ngIf="feedbackwindowClose" class="feedback-rating">
    <kendo-dialog (close)="feedbackWindow()" [minWidth]="460">
        <kendo-dialog-titlebar>
            <div style="font-size: 16px; line-height: 1.3em; color: #FFF; font-family: nunito;">
                {{ 'home.feedbackRatingTitle' | translate }}
            </div>
        </kendo-dialog-titlebar>

        <ng-container *ngIf="starRatingScreen && !showstarsuccessmessage">
            <p class="note-text">{{ 'home.feedbackNote' | translate }}</p>
            <div class="feedback-heading">{{ 'home.feedbackRatingHeader' | translate }}</div>
            <div class="each-star d-flex flex-row">
                <div class="star-holder"><span (click)="starclick1()" [ngClass]="{'activeStar':starbackroundColor1}"
                        class="fa fa-star-o each-star-item"></span>
                </div>
                <div class="star-holder"><span (click)="starclick2()" [ngClass]="{'activeStar':starbackroundColor2}"
                        class="fa fa-star-o each-star-item"></span>
                </div>
                <div class="star-holder"><span (click)="starclick3()" [ngClass]="{'activeStar':starbackroundColor3}"
                        class="fa fa-star-o each-star-item"></span>
                </div>
                <div class="star-holder"><span (click)="starclick4()" [ngClass]="{'activeStar':starbackroundColor4}"
                        class="fa fa-star-o each-star-item"></span>
                </div>
                <div class="star-holder"><span (click)="starclick5()" [ngClass]="{'activeStar':starbackroundColor5}"
                        class="fa fa-star-o each-star-item"></span>
                </div>
            </div>
            <!-- <button kendoButton (click)="feedbackRating1()" style="color:#42B4E6;float:right;"
                [disabled]="nextButton">{{ 'home.next' | translate }} > </button> -->
            <div class="d-flex">
                <form name="feedbackgroupForm" [formGroup]="feedbackgroupForm">
                    <div class="form-group text-start ms-0">
                        <!-- <ng-container *ngIf="checkboxScreen"> -->
                        <div class="feedback-category">{{ 'home.feedbackCategory' | translate }}</div>
                        <div class="d-flex flex-column checkbox-holder">
                            <div class="checkbox-item"><input type="checkbox" formControlName="PUE"
                                    (change)="checkSelected($event , 'PUE')">
                                {{
                                'home.PUE' | translate }}
                            </div>
                            <div class="checkbox-item"><input type="checkbox" value="Source to Contract"
                                    formControlName="STC" (change)="checkSelected($event , 'STC')"> {{ 'home.a' |
                                translate }}</div>
                            <div class="checkbox-item"><input type="checkbox" formControlName="SRM"
                                    (change)="checkSelected($event , 'SRM')">
                                {{
                                'home.b' | translate }}
                            </div>
                            <div class="checkbox-item"><input type="checkbox" formControlName="PTP"
                                    (change)="checkSelected($event , 'PTP')">
                                {{
                                'home.ProcuretoPay' | translate }}
                            </div>
                            <div class="checkbox-item"><input type="checkbox" formControlName="SCM"
                                    (change)="checkSelected($event , 'SCM')">
                                {{
                                'home.SCM' | translate }}
                            </div>
                            <div class="checkbox-item"><input type="checkbox" formControlName="I2P"
                                    (change)="checkSelected($event , 'I2P')">
                                {{
                                'home.I2P' | translate }}
                            </div>
                            <div class="checkbox-item"><input type="checkbox" formControlName="OP"
                                    (change)="checkSelected($event , 'OP')"> {{
                                'home.coInnovations' | translate }}
                            </div>
                            <div class="checkbox-item"><input type="checkbox" formControlName="KH"
                                    (change)="checkSelected($event , 'KH')"> {{
                                'home.knowledgeHub' | translate }}
                            </div>
                        </div>
                        <!-- <button kendoButton (click)="feedbackRating2()" style="color:#42B4E6;float:right; bottom: 10px;"
                                [disabled]="categoryNextButton">{{ 'home.next' | translate }} ></button> -->
                        <!-- </ng-container> -->
                        <form name="commentForm" class="comment-form" [formGroup]="commentForm">
                            <!-- <ng-container *ngIf="textareaScreen"> -->
                            <div class="feedback-heading">{{ 'home.feedbackCommentHeader' | translate }}: <span
                                    style="color: #B10043;">*</span></div>
                            <div class="d-flex flex-row comment-area">
                                <div class="flex-grow-1">
                                    <textarea rows="4" cols="50" maxlength="200" [(ngModel)]="CommentLength"
                                        formControlName="comment" placeholder="Enter text here">
                                            </textarea>
                                    <div class="comment-count">{{CommentLength.length}}/200</div>
                                </div>
                            </div>
                            <!-- <div class="float-end" (click)="feedbackWindow()">
                                <se-icon size="small" class="star-icon-color">action_close</se-icon>
                            </div> -->
                            <button kendoButton (click)="feedbackRating3()" class="float-end submit-btn"
                                [disabled]="CommentLength.length===0">{{
                                'home.submit' | translate }}</button>
                            <!-- <button kendoButton (click)="feedbackRating3()" style="color:#42B4E6;float:right;">Submit</button> -->
                            <!-- </ng-container> -->
                        </form>
                    </div>
                </form>
            </div>
        </ng-container>
        <ng-container *ngIf="!starRatingScreen">
            <div class="feedback-thanks">
                <p>{{ 'home.feedbackThanks' | translate }}! :)</p>
            </div>
        </ng-container>
    </kendo-dialog>
</div>
<!-- <div *ngIf="feedbackcheckboxForm" class="feedback-checkbox">
    <kendo-dialog title="" (close)="close()" [minWidth]="350" [height]="240">
        <div class="float-end" (click)="feedbackWindow()">
            <se-icon size="small" class="action-icon-close">action_close</se-icon>
        </div>
        <form name="feedbackgroupForm" [formGroup]="feedbackgroupForm" style="margin-left: -3% !important;">
            <div class="form-group text-start ms-0">
                <ng-container *ngIf="checkboxScreen">
                    <div class="feedback-category">{{ 'home.feedbackCategory' | translate }}</div>
                    <div class="d-flex flex-column checkbox-holder">
                        <div><input type="checkbox" value="Source to Contract" formControlName="STC"
                                (change)="checkSelected($event , 'STC')"> {{ 'home.a' | translate }}</div>
                        <div><input type="checkbox" formControlName="SRM" (change)="checkSelected($event , 'SRM')"> {{
                            'home.b' | translate }}
                        </div>
                        <div><input type="checkbox" formControlName="PTP" (change)="checkSelected($event , 'PTP')"> {{
                            'home.ProcuretoPay' | translate }}
                        </div>
                        <div><input type="checkbox" formControlName="SCM" (change)="checkSelected($event , 'SCM')"> {{
                            'home.SCM' | translate }}
                        </div>
                        <div><input type="checkbox" formControlName="PUE" (change)="checkSelected($event , 'PUE')"> {{
                            'home.PUE' | translate }}
                        </div>
                        <div><input type="checkbox" formControlName="I2P" (change)="checkSelected($event , 'I2P')"> {{
                            'home.I2P' | translate }}
                        </div>
                        <div><input type="checkbox" formControlName="None" (change)="checkSelected($event , 'None')"> {{
                            'home.none' | translate }}
                        </div>
                    </div>
                    <button kendoButton (click)="feedbackRating2()" style="color:#42B4E6;float:right; bottom: 10px;"
                        [disabled]="categoryNextButton">{{ 'home.next' | translate }} ></button>
                </ng-container>
                <form name="commentForm" class="float-end" [formGroup]="commentForm">
                    <ng-container *ngIf="textareaScreen">
                        <div class="feedback-heading">{{ 'home.feedbackCommentHeader' | translate }}</div>
                        <div class="d-flex flex-row comment-area">
                            <div class="flex-grow-1">
                                <textarea rows="4" cols="50" maxlength="200" [(ngModel)]="CommentLength"
                                    formControlName="comment" placeholder="Enter text here">
                                </textarea>
                                <div class="comment-count">{{CommentLength.length}}/200</div>
                            </div>
                        </div>
                        <button kendoButton (click)="feedbackRating3()" class="float-end submit-btn"
                            [disabled]="CommentLength.length===0">{{
                            'home.submit' | translate }}</button>
                        <button kendoButton (click)="feedbackRating3()" style="color:#42B4E6;float:right;">Submit</button>
                    </ng-container>
                </form>
            </div>
        </form>
        <ng-container *ngIf="ratingSuccessScreen">
            <div class="feedback-thanks-note">
                <p>{{ 'home.feedbackThanks' | translate }}! :)</p>
            </div>
        </ng-container>
    </kendo-dialog>
</div> -->