import { Component, Input, OnChanges, OnInit, ViewEncapsulation } from '@angular/core';
import { VendorService } from '../../vendor.service';
import { model } from '../../Traffic';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-traffic-monitoring',
  encapsulation: ViewEncapsulation.None,
  templateUrl: './traffic-monitoring.component.html',
  styleUrls: ['./traffic-monitoring.component.scss']
})
export class TrafficMonitoringComponent implements OnInit, OnChanges {
  loggedInlanguage: any;
  loggedInCode!: string;
  categories: any;
  seriesYearlyDataNew!: never[];
  seriesmonthlyDataNew!: never[];
  seriesmonthlyData!: never[];
  seriesYearlyData!: never[];

  constructor(private _service: VendorService, public translate: TranslateService) { }
  public seriesWeeklyData: model[] = [];
  public seriesWeeklyDataNew: model[] = [];
  public seriesYearlyData2022: any;
  public count: any;
  public year: any;
  public yearlyBarColor: any;
  public count2021: any;
  public count2022: any;
  public count2020: any;
  public barColor: any;
  public weekly = true;
  public Monthly = false;
  public yearly = false;
  public color: any;
  public portalTranslate!: boolean;
  public monthlyTrafficDatesCategories: any;
  public trafficMonthlyTranslate!: boolean;
  public weekDownloadUrl = "";
  public dropDownWeekDetails: any = [];
  public selectedWeek: any;
  public length: any;
  public data: any;


  public currentYear = new Date().getFullYear();
  public currentYearstring = this.currentYear.toString();

  @Input() trafficMonitoringTranslate!: boolean;

  ngOnChanges(): void {
    this.portalTranslate = this.trafficMonitoringTranslate;
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  ngOnInit(): void {
    this._service.getWeeklyregionCount("WEEK")
    this._service.getWeeklyregionCount("MONTH")
    this._service.getWeeklyregionCount("YEAR")
    this._service.getTrafficWeeklyDataNew();
    this._service.getTrafficYearlyData2022();

    this._service.weeklyRegionCount.subscribe(data => {
      if (data.length) {
        this.seriesWeeklyData = data
      }
    })
    this._service.yearlyRegionCount.subscribe(data => {
      if (data.length) {
        this.seriesYearlyData = data
      }
    })
    this._service.monthlyRegionCount.subscribe(data => {
      if (data.length) {
        this.seriesmonthlyData = data
      }
    })
    this._service.getWeekDateDetails()
    this._service.weekExcel.subscribe(data => {
      if (Object.keys(data).length !== 0 && data.constructor === Object) {
        let weekDetails: any = data
        weekDetails?.getLoginExtractWeekRange.forEach((week: { dateRangeList: string | any[]; weekNumber: any; }) => {
          let weekDates = `${week.dateRangeList[0]} to ${week.dateRangeList[week.dateRangeList.length - 1]}`;
          let weekObj = {
            dates: weekDates,
            number: week.weekNumber
          }
          this.dropDownWeekDetails.push(weekObj)

        })
        this.weekSelection(this.dropDownWeekDetails[this.dropDownWeekDetails.length - 1].number);
        this.selectedWeek = this.dropDownWeekDetails[this.dropDownWeekDetails.length - 1].dates
      }

    })

    this._service.weekExcelUrlDownload.subscribe(value => {
      if (value) {
        this.weekDownloadUrl = value
      }
    })
    this._service.TrafficYearlyAPI2022.subscribe(value => {
      if (value) {
        this.seriesYearlyData2022 = JSON.parse(sessionStorage.getItem('TrafficYearlyQueryData2022') || '{}');
      }
    })
    this._service.TrafficWeeklyNewAPI.subscribe(value => {
      if (value) {
        this.seriesWeeklyDataNew = JSON.parse(sessionStorage.getItem('TrafficWeeklyQueryDataNew') || '{}');
        this.categories = JSON.parse(sessionStorage.getItem('TrafficWeeklyQueryDatesNew') || '{}');
      }
    })
  }

  public monthlyTraffic() {
    this.weekly = false;
    this.Monthly = true;
    this.yearly = false;
    // this.barColor = "#7CE8F4";

  }
  public weeklyTraffic() {
    this.weekly = true;
    this.Monthly = false;
    this.yearly = false;
  }

  public yearlyTraffic() {
    this.weekly = false;
    this.Monthly = false;
    this.yearly = true;

  }

  weekSelection(event: any) {
    let weekNumber
    if (typeof event === 'number') {
      weekNumber = event
    } else {
      weekNumber = event.target.value
    }
    this.weekDownloadUrl = ""
    this._service.getWeekExcelUrl(weekNumber)
  }
}
