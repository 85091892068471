<se-block class="esurvey-table tab-panel" id="tabPanel1">
    <div class="tableArrow"></div>
    <se-table height="500px" class="block-content-border" class="tableVew">
        <p class="deleteIcon">
            <se-icon class="delete-icon-color" (click)="onESurveycancelClick()">action_delete_cross
            </se-icon>
        </p>
        <p class="tableHeader">
            {{ 'home.tableheader' | translate }}
        </p>
        <div class="tableContent desktopTable">
            <div class="float-end pb-3">
                <a class="buttonAnchor text-white" kendoTooltip tooltipClass="my-class" position="top"
                    title="{{ESurvey}}" href="{{ESurvey}}" target="_blank">
                    <se-button color="primary"> {{ 'home.allsurveys' | translate }}</se-button>
                </a>
            </div>
            <div class="esurvey-id">
                <kendo-grid [kendoGridBinding]="tooltipGridValue" [sortable]="true" [height]="530"
                    class="k-button-group" [pageSize]="pageSize" [skip]="skip" [sort]="sort" [pageable]="true"
                    (pageChange)="onPageChange($event)" kendoGridSelectBy="ID" class="mobileGrid">
                    <kendo-grid-column field="ID" title="ID" [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="padding-left: 10px">{{ 'home.serial' | translate }}</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-gridView>
                            <a style=" padding-left: 10px;color: #42b4e6; cursor: pointer" href="{{gridView.Deeplink}}"
                                target=" ">{{ gridView.ID }}
                            </a>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Project_name" title="{{ 'home.projectname' | translate }}"
                        [headerClass]="'gridColumnStyle'" class="columnData">
                    </kendo-grid-column>
                    <kendo-grid-column field="Start_date" title="{{ 'home.startdate' | translate }}"
                        [headerClass]="'gridColumnStyle'" class="columnData">
                        <ng-template kendoGridCellTemplate let-gridView>
                            {{ gridView.Start_date | date: 'dd MMM yyyy' }}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="End_date" title="{{ 'home.enddate' | translate }}"
                        [headerClass]="'gridColumnStyle'" class="columnData">
                        <ng-template kendoGridCellTemplate let-gridView>
                            {{ gridView.End_date | date: 'dd MMM yyyy' }}
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Status" title="{{ 'home.status' | translate }}"
                        [headerClass]="'gridColumnStyle'" class="columnData">
                    </kendo-grid-column>
                    <kendo-grid-column field="Supplier_Company" title="{{ 'home.supplierdate' | translate }}"
                        [headerClass]="'gridColumnStyle'" class="columnData">
                    </kendo-grid-column>
                    <kendo-grid-column field="Plateform" title="{{ 'home.plat' | translate }}"
                        [headerClass]="'gridColumnStyle'" class="columnData">
                    </kendo-grid-column>
                    <ng-template kendoGridNoRecordsTemplate>
                        <span *ngIf="!portalTranslate"> There is currently no ongoing events, to see past events you can
                            click on All Surveys.</span>
                        <span *ngIf="portalTranslate">目前没有未完成调查，如果查看已完成申请请点击所有申请按钮。
                        </span>
                    </ng-template>
                </kendo-grid>
            </div>
        </div>
    </se-table>
</se-block>