<se-block style="padding-top: 4%;  padding-bottom: 0%; width: 100%;background-color: #fafafa;">
    <span id="scrollsrm" #scrollsrm></span>
    <div style="padding-bottom: 2%; background-color: #fafafa;">
        <div class="item-wrapper" style="margin-left:2% !important;">
            <div style="position: absolute;" class="desktopNotification">
                <p *ngIf="myAccValue>0 && !portalTranslate"
                    style="display: inline;z-index: 1;left: 11%;position: absolute;top:-28px" kendoTooltip
                    tooltipClass="my-class" position="top" title="You have {{myAccValue}} document(s) to read">
                    <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                </p>
                <p *ngIf="myAccValue>0 && portalTranslate"
                    style="display: inline;z-index: 1;left: 11%;position: absolute;top:-28px" kendoTooltip
                    tooltipClass="my-class" position="top" title="您有未读文档 {{myAccValue}}">
                    <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                </p>
            </div>
            <a id="Contact" href="{{MyAccountDocument}}" target="_blank">
                <img src="../assets/images/Desk/Desk_myaccountdocument.png" height="175" width="175" />
                <div class="text-block">
                    <se-icon style="
                   width: 15%;
                   display: block;
                   margin-left: auto;
                   margin-right: auto;
                   margin-top: 10%;
                   ">page
                    </se-icon>
                    <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[0].nameEn }}</p>
                    <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[0].nameCh }}</p>
                    <div>
                        <p>
                            <se-icon size="medium" style="padding-top:4%">page</se-icon>
                            <span *ngIf="!portalTranslate" style="font-size: 15px;font-weight:600;font-family: Arial, Helvetica,
                         sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[0]?.modules[0].nameEn
                                }}</span>
                            <span *ngIf="portalTranslate" style="padding-top:2%;font-size: 15px;font-weight:600;font-family: Arial, Helvetica,
                         sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[0]?.modules[0].nameCh
                                }}</span>
                        </p>
                    </div>
                    <p *ngIf="!portalTranslate"
                        style="text-align: left;margin-top: 9.5%;font-weight:normal;font-size:11px !important">{{
                        homeContentFromAPI[0]?.modules[0].descriptionEn }}
                    </p>
                    <p *ngIf="portalTranslate"
                        style="text-align: left;margin-top: 9.5%;font-weight:normal;font-size:8px !important">{{
                        homeContentFromAPI[0]?.modules[0].descriptionCh }}
                    </p>
                </div>
            </a>
        </div>
        <span>
            <div class="item-wrapper">
                <div style="position: absolute;" class="desktopNotification">
                    <p *ngIf="myGenValue>0 && !portalTranslate"
                        style="display: inline;z-index: 1;left: 32.5%;position: absolute;top:-28px" kendoTooltip
                        tooltipClass="my-class" position="top" title="You have {{myGenValue}} document(s) to read">
                        <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                    </p>
                    <p *ngIf="myGenValue>0 && portalTranslate"
                        style="display: inline;z-index: 1;left: 32.5%;position: absolute;top:-28px" kendoTooltip
                        tooltipClass="my-class" position="top" title="您有未读文档 {{myGenValue}}">
                        <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                    </p>
                </div>
                <a id="Contact" href="{{GeneralDocuments}}" target="_blank">
                    <img src="../assets/images/Desk/Desk_generalDocuments.png" height="175" width="175" />
                    <div class="text-block">
                        <se-icon style="
                      width: 15%;
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                      margin-top: 10%;
                      ">notification_historic</se-icon>
                        <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[1].nameEn }}</p>
                        <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[1].nameCh }}</p>
                        <div>
                            <p>
                                <se-icon size="medium" style="padding-top:4%">notification_historic</se-icon>
                                <span *ngIf="!portalTranslate" style="font-size: 16px;font-weight:600;font-family: Arial, Helvetica, 
                            sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[0]?.modules[1].nameEn
                                    }}</span>
                                <span *ngIf="portalTranslate" style="padding-top:2%;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, 
                            sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[0]?.modules[1].nameCh
                                    }}</span>
                            </p>
                        </div>
                        <p *ngIf="!portalTranslate"
                            style="text-align: left;margin-top: 9.5%;font-weight:normal;font-size:11px !important">{{
                            homeContentFromAPI[0]?.modules[1].descriptionEn }}
                        </p>
                        <p *ngIf="portalTranslate"
                            style="text-align: left;margin-top: 10%;font-weight:normal;font-size:8px !important">{{
                            homeContentFromAPI[0]?.modules[1].descriptionCh }}
                        </p>
                    </div>
                </a>
            </div>
        </span>
        <span>
            <div class="item-wrapper">
                <a id="Contact" href="{{performanceDashboard}}" target="_blank">
                    <img src="../assets/images/Desk/Desk_performencedashboard.png" height="175" width="175" />
                    <div class="text-block">
                        <se-icon style="
                      width: 15%;
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                      margin-top: 10%;
                      ">graph_barchart</se-icon>
                        <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[2].nameEn }}</p>
                        <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[2].nameCh }}</p>
                        <div>
                            <p>
                                <se-icon size="medium" style="padding-top:4%">graph_barchart</se-icon>
                                <span *ngIf="!portalTranslate" style="font-size: 15px;font-weight:600;font-family: Arial, Helvetica, 
                            sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[0]?.modules[2].nameEn
                                    }}</span>
                                <span *ngIf="portalTranslate" style="padding-top:2%;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, 
                            sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[0]?.modules[2].nameCh
                                    }}</span>
                            </p>
                        </div>
                        <p *ngIf="!portalTranslate"
                            style="text-align: left;margin-top: 9.5%;font-weight:normal;font-size:11px !important">{{
                            homeContentFromAPI[0]?.modules[2].descriptionEn }}
                        </p>
                        <p *ngIf="portalTranslate"
                            style="text-align: left;margin-top: 10%;font-weight:normal;font-size:8px !important">{{
                            homeContentFromAPI[0]?.modules[2].descriptionCh }}
                        </p>
                    </div>
                </a>
            </div>
        </span>
        <span>
            <div class="item-wrapper">
                <div style="position: absolute;" class="desktopNotification">
                    <p *ngIf="actionPlanDataFlag === true && !portalTranslate"
                        style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                        kendoTooltip tooltipClass="my-class" position="top" title="New records found">
                        <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                    </p>
                    <p *ngIf="actionPlanDataFlag === true && portalTranslate"
                        style="display: inline;z-index: 1;left: 13%;margin-left:76%;position: absolute;top:-28px"
                        kendoTooltip tooltipClass="my-class" position="top" title="找到的新记录">
                        <img class="notificationIcon" src="../assets/images/white1_bell.jpg" />
                    </p>
                </div>
                <a id="Contact" (click)="onActionPlanClicked()" style="cursor: pointer;">
                    <img src="../assets/images/Desk/Desk_actionplan.png" height="175" width="175" />
                    <div class="text-block">
                        <se-icon style="
                      width: 15%;
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                      margin-top: 10%;
                      ">connection_ethernet</se-icon>
                        <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[3].nameEn }}</p>
                        <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[3].nameCh }}</p>
                        <div>
                            <p>
                                <se-icon size="medium" style="padding-top:4%">connection_ethernet</se-icon>
                                <span *ngIf="!portalTranslate" style="font-size: 15px;font-weight:600;font-family: Arial, Helvetica, 
                            sans-serif;margin-left:5%;position:absolute;margin-top: 6%">{{
                                    homeContentFromAPI[0]?.modules[3].nameEn }}</span>
                                <span *ngIf="portalTranslate" style="font-size: 17px;font-weight:600;font-family: Arial, Helvetica, 
                            sans-serif;margin-left:5%;position:absolute;margin-top: 6%">{{
                                    homeContentFromAPI[0]?.modules[3].nameCh }}</span>
                            </p>
                        </div>
                        <p *ngIf="!portalTranslate"
                            style="text-align: left;margin-top: 5%;font-weight:normal;font-size:11px !important">{{
                            homeContentFromAPI[0]?.modules[3].descriptionEn }}
                        </p>
                        <p *ngIf="portalTranslate"
                            style="text-align: left;margin-top: 5%;font-weight:normal;font-size:8px !important">{{
                            homeContentFromAPI[0]?.modules[3].descriptionCh }}
                        </p>
                    </div>
                </a>
            </div>
        </span>
        <!-- SPCN -->
        <span>
            <div class="item-wrapper">
                <!-- Remove temp-class after demo -->
                <a id="Contact" href="{{spcnDashboard}}" target="_blank" class="temp-class">
                    <img src="../assets/images/Desk/Desk_SPCN.png" height="175" width="175" />
                    <div class="text-block">
                        <se-icon style="
                      width: 15%;
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                      margin-top: 10%;
                      ">action_notification_default</se-icon>
                        <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[4].nameEn }}</p>
                        <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[4].nameCh }}</p>
                        <div>
                            <p>
                                <se-icon size="medium" style="padding-top:4%">action_notification_default</se-icon>
                                <span *ngIf="!portalTranslate" style="font-size: 15px;font-weight:600;font-family: Arial, Helvetica, 
                            sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[0]?.modules[4].nameEn
                                    }}</span>
                                <span *ngIf="portalTranslate" style="padding-top:2%;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, 
                            sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[0]?.modules[4].nameCh
                                    }}</span>
                            </p>
                        </div>
                        <p *ngIf="!portalTranslate"
                            style="text-align: left;margin-top: 9.5%;font-weight:normal;font-size:11px !important">{{
                            homeContentFromAPI[0]?.modules[4].descriptionEn }}
                        </p>
                        <p *ngIf="portalTranslate"
                            style="text-align: left;margin-top: 10%;font-weight:normal;font-size:8px !important">{{
                            homeContentFromAPI[0]?.modules[4].descriptionCh }}
                        </p>
                    </div>
                </a>
            </div>
        </span>
        <!-- Zero Carbon tile -->
        <span>
            <div class="item-wrapper">
                <!-- Remove temp-class after demo -->
                <a id="Contact" href="{{zeroCarbonDashboard}}" target="_blank" class="temp-class">
                    <img src="../assets/images/Desk/Desk_zerocarbonproject.png" height="175" width="175" />
                    <div class="text-block">
                        <se-icon style="
                      width: 15%;
                      display: block;
                      margin-left: auto;
                      margin-right: auto;
                      margin-top: 10%;
                      ">energy_efficiency</se-icon>
                        <p *ngIf="!portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[5].nameEn }}</p>
                        <p *ngIf="portalTranslate" class="context">{{ homeContentFromAPI[0]?.modules[5].nameCh }}</p>
                        <div>
                            <p>
                                <se-icon size="medium" style="padding-top:4%">energy_efficiency</se-icon>
                                <span *ngIf="!portalTranslate" style="font-size: 15px;font-weight:600;font-family: Arial, Helvetica, 
                            sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[0]?.modules[5].nameEn
                                    }}</span>
                                <span *ngIf="portalTranslate" style="padding-top:2%;font-size: 17px;font-weight:600;font-family: Arial, Helvetica, 
                            sans-serif;margin-left:5%;position:absolute">{{ homeContentFromAPI[0]?.modules[5].nameCh
                                    }}</span>
                            </p>
                        </div>
                        <p *ngIf="!portalTranslate"
                            style="text-align: left;margin-top: 9.5%;font-weight:normal;font-size:11px !important">{{
                            homeContentFromAPI[0]?.modules[5].descriptionEn }}
                        </p>
                        <p *ngIf="portalTranslate"
                            style="text-align: left;margin-top: 10%;font-weight:normal;font-size:8px !important">{{
                            homeContentFromAPI[0]?.modules[5].descriptionCh }}
                        </p>
                    </div>
                </a>
            </div>
        </span>
    </div>
</se-block>
<!-- <div class="surround20"
    style="margin-top: -0.8% !important;margin-left: 2.5% !important; width: 90%;padding-bottom: 5%; background-color: #fafafa;"
    *ngIf="eSourceClicked">
    <app-e-source [portalTranslate]="portalTranslate" (canCloseDialog)="onESourcingClick()"></app-e-source>
    </div> -->
<div class="surround20" style="margin-top: -3%; width:85.5%;padding-bottom: 5%; background-color:#fafafa"
    *ngIf="actionPlanClicked">
    <app-action-plan [portalTranslate]="portalTranslate" (canCloseDialog)="onActionPlanClicked()"></app-action-plan>
</div>