<div class="traffic-monitor-blk">
    <div class="d-flex flex-wrap">
        <button class="btn chartTabs" (click)="weeklyTraffic()" [disabled]="weekly">
            <span>{{'home.weeklyChart' | translate}}</span>
        </button>
        <button class="btn chartTabs" (click)="monthlyTraffic()" [disabled]="!weekly && !yearly">
            <span>{{'home.monthlyChart' | translate}}</span>
        </button>
        <button class="btn chartTabs" (click)="yearlyTraffic()" [disabled]="yearly">
            <span>{{'home.yearlyChart' | translate}}</span>
        </button>
        <button class="btn chartTabs">
            <a class="text-white"
                href="https://tableau.schneider-electric.com/views/SupplierPortalDashbaordVersion2/SupplierPortalDashboard"
                target="_blank">{{'home.tableau' | translate}}</a>
        </button>
    </div>
    <!-- dropdown for download excel -->
    <span class="desktopDropdown">
        <div [disabled]="weekDownloadUrl === ''" class="dropdown-icon">
            <se-icon size="small" class="action-download-icon">action_download_stroke</se-icon>
            <a href="{{weekDownloadUrl}}" class="download-extract">{{'home.downloadLoginExtract' |
                translate}}</a>
        </div>
        <select (change)="weekSelection($event)" style="height: 44px;" class="mobileSelect">
            <option style="width: 80%;" *ngFor="let week of dropDownWeekDetails" [value]="week.number"
                [selected]='selectedWeek == week.dates' [id]="week.number">{{week.dates}}</option>
        </select>
    </span>
    <div *ngIf="weekly">
        <div *ngIf="!portalTranslate">
            <kendo-chart [categoryAxis]='{ categories: categories }'>
                <kendo-chart-title text="Country Wise chart" font="20pt bold">
                </kendo-chart-title>
                <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [title]="{ text: 'Number of users logged in' }" [min]="0">
                    </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-legend position='bottom' orientation='horizontal'></kendo-chart-legend>
                <kendo-chart-series>
                    <kendo-chart-series-item [stack]="true" *ngFor='let item of seriesWeeklyDataNew' type='column'
                        [data]='item.data' [name]='item.nameEn' [color]="item.color">
                        <kendo-chart-series-item-tooltip>
                            <ng-template let-dataItem="dataItem" let-series="series">
                                <div>{{series.name}} : {{dataItem}}</div>
                            </ng-template>
                        </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                </kendo-chart-series>
            </kendo-chart>
        </div>
        <div *ngIf="portalTranslate">
            <kendo-chart [categoryAxis]='{ categories: categories }'>
                <kendo-chart-title text="Country Wise chart" font="20pt bold">
                </kendo-chart-title>
                <kendo-chart-value-axis>
                    <kendo-chart-value-axis-item [title]="{ text: '登录用户数量' }" [min]="0">
                    </kendo-chart-value-axis-item>
                </kendo-chart-value-axis>
                <kendo-chart-legend position='bottom' orientation='horizontal'></kendo-chart-legend>
                <kendo-chart-tooltip format='计数:{0}'>
                </kendo-chart-tooltip>
                <kendo-chart-series>
                    <kendo-chart-series-item [stack]="true" *ngFor='let item of seriesWeeklyDataNew' type='column'
                        [data]='item.data' [name]='item.nameCh' [color]="item.color">
                        <kendo-chart-series-item-tooltip>
                            <ng-template let-dataItem="dataItem" let-series="series">
                                <div>{{series.name}} : {{dataItem}}</div>
                            </ng-template>
                        </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                </kendo-chart-series>
            </kendo-chart>
        </div>
        <app-region-wise-pie-chart [data]="seriesWeeklyData"></app-region-wise-pie-chart>
    </div>
    <div *ngIf="Monthly">
        <app-traffic-monitoring-monthly [trafficMonthlyTranslate]="portalTranslate"></app-traffic-monitoring-monthly>
        <app-region-wise-pie-chart [data]="seriesmonthlyData"></app-region-wise-pie-chart>
    </div>
    <div *ngIf="yearly">
        <div class="yearly-chart">
        </div>
        <kendo-chart>
            <kendo-chart-title text="Country Wise chart" font="20pt bold">
            </kendo-chart-title>
            <kendo-chart-legend position='bottom' orientation='horizontal'></kendo-chart-legend>
            <kendo-chart-tooltip format="Count : {0}"> </kendo-chart-tooltip>
            <kendo-chart-category-axis>
                <kendo-chart-category-axis-item [categories]="[currentYearstring]">
                </kendo-chart-category-axis-item>
            </kendo-chart-category-axis>
            <kendo-chart-series>
                <ng-container *ngIf="!portalTranslate">
                    <kendo-chart-series-item type="column" *ngFor='let item of seriesYearlyData2022'
                        [color]="item.color" [data]="[item.count]" [name]="item.countryName">
                        <kendo-chart-series-item-tooltip>
                            <ng-template let-dataItem="dataItem" let-series="series" let-value="value"
                                let-category="category">
                                <div>{{series.name}} : {{dataItem}} </div>
                            </ng-template>
                        </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                </ng-container>
                <ng-container *ngIf="portalTranslate">
                    <kendo-chart-series-item type="column" *ngFor='let item of seriesYearlyData2022'
                        [color]="item.color" [data]="[item.count]" [name]="item.countryNameCh">
                        <kendo-chart-series-item-tooltip>
                            <ng-template let-dataItem="dataItem" let-series="series" let-value="value"
                                let-category="category">
                                <div>{{series.name}} : {{dataItem}} </div>
                            </ng-template>
                        </kendo-chart-series-item-tooltip>
                    </kendo-chart-series-item>
                </ng-container>
            </kendo-chart-series>
        </kendo-chart>
        <app-region-wise-pie-chart [data]="seriesYearlyData"></app-region-wise-pie-chart>
    </div>
</div>