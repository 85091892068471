export const environment = {
   production: false,
   currentConfig: 'preprod',
   region: 'us-east-2',
   endPoint: 'https://7sb6mpen4fhsllrw6ukgpjbt2i.appsync-api.us-east-2.amazonaws.com/graphql',
   apiKey: 'da2-g62vif3a4vf23nvlmw25fzwfla',
   IDMSLoginURL: 'https://secureidentity-uat.schneider-electric.com/identity/idplogin?app=SupplierPortalVendor&response_type=code&client_id=7b2245257da60f8829b1d463548b433445b34fa4d87c8687a389bda1fa2e16b457501700D7b0000004dBjEAI&redirect_uri=https://d24o0gzhmy9jih.cloudfront.net/',
   //  IDMSLoginURL_CHINA: 'https://identity-stg.schneider-electric.com/identity/idplogin?app=SupplierPortalVendor&response_type=code&client_id=7b2245257da60f8829b1d463548b433445b34fa4d87c8687a389bda1fa2e16b457501700D7b0000004dBjEAI&redirect_uri=https://test-ssp.se.com/',
   // IDMSLoginURL: 'https://secureidentity-uat.schneider-electric.com/identity/idplogin?app=SupplierPortalVendor&response_type=code&client_id=3MVG9MzBf_P2Sb150wiNlSTTcvykHemyEP08fAwETj2svrFfADn1DTEHQlvtDnqGIy3vYxry7SIfb3lHkVFKF&redirect_uri=https://test-ssp.se.com/',
   companyInformation: 'https://schneider-electric-pp.synertrade.com/sdl/supplier/myaccount.do',
   // logoutURL: 'https://secureidentity-uat.schneider-electric.com/identity/IDMS_IDPSloInit?RelayState=https://test-ssp.se.com/&app=SupplierPortalVendor'
   logoutURL: 'https://secureidentity-uat.schneider-electric.com/identity/IDMS_IDPSloInit?RelayState=https://d24o0gzhmy9jih.cloudfront.net/&app=SupplierPortalVendor',
   Contracts: 'https://schneider-electric-pp-idms.synertrade.com/sdl/contract/supplier/contracts.do',
   ActionPlan: 'https://schneider-electric-pp-idms.synertrade.com/sdl/srm/supplier/measures.do',
   Auctions: 'https://schneider-electric-pp-idms.synertrade.com/sdl/auction/supplier/auctions.do',
   MyAccountDocument: 'https://schneider-electric-pp.synertrade.com/sdl/supplier/myaccount.do',
   GeneralDocuments: 'https://schneider-electric-pp-idms.synertrade.com/sdl/dms/supplier/documents.do',
   SupplierQualification: 'https://schneider-electric-pp-idms.synertrade.com/sdl/supplier/additionainformation.do',
   CompanyInformation: 'https://schneider-electric-pp-idms.synertrade.com/sdl/supplier/myaccount.do',
   ESurvey: 'https://schneider-electric-pp-idms.synertrade.com/sdl/rfx/supplier/projects.do',
   performanceDashboard: 'https://schneider-electric-pp-idms.synertrade.com/sdl/supplier/reporting.do',
   editProfileEN: 'https://uatbfo22-secommunities.cs197.force.com/identity/UserProfile?app=SupplierPortalVendor',
   editProfileCH: 'https://identity-stg.schneider-electric.com/identity/UserProfile?app=SupplierPortalVendor',
   zeroCarbonUrl: 'https://hub-pre.zeigo.com/idms-sso-login',
   SPCNUrl: 'https://schneider-electric-pp-idms.synertrade.com/sdl/srm/supplier/measures.do',
   coupaMyAccount: 'https://schneider-electric.coupahost.com/user/home',
   supplyOnMyAccount: 'https://webedi.application.prd.supplyon.com/dashboard/#/dashboards/1',
   coInnovationAccount: 'https://se.yoomap.pub',
   i2pUrl: 'https://se--uatbfo22.sandbox.my.site.com/ExtranetI2PLightning',
};