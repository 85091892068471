import autoMajorUnit from '../../core/utils/auto-major-unit';
import { valueOrDefault } from '../../common';
import calculateSlope from './calculate-slope';
import scatterValueGetter from './scatter-value-getter';
function scatterLinearTrendLine(context) {
  const {
    options,
    seriesValues
  } = context;
  const data = getData({
    seriesValues,
    options
  });
  if (data) {
    return Object.assign({}, options, {
      type: 'scatterLine',
      data
    });
  }
  return null;
}
function getData({
  seriesValues,
  options
}) {
  let {
    slope,
    intercept,
    count,
    xMin,
    xMax
  } = calculateSlope(seriesValues(), scatterValueGetter(options.field));
  if (count > 0) {
    const data = [];
    const forecast = (options.trendline || {}).forecast;
    if (forecast) {
      if (forecast.before > 0) {
        xMin -= forecast.before;
      }
      if (forecast.after > 0) {
        xMax += forecast.after;
      }
    }
    const samplingInterval = (options.trendline || {}).samplingInterval;
    let delta = valueOrDefault(samplingInterval, autoMajorUnit(xMin, xMax) / 10);
    if (samplingInterval <= 0) {
      delta = xMax - xMin;
    }
    for (let x = xMin; x <= xMax; x += delta) {
      data.push([x, slope * x + intercept]);
    }
    return data;
  }
  return null;
}
export default scatterLinearTrendLine;