import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { VendorService } from '../../../vendor.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-user-guides-scm',
  templateUrl: './user-guides-scm.component.html',
  styleUrl: './user-guides-scm.component.scss'
})
export class UserGuidesScmComponent implements OnInit, OnChanges {

  @Input() portalTranslate: any;
  getRepoList: any;
  repoURL: any;
  key: any;
  showDafaults = true;
  selectedTemplate = '';

  constructor(public translate: TranslateService, public _service: VendorService, private router: Router) { }
  ngOnInit(): void {
    this._service.getRepoData();
  }

  ngOnChanges(): void {
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  getInnerHTML(val: string) {
    return val.replace(/\r?\n|\r/g, '<br/>');
  }

  onOpenUrl(url: string) {
    window.open(url);
  }

  onDeliveryClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'delivery';
  }

  ongenralFunClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'genralfunctions';
  }

  onOrderClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'order';
  }

  onRepositoryClick(e: any) {
    this.showDafaults = !this.showDafaults;
    this.selectedTemplate = 'repository';
  }

}
