<div class="container">
    <div class="row" *ngIf="!noDeliveryData || supplyOnDropdown?.length">
        <div style="margin-left: 5%; padding-bottom: 3%;">
            <button class="btn" [disabled]="asnScmButton || noDeliveryData" style="background-color: #3dcd58 !important;border:#3dcd58 !important;color:#ffffff;margin-right: 20px;
             margin-left: -10px;
             "><span *ngIf="!portalTranslate">Supply Chain Management</span><span *ngIf="portalTranslate">供应链管理
                </span></button>
        </div>
    </div>
    <div style="text-align: center; margin-bottom: 5%; color:#8c8686; font-size: 32px;" *ngIf="noDeliveryData">No data
        to
        display
    </div>
    <div style="text-align:left;margin-bottom: 2%;" *ngIf="!noDeliveryData || supplyOnDropdown?.length">
        <div style="float: left;padding-right:20px;padding-top:4px">Select Company</div>
        <kendo-dropdownlist [style.width.px]="300" [data]="supplyOnDropdown" valueField="sma" textField="com"
            [(ngModel)]="supplyOnDefaultDL" (valueChange)="supplyOnDropdownList($event)"
            [valuePrimitive]="true"></kendo-dropdownlist>
    </div>
    <div class="box">
        <div class="row">
            <div style="margin-left: 15px; margin-bottom: 15px;">
                <span>
                    <ul class="list-group" style="width:300px">
                        <li class="list-group-item"><b>{{ "home.advanceShipping" | translate }}</b>
                            <span style="margin-left: 15%;"> <span class="k-icon k-i-arrow-60-right"></span> </span>
                        </li>
                        <div class="list-group-item" style="color:#33A2FF">
                            <a href="{{asnCreationDueLink}}" target="_blank">
                                <span
                                    style="font-size:20px;font-weight:bold;color:#007bff">{{asnCreationDue}}</span></a>
                            <span style="margin-left: 46%;">
                                <a href="{{asnPendingVauleLink}}" target="_blank">
                                    <span
                                        style="font-size:20px;font-weight:bold;color:#007bff">{{asnPendingVaule}}</span></a>
                            </span>
                            <div style="color: black;">ASN Creation Due<span style="margin-left: 10%;">Pending
                                    ASN</span>
                            </div>
                        </div>
                        <div class="list-group-item">
                            <b>{{ "home.dueDeliveries" | translate }} </b>
                            <div class="container">
                                <a href="{{overDueAsnLink}}" target="_blank">
                                    <div class="progress" style="height:10px; margin-top: 15px;">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="overDueAsnBar"
                                            aria-valuemin="20" aria-valuemax="100" [style.width.%]="overDueAsnBar"
                                            style="background-color:#ff1076">
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div>
                                <div style="float:left;font-size: 14px;">Overdue</div>
                                <div style="float:right;">
                                    <span style="color: #007bff;"><a href="{{overDueAsnLink}}" target="_blank">
                                            <b>{{overDueASN}}</b></a></span>
                                </div>
                            </div>
                            <br />
                            <div class="container">
                                <a href="{{dueTodayAsnLink}}" target="_blank">
                                    <div class="progress" style="height:10px; margin-top: 15px;">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="overDueAsnBar"
                                            aria-valuemin="20" aria-valuemax="100" [style.width.%]="dueTodayAsnBar"
                                            style="background-color:#ffc107">
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div>
                                <div style="float:left;font-size: 14px;">Due Today</div>
                                <div style="float:right;"><span style="color: #007bff;"><a href="{{dueTodayAsnLink}}"
                                            target="_blank">
                                            <b>{{dueTodayASN}}</b></a></span></div>
                            </div>
                            <br />
                            <div class="container">
                                <a href="{{dueNext7DaysAsnLink}}" target="_blank">
                                    <div class="progress" style="height:10px;margin-top: 15px;">
                                        <div class="progress-bar" role="progressbar" aria-valuenow="overDueAsnBar"
                                            aria-valuemin="20" aria-valuemax="100" [style.width.%]="dueNext7DaysAsnBar"
                                            style="background-color:#007bff">
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div>
                                <div style="float:left;font-size: 14px;">Due Next 7 Days</div>
                                <div style="float:right;"><span style="color: #007bff;"><a
                                            href="{{dueNext7DaysAsnLink}}"
                                            target="_blank"><b>{{dueNext7DaysASN}}</b></a></span></div>
                            </div>
                        </div>
                    </ul>
                </span>
            </div>
        </div>
        <!-- <div class="row" *ngIf="asnProcureToPayButton">
    <div  style="width:100%;margin-left:5%; margin-right: 5%;">
      <div  style="margin-left: 16%; width:350px;margin-bottom: 30px;"></div>
      <span>
      <b>Procure to Pay data will display here</b>
      
      </span>
      </div>
      </div> -->
    </div>
</div>