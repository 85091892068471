
import { Component, Input, OnInit, ViewChild, HostListener, Renderer2, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { VendorService } from '../../vendor.service';
import { SortDescriptor } from '@progress/kendo-data-query';
import { environment } from '../../../environments/environment';
import { GridComponent } from '@progress/kendo-angular-grid';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { model } from '../../Traffic';

const hasClass = (el: { className: string; }, className: string | RegExp) => new RegExp(className).test(el.className);

const isChildOf = (el: { parentElement: any; }, className: string) => {
  while (el && el.parentElement) {
    if (hasClass(el.parentElement, className)) {
      return true;
    }
    el = el.parentElement;
  }
  return false;
};

@Component({
  selector: 'app-admin-hometable',
  templateUrl: './admin-hometable.component.html',
  styleUrls: ['./admin-hometable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})

export class AdminHometableComponent implements OnInit {
  public formGroup: FormGroup | undefined;
  public HomeFormGroup: FormGroup | undefined;
  public view: any[] = [];
  uploadSaveUrl = "saveUrl"; // should represent an actual API endpoint
  uploadRemoveUrl = "removeUrl"; // should represent an actual API endpoint
  public opened = false;
  public userPortalData: any;
  public userContactData: any;
  public contactData: any;
  public portalData: any;
  public portalpData: any;
  public userPersonaPortalUpdateData: any;
  public newForm!: FormGroup;
  public contactForm!: FormGroup;
  public userPersonaContactUpdateData: any;
  public userPersonaContactTableAPIData: any;
  public showEditButton = true;
  public companyDynamicDataFlag: boolean = false;
  public contractDynamicDataFlag: boolean = false;
  public emptyImage = false;
  public barColor: any;
  public supplyOnData: any;
  public orderNewValue: any;
  public newUpdatesValue: any;
  public procureToPay: boolean = false;
  public SCM: boolean = true;
  public asnPendingVauleLink: any;
  public asnPendingVaule: any;
  public value = 0;
  public indeterminate = false;
  public barWidth: number = 20;
  public newOrderBarValue: any;
  public orderUpdateBarValue: any;
  public orderPartlyConfirmedBarValue: any;
  public orderOpenBarVaule: any;
  public orderDateBarValue: any;
  public asnCreationDue: any;
  public dueNext7DaysAsnBar: any;
  public overDueAsnBar: any;
  public dueTodayAsnBar: any;
  public asnCreationDueLink: any;
  public overDueAsnLink: any;
  public dueTodayAsnLink: any;
  public dueNext7DaysAsnLink: any;
  public seriesYearlyData2022: any;
  public count: any;
  public year: any;
  public yearlyBarColor: any;
  public seriesYearlyData2021: any;
  public seriesYearlyData2020: any
  public count2021: any;
  public count2022: any;
  public count2020: any;
  public editedValue: any;
  public homeEditSave = false;
  public editPPsave = false;
  public csTablePopupMsg = false;
  public startIndex: any;
  public endIndex: any;
  public fileName: any;
  public fileNameEn: any;
  public supplyOnDropdown: any;
  public supplyOnDropdownData: any;
  public id: any;
  public supplyOnDL: any[] = [];
  public supplyOnDefaultDL: any;
  send_date = new Date();
  formattedDate: any;
  public myGenValue: any;
  public noData = false;
  public singleDataValue: any;
  public noDeliveryData: any;
  public order: any;
  public noOrderData: any;
  public delivery: any;
  public noDelivery: any;
  public deliveryProcureToPay: any;
  public singleArrayValue: any;
  public coupaDropdown: any;
  public coupaDropdownId: any;
  public coupaTableDataValue: any;
  public role: any;

  createTableForm = (dataItem: { id: any; order: any; application: any; supportEmail: any; module: any; }) =>
    new FormGroup({
      id: new FormControl(dataItem.id),
      order: new FormControl(dataItem.order),
      application: new FormControl(dataItem.application, Validators.required),
      supportEmail: new FormControl(dataItem.supportEmail, Validators.required),
      module: new FormControl(dataItem.module, Validators.required)
    });

  createHomeTableForm = (dataItem: { descriptionCh: any; descriptionEn: any; nameCh: any; nameEn: any; order: any; uid: any; }) =>
    new FormGroup({
      descriptionCh: new FormControl(dataItem.descriptionCh, Validators.maxLength(300)),
      descriptionEn: new FormControl(dataItem.descriptionEn, Validators.maxLength(300)),
      nameCh: new FormControl(dataItem.nameCh, Validators.maxLength(25)),
      nameEn: new FormControl(dataItem.nameEn, Validators.maxLength(25)),
      order: new FormControl(dataItem.order),
      uid: new FormControl(dataItem.uid)
    });

  myForm = new FormGroup({
    name: new FormControl('', [Validators.required, Validators.minLength(3)]),
    file: new FormControl('', [Validators.required]),
    fileSource: new FormControl('', [Validators.required])
  });

  public userMyaccountData: any;
  public tooltipGridMyaccountValue: any;
  public userGeneralData: any;
  public tooltipGridGeneralValue: any;
  public companyDynamicDataview: any;
  public companyDynamicData: any;
  public companyDynamicDatalastUpdate: any;
  public myAccValue: any;
  public myAccValueData: boolean = false;
  public generalValueData: boolean = false;
  public companyDynamicDataviewMessage: any;
  public seriesWeeklyData: model[] = [];
  public seriesMonthlyData: model[] = [];
  userContactUpdateData: any;
  userPortalDataLatest: any;
  portalDetails: any;
  contactDetails: any;
  portalpDataEN: any;
  portalpDataCH: any;
  contactNewDataEn: any;
  contactNewDataCh: any;
  baseImageURL!: string | ArrayBuffer | null;
  preSignedEn: any;
  preSignedCh: any;
  fileSize: any;
  userPersonaSurveyData1: any;


  public get isInEditingMode(): boolean {
    return this.editedRowIndex !== undefined || this.isNew;
  }

  constructor(private fb: FormBuilder, public translate: TranslateService, public router: Router, private _service: VendorService, private formBuilder: FormBuilder, private renderer: Renderer2, private ref: ChangeDetectorRef) {

    this.send_date.setMonth(this.send_date.getMonth());
    this.formattedDate = this.send_date.toISOString().slice(0, 4);
  }

  public flag = false;
  public userPersona: any;
  public userPersonaEsourceingData: any[] = [];
  public userPersonaSurveyData: any[] = [];
  public personaOne = true;
  public personaChips = true;
  public personaTwo = false;
  public personaThree = false;
  public sspsrm = true;
  public sspinego = true;
  public personafour = false;
  public personafive = false;
  public personasix = false;
  public personaseven = false;
  public finalEsource = [];
  public pageSize = 10;
  public skip = 0;
  public portalImage = false;
  public loggedInlanguage: any;
  public loggedInCode: any;
  public tooltipGridData: any;
  public tooltipGridValue: any;
  public tooltipGridContractValue: any;
  public pClicked = false;
  public isSupplierDropdownShown = false;
  public isHomeDropdownShown = false;
  public isHomeDropdownPortal = false;
  public showMobileScreenNav = true;
  public eSourcingsspClicked = false;
  public eSourcinginegoClicked = false;
  public eSurveyClicked = false;
  public eSurveySSPClicked = false;
  public eSourcingpersonaClicked = true;
  public companyClicked = false;
  public contractClicked = false;
  public myaccountClicked = false;
  public mygeneralClicked = false;
  public orderClicked = false;
  public confirmorderClicked = false;
  public confirmDeliveryClicked = false
  public deliveryClicked = false;
  public confirmPartlyClicked = false;
  public confirmOpenClicked = false;
  public confirmDueClicked = false;
  public confirmNextClicked = false;
  public confirmNextValueClicked = false;
  public confirmDueValueClicked = false;
  public weekly = true;
  public Monthly = false;
  public yearly = false;
  public color: any;
  public imageExist!: boolean;
  public overWrite: any;
  public orderUnconfirmedVaule: any;
  public withOutUpdateValue: any;
  public orderDatedVaule: any;
  public partlyConfirmedVaule: any;
  public orderOpenVaule: any;
  public orderUnconfirmedVauleLink: any;
  public withOutUpdateValueLink: any;
  public orderDatedVauleLink: any;
  public partlyConfirmedVauleLink: any;
  public orderOpenVauleLink: any;
  public orderNewValueLink: any;
  public newUpdatesValueLink: any;
  public overDueASN: any;
  public dueTodayASN: any;
  public dueNext7DaysASN: any;
  public asnScmButton = true;
  public asnProcureToPayButton = false;
  public cspopupmsg = false;
  public dropDownlistData: any;


  public userName: any;
  public sort: SortDescriptor[] = [
    {
      field: 'End_date',
      dir: 'asc'
    }
  ];
  public logoutURL = '';
  public IDMSPersona = '';
  public code = '';
  public eSourcingLatestCount = '0';
  public surveyLatestCount = '0';
  public mySelection: any[] = [];
  public homeContentFromAPI: any = [];
  public AdminHome = [];
  public AdminHomeS2C = [];
  public AdminHomeSSPSRM = [];
  public AdminHomeP2P = [];
  public AdminHomeCOI = [];
  public AdminHomeI2P = [];
  public AdminHomeSupplyOn = [];
  public selectedTab = 'Supplier Relationship Management';
  private editedRowIndex: any;
  private docClickSubscription: any;
  private isNew = false;
  public isUsernameDropdown = false;
  public isnormalView = true;
  public isadminView = false;
  public actionPlanClicked = false;
  public ActionPlanData: any;
  public supplierQualificationClicked = false;
  public SupplierQualificationData: any;
  public portalTranslate!: boolean;
  @ViewChild(GridComponent)
  private grid: GridComponent | undefined;
  @HostListener('window:scroll', ['$event'])
  @Input() adminHomeTableTranslate!: boolean;


  scrollHandler(event: any) {
    this.onClick();
  }

  public close(): void {
    this.opened = false;
    this.emptyImage = false;
    this.imageExist = false;
    this.homeEditSave = false;
    this.editPPsave = false;
    this.cspopupmsg = false;
    this.csTablePopupMsg = false;
  }


  ngOnChanges() {
    this.portalTranslate = this.adminHomeTableTranslate;
    if (this.portalTranslate) {
      this.translate.use('cn')
    } else {
      this.translate.use('en');
    }
  }

  ngOnInit(): void {
    //  this._service.getHomePageContent();
    this.renderer.listen('document', 'click', ({ target }) => {
      if (!isChildOf(target, 'k-grid')) {

      }
    });

    this.docClickSubscription = this.renderer.listen(
      'document',
      'click',
      this.onDocumentClick.bind(this)
    );
    if (sessionStorage.getItem('UserLoggedInDetails')) {
      let loggedInDetails: any;
      loggedInDetails = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
      if (loggedInDetails && loggedInDetails) {
        this.IDMSPersona = loggedInDetails.persona;
        this.userName = loggedInDetails.firstName + ' ' + loggedInDetails.lastName;
        this.code = sessionStorage.getItem('Idmscode') || '';
      }

    }
    else {
      // this._service.isUserLoggedIn();
      this._service.IdmsInfo.subscribe((data) => {
        if (data) {
          let loggedInDetails: any;
          loggedInDetails = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
          if (loggedInDetails && loggedInDetails) {

            this.IDMSPersona = loggedInDetails.persona;
            this.userName = loggedInDetails.firstName + ' ' + loggedInDetails.lastName;
            this.code = sessionStorage.getItem('Idmscode') || '';
            this.role = loggedInDetails.portalUserType;
          }
        }
      });

    }


    this.loggedInlanguage = JSON.parse(sessionStorage.getItem('UserLoggedInDetails') || '{}');
    this.role = this.loggedInlanguage.portalUserType;

    // this.logoutURL = environment.logoutURL;
    // this.translate.addLangs(['en', 'cn']);
    // this.translate.setDefaultLang(this.loggedInCode);
    // const browserLang = this.translate.getBrowserLang();
    // this.translate.use(browserLang.match(/en|cn/) ? browserLang : this.loggedInCode);

    setTimeout(() => {
      this.showMobileScreenNav = false;
    }, 10000);


    this._service.getHomeContentAPI.subscribe((data) => {
      if (data) {
        this.homeContentFromAPI = JSON.parse(sessionStorage.getItem('homeContent') || '{}');
        this.AdminHomeI2P = this.homeContentFromAPI[4].modules;
        this.AdminHomeCOI = this.homeContentFromAPI[5].modules;
        this.AdminHomeS2C = this.homeContentFromAPI[3].modules;
        this.AdminHomeSSPSRM = this.homeContentFromAPI[0].modules;
        this.AdminHomeP2P = this.homeContentFromAPI[2].modules;
        this.AdminHomeSupplyOn = this.homeContentFromAPI[1].modules;
        this.tabClick(1);
      }
    });

  }


  getInnerHTML(val: string) {
    return val.replace(/\r?\n|\r/g, '<br/>');
  }

  public tabClick(tabNum?: number): void {
    if (tabNum === 1) {
      this.AdminHome = this.AdminHomeS2C;
      this.selectedTab = 'Source to Contract';
    } else if (tabNum === 2) {
      this.AdminHome = this.AdminHomeSSPSRM;
      this.selectedTab = 'Supplier Relationship Management';
    } else if (tabNum === 3) {
      this.AdminHome = this.AdminHomeP2P;
      this.selectedTab = 'Procure to Pay';
    } else if (tabNum === 4) {
      this.AdminHome = this.AdminHomeSupplyOn;
      this.selectedTab = 'Supply Chain Management';
    } else if (tabNum === 5) {
      this.AdminHome = this.AdminHomeCOI;
      this.selectedTab = 'Co-Innovation';
    } else if (tabNum === 6) {
      this.AdminHome = this.AdminHomeI2P;
      this.selectedTab = 'I2P';
    } else {
      this.AdminHome = this.AdminHomeS2C;
      this.selectedTab = 'Source to Contract';
    }
  }

  public addHandlerHome(): void {
    this.closeEditorHome();

    this.HomeFormGroup = this.formBuilder.group({
      descriptionCh: ["", [Validators.required, Validators.maxLength(300)]],
      descriptionEn: ["", [Validators.required, Validators.maxLength(300)]],
      nameCh: ["", [Validators.required, Validators.maxLength(25)]],
      nameEn: ["", [Validators.required, Validators.maxLength(25)]],
      order: ["", [Validators.required]],
      uid: [""]
    });
    this.isNew = true;
    if (this.grid) {
      this.grid.addRow(this.HomeFormGroup);
    }
  }

  public cellClickHandler({ isEdited, dataItem, rowIndex }: { isEdited: boolean, dataItem: object, rowIndex: number }): void {
    if (isEdited || (this.HomeFormGroup && !this.HomeFormGroup.valid)) {
      return;
    }

    if (this.isNew) {
      rowIndex += 1;
    }

    this.saveCurrentHome();

    this.HomeFormGroup = this.formBuilder.group(dataItem);
    this.editedRowIndex = rowIndex;
    if (this.grid) {
      this.grid.editRow(rowIndex, this.HomeFormGroup);
    }
  }

  private onDocumentClick(e: any): void {

  }

  public scroll(event: any, scrollItem: string): void {
    if (scrollItem) {
      const element = document.getElementById(scrollItem) as HTMLElement;

      element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
    }
  }

  public chipSelect(chip: any): void {
    const eles = document.getElementById("chip-nav-btns") as HTMLElement;
    Array.from(eles.children).forEach((scroll) => {
      scroll.setAttribute('class', '');
    });
    const triggerEle = `${chip}`;
    const triggerElement = document.getElementById(triggerEle) as HTMLElement;
    if (triggerElement) {
      triggerElement.setAttribute('class', 'active');
    }
  }


  public onPageChange(state: any): void {
    this.pageSize = state.take;
  }


  public dataClear(): void {
    sessionStorage.clear();
  }

  public onClick(): void {
    this.isUsernameDropdown = false;
    this.isSupplierDropdownShown = false;
    this.isHomeDropdownShown = false;
    this.isHomeDropdownPortal = false;

  }

  public onActivate(): void {
    window.scroll({
      top: 800,
      left: 100,
      behavior: 'smooth'
    });
  }

  public saveRowHome(): void {
    if (this.HomeFormGroup && this.HomeFormGroup.valid) {
      this.saveCurrentHome();
    }
  }

  public cellClickHandlerHome({ isEdited, dataItem, rowIndex }: any): void {
    if (isEdited || (this.HomeFormGroup && !this.HomeFormGroup.valid)) {
    }

    if (this.isNew) {
      rowIndex += 1;
    }

    this.cancelHandlerHome();
    this.HomeFormGroup = this.createHomeTableForm(dataItem);
    this.editedRowIndex = rowIndex;
    if (this.grid) {
      this.grid.editRow(rowIndex, this.HomeFormGroup);
    }
  }

  public cancelHandlerHome(): void {
    this.closeEditorHome();
  }

  private closeEditorHome(): void {
    if (this.grid) {
      this.grid.closeRow(this.editedRowIndex);
    }
    this.isNew = false;
    this.editedRowIndex = undefined;
    this.HomeFormGroup = undefined;
  }

  public saveCurrentHome(): void {
    if (this.HomeFormGroup && this.HomeFormGroup?.controls['descriptionEn'].value !== "" && this.HomeFormGroup?.controls['descriptionCh'].value !== "" && this.HomeFormGroup?.controls['nameEn'].value !== "" && this.HomeFormGroup?.controls['nameCh'].value !== ""
      && this.HomeFormGroup?.controls['nameEn'].value.length < 25 && this.HomeFormGroup?.controls['nameCh'].value.length < 25 && this.HomeFormGroup?.controls['descriptionEn'].value.length <= 300 && this.HomeFormGroup?.controls['descriptionCh'].value.length <= 300 &&
      this.HomeFormGroup?.controls['uid'].value) {
      const value = this.HomeFormGroup;
      if (value) {
        sessionStorage.setItem('updateHomeTable', JSON.stringify(value.value));
        this.homeEditSave = true;
      }
      this._service.setHomeContent(this.selectedTab);
      this._service.setHomeContentAPI.subscribe(data => {
        if (data) {
          this.homeContentFromAPI = JSON.parse(sessionStorage.getItem('homeContent') || '{}');
          this.AdminHomeI2P = this.homeContentFromAPI[4].modules;
          this.AdminHomeCOI = this.homeContentFromAPI[5].modules;
          this.AdminHomeS2C = this.homeContentFromAPI[3].modules;
          this.AdminHomeSSPSRM = this.homeContentFromAPI[0].modules;
          this.AdminHomeP2P = this.homeContentFromAPI[2].modules;
          this.AdminHomeSupplyOn = this.homeContentFromAPI[1].modules;
          this.ref.detectChanges();
          if (this.selectedTab === 'Source to Contract') {
            this.tabClick(1);
          }
          else if (this.selectedTab === 'Supplier Relationship Management') {
            this.tabClick(2);
          }
          else if (this.selectedTab === 'Procure to Pay') {
            this.tabClick(3);
          }
          else if (this.selectedTab === 'Supply Chain Management') {
            this.tabClick(4);
          }
          else if (this.selectedTab === 'Co-Innovation') {
            this.tabClick(5);
          }
          else if (this.selectedTab === 'I2P') {
            this.tabClick(6);
          }
          else {
            this.tabClick(1);
          }

        }
      });
      this.closeEditorHome();
    }
  }

  get f() {
    return this.myForm.controls;
  }

  public preSigned: any;

  public ngOnDestroy(): void {
    this.docClickSubscription();
  }

}

