<div id="newposition" class="sticky fixed-top bg-white">
    <se-block-content *ngIf="showMobileScreenNav" class="text-center text-varient">
        <div class="surround20 py-3">
            <div *ngIf="!portalTranslate" class="text px-7">
                {{ "home.bannerInfo" | translate }}
            </div>
            <div *ngIf="portalTranslate" class="text px-30">
                欢迎来到施耐德供应商门户
            </div>
        </div>
    </se-block-content>
</div>