<se-block class="tab-panel" id="tabPanel1" style="margin-top: -1.5%">
    <div class="tableArrow"></div>
    <se-table height="500px" class="tableVew block-content-border">
        <p class="deleteIcon">
            <se-icon class="delete-icon-attrb" (click)="onAuctionCancelClicked()">action_delete_cross</se-icon>
        </p>
        <p class="tableHeader mobileFont">
            <span *ngIf="!portalTranslate">{{ 'home.openAuctions' | translate }}</span>
            <span *ngIf="portalTranslate">公开拍卖</span>
        </p>
        <div class="tableContent desktopTable">
            <div class="float-end pb-2">
                <a class="buttonAnchor text-white" kendoTooltip tooltipClass="my-class" position="top"
                    title="{{Auctions}}" href="{{Auctions}}" target="_blank">
                    <se-button *ngIf="!portalTranslate" color="primary"> {{ 'home.auctions' | translate }}
                    </se-button>
                    <se-button *ngIf="portalTranslate" color="primary"> 所有行动计划 </se-button>
                </a>
            </div>
            <div class="table-view">
                <kendo-grid [kendoGridBinding]="AuctionData" [sortable]="true" [height]="530" class="k-button-group"
                    [pageSize]="pageSize" [skip]="skip" [sort]="sort" [pageable]="true"
                    (pageChange)="onPageChange($event)" class="mobileGrid">
                    <kendo-grid-column field="Auction_ID" title="{{ 'home.auctionId' | translate }}"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="padding-left: 10px" *ngIf="!portalTranslate">{{ 'home.auctionId' | translate
                                }}</span>
                            <span style="padding-left: 10px" *ngIf="portalTranslate">拍卖 ID</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-gridView>
                            <a *ngIf="gridView.DeepLink !== null" href="{{gridView.DeepLink}}" target="_blank">
                                <span style="width:20%; padding-left: 10px;color: #33A2FF;">{{ gridView.Auction_ID }}
                                </span>
                            </a>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Auction_Name" *ngIf="!portalTranslate"
                        title="{{ 'home.auctionName' | translate }}" [headerClass]="'gridColumnStyle'">
                    </kendo-grid-column>
                    <kendo-grid-column field="Start_Date" title="{{ 'home.startDate' | translate }}"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="padding-left: 10px" *ngIf="!portalTranslate">{{ 'home.startDate' | translate
                                }}</span>
                            <span style="padding-left: 10px" *ngIf="portalTranslate">开始日期</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-gridView>
                            <!-- {{ gridView.Deadline | date: 'dd MMM yyyy' }} -->
                            <span style=" padding-left: 10px;">{{ gridView.Start_Date | date: 'dd MMM yyyy' }}
                            </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="End_Date" title="{{ 'home.endDate' | translate }}"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="padding-left: 10px" *ngIf="!portalTranslate">{{ 'home.endDate' | translate
                                }}</span>
                            <span style="padding-left: 10px" *ngIf="portalTranslate">结束日期</span>
                        </ng-template>
                        <ng-template kendoGridCellTemplate let-gridView>
                            <!-- {{ gridView.Deadline | date: 'dd MMM yyyy' }} -->
                            <span style=" padding-left: 10px;">{{ gridView.End_Date | date: 'dd MMM yyyy' }} </span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Auc_Status" title="{{ 'home.status' | translate }}"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="padding-left: 25px">{{ 'home.status' | translate }}</span>
                        </ng-template>
                    </kendo-grid-column>
                    <kendo-grid-column field="Project_Leader" title="{{ 'home.projectLeader' | translate }}"
                        [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="margin-left: 2px !important" *ngIf="!portalTranslate">{{ 'home.projectLeader' |
                                translate }}</span>
                            <span style="margin-left: 2px !important" *ngIf="portalTranslate">项目负责人</span>
                        </ng-template>
                    </kendo-grid-column>
                    <!-- <kendo-grid-column field="Email_ID__of_responsible_user"
                        title="{{ 'home.auctionEmail' | translate }}" [headerClass]="'gridColumnStyle'">
                        <ng-template kendoGridHeaderTemplate let-gridView>
                            <span style="margin-left: 2px !important" *ngIf="!portalTranslate">{{ 'home.auctionEmail' |
                                translate }}</span>
                            <span style="margin-left: 2px !important" *ngIf="portalTranslate">电子邮件 ID</span>
                        </ng-template>
                    </kendo-grid-column> -->
                    <ng-template kendoGridNoRecordsTemplate>
                        <span *ngIf="!portalTranslate">There is currently no auctions data.</span>
                        <span *ngIf="portalTranslate">目前没有行动计划.</span>
                    </ng-template>
                </kendo-grid>
            </div>
        </div>
    </se-table>
</se-block>