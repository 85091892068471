<!-- <ng-container *ngIf="showDafaults; else showOther"> -->
<!-- <div class="container px-5">
    <div class="" style="
           /* background-color: white; */
           color: #2dcd58;
           font-size: 25px;
           padding-top: 2.5% !important;
           font-family: Nunito;
           background-color: #fafafa;
           ">
        <span> {{ "home.PortalPrinciple" | translate }}</span>
    </div>
    <div style="width: 100%; padding-top: 2%; margin-left: 0%;padding-bottom: 4.5%;">
        <div style="background-color: #fafafa;">
            <div style="background-color: #fafafa;">
                <span *ngIf="!portalTranslate" [innerHTML]="getInnerHTML(portalpDataEN)"> </span>
                <span *ngIf="portalTranslate" [innerHTML]="getInnerHTML(portalpDataCH)"> </span>
            </div>
            <br />
            <br />
            <div style="background-color: #fafafa; border: 1px solid black;">
                <video id="myVideo" preload="auto" controls controlsList="nodownload" style="width: 100%">
                    <source src="{{ppVideo}}" type="video/mp4">
                </video>
            </div>
        </div>
    </div> -->
<!-- <ngb-carousel *ngIf="images" class="pt-4">
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <div class="bg-dark"></div>
                <img [src]="images[0]" alt="Random first slide">
            </div>
            <div class="carousel-caption">
                <p class="slider-content" (click)="openVerticallyCentered(content)">Nulla vitae elit libero, a
                    pharetra augue mollis interdum.</p>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <div class="bg-dark"></div>
                <img [src]="images[1]" alt="Random second slide">
            </div>
            <div class="carousel-caption">
                <p class="slider-content" (click)="openVerticallyCentered(content)">Lorem ipsum dolor sit amet,
                    consectetur adipiscing elit.</p>
            </div>
        </ng-template>
        <ng-template ngbSlide>
            <div class="picsum-img-wrapper">
                <div class="bg-dark"></div>
                <img [src]="images[2]" alt="Random third slide">
            </div>
            <div class="carousel-caption">
                <p class="slider-content" (click)="openVerticallyCentered(content)">Praesent commodo cursus magna,
                    vel scelerisque nisl consectetur.</p>
            </div>
        </ng-template>


        <ng-template #content let-modal>
            <div class="modal-header">
                <h4 class="modal-title">Supplier Portal New Update</h4>
                <button type="button" class="btn-close" aria-label="Close"
                    (click)="modal.dismiss('Cross click')"></button>
            </div>
            <div class="modal-body">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                    labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco
                    laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in
                    voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat
                    non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.&hellip;</p>
            </div>
        </ng-template>
    </ngb-carousel>
    <div class="surround20" style="
           /* background-color: white; */
           color: #2dcd58;
           font-size: 25px;
           padding-top: 3% !important;
           font-family: Nunito;
           background-color: #fafafa;
           font-weight: 500;
           ">
        <span>SSP-SRM</span>
    </div>
    <div class="row">
        <div class="col-md-2">
            <div class="card-group">
                <div class="card bg-transparent border-0" (click)="onKnldgClick($event)">
                    <img src="../assets/images/Folder_Icon.png" class="card-img-top">
                    <div class="card-body p-0">
                        <h6 class="card-title pl-2">User Guides</h6>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="card-group">
                <div class="card bg-transparent border-0" (click)="communicationClick($event)">
                    <img src="../assets/images/Folder_Icon.png" class="card-img-top">
                    <div class="card-body p-0">
                        <h6 class="card-title pl-2">Communications</h6>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</ng-container>
<ng-template #showOther>
    <div class="container">
        <div class="row">
            <div class="col-md-12 my-5">
                <a (click)="showDafaults = !showDafaults">
                    << Back</a>
                        <app-user-guides-repo></app-user-guides-repo>
            </div>
        </div>
    </div>
</ng-template> -->


<!-- 360 Cockpit Design -->

<div class="supplier-cockpit">
    <div class="container">
        <div class="row mb-4">
            <div class="col-md-12">
                <h4 class="main-heading">New Today</h4>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-4">

            </div>
            <div class="col-lg-4">
                <ngb-carousel *ngIf="images">
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                            <div class="bg-dark"></div>
                            <img [src]="images[0]" alt="Random first slide">
                        </div>
                        <div class="carousel-caption">
                            <p class="slider-content" (click)="openVerticallyCentered(content)">Nulla vitae elit
                                libero,
                                a
                                pharetra augue mollis interdum.</p>
                        </div>
                    </ng-template>
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                            <div class="bg-dark"></div>
                            <img [src]="images[1]" alt="Random second slide">
                        </div>
                        <div class="carousel-caption">
                            <p class="slider-content" (click)="openVerticallyCentered(content)">Lorem ipsum dolor
                                sit
                                amet,
                                consectetur adipiscing elit.</p>
                        </div>
                    </ng-template>
                    <ng-template ngbSlide>
                        <div class="picsum-img-wrapper">
                            <div class="bg-dark"></div>
                            <img [src]="images[2]" alt="Random third slide">
                        </div>
                        <div class="carousel-caption">
                            <p class="slider-content" (click)="openVerticallyCentered(content)">Praesent commodo
                                cursus
                                magna,
                                vel scelerisque nisl consectetur.</p>
                        </div>
                    </ng-template>


                    <ng-template #content let-modal>
                        <div class="modal-header">
                            <h4 class="modal-title">Supplier 360 Cockpit News Update</h4>
                            <button type="button" class="btn-close" aria-label="Close"
                                (click)="modal.dismiss('Cross click')"></button>
                        </div>
                        <div class="modal-body">
                            <img src="/assets/images/sample/repo/700.png" class="w-100" alt="online-repository">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                                incididunt
                                ut
                                labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                                ullamco
                                laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
                                reprehenderit
                                in
                                voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat
                                cupidatat
                                non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.&hellip;
                            </p>
                        </div>
                    </ng-template>
                </ngb-carousel>

            </div>
            <div class="col-lg-4">
                <div class="card rounded-0">
                    <div class="card-header border-0 bg-white">
                        <h3 class="task-heading">Pending Task</h3>
                    </div>
                    <div class="card-body bg-body-secondary">
                        <p>You have 1 contract waiting for signature.</p>
                        <p>New Record found in Action Plan.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="supplier-favourites">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <h4 class="main-heading">My Favourites</h4>
            </div>
        </div>
        <div class="row mx-5">
            <div class="col-12">
                <app-ssp-srm [portalTranslate]="portalTranslate" (isTileClicked)="srmTileClicked()"></app-ssp-srm>
            </div>
        </div>
    </div>
</div>


<div class="supplier-dkc">
    <div class="container">
        <div class="row mt-3">
            <div class="col-md-12">
                <h4 class="main-heading">Things you should know</h4>
            </div>
        </div>
        <div class="row mx-5 mt-5">
            <div class="col-lg-3">
                <div class="card" style="width: 18rem;">
                    <img src="/assets/images/temp-images/machine.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">Supplier Guidbook</h5>
                        <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card" style="width: 18rem;">
                    <img src="/assets/images/temp-images/cybersecurity.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">Anti Corruption</h5>
                        <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card" style="width: 18rem;">
                    <img src="/assets/images/temp-images/cyber.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">Cyber Security</h5>
                        <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        </p>
                    </div>
                </div>
            </div>
            <div class="col-lg-3">
                <div class="card" style="width: 18rem;">
                    <img src="/assets/images/temp-images/growth.png" class="card-img-top" alt="...">
                    <div class="card-body">
                        <h5 class="card-title">Get your knowledge <span class="underline">here</span></h5>
                        <p class="card-text">Lorem ipsum dolor sit amet, consectetur adipiscing elit
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>